<div class="container-fluid">
  <div id="basicProfileDetails">
    <nz-card *ngIf="machineryList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i>
        Machinery/Equipment/Spares (Required vs Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #machineryTable [nzData]="machineryList" nzTableLayout="fixed" nzShowSizeChanger nzBordered
          nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Name of Machinery/Equipment/Spares</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Purchase year</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of machineryTable.data">
              <tr>
                <td>{{ data.equipmentName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.purchaseYear }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="measuringInstrumentList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Measuring Instruments
        (Required vs Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #measuringInstrumentTable [nzData]="measuringInstrumentList" nzTableLayout="fixed" nzShowSizeChanger
          nzBordered nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Name of Measuring Instruments</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Purchase year</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of measuringInstrumentTable.data">
              <tr>
                <td>{{ data.measuringInsName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.purchaseYear }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="toolList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Tools (Required vs
        Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #toolTable [nzData]="toolList" nzTableLayout="fixed" nzShowSizeChanger nzBordered nzSize="middle"
          nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Name of Tools</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Purchase year</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of toolTable.data">
              <tr>
                <td>{{ data.toolsName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.purchaseYear }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="materialConsumableList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Materials/ Consumables
        (Required vs Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #materialConsumableTable [nzData]="materialConsumableList" nzTableLayout="fixed" nzShowSizeChanger
          nzBordered nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Name of Materials/ Consumables</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Purchase year</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of materialConsumableTable.data">
              <tr>
                <td>{{ data.materialConsumablesName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.purchaseYear }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="ppeList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Personal Protective
        Equipments (PPE): (Required vs Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #ppeTable [nzData]="ppeList" nzTableLayout="fixed" nzShowSizeChanger nzBordered nzSize="middle"
          nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Name of Personal Protective Equipments (PPE)</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Purchase year</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of ppeTable.data">
              <tr>
                <td>{{ data.ppeName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.purchaseYear }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="furnitureList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Furniture (Required vs
        Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #furnitureTable [nzData]="furnitureList" nzTableLayout="fixed" nzShowSizeChanger nzBordered
          nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Name of Furniture</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Purchase year</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of furnitureTable.data">
              <tr>
                <td>{{ data.furnitureName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.purchaseYear }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="learningMaterialList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Learning Materials
        (Required vs Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #learningMaterialTable [nzData]="learningMaterialList" nzTableLayout="fixed" nzShowSizeChanger
          nzBordered nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Name of Learning Materials</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Version or details</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of learningMaterialTable.data">
              <tr>
                <td>{{ data.learningMaterialsName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.versionOrDetails }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="trainingFacilitiyList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Training Facilities
        (Required vs Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #trainingFacilitiyTable [nzData]="trainingFacilitiyList" nzTableLayout="fixed" nzShowSizeChanger
          nzBordered nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Name of Training Facility</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Year of Construction</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of trainingFacilitiyTable.data">
              <tr>
                <td>{{ data.trainingFacilitiesName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.yearOfConstruction }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="trainingAndStaffList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Trainers and other staff
        (Required vs Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #trainingAndStaffTable [nzData]="trainingAndStaffList" nzTableLayout="fixed" nzShowSizeChanger
          nzBordered nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Position</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Qualification</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of trainingAndStaffTable.data">
              <tr>
                <td>{{ data.position }}</td>
                <td>{{ data.qualification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.qualification }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="otherRequirementList?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Other requirements
        (Required vs Available)
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #otherRequirementTable [nzData]="otherRequirementList" nzTableLayout="fixed" nzShowSizeChanger
          nzBordered nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
          <thead>
            <tr>
              <th>Item</th>
              <th>Specification</th>
              <th>Unit</th>
              <th>Minimum Quantity Required</th>
              <th>Available Quantity</th>
              <th>Description</th>
              <th>Remarks Made by STP</th>
              <th>Acceptable Quantity Found During Inspection</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of otherRequirementTable.data">
              <tr>
                <td>{{ data.itemName }}</td>
                <td>{{ data.specification }}</td>
                <td>{{ data.unit }}</td>
                <td>{{ data.minQuantityReq }}</td>
                <td>{{ data.availableQuantity }}</td>
                <td>{{ data.description }}</td>
                <td>{{ data.remarks }}</td>
                <td>{{ data.quantityFound }}</td>
                <td>{{ data.remarksByInspector }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="ccTvData?.length > 0">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> CC TV Info
      </div>
      <div class="searchboxAerarWithoutShadow">
        <nz-table #ccTvTable [nzData]="ccTvData" nzTableLayout="fixed" nzShowSizeChanger nzBordered nzSize="middle"
          nzAlign="middle" [nzFrontPagination]="false" *ngIf="ccTvData?.length>0">
          <thead>
            <tr>
              <th>Is there CCTV camera Available?</th>
              <th> Details (Number of Cameras and Locations)</th>
              <th>Renewal Application?</th>
              <th>Date of Expiry of Previous Approval</th>
              <th>Found by Inspector</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ccTvData of ccTvTable.data">

              <td>
                <ng-container
                  [ngTemplateOutlet]="(ccTvData?.isCcCameraAvailable==1) ? yes : ccTvData?.isCcCameraAvailable==0 ? no : na">
                </ng-container>

                <ng-template #yes>
                  Yes
                </ng-template>
                <ng-template #no>
                  No
                </ng-template>
                <ng-template #na>
                  N/A
                </ng-template>
              </td>
              <td>{{ ccTvData?.ccCameraDetails }}</td>
              <td>
                <ng-container
                  [ngTemplateOutlet]="(ccTvData?.isRenewalApplication==1) ? yes : ccTvData?.isRenewalApplication == 0 ? no : na">
                </ng-container>

                <ng-template #yes>
                  Yes
                </ng-template>
                <ng-template #no>
                  No
                </ng-template>
                <ng-template #na>
                  N/A
                </ng-template>
              </td>
              <td>{{ ccTvData.prevApprovalExpiryDate | date: 'dd-MM-yyyy' }}</td>
              <td>

                <ng-container
                  [ngTemplateOutlet]="(ccTvData?.isInspectorFoundCcCamera==1) ? yes : ccTvData?.isInspectorFoundCcCamera == 0 ? no : na">
                </ng-container>

                <ng-template #yes>
                  Yes
                </ng-template>
                <ng-template #no>
                  No
                </ng-template>
                <ng-template #na>
                  N/A
                </ng-template>
              </td>
              <td>
                {{ ccTvData?.remarksInspectorCcCamera}}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-card>

    <nz-card *ngIf="previewAttachments.length > 0">

      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Preview Attachments
      </div>

      <nz-table *ngIf="previewAttachments.length>0" [nzData]="previewAttachments" nzTableLayout="fixed"
        nzShowSizeChanger nzBordered nzSize="middle" nzAlign="middle" [nzFrontPagination]="false">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of previewAttachments; let i = index">
            <td>{{ item?.fileName }}</td>

            <td>
              <button nz-button type="button" nzType="default" [nzSize]="'small'"
                (click)="previewAllImage(i, item?.fileType)">

                <i nz-icon *ngIf="item.fileType=='image' ||item.fileType=='pdf'" nzType="eye" nzTheme="fill"></i>
                <i nz-icon *ngIf="item.fileType=='other'" nzType="file" nzTheme="fill"></i>
              </button>

            </td>
          </tr>
        </tbody>
      </nz-table>

    </nz-card>

    <nz-card *ngIf="!inspectionResultIdforSharedPreview">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Remarks
      </div>
      <div>
        <nz-descriptions nzBordered>
          <nz-descriptions-item nzSpan="3" nzTitle="Inspection Remarks for Course">
            {{ courseAccreditationData?.inspectorRemarksCourse ? courseAccreditationData?.inspectorRemarksCourse : 'NA' }}
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="3" nzTitle="Inspection Remarks for Assessment">
            {{ courseAccreditationData?.inspectorRemarksAssessment ? courseAccreditationData?.inspectorRemarksAssessment : 'NA' }}
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="3" nzTitle="Scrutiny Remarks for Course">
            {{ courseAccreditationData?.scrutinyFinalRemarksCourse ? courseAccreditationData?.scrutinyFinalRemarksCourse : 'NA' }}
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="3" nzTitle="Scrutiny Remarks for Assessment">
            {{ courseAccreditationData?.scrutinyFinalRemarksAssessment ? courseAccreditationData?.scrutinyFinalRemarksAssessment : 'NA' }}
          </nz-descriptions-item>
        </nz-descriptions>
      </div>
    </nz-card>

    <div class="row justify-content-center media-button mt-4" *ngIf="!inspectionResultIdforSharedPreview">
      <div class="col-xs-6 col-sm-3">
        <button type="button" class="btn btn-info active btn-lg btn-block cari border-redius"
          (click)="backToPreviousPage()" nz-button>
          <i nz-icon nzType="backward" nzTheme="fill"></i>Back to Previous Page
        </button>
      </div>
    </div>
  </div>
</div>

<nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" [nzClosable]="false" [nzOkText]="null"
  [nzWidth]="1200">
  <ng-container *nzModalContent>
    <iframe *ngIf="!isImageLoading && pdfUrl" [src]="pdfUrl" style="width: 100%; height: 35vw"></iframe>
    <div style="text-align: center">
      <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
      <ng-template #noImageFound>
        <img src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg" alt="Fallbackimage" />
      </ng-template>
    </div>
  </ng-container>
</nz-modal>