import { NAPChangePasswordComponent } from './components/nap-change-password/nap-change-password.component';
import { StpRegistrationComponent } from './components/stp-registration/stp-registration.component';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserVerificationComponent } from './components/user-verification/user-verification.component';
import { RegistrationCategoryComponent } from './components/registration-category/registration-category.component';
import { NapForgotPasswordComponent } from './components/nap-forgot-password/nap-forgot-password.component';
import { NapResetPasswordComponent } from './components/nap-reset-password/nap-reset-password.component';

const routes: Routes = [
  { path: 'user-verification', component: UserVerificationComponent },
  { path: 'register', component: StpRegistrationComponent },
  { path: 'registration-category', component: RegistrationCategoryComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:newToken', component: ResetPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'nap-forgot-password', component: NapForgotPasswordComponent },
  { path: 'nap-change-password', component: NAPChangePasswordComponent },
  { path: 'nap-reset-password/:id', component: NapResetPasswordComponent }
];

export const AuthenticationRoutes = RouterModule.forChild(routes);
