<app-header></app-header>
<div class="scrolling-container"
(mouseover)="onMouseOver()"
(mouseout)="onMouseOut()">
<div class="marquee"
>
  <ng-container
    *ngFor="
      let key of objectKeys(groupedFeedData);
      let mraIndex = index;
      let isLastMra = last
    "
  >
    <!-- MRA Subject with fallback logic -->
    <span style="color: Green ; cursor: pointer"
        class="rss-feed-item"
        nz-tooltip
        nzTooltipTitle="Click to view details"
        (click)="onClick(groupedFeedData[key].id)">
      {{
        groupedFeedData[key].subjectEnglish ||
        groupedFeedData[key].subjectBangla ||
        'No Subject Available'
      }}
    </span>
    <ng-container *ngIf="!isLastMra; else lastItem">,</ng-container>
    <ng-template #lastItem>.</ng-template>
    </ng-container>
  </div>
</div>

<div class="container-fluid">
  <div class="row info">
    <div class="col-lg-4 d-flex flex-row">
      <div class="p-2"><img src="assets/images/Group 228.svg" /></div>
      <div class="p-2 brant-text">
        <h4>{{ totalNoOfCADCount }}</h4>
        <a
          style="color: aliceblue"
          class="btn btn-outline-primary"
          (click)="onViewButtonClick(2)"
          routerLinkActive="router-link-active"
          target="_blank"
          >CAD</a
        >
      </div>
    </div>
    <div class="col-lg-4 d-flex flex-row">
      <div class="p-2"><img src="assets/images/Group 228.svg" /></div>
      <div class="p-2 brant-text">
        <h4>{{ totalNoOfCSCount }}</h4>
        <a
          style="color: aliceblue"
          class="btn btn-outline-primary"
          (click)="onViewButtonClick(1)"
          routerLinkActive="router-link-active"
          target="_blank"
          >CS</a
        >
      </div>
    </div>
    <div class="col-lg-4 d-flex flex-row">
      <div class="p-2"><img src="assets/images/Group 228.svg" /></div>
      <div class="p-2 brant-text">
        <h4>{{ totalNoOfCBLMCount }}</h4>
        <a
          style="color: aliceblue"
          class="btn btn-outline-primary"
          (click)="onViewButtonClick(4)"
          routerLinkActive="router-link-active"
          target="_blank"
          >CBLM</a
        >
      </div>
    </div>
  </div>

  <div class="p-4 card col-md-12 mt-4">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
      Description of MRA/MOU
    </div>
    {{ mraDescription }}
  </div>
  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="search" nzTheme="outline"></i>
      {{ "Search MRA / MOU" | translate }}
    </div>
    <form
      nz-form
      [formGroup]="searchForm"
      class="ant-advanced-search-form"
      (ngSubmit)="submitSearchForm()"
      style="
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      "
    >
      <div class="row">
        <div class="form-group col-md-4">
          <div class="col-md-12">
            <nz-form-label>{{ "Agreement Type" | translate }}</nz-form-label>
            <nz-form-item>
              <nz-form-control
                nzHasFeedback
                [nzErrorTip]="agreementTypeErrorTpl"
              >
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  formControlName="agreementTypeId"
                  nzPlaceHolder="Select one"
                >
                  <nz-option
                    *ngFor="let at of agreementType"
                    [nzValue]="at.id"
                    [nzLabel]="at.agreementType"
                  >
                  </nz-option>
                </nz-select>
                <ng-template #agreementTypeErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select type!
                  </ng-container>
                </ng-template>
              </nz-form-control>
              <ng-template #agreementTypeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Select type please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Select valid type please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="col-md-12">
            <nz-form-label>{{
              "Name of Organization" | translate
            }}</nz-form-label>
            <nz-form-item>
              <nz-form-control
                nzHasFeedback
                [nzErrorTip]="nameOfOrganizationErrorTpl"
              >
                <input
                  nz-input
                  type="text"
                  formControlName="nameOfOrganization"
                  placeholder="Enter organization name"
                />
              </nz-form-control>
              <ng-template #nameOfOrganizationErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Enter organization name please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Enter valid organization name please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="col-md-12">
            <nz-form-label>{{ "Country Name" | translate }}</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback [nzErrorTip]="countryNameErrorTpl">
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  formControlName="countryId"
                  nzPlaceHolder="Select one"
                >
                  <nz-option
                    *ngFor="let c of countries"
                    [nzValue]="c.id"
                    [nzLabel]="c.country"
                  >
                  </nz-option>
                </nz-select>
                <ng-template #countryNameErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select country!
                  </ng-container>
                </ng-template>
              </nz-form-control>
              <ng-template #countryNameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Select country please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Select valid country please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="col-md-12">
            <nz-form-label>Subject</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  type="text"
                  formControlName="subjectEnglish"
                  placeholder="Enter subject"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <!-- <div class="form-group col-md-4">
          <div class="col-md-12">
            <nz-form-label>{{ "Access Type" | translate }}</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback [nzErrorTip]="accessTypeErrorTpl">
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  formControlName="accessTypeId"
                  nzPlaceHolder="Select one"
                >
                  <nz-option
                    *ngFor="let at of accessType"
                    [nzValue]="at.id"
                    [nzLabel]="at.name"
                  >
                  </nz-option>
                </nz-select>
                <ng-template #accessTypeErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select type!
                  </ng-container>
                </ng-template>
              </nz-form-control>
              <ng-template #accessTypeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Select type please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Select valid type please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div> -->
        <div class="form-group col-md-4">
          <nz-form-label style="margin-left: 15px">{{
            "Signing Date From" | translate
          }}</nz-form-label>
          <div class="col-md-12">
            <nz-form-item>
              <nz-form-control
                [nzSpan]="null"
                nzHasFeedback
                nz-col
                [nzErrorTip]="signingDateFromErrorTpl"
              >
                <nz-date-picker
                  formControlName="signingDateFrom"
                  placeholder="From Date"
                  style="width: 100%"
                  nzFormat="dd-MM-yyyy"
                  [nzDisabledDate]="disabledFutureDate"
                >
                </nz-date-picker>
              </nz-form-control>
              <ng-template #signingDateFromErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Please select from date!
                </ng-container>
                <!-- <ng-container *ngIf="control.errors?.dateValidator">
                  Start date can not be greater than End Date
                </ng-container> -->
              </ng-template>
            </nz-form-item>
          </div>
        </div>
        <div class="form-group col-md-4">
          <nz-form-label style="margin-left: 15px">{{
            "Signing Date To" | translate
          }}</nz-form-label>
          <div class="col-md-12">
            <nz-form-item>
              <nz-form-control
                [nzSpan]="null"
                nzHasFeedback
                nz-col
                [nzErrorTip]="signingDateToErrorTpl"
              >
                <nz-date-picker
                  formControlName="signingDateTo"
                  placeholder="To Date"
                  style="width: 100%"
                  nzFormat="dd-MM-yyyy"
                  [nzDisabledDate]="disabledPastDate"
                >
                </nz-date-picker>
              </nz-form-control>
              <ng-template #signingDateToErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Please select to date!
                </ng-container>
              </ng-template>
            </nz-form-item>
          </div>
        </div>
      </div>

      <div nz-row>
        <div nz-col [nzSpan]="24" class="search-area text-right">
          <button nz-button [nzType]="'primary'">Search</button>
          <button class="btn-success ml-3" nz-button (click)="onRefresh()">
            Refresh Data
          </button>
        </div>
      </div>
    </form>
  </nz-card>

  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
      {{ "List of MRA / MOU" | translate }}
    </div>
    <div nz-col [nzSpan]="24">
      <nz-table
        #basicTableForMRAPublicListInfo
        [nzData]="mraPublicListInfo"
        nzTableLayout="fixed"
        nzShowSizeChanger
        nzBordered
        nzSize="middle"
        nzAlign="middle"
        [nzFrontPagination]="false"
        [nzTotal]="total"
        [(nzPageSize)]="size"
        [nzShowTotal]="totalRowRangeTemplate"
        [(nzPageIndex)]="page"
        (nzQueryParams)="onQueryParamsChange($event)"
        [nzPaginationPosition]="'both'"
        [nzScroll]="{ x: '1100px' }"
      >
        <ng-template
          #totalRowRangeTemplate
          let-range="range"
          let-total
          style="text-align: left"
        >
          <div style="text-align: left">
            Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
          </div>
        </ng-template>
        <thead>
          <tr>
            <th style="width: 50px; text-align: center" rowspan="1">SI.</th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="agreement_type_id"
              [nzSortFn]="true"
            >
              Agreement Type
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="subject_english"
              [nzSortFn]="true"
            >
              Subject
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="name_of_Organization_english"
              [nzSortFn]="true"
            >
              Name of Organization
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="country_id"
              [nzSortFn]="true"
            >
              Country Name
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="date_Of_signature"
              [nzSortFn]="true"
            >
              Date Of Signature
            </th>
            <th style="width: 15%; text-align: center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let data of basicTableForMRAPublicListInfo.data;
              let i = index
            "
          >
            <td style="text-align: center">
              {{ (page - 1) * size + 1 + i }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.agreementType?.agreementType }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.subjectEnglish }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.nameOfOrganizationEnglish }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.country?.country }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.dateOfSignature | date : "dd-MM-yyyy" }}
            </td>
            <td style="width: 15%; text-align: center">
              <a
                class="ml-2 mt-2"
                nz-tooltip
                nzTooltipTitle="View in Details"
                nzTooltipPlacement="bottomLeft"
                nz-button
                nzType="primary"
                [routerLink]="['/mra/mra-view-details-public']"
                [queryParams]="{ id: data.id }"
                type="submit"
                routerLinkActive="router-link-active"
                target="_blank"
                style="margin: 1%"
              >
                <i nz-icon nzType="eye" nzTheme="outline"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>
<!-- <nz-modal
      [(nzVisible)]="isVisible"
      (nzOnCancel)="handleCancel()"
      [nzClosable]="false"
      [nzOkText]="null"
      [nzWidth]="1200"
    >
      <ng-container *nzModalContent>
        <iframe
          *ngIf="!isImageLoading && pdfUrl"
          [src]="pdfUrl"
          style="width: 100%; height: 35vw"
        ></iframe>
        <div style="text-align: center">
          <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
          <ng-template #noImageFound>
            <img
              src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
              alt="Fallbackimage"
            />
          </ng-template>
        </div>
      </ng-container>
    </nz-modal> -->
<app-footer></app-footer>
