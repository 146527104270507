import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';

@Injectable({
  providedIn: 'root'
})
export class IndustrySkillsCouncilService {

  constructor(private httpClient: HttpClient) { }

  //#region Emitter
  previousButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  nextButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  basicInfoEmitter: EventEmitter<any> = new EventEmitter<any>();
  //#endregion Emitter

  getISCList(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.tamIndustrialSector.getAllTamIndustrialSectors}`);
  }

  getAllDivision(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllDivision}`);
  }

  getDistrictsByDivisionId(divisionId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getDistrictsByDivisionId}/${divisionId}`);
  }

  getUpazilasByDistrictId(districtId: number): Observable<any> {
    return this.httpClient.get<any>(
        `${applicationUrls.industrySkillsCouncil.getUpazilasByDistrictId}/${districtId}`);
  }

  getCityCorporationsByDistrictId(districtId: number): Observable<any> {
    return this.httpClient.get<any>(
        `${applicationUrls.industrySkillsCouncil.getCityCorporationsByDistrictId}/${districtId}`);
  }

  getAllCityCorporation(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllCityCorporation}`);
  }

  getAllDisburseBy(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllDisburseBy}`);
  }

  getAllIscStatusType(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllIscStatusType}`);
  }

  checkCEOEmailIfExist(ceoEmail: string): Observable<ServerResponse> {
    const params = new HttpParams().append('email', `${ceoEmail}`);
    return this.httpClient.get<ServerResponse>(`${applicationUrls.userVerification.checkEmailExist}`, {params})
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  checkAlternativeCEOEmailIfExist(alternativeCEOEmail: string): Observable<ServerResponse> {
    const params = new HttpParams().append('email', `${alternativeCEOEmail}`);
    return this.httpClient.get<ServerResponse>(`${applicationUrls.userVerification.checkEmailExist}`, {params})
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  checkIndustryNameIfExist(industryName: string): Observable<ServerResponse> {
    const params = new HttpParams().append('industryName', `${industryName}`);
    return this.httpClient.get<ServerResponse>(`${applicationUrls.tamIndustrialSector.checkIndustryNameExist}`, {params})
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  checkIndustryShortNameIfExist(industryShortName: string): Observable<ServerResponse> {
    const params = new HttpParams().append('industryShortName', `${industryShortName}`);
    return this.httpClient.get<ServerResponse>(`${applicationUrls.tamIndustrialSector.checkIndustryShortNameExist}`, {params})
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  saveTamIndustrialSector(tamIndustrialSector : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.tamIndustrialSector.saveTamIndustrialSector}`, tamIndustrialSector);
  }

  checkIfIscUserExists(industrySkillsCouncil : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.checkIfIscUserExists}`, industrySkillsCouncil);
  }

  saveIndustrySkillsCouncil(industrySkillsCouncil : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIndustrySkillsCouncil}`, industrySkillsCouncil);
  }

  createIscUser(industrySkillsCouncil : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.createIscUser}`, industrySkillsCouncil);
  }

  updateIscFocalPerson(iscUserList : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.updateIscFocalPerson}`, iscUserList);
  }

  //#region Emitter
  setBasicInfoData(data: any) {
    this.basicInfoEmitter.emit(data);
  }

  getBasicInfoData(): EventEmitter<any> {
    return this.basicInfoEmitter;
  }

  setPreviousButton(): void {
    this.previousButtonEmitter.emit(true);
  }

  getPreviousButton(): EventEmitter<boolean> {
    return this.previousButtonEmitter;
  }

  setNextButton(): void {
    this.nextButtonEmitter.emit(true);
  }

  getNextButton(): EventEmitter<boolean> {
    return this.nextButtonEmitter;
  }
  //#endregion

  getIscBasicInformation(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getIscBasicInformation}`);
  }

  getIscFocalPersons(iscId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getIscFocalPersons}/${iscId}`);
  }

  saveTempFile(fileSaveReq: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveTempFile}`, fileSaveReq);
  }

  deleteTempFile(fileDeleteReq: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.deleteTempFile}`, fileDeleteReq);
  }

  saveIscFullBasicInformation(iscBasicInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscFullBasicInformation}`, iscBasicInformation);
  }

  saveIscBasicInformation(iscBasicInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscBasicInformation}`, iscBasicInformation);
  }

  getVisionInfoByIscId(iscId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getVisionInfoByIscId}/${iscId}`);
  }

  saveIscVisionInformation(iscVisionInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscVisionInformation}`, iscVisionInformation);
  }

  getAllMissionByIscId(iscId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllMissionByIscId}/${iscId}`);
  }

  getAllMissionInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllMissionInfoByIscId}`, pageableRequestDTO);
  }

  getMissionInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getMissionInfoById}/${id}`);
  }

  saveIscMissionInformation(iscMissionInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscMissionInformation}`, iscMissionInformation);
  }

  deleteMissionInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteMissionInfoById}/${id}`);
  }

  updateMissionsSerialNo(missionsList : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.updateMissionsSerialNo}`, missionsList);
  }

  getAllBoardOfDirectorsByIscId(iscId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllBoardOfDirectorsByIscId}/${iscId}`);
  }

  getAllBoardOfDirectorsInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllBoardOfDirectorsInfoByIscId}`, pageableRequestDTO);
  }

  getBoardOfDirectorsInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getBoardOfDirectorsInfoById}/${id}`);
  }

  saveIscBoardOfDirectorsInformation(iscBoardOfDirectorsInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscBoardOfDirectorsInformation}`, iscBoardOfDirectorsInformation);
  }

  deleteBoardOfDirectorsInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteBoardOfDirectorsInfoById}/${id}`);
  }

  updateBoardOfDirectorsSerialNo(boardOfDirectorsList : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.updateBoardOfDirectorsSerialNo}`, boardOfDirectorsList);
  }

  getAllRelatedAssociationsInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllRelatedAssociationsInfoByIscId}`, pageableRequestDTO);
  }

  getRelatedAssociationsInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getRelatedAssociationsInfoById}/${id}`);
  }

  saveIscRelatedAssociationsInformation(iscRelatedAssociationsInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscRelatedAssociationsInformation}`, iscRelatedAssociationsInformation);
  }

  deleteRelatedAssociationsInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteRelatedAssociationsInfoById}/${id}`);
  }

  getAllSubSectorsByIscId(iscId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllSubSectorsByIscId}/${iscId}`);
  }

  getAllSubSectorsInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllSubSectorsInfoByIscId}`, pageableRequestDTO);
  }

  getSubSectorInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getSubSectorInfoById}/${id}`);
  }

  saveIscSubSectorInformation(iscSubSectorInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscSubSectorInformation}`, iscSubSectorInformation);
  }

  deleteSubSectorInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteSubSectorInfoById}/${id}`);
  }

  getTamMains(occupationName: string, tamType?: number): Observable<ServerResponse> {
    let url = applicationUrls.tamMain.readTamMains;

    if (occupationName && tamType) {
      url += '?occupationName=' + occupationName + '&tamType=' + tamType;
    } else if (occupationName) {
      url += '?occupationName=' + occupationName;
    } else if (tamType) {
      url += '?tamType=' + tamType;
    }

    return this.httpClient.get<ServerResponse>(url).pipe(
      map((serverResponse: ServerResponse) => {
        return serverResponse;
      })
    );
  }

  getAllDemandedOccupationsInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllDemandedOccupationsInfoByIscId}`, pageableRequestDTO);
  }

  getDemandedOccupationsInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getDemandedOccupationsInfoById}/${id}`);
  }

  saveIscDemandedOccupationsInformation(iscDemandedOccupationsInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscDemandedOccupationsInformation}`, iscDemandedOccupationsInformation);
  }

  deleteDemandedOccupationsInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteDemandedOccupationsInfoById}/${id}`);
  }

  getAllExistFutureManpowerDemandInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllExistFutureManpowerDemandInfoByIscId}`, pageableRequestDTO);
  }

  getExistFutureManpowerDemandInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getExistFutureManpowerDemandInfoById}/${id}`);
  }

  saveIscExistFutureManpowerDemandInformation(iscExistFutureManpowerDemandInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscExistFutureManpowerDemandInformation}`, iscExistFutureManpowerDemandInformation);
  }

  deleteExistFutureManpowerDemandInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteExistFutureManpowerDemandInfoById}/${id}`);
  }

  getAllJobOpportunitiesInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllJobOpportunitiesInfoByIscId}`, pageableRequestDTO);
  }

  getJobOpportunitiesInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getJobOpportunitiesInfoById}/${id}`);
  }

  saveIscJobOpportunitiesInformation(jobOpportunitiesInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscJobOpportunitiesInformation}`, jobOpportunitiesInformation);
  }

  deleteJobOpportunitiesInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteJobOpportunitiesInfoById}/${id}`);
  }

  getAllApprenticeshipInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllApprenticeshipInfoByIscId}`, pageableRequestDTO);
  }

  getApprenticeshipInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getApprenticeshipInfoById}/${id}`);
  }

  saveIscApprenticeshipInformation(apprenticeshipInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscApprenticeshipInformation}`, apprenticeshipInformation);
  }

  deleteApprenticeshipInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteApprenticeshipInfoById}/${id}`);
  }

  getAllAbledPwdMarginalizedInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllAbledPwdMarginalizedInfoByIscId}`, pageableRequestDTO);
  }

  getAbledPwdMarginalizedInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAbledPwdMarginalizedInfoById}/${id}`);
  }

  saveIscAbledPwdMarginalizedInformation(abledPwdMarginalizedInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscAbledPwdMarginalizedInformation}`, abledPwdMarginalizedInformation);
  }

  deleteAbledPwdMarginalizedInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteAbledPwdMarginalizedInfoById}/${id}`);
  }

  getAllActivityByIscId(iscId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllActivityByIscId}/${iscId}`);
  }

  getAllActivityInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllActivityInfoByIscId}`, pageableRequestDTO);
  }

  getActivityInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getActivityInfoById}/${id}`);
  }

  saveIscActivityInformation(activityInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscActivityInformation}`, activityInformation);
  }

  deleteActivityInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteActivityInfoById}/${id}`);
  }

  getAllBusinessPlanInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllBusinessPlanInfoByIscId}`, pageableRequestDTO);
  }

  getBusinessPlanInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getBusinessPlanInfoById}/${id}`);
  }

  saveIscBusinessPlanInformation(businessPlanInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscBusinessPlanInformation}`, businessPlanInformation);
  }

  deleteBusinessPlanInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteBusinessPlanInfoById}/${id}`);
  }

  getAllAchievementInfoByIscId(iscId: number, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      iscId: iscId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllAchievementInfoByIscId}`, pageableRequestDTO);
  }

  getAchievementInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAchievementInfoById}/${id}`);
  }

  saveIscAchievementInformation(achievementInformation : any): Observable<any>{
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.saveIscAchievementInformation}`, achievementInformation);
  }

  deleteAchievementInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${applicationUrls.industrySkillsCouncil.deleteAchievementInfoById}/${id}`);
  }

  submitIscFullApplication(submitIscFullApplication: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.submitIscFullApplication}`, submitIscFullApplication);
  }

  getAllNsdaIscList(dataSet: any, page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      pageNumber: page,
      pageSize: size,
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null,
      iscNameId: dataSet?.iscNameId,
      email: dataSet?.email,
      contactNo: dataSet?.contactNo,
      registrationNo: dataSet?.registrationNo,
      licenseNo: dataSet?.licenseNo,
      applicationStatusId: dataSet?.applicationStatusId
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllNsdaIscList}`, pageableRequestDTO);
  }

  getAllNsdaIscListExcel(dataSet: any): Observable<any> {
    let pageableRequestDTO = {
      iscNameId: dataSet?.iscNameId,
      email: dataSet?.email,
      contactNo: dataSet?.contactNo,
      registrationNo: dataSet?.registrationNo,
      licenseNo: dataSet?.licenseNo,
      applicationStatusId: dataSet?.applicationStatusId
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getAllNsdaIscListExcel}`, pageableRequestDTO);
  }

  getAllIscDashboardCount(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getAllIscDashboardCount}`);
  }

  publishIsc(id: number): Observable<any> {
    return this.httpClient.put<any>(`${applicationUrls.industrySkillsCouncil.publishIsc}?id=${id}`, {});
  }

  unpublishIsc(id: number): Observable<any> {
    return this.httpClient.put<any>(`${applicationUrls.industrySkillsCouncil.unpublishIsc}?id=${id}`, {});
  }

  getIscBasicInformationByIscId(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getIscBasicInformationByIscId}/${id}`);
  }

  getIscDetailInfoByIscId(iscId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getIscDetailInfoByIscId}/${iscId}`);
  }

  needCorrectionRepresentative(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.needCorrectionRepresentative}`, iscApprovalForm);
  }

  submitApprovalRepresentative(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.submitApprovalRepresentative}`, iscApprovalForm);
  }

  rollbackScrutiny(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.rollbackScrutiny}`, iscApprovalForm);
  }

  submitApprovalScrutiny(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.submitApprovalScrutiny}`, iscApprovalForm);
  }

  rollbackMember(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.rollbackMember}`, iscApprovalForm);
  }

  submitApprovalMember(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.submitApprovalMember}`, iscApprovalForm);
  }

  approveMember(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.approveMember}`, iscApprovalForm);
  }

  rejectMember(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.rejectMember}`, iscApprovalForm);
  }

  rollbackEC(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.rollbackEC}`, iscApprovalForm);
  }

  approveEC(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.approveEC}`, iscApprovalForm);
  }

  rejectEC(iscApprovalForm: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.rejectEC}`, iscApprovalForm);
  }

  getRepresentativeIscList(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getRepresentativeIscList}`);
  }

  getPublishedIscList(page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    let pageableRequestDTO = {
      pageNumber: page,
      pageSize: size,
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null
    };
    return this.httpClient.post<any>(`${applicationUrls.industrySkillsCouncil.getPublishedIscList}`, pageableRequestDTO);
  }

  getPublishedIscDirectorListByIscId(iscId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getPublishedIscDirectorListByIscId}/${iscId}`);
  }

  getIscRecommendationHistoryListByIscId(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getIscRecommendationHistoryListByIscId}/${id}`);
  }

  getIscApplicationHistoryListByIscId(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.industrySkillsCouncil.getIscApplicationHistoryListByIscId}/${id}`);
  }
}
