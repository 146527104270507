<app-header></app-header>
<main>
  <div class="marketing">
    <section class="form-section">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-lg-12 col-xl-11">
            <div class="card card-registration my-4" style="border: 0px">
              <div class="row g-0 mt-5 mb-5">
                <div class="container text-center both-space">
                  <h3>Please Select Your Registration Category</h3>
                  <div class="row mt-5" style="justify-content: center;">

                    <div class="col-lg-3 div-hover">
                      <div class="col-lg-12 rectangle mt-3">
                        <img src="assets/images/Group 159.svg" />
                        <div class="mt-3">
                          <div class="box-title">
                            Skills Training Providers (STP) Registration
                          </div>
                          <a type="" class="btn btn-outline-primary rectanlge-btn-style mt-3"
                            routerLink="/register">Click To Apply</a>

                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 div-hover">
                      <div class="col-lg-12 rectangle mt-3">
                        <img src="assets/images/Group 179.svg" />
                        <div class="mt-3">
                          <div class="box-title">
                            Individual for Assessment
                          </div>
                          <a type="" class="btn btn-outline-primary rectanlge-btn-style mt-3"
                            routerLink="/applicant-register">Click To Apply</a>

                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 div-hover">
                      <div class="col-lg-12 rectangle mt-3">
                        <img src="assets/images/Group 179.svg" />
                        <div class="mt-3">
                          <div class="box-title">
                            Assessors & Trainers
                          </div>
                          <a type="" class="btn btn-outline-primary rectanlge-btn-style mt-3"
                            routerLink="/assessor-register">Click To Apply</a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
<app-footer></app-footer>