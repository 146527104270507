import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TciInfoDto } from '../DTO/tciInfoDto.model';

@Injectable({
  providedIn: 'root'
})
export class TciInfoService {
  onRefreshTciInfoList = new Subject<TciInfoDto[]>();
  tciInfoList: TciInfoDto [] = [];
  private subject = new Subject<any>();
constructor() { }

setTciInfoList(tciInfoDtos: TciInfoDto[]): void {
  this.tciInfoList = [...this.tciInfoList, ...tciInfoDtos];
  this.onRefreshTciInfoList.next(this.tciInfoList.slice());
}

  setTciInfo(): void {
    this.tciInfoList = [];
    this.onRefreshTciInfoList.next(this.tciInfoList.slice());
  }

  tciInfoSaveClickEvent(){
    this.subject.next();
  }

  // for get ProjectSummary Save Event
  // @ts-ignore
  getTciInfoSaveEvent():Observable<any>{
    return this.subject.asObservable();
  }

getTciInfos(): TciInfoDto[] {
    return this.tciInfoList;
}

}
