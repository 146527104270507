<app-header></app-header>
<div class="container-fluid">
  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="search" nzTheme="outline"></i> Search
    </div>
    <form
      nz-form
      [formGroup]="searchForm"
      class="ant-advanced-search-form"
      (ngSubmit)="submitSearchForm()"
      style="
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      "
    >
      <div class="row">
        <div class="col-md-4">
          <nz-form-label style="margin-left: 15px"
            >Research Start Date</nz-form-label
          >
          <div class="col-md-12">
            <nz-form-item>
              <nz-form-control
                [nzSpan]="null"
                nzHasFeedback
                nz-col
                nzErrorTip="Please insert valid Date"
              >
                <nz-date-picker
                  formControlName="researchFromDate"
                  placeholder="From Date"
                  style="width: 100%"
                >
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="col-md-4">
          <nz-form-label style="margin-left: 15px"
            >Research End Date</nz-form-label
          >
          <div class="col-md-12">
            <nz-form-item>
              <nz-form-control
                [nzSpan]="null"
                nzHasFeedback
                nz-col
                nzErrorTip="Please insert valid Date"
              >
                <nz-date-picker
                  formControlName="researchToDate"
                  placeholder="to Date"
                  style="width: 100%"
                >
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-12">
            <nz-form-label>Document ID</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please insert valid document id">
                <input
                  nz-input
                  type="text"
                  placeholder="Document ID"
                  formControlName="documentId"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-12">
            <nz-form-label>Research Title</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please insert valid title">
                <input
                  nz-input
                  type="text"
                  placeholder="Title"
                  formControlName="title"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="col-md-12">
            <div class="row">
              <div style="margin-left: 15px">
                <nz-form-label>Research Type</nz-form-label>
              </div>
            </div>
            <nz-form-item>
              <nz-form-control nzHasFeedback [nzErrorTip]="rtNameErrorTpl">
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  formControlName="researchTypeId"
                  nzPlaceHolder="Select type"
                >
                  <nz-option
                    *ngFor="let rt of researchTypeList"
                    [nzValue]="rt.id"
                    [nzLabel]="rt.name"
                  >
                  </nz-option>
                </nz-select>
                <ng-template #rtNameErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select type !
                  </ng-container>
                </ng-template>
              </nz-form-control>
              <ng-template #rtNameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Insert title please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Insert valid title please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div>
      </div>

      <div nz-row>
        <div nz-col [nzSpan]="24" class="search-area text-right">
          <button nz-button [nzType]="'primary'">Search</button>
          <button class="btn-success ml-3" nz-button (click)="onRefresh()">
            Refresh Data
          </button>
        </div>
      </div>
    </form>
  </nz-card>

  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> List of Research
      & Studies
    </div>
    <div nz-col [nzSpan]="24">
      <nz-table
        #basicTable
        [nzData]="adminListInfo"
        nzTableLayout="fixed"
        nzShowSizeChanger
        nzBordered
        nzSize="middle"
        nzAlign="middle"
        [nzFrontPagination]="false"
        [nzTotal]="total"
        [(nzPageSize)]="size"
        [nzShowTotal]="totalRowRangeTemplate"
        [(nzPageIndex)]="page"
        (nzQueryParams)="onQueryParamsChange($event)"
        [nzPaginationPosition]="'both'"
        [nzScroll]="{ x: '1000px' }"
      >
        <ng-template
          #totalRowRangeTemplate
          let-range="range"
          let-total
          style="text-align: left"
        >
          <div style="text-align: left">
            Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
          </div>
        </ng-template>
        <thead>
          <tr>
            <th style="width: 50px; text-align: center" rowspan="1">SI.</th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="document_id"
              [nzSortFn]="true"
            >
              Document ID
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="title"
              [nzSortFn]="true"
            >
              Title
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="research_type_id"
              [nzSortFn]="true"
            >
              Research Type
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="research_from_date"
              [nzSortFn]="true"
            >
              Research Start Date
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="research_to_date"
              [nzSortFn]="true"
            >
              Research End Date
            </th>
            <th style="width: 15%; text-align: center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i = index">
            <td style="text-align: center">
              {{ (page - 1) * size + 1 + i }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.documentId }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.title }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.researchType.name }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.researchFromDate | date : "dd-MM-yyyy" }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.researchToDate | date : "dd-MM-yyyy" }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              <a
                class="ml-2 mt-2"
                nz-tooltip
                nzTooltipTitle="View in Details"
                nzTooltipPlacement="bottomLeft"
                nz-button
                nzType="primary"
                (click)="onViewButtonClick(data.id)"
                routerLinkActive="router-link-active"
                style="margin: 1%"
              >
                <i nz-icon nzType="eye" nzTheme="outline"></i>
              </a>

              <a
                class="ml-2 mt-2"
                nz-tooltip
                nzTooltipTitle="View Documents"
                nzTooltipPlacement="bottomLeft"
                nz-button
                nzType="primary"
                target="_blank"
                [routerLink]="['/research-and-studies/document-list-public']"
                target="_blank"
                routerLinkActive="router-link-active"
                style="margin-right: 5px"
              >
                <i nz-icon nzType="file-pdf" nzTheme="outline"></i>
              </a>

              <a
                *ngIf="
                  data.isOpinionOpenToCollect == true &&
                  checkIsOpinionOpenToCollect(data?.opinionEndDate) == 0
                "
                class="ml-2 mt-2"
                nz-tooltip
                nzTooltipTitle="Write your Opinion"
                nzTooltipPlacement="bottomLeft"
                nz-button
                nzType="primary"
                (click)="showModalForOpinion(data.id)"
                target="_blank"
                routerLinkActive="router-link-active"
                style="margin-right: 5px"
              >
                <i nz-icon nzType="form" nzTheme="outline"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>

<nz-modal
  [(nzVisible)]="isVisibleOpinion"
  (nzOnCancel)="handleCancelOpinionModal()"
  [nzWidth]="1100"
>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="form" nzTheme="outline"></i> Write your Opinion
  </div>
  <div class="searchboxAerar pt-2">
    <form nz-form [formGroup]="opinionForm">
      <div class="row pt-4">
        <div class="col-md-6 margin-bottom">
          <nz-form-label class="col-md-12" style="text-align: left" nzRequired
            >Name</nz-form-label
          >
          <nz-form-item class="col-md-12">
            <nz-form-control nzHasFeedback [nzErrorTip]="nameErrorTpl">
              <input
                nz-input
                type="text"
                formControlName="name"
                placeholder="Name"
              />
            </nz-form-control>
            <ng-template #nameErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Insert name please!
              </ng-container>
              <ng-container *ngIf="control.hasError('pattern')"
                >Insert valid name please!
              </ng-container>
            </ng-template>
          </nz-form-item>
        </div>

        <div class="col-md-6 margin-bottom">
          <nz-form-label class="col-md-12" style="text-align: left"
            >Designation</nz-form-label
          >
          <nz-form-item class="col-md-12">
            <nz-form-control nzHasFeedback [nzErrorTip]="designationErrorTpl">
              <input
                nz-input
                type="text"
                formControlName="designation"
                placeholder="Designation"
              />
            </nz-form-control>
            <ng-template #designationErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Insert designation please!
              </ng-container>
              <ng-container *ngIf="control.hasError('pattern')"
                >Insert valid designation please!
              </ng-container>
            </ng-template>
          </nz-form-item>
        </div>

        <div class="col-md-6 margin-bottom">
          <nz-form-label class="col-md-12" style="text-align: left" nzRequired
            >Contact No.</nz-form-label
          >
          <nz-form-item class="col-md-12">
            <nz-form-control nzHasFeedback [nzErrorTip]="contactErrorTpl">
              <input
                nz-input
                formControlName="contactNo"
                placeholder="Contact No."
              />
            </nz-form-control>
            <ng-template #contactErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Insert contact no. please!
              </ng-container>
              <ng-container *ngIf="control.hasError('pattern')"
                >Insert valid contact no whitch is start with 01XXXXXXXXX
                please!
              </ng-container>
            </ng-template>
          </nz-form-item>
        </div>

        <div class="col-md-6 margin-bottom">
          <nz-form-label class="col-md-12" style="text-align: left" nzRequired
            >Mail</nz-form-label
          >
          <nz-form-item class="col-md-12">
            <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
              <input
                nz-input
                type="email"
                formControlName="email"
                placeholder="E-Mail"
              />
            </nz-form-control>
            <ng-template #emailErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Insert email please!
              </ng-container>
              <ng-container *ngIf="control.hasError('pattern')"
                >Insert valid email please!
              </ng-container>
            </ng-template>
          </nz-form-item>
        </div>

        <div class="col-md-12 mt-2">
          <nz-form-label class="col-md-12" style="text-align: left" nzRequired
            >Opinion</nz-form-label
          >
          <nz-form-item class="col-md-12">
            <nz-form-control nzHasFeedback [nzErrorTip]="opinionErrorTpl">
              <textarea
                nz-input
                id=""
                placeholder="Type your opinion here"
                formControlName="opinion"
                [nzAutosize]="{ minRows: 4, maxRows: 10 }"
              ></textarea>
            </nz-form-control>
            <ng-template #opinionErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Insert opinion please!
              </ng-container>
              <ng-container *ngIf="control.hasError('pattern')"
                >Avoid white space with empty character please!
              </ng-container>
            </ng-template>
          </nz-form-item>
        </div>

        <div class="col-md-12 text-center margin-bottom">
          <button
            nz-button
            nzType="primary"
            (click)="saveOpinion()"
            [disabled]="!opinionForm.valid"
            nzShape="round"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="handleCancelOpinionModal()">
      Close
    </button>
  </div>
</nz-modal>
<app-footer></app-footer>
