import { Injectable } from '@angular/core';
import { CircularPreventedOrg } from '../models/DTO/circular-prevented-org';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { CircularService } from './circular.service';
import { CircularApplicableOccupations } from '../models/DTO/circular-applicable-occupations';
import { Observable } from 'rxjs';
import { fileDeleteRequest } from '../models/DTO/fileDeleteRequest';
import { CircularInfo } from '../models/circular-info.model';
import { CircularInfoSearch } from '../models/DTO/circular-info-search';

@Injectable({
  providedIn: 'root',
})
export class CircularStorageService {
  constructor(
    private httpClient: HttpClient,
    private circularSirvice: CircularService
  ) {}

  checkFinancingMainByCircularId(circularId: number): Observable<any> {
    const params = new HttpParams().append('circularId', `${circularId}`);
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingMain.checkFinancingMainByCircularId}`,
      { params }
    );
  }

  savePreventedOrg(circularPreventedOrg: CircularPreventedOrg) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.finance.savePreventedOrg}`,
        circularPreventedOrg
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  updatePreventedOrg(circularPreventedOrg: CircularPreventedOrg) {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.finance.updatePreventedOrg}` +
          circularPreventedOrg.circularId,
        circularPreventedOrg
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.circularSirvice.updatePreventedOrg(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  saveApplicableOccupations(
    circularApplicableOccupations: CircularApplicableOccupations
  ) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.finance.saveApplicableOccupations}`,
        circularApplicableOccupations
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  updateApplicableOccupations(
    circularApplicableOccupations: CircularApplicableOccupations
  ) {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.finance.updateApplicableOccupations}` +
          circularApplicableOccupations.circularId,
        circularApplicableOccupations
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.circularSirvice.updateApplicableOccupations(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  deleteAttachment(data: fileDeleteRequest): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.deleteAttachment}`,
      data
    );
  }

  updateAttachment(
    circularId: number | null,
    fileList: File[]
  ): Observable<ServerResponse> {
    const formData: FormData = new FormData();
    fileList.forEach((item: File, index: number) => {
      formData.append('file', item);
    });
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.finance.updateAttachments}` + circularId,
      formData
    );
  }

  saveAttachment(
    circularId: number,
    fileList: File[],
    attachmentSaveUpdateStatus: string
  ): Observable<ServerResponse> {
    const formData: FormData = new FormData();
  
    fileList.forEach((item: File, index: number) => {
      // Append each file with a unique key (optional, but avoids conflicts)
      formData.append('file', item);
    });
  
    // Append other data
    formData.append('circularId', circularId.toString());
    formData.append('attachmentSaveUpdateStatus', attachmentSaveUpdateStatus);
  
    // Post request to save attachments
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.saveAttachments}`,
      formData
    );
  }

  readCirApplicantType(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.finance.circularApplicantTypeInfo}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readCirOrganizationType(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.finance.circularOrganizationTypeInfo}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  saveCircularInfo(circularInfo: CircularInfo): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.saveCircularInfo}`,
      circularInfo
    );
  }

  updateCircularInfo(
    circularInfo: any,
    id: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.finance.updateCircularInfo}` + id,
      circularInfo
    );
  }

  readCircularInfo(
    circularInfoSearch: CircularInfoSearch
  ): Observable<ServerResponse> {
    circularInfoSearch.disabled = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.getCircularInfoList}`,
      circularInfoSearch
    );
  }

  readCircularPublicInfo(
    circularInfoSearch: CircularInfoSearch
  ): Observable<ServerResponse> {
    circularInfoSearch.disabled = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.getCircularPublicListInfo}`,
      circularInfoSearch
    );
  }

  deleteCircular(circularId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.finance.deleteCircular}`,
      {
        circularId: circularId,
      }
    );
  }

  getCircularDataByGlobalSearch(
    circularInfoSearch: CircularInfoSearch
  ): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.getCircularDataByGlobalSearch}`,
      circularInfoSearch
    );
  }

  getCircularInfoById(circularId: number): Observable<any> {
    const params = new HttpParams().append('circularId', `${circularId}`);
    return this.httpClient.get<any>(
      `${applicationUrls.finance.getCircularInfoById}`,
      { params }
    );
  }

  getCircularPublicInfoById(circularId: number): Observable<ServerResponse> {
    const params = new HttpParams().append('circularId', `${circularId}`);
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.finance.getCircularPublicInfoById}`,
      { params }
    );
  }

  removeCirPreventedOrg(
    circularId: number,
    stpMainId: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.finance.removeCirPreOrg}`,
      {
        circularId: circularId,
        stpMainId: stpMainId,
      }
    );
  }

  removeCirApplicableOccupations(
    circularId: number,
    tamMainId: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.finance.removeCirAppOccupations}`,
      {
        circularId: circularId,
        tamMainId: tamMainId,
      }
    );
  }

  submitCircular(
    circularId: any,
    isSavedAllInfo?: boolean
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.finance.submitCircular}`,
      {
        circularId: circularId,
        isSavedAllInfo: isSavedAllInfo,
      }
    );
  }

  updateCircularExpenseSectorHead(
    expenceSector: FormData,
    id: number | null
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.finance.updateCircularExpenseSectorHead}` + id,
      expenceSector
    );
  }

  saveCircularExpenseSectorHead(
    expenceSector: FormData
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.finance.saveCircularExpenseSectorHead}`,
        expenceSector
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  getStpMainInfo(): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.stpInformation.getStpMainInfo}`
    );
  }

  getCircularPreventedOrgInfoByStpMainIdAndCircularId(
    stpMainId: number,
    circularId: number
  ): Observable<ServerResponse> {
    const params = new HttpParams()
      .append('stpMainId', `${stpMainId}`)
      .append('circularId', `${circularId}`);
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.finance.getCircularPreventedOrgInfoByStpMainIdAndCircularId}`,
      { params }
    );
  }

  getApprovedTamInfoListByStpAndCourseCenterApprovalDate(
    stpMainId: number,
    stpExpiryDate: any,
    courseCenterExpiryDate: any
  ): Observable<any> {
    const params = new HttpParams()
      .append('stpMainId', `${stpMainId}`)
      .append('stpExpiryDate', `${stpExpiryDate}`)
      .append('courseCenterExpiryDate', `${courseCenterExpiryDate}`);
    return this.httpClient.get<any>(
      `${applicationUrls.inspection.getApprovedTamInfoListByStpAndCourseCenterApprovalDate}`,
      { params }
    );
  }

  getTamMains(
    occupationName: string,
    tamType?: number
  ): Observable<ServerResponse> {
    let url = applicationUrls.tamMain.readTamMains;

    if (occupationName && tamType) {
      url += '?occupationName=' + occupationName + '&tamType=' + tamType;
    } else if (occupationName) {
      url += '?occupationName=' + occupationName;
    } else if (tamType) {
      url += '?tamType=' + tamType;
    }

    return this.httpClient.get<ServerResponse>(url).pipe(
      map((serverResponse: ServerResponse) => {
        return serverResponse;
      })
    );
  }

  //#region download circular report
  onDownloadCircularReport(
    circularInfoSearch: CircularInfoSearch
  ): Observable<ServerResponse> {
    circularInfoSearch.disabled = true;
    return this.httpClient
      .post<ServerResponse>(
        applicationUrls.finance.downloadCircularReport,
        circularInfoSearch
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.circularSirvice.setCircularReport(response.data);
          }
        })
      );
  }
}
