import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { CadEquipment } from '../models/cad-equipment.model';
import { CourseAccreditationDocumentService } from './course-accreditation-document.service';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { CadFurniture } from '../models/cad-furniture.model';
import { CadMeasuringInstrument } from '../models/cad-measuring-instrument.model';
import { CadTool } from '../models/cad-tool.model';
import { CadMaterialConsumable } from '../models/cad-material-consumable.model';
import { CadPpe } from '../models/cad-ppe.model';
import { CadLearningMaterial } from '../models/cad-learning-material.model';
import { CadTrainingFacility } from '../models/cad-training-facility.model';
import { CadTrainerAndStaff } from '../models/cad-trainer-and-staff.model';
import { CadOtherRequirement } from '../models/cad-other-requirement.model';

@Injectable({
  providedIn: 'root'
})
export class CourseAccreditationDocumentStorageService {
  // public isStpMainAlreadyCreated = false;

  constructor(
    private cadService: CourseAccreditationDocumentService,
    private httpClient: HttpClient,
  ) { }

  
  //#region Cad Equipment
  createCadEquipment(cadEquipment: CadEquipment): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadEquipment.create}`,
        cadEquipment
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadEquipment(
              res.data
            );
          }
        })
      );
  }

  readCadEquipmentListByTamId(tamMainId: number): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadEquipment.readByTamId}` + tamMainId   )
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadEquipment[]) => {
      //     this.cadService.setCadEquipmentList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadEquipmentList(response.data);
          }
        })
      );
  }

  updateCadEquipment(data: CadEquipment, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadEquipment.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadEquipment(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
  
  deleteCadEquipment(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadEquipment.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadEquipment(id);
        })
      );
  }
 //#endregion


  //#region Cad Equipment
  createCadFurniture(cadFurniture: CadFurniture): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadFurniture.create}`,
        cadFurniture
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadFurniture(
              res.data
            );
          }
        })
      );
  }

  readCadFurnitureListByTamId(tamMainId: number, page: number,
    size: number): Observable<ServerResponse> {

    const startPage = page - 1;

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadFurniture.readByTamId}` + tamMainId )
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadFurniture[]) => {
      //     this.cadService.setCadFurnitureList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadFurnitureList(response.data);
          }
        })
      );
  }
      
  deleteCadFurniture(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadFurniture.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadFurniture(id);
        })
      );
  }
  
  updateCadFurniture(data: CadFurniture, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadFurniture.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadFurniture(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

 //#region Cad MeasuringInstrument
  createCadMeasuringInstrument(cadData: CadMeasuringInstrument): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadMeasuringInstrument.create}`,
        cadData
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadMeasuringInstrument(
              res.data
            );
          }
        })
      );
  }

  readCadMeasuringInstrumentListByTamId(tamMainId: number, page: number,
    size: number): Observable<ServerResponse> {

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadMeasuringInstrument.readByTamId}` + tamMainId   )
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadMeasuringInstrument[]) => {
      //     this.cadService.setCadMeasuringInstrumentList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadMeasuringInstrumentList(response.data);
          }
        })
      );
  }
        
  deleteCadMeasuringInstrument(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadMeasuringInstrument.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadMeasuringInstrument(id);
        })
      );
  }

    
  updateCadMeasuringInstrument(data: CadMeasuringInstrument, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadMeasuringInstrument.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadMeasuringInstrument(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

 //#region CadTool
  createCadTool(cadData: CadTool): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadTool.create}`,
        cadData
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadTool(
              res.data
            );
          }
        })
      );
  }

  readCadToolListByTamId(tamMainId: number, page: number, size: number): Observable<ServerResponse> {
    const startPage = page - 1;

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadTool.readByTamId}` + tamMainId )
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadTool[]) => {
      //     this.cadService.setCadToolList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadToolList(response.data);
          }
        })
      );
  }
          
  deleteCadTool(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadTool.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadTool(id);
        })
      );
  }   

  updateCadTool(data: CadTool, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadTool.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadTool(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

 //#region Cad Tool
  createCadMaterialConsumable(cadData: CadTool): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadMaterialConsumable.create}`,
        cadData
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadMaterialConsumable(
              res.data
            );
          }
        })
      );
  }

  readCadMaterialConsumableListByTamId(tamMainId: number, page: number,
    size: number): Observable<ServerResponse> {
      const startPage = page - 1;

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadMaterialConsumable.readByTamId}` + tamMainId  )
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadTool[]) => {
      //     this.cadService.setCadMaterialConsumableList(cadList);
      //   })
      // );

      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadMaterialConsumableList(response.data);
          }
        })
      );
  }
   
  deleteCadMaterialConsumable(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadMaterialConsumable.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadMaterialConsumable(id);
        })
      );
  }
  
  updateCadMaterialConsumable(data: CadMaterialConsumable, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadMaterialConsumable.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadMaterialConsumable(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

 //#region Cad CadPpe
  createCadPpe(cadData: CadPpe): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadPpe.create}`,
        cadData
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadPpe(
              res.data
            );
          }
        })
      );
  }

  readCadPpeListByTamId(tamMainId: number, page: number,
    size: number): Observable<ServerResponse> {
      const startPage = page - 1;

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadPpe.readByTamId}` + tamMainId )
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadPpe[]) => {
      //     this.cadService.setCadPpeList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadPpeList(response.data);
          }
        })
      );
  }
              
  deleteCadPpe(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadPpe.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadPpe(id);
        })
      );
  }

  updateCadPpe(data: CadPpe, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadPpe.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadPpe(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

 //#region Cad CadLearningMaterial
  createCadLearningMaterial(cadData: CadLearningMaterial): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadLearningMaterial.create}`,
        cadData
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadLearningMaterial(
              res.data
            );
          }
        })
      );
  }

  readCadLearningMaterialListByTamId(tamMainId: number, page: number,
    size: number): Observable<ServerResponse> {

      const startPage = page - 1;

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadLearningMaterial.readByTamId}` + tamMainId  )
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadLearningMaterial[]) => {
      //     this.cadService.setCadLearningMaterialList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadLearningMaterialList(response.data);
          }
        })
      );
  } 

  deleteCadLearningMaterial(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadLearningMaterial.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadLearningMaterial(id);
        })
      );
  }

  updateCadLearningMaterial(data: CadLearningMaterial, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadLearningMaterial.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadLearningMaterial(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

 //#region Cad CadTrainingFacility
  createCadTrainingFacility(cadData: CadTrainingFacility): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadTrainingFacility.create}`,
        cadData
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadTrainingFacility(
              res.data
            );
          }
        })
      );
  }

  readCadTrainingFacilityListByTamId(tamMainId: number, page: number,
    size: number): Observable<ServerResponse> {
      const startPage = page - 1;

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadTrainingFacility.readByTamId}` + tamMainId)
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadTrainingFacility[]) => {
      //     this.cadService.setCadTrainingFacilityList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadTrainingFacilityList(response.data);
          }
        })
      );
  }

  deleteCadTrainingFacility(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadTrainingFacility.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadTrainingFacility(id);
        })
      );
  }

  updateCadTrainingFacility(data: CadTrainingFacility, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadTrainingFacility.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadTrainingFacility(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

 //#region Cad CadTrainerAndStaff
  createCadTrainerAndStaff(cadData: CadTrainerAndStaff): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadTrainerAndStaff.create}`,
        cadData
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadTrainerAndStaff(
              res.data
            );
          }
        })
      );
  }

  readCadTrainerAndStaffListByTamId(tamMainId: number, page: number,
    size: number): Observable<ServerResponse> {

      const startPage = page - 1;

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadTrainerAndStaff.readByTamId}` + tamMainId )
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadTrainerAndStaff[]) => {
      //     this.cadService.setCadTrainerAndStaffList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadTrainerAndStaffList(response.data);
          }
        })
      );
  }
    
  deleteCadTrainerAndStaff(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadTrainerAndStaff.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadTrainerAndStaff(id);
        })
      );
  }

  updateCadTrainerAndStaff(data: CadTrainerAndStaff, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadTrainerAndStaff.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadTrainerAndStaff(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

 //#region Cad OtherRequirement
  createCadOtherRequirement(cadData: CadOtherRequirement): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadOtherRequirement.create}`,
        cadData
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.cadService.addCadOtherRequirement(
              res.data
            );
          }
        })
      );
  }

  readCadOtherRequirementListByTamId(tamMainId: number, page: number,
    size: number): Observable<ServerResponse> {

      const startPage = page - 1;

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.cadOtherRequirement.readByTamId}` + tamMainId)
      // .pipe(
      //   map((serverResponse: ServerResponse) => {
      //     if (!serverResponse.data) {
      //       return [];
      //     }
      //     return serverResponse.data;
      //   }),
      //   tap((cadList: CadOtherRequirement[]) => {
      //     this.cadService.setCadOtherRequirementList(cadList);
      //   })
      // );
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadOtherRequirementList(response.data);
          }
        })
      );
  }

  deleteCadOtherRequirement(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.cadOtherRequirement.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.cadService.deleteCadOtherRequirement(id);
        })
      );
  }

  updateCadOtherRequirement(data: CadOtherRequirement, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.cadOtherRequirement.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.upatedCadOtherRequirement(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
 //#endregion

  //#region Excel data save for CadOtherRequirement

  saveExcelCadOtherRequirementData(cadOtherRequirement: CadOtherRequirement[]): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadOtherRequirement.saveExcelData}`,
        cadOtherRequirement
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadOtherRequirementForExcel(response.data);
          }
        })
      );
  }

  //#endregion Excel data save for CadOtherRequirement

  //#region Excel data save for CadTrainerAndStaff

  saveExcelCadTrainerAndStaffData(cadTrainerAndStaffs: CadTrainerAndStaff[]): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadTrainerAndStaff.saveExcelData}`,
        cadTrainerAndStaffs
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadTrainerAndStaffForExcel(response.data);
          }
        })
      );
  }

  //#endregion Excel data save for CadTrainerAndStaff

  //#region Excel data save for CadTrainingFacility

  saveExcelCadTrainingFacilityData(cadTrainingFacilities: CadTrainingFacility[]): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadTrainingFacility.saveExcelData}`,
        cadTrainingFacilities
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadTrainingFacilityForExcel(response.data);
          }
        })
      );
  }

  //#endregion Excel data save for CadTrainingFacility

  //#region Excel data save for CadLearningMaterial

  saveExcelCadLearningMaterialsData(cadLearningMaterials: CadLearningMaterial[]): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadLearningMaterial.saveExcelData}`,
        cadLearningMaterials
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadLearningMaterialForExcel(response.data);
          }
        })
      );
  }

  //#endregion Excel data save for CadLearningMaterial

  //#region Excel data save for CadFurniture

  saveExcelCadRequiredFurnitureData(cadFurniture: CadFurniture[]): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.cadFurniture.saveExcelData}`,
        cadFurniture
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.cadService.setCadRequiredFurnitureForExcel(response.data);
          }
        })
      );
  }

  //#endregion Excel data save for CadLearningMaterial


    //#region Excel data save for CadEquipment

    saveExcelCadEquipmentData(cadEquipment: CadEquipment[]): Observable<ServerResponse> {
      // console.log('furniture', furniture);
      return this.httpClient
        .post<ServerResponse>(
          `${applicationUrls.cadEquipment.saveExcelData}`,
          cadEquipment
        )
        .pipe(
          tap((response) => {
            if (response.data) {
              this.cadService.setCadEquipmentsForExcel(response.data);
            }
          })
        );
    }

    //#endregion Excel data save for CadEquipment


    //#region Excel data save for cad-measuring-instrument

        saveExcelCadMeasurInginstrumentData(cadMeasuringInstrument: CadMeasuringInstrument[]): Observable<ServerResponse> {
          return this.httpClient
            .post<ServerResponse>(
              `${applicationUrls.cadMeasuringInstrument.saveExcelData}`,
              cadMeasuringInstrument
            )
            .pipe(
              tap((response) => {
                if (response.data) {
                  this.cadService.setCadMeasuringInstrumentForExcel(response.data);
                }
              })
            );
        }

        //#endregion Excel data save for cad-measuring-instrument


    //#region Excel data save for cadTool

        saveExcelCadToolData(cadTool: CadTool[]): Observable<ServerResponse> {
          return this.httpClient
            .post<ServerResponse>(
              `${applicationUrls.cadTool.saveExcelData}`,
              cadTool
            )
            .pipe(
              tap((response) => {
                if (response.data) {
                  this.cadService.setCadToolForExcel(response.data);
                }
              })
            );
        }

        //#endregion Excel data save for cadTool


    //#region Excel data save for Materials/Consumables

    saveExcelCadMaterialsAndConsumablesData(cadMaterialConsumable: CadMaterialConsumable[]): Observable<ServerResponse> {
      // console.log('furniture', furniture);
      return this.httpClient
        .post<ServerResponse>(
          `${applicationUrls.cadMaterialConsumable.saveExcelData}`,
          cadMaterialConsumable
        )
        .pipe(
          tap((response) => {
            if (response.data) {
              this.cadService.setCadMaterialConsumableForExcel(response.data);
            }
          })
        );
    }

    //#endregion Excel data save for Materials/Consumables




    //#region Excel data save for cad-ppe

    saveExcelCadPpeData(cadPpe: CadPpe[]): Observable<ServerResponse> {
      // console.log('furniture', furniture);
      return this.httpClient
        .post<ServerResponse>(
          `${applicationUrls.cadPpe.saveExcelData}`,
          cadPpe
        )
        .pipe(
          tap((response) => {
            if (response.data) {
              this.cadService.setCadPpesForExcel(response.data);
            }
          })
        );
    }

    //#endregion Excel data save for cad-ppe





}
