import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Attachment } from '../../course-acc-inspection-report/models/attachment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { InspectionResultResponse } from './../../course-acc-inspection-report/DTO/InspectionResultResponseDto';
import { BasicInformationResponse } from './../../course-acc-inspection-report/models/basic-information-response';
import { CourseAccInspectionReportStorageService } from './../../course-acc-inspection-report/services/course-acc-inspection-report-storage.service';
import { CourseAccInspectionReportService } from './../../course-acc-inspection-report/services/course-acc-inspection-report.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';


@Component({
  selector: 'app-course-accreditation-inspection-preview-shared',
  templateUrl: './course-accreditation-inspection-preview-shared.component.html',
  styleUrls: ['./course-accreditation-inspection-preview-shared.component.scss']
})
export class CourseAccreditationInspectionPreviewSharedComponent implements OnInit {

  machineries: any;
  machineryList: any;
  measuringInstrumentList: any;

  toolList: any;
  materialConsumableList: any;
  ppeList: any;
  furnitureList: any;
  learningMaterialList: any;
  trainingFacilitiyList: any;
  trainingAndStaffList: any;
  otherRequirementList: any;
  occupationList: InspectionResultResponse[] = [];
  basicInfoResponse: BasicInformationResponse;
  inspectionId: number;
  noOfOccupation: number;
  currentInspectionResultId: number;
  previewAttachments: Attachment[] = [];

  isVisible = false;
  imageView: boolean;
  isImageLoading: boolean;
  imageURL: any;
  pdfUrl: any;
  otherPdfUrl: any;

  imageTypeArray: string[] = ['jpg', 'jpeg', 'png'];

  inspectionResultId: number;

  ccTvData: any;
  basicInfoData:any;
  showBackButton:boolean = false;

  courseAccreditationData: any;

  tabArray = [
    { name: 'Basic Information', index: 0 },
    { name: 'Machinery', index: 1 },
    { name: 'Measuring Instruments', index: 2 },
    { name: 'Tools', index: 3 },
    { name: 'Materials/ Consumables', index: 4 },
    { name: 'PPE', index: 5 },
    { name: 'Furniture', index: 6 },
    { name: 'Learning Materials', index: 7 },
    { name: 'Training Facilities', index: 8 },
    { name: 'Trainers and other staff', index: 9 },
    { name: 'Other Requirements', index: 10 },
    { name: 'CCTV', index: 11 },
    { name: 'Preview Attachments', index: 12 }
  ];

  @Input() inspectionResultIdforSharedPreview: number;
  @Output() previewResponse: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modal: NzModalService,
    private sanitizer: DomSanitizer,
    private notification: NzNotificationService,
    private courseAccInspectionReportStorageService: CourseAccInspectionReportStorageService,
    private courseAccInspectionReportService: CourseAccInspectionReportService,
    private activatedRoute: ActivatedRoute,
    private location:Location
    ) { }

  ngOnInit(): void {
    let inspectionResultId = this.activatedRoute.snapshot.params.inspectionResultId;
    //  console.log(inspectionResultId);
    if (inspectionResultId != null) {
      this.inspectionResultId = inspectionResultId;
      this.showBackButton = false;
    }
    if (this.inspectionResultIdforSharedPreview != null) {
      this.inspectionResultId = this.inspectionResultIdforSharedPreview;
      this.showBackButton = true;
    }

    this.preview(this.inspectionResultId);
  }

  getJsonData(index: number) {
    let findObj = this.machineries.find(
      (x: any) => x.name == this.tabArray[index].name
    );
    return findObj?.inspectionData != null ? JSON.parse(findObj?.inspectionData) : null;
  }

  preview(inspectionResultId: number): void {
    this.currentInspectionResultId = inspectionResultId;

    this.courseAccInspectionReportStorageService
      .getCourseAccreditationByResultId(inspectionResultId)
      .subscribe({
        next: (response) => {
          this.courseAccreditationData = response.data;
          this.previewResponse.emit(response.data);
          this.basicInfoData = {
            tamMainId: response.data?.tamMainId,
            inspectionApplicationTypeId:response.data?.inspectionApplicationType?.id,
            inspectionId: this.inspectionId,
            inspectionResultId: response.data?.id,
          };


          let ccTvData = {
            ccCameraDetails: response.data?.ccCameraDetails,
            isCcCameraAvailable: (response.data?.isCcCameraAvailable!=null)?(response.data?.isCcCameraAvailable? 1 : 0):null,
            isRenewalApplication: (response.data?.isRenewalApplication!=null) ?(response.data?.isRenewalApplication? 1 : 0):null,
            prevApprovalExpiryDate: response.data?.prevApprovalExpiryDate,
            isInspectorFoundCcCamera: response.data?.isInspectorFoundCcCamera != null? (response.data?.isInspectorFoundCcCamera ? 1 : 0) : null,
            remarksInspectorCcCamera: response.data?.remarksInspectorCcCamera
          };

          this.ccTvData = [ccTvData];


          this.machineries = response.data.inspectionCadJsonList;
          if (response.data.inspectionAttachment != null) {
            this.previewAttachments = [...response.data.inspectionAttachment];
            if (this.previewAttachments?.length > 0) {
              this.setPreviousAttachments();
            }
          }

          this.tabArray.forEach((item, index) => {
            switch (index) {
              case 1:
                this.machineryList = this.getJsonData(index);
                break;
              case 2:
                this.measuringInstrumentList = this.getJsonData(index);
                break;
              case 3:
                this.toolList = this.getJsonData(index);
                break;

              case 4:
                this.materialConsumableList = this.getJsonData(index);
                break;

              case 5:
                this.ppeList = this.getJsonData(index);
                break;

              case 6:
                this.furnitureList = this.getJsonData(index);
                break;

              case 7:
                this.learningMaterialList = this.getJsonData(index);
                break;

              case 8:
                this.trainingFacilitiyList = this.getJsonData(index);
                break;

              case 9:
                this.trainingAndStaffList = this.getJsonData(index);
                break;

              case 10:
                this.otherRequirementList = this.getJsonData(index);
                break;

            }
          });
        },
      });
  }

  setPreviousAttachments() {
    this.previewAttachments.forEach((item: Attachment, index: number) => {
      let obj = { ...item };
      let fileType = this.getExtensionFromUrl(item.fileUrl);
      item.fileType = this.getFileTypeFromPreviousAttachment(fileType);
      this.setBase64AttachmentforPreviewing(index, item.fileUrl);
    })
  }

  async setBase64AttachmentforPreviewing(index: number, fileUrl: string) {
    const data = await fetch(environment.fileServiceApiUrl + fileUrl);
    const blob = await data.blob();
    this.previewAttachments[index].fileUrl = URL.createObjectURL(blob);
  }

  previewAllImage(index: number, fielType: string | undefined) {
    this.imageURL = '';
    this.pdfUrl = '';
    this.otherPdfUrl = '';
    if (fielType == "image") {
      this.isVisible = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.previewAttachments[index].fileUrl
      );
    } else if (fielType == 'pdf') {
      this.isVisible = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.previewAttachments[index].fileUrl
      );
    } else {
      // console.log(this.otherPdfUrl)
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.previewAttachments[index].fileUrl);
      link.setAttribute('download', this.previewAttachments[index].fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  getExtensionFromUrl(url: string) {
    let fileType: string = "";
    let basename = url.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;

    fileType = basename?.slice(pos + 1) ?? '';
    return fileType;
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {
    let contentType = "";
    let basename = fileUrl.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;

    let fileType = basename?.slice(pos + 1) ?? '';
    if (this.imageTypeArray.includes(fileType)) {
      contentType = "image";
    } else if (fileType == "pdf") {
      contentType = "pdf";
    } else {
      contentType = "other";
    }
    return contentType;
  }

  getFileTypeFromNewAttachment(fileType: string) {
    let contentType = "";
    if (fileType.match(/image/)) {
      contentType = "image";
    } else if (fileType == 'application/pdf') {
      contentType = "pdf";
    } else {
      contentType = "other";
    }
    return contentType;

  }

  handleCancel(): void {
    this.isVisible = false;
  }

  backToPreviousPage(){
    this.location.back();
  }

}
