<app-header></app-header>
<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    MRA/MOU Details
  </div>
  <nz-table
    #basicTable
    [nzData]="['']"
    nzBordered
    [nzShowPagination]="false"
    nzAlign="middle"
  >
    <tbody>
      <tr>
        <td class="p-10 bold">Agreement Type:</td>
        <td class="p-10" colspan="2">
          {{
            mraDetailsInfo?.agreementType?.agreementType
              ? mraDetailsInfo?.agreementType?.agreementType
              : "N/A"
          }}
        </td>
        <td class="p-10 bold">Date of Signature:</td>
        <td class="p-10">
          {{
            mraDetailsInfo?.dateOfSignature
              ? (mraDetailsInfo?.dateOfSignature | date : "dd-MM-yyyy")
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Name of Organization (English):</td>
        <td class="p-10" colspan="2">
          {{
            mraDetailsInfo?.nameOfOrganizationEnglish
              ? mraDetailsInfo?.nameOfOrganizationEnglish
              : "N/A"
          }}
        </td>
        <td class="p-10 bold">Name of Organization (Bangla):</td>
        <td class="p-10">
          {{
            mraDetailsInfo?.nameOfOrganizationBangla
              ? mraDetailsInfo?.nameOfOrganizationBangla
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Subject (English):</td>
        <td class="p-10" colspan="2">
          {{
            mraDetailsInfo?.subjectEnglish
              ? mraDetailsInfo?.subjectEnglish
              : "N/A"
          }}
        </td>
        <td class="p-10 bold">Subject (Bangla):</td>
        <td class="p-10">
          {{
            mraDetailsInfo?.subjectBangla
              ? mraDetailsInfo?.subjectBangla
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Introduction (English):</td>
        <td class="p-10" colspan="2">
          {{
            mraDetailsInfo?.introductionEnglish
              ? mraDetailsInfo?.introductionEnglish
              : "N/A"
          }}
        </td>
        <td class="p-10 bold">Introduction (Bangla):</td>
        <td class="p-10">
          {{
            mraDetailsInfo?.introductionBangla
              ? mraDetailsInfo?.introductionBangla
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Country Name:</td>
        <td class="p-10" colspan="4">
          {{
            mraDetailsInfo?.country?.country
              ? mraDetailsInfo?.country?.country
              : "N/A"
          }}
        </td>
      </tr>

      <ng-container *ngFor="let mraDetailsInfo of attachmentInfo">
        <tr>
          <td class="p-10 bold">Attachment Title:</td>
          <td class="p-10">
            {{
              mraDetailsInfo?.attachmentTitle
                ? mraDetailsInfo?.attachmentTitle
                : "N/A"
            }}
          </td>
          <td class="p-10">
            <a *ngIf="mraDetailsInfo?.file"
            nz-button
            nz-tooltip
            nzTooltipTitle="Preview"
            nzTooltipPlacement="topLeft"
            nzType="primary"
            (click)="setBase64FileFromUrl(mraDetailsInfo.file)"
          >
            <i nz-icon nzType="eye" nzTheme="outline"></i>
          </a>
          &nbsp;
            <a
              nz-button
              nz-tooltip
              nzTooltipTitle="Download Document"
              nzTooltipPlacement="bottomLeft"
              nzType="primary"
              target="_blank"
              routerLinkActive="router-link-active"
              (click)="
                mraDetailsInfo?.file
                  ? onDownloadClick(mraDetailsInfo?.attachmentTitle, mraDetailsInfo?.file)
                  : null
              "
            >
              <i nz-icon nzType="download" nzTheme="outline"></i>
            </a>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</nz-card>
<div *ngIf="attachmentInfo?.length" class="row justify-content-center media-button mt-4 pb-5">
  <div class="col-xs-6 col-sm-3">
    <button
      type="button"
      class="btn btn-primary active btn-lg btn-block cari border-redius"
      (click)="export('pdfData')"
    >
      <span nz-icon nzType="download" nzTheme="outline"></span>
      {{ "Download full MRA info" | translate }}
    </button>
  </div>

  <div *ngIf="attachmentInfo.length > 0" class="col-xs-6 col-sm-3">
    <button
      type="button"
      class="btn btn-primary active btn-lg btn-block cari border-redius"
      (click)="downloadZipAttachment(attachmentInfo)"
    >
      <span nz-icon nzType="download" nzTheme="outline"></span>
      Download Attachments (ZIP)
    </button>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisibleForAttachment"
  (nzOnCancel)="handleCancelFile()"
  [nzClosable]="false"
  [nzOkText]="null"
  (nzOnOk)="handleOkFile()"
  [nzWidth]="1200"
  >
    <ng-container *nzModalContent>
      <iframe
        *ngIf="!isImageLoading && pdfUrl"
        [src]="pdfUrl"
        style="width: 100%; height: 35vw"
      ></iframe>
      <div style="text-align: center">
        <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
        <ng-template #noImageFound>
          <img
            src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
            alt="Fallbackimage"
          />
        </ng-template>
      </div>
    </ng-container>
    <div *nzModalFooter>
      <button nz-button nzType="primary" (click)="handleCancelFile()">
        Close
      </button>
    </div>
  </nz-modal>

<div id="pdfData" style="margin-top: 5px; display: none">
  <nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    MRA/MOU Details
  </div>
  <nz-table
    #basicTable
    [nzData]="['']"
    nzBordered
    [nzShowPagination]="false"
    nzAlign="middle"
  >
    <tbody *ngIf="attachmentInfo?.length">
      <tr>
        <td class="p-10 bold">Agreement Type:</td>
        <td class="p-10" colspan="2">
          {{
            mraDetailsInfo?.agreementType?.agreementType
              ? mraDetailsInfo?.agreementType?.agreementType
              : "N/A"
          }}
        </td>
        <td class="p-10 bold">Date of Signature:</td>
        <td class="p-10">
          {{
            mraDetailsInfo?.dateOfSignature
              ? (mraDetailsInfo?.dateOfSignature | date : "dd-MM-yyyy")
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Name of Organization (English):</td>
        <td class="p-10" colspan="2">
          {{
            mraDetailsInfo?.nameOfOrganizationEnglish
              ? mraDetailsInfo?.nameOfOrganizationEnglish
              : "N/A"
          }}
        </td>
        <td class="p-10 bold">Name of Organization (Bangla):</td>
        <td class="p-10">
          {{
            mraDetailsInfo?.nameOfOrganizationBangla
              ? mraDetailsInfo?.nameOfOrganizationBangla
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Subject (English):</td>
        <td class="p-10" colspan="2">
          {{
            mraDetailsInfo?.subjectEnglish
              ? mraDetailsInfo?.subjectEnglish
              : "N/A"
          }}
        </td>
        <td class="p-10 bold">Subject (Bangla):</td>
        <td class="p-10">
          {{
            mraDetailsInfo?.subjectBangla
              ? mraDetailsInfo?.subjectBangla
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Introduction (English):</td>
        <td class="p-10" colspan="2">
          {{
            mraDetailsInfo?.introductionEnglish
              ? mraDetailsInfo?.introductionEnglish
              : "N/A"
          }}
        </td>
        <td class="p-10 bold">Introduction (Bangla):</td>
        <td class="p-10">
          {{
            mraDetailsInfo?.introductionBangla
              ? mraDetailsInfo?.introductionBangla
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Country Name:</td>
        <td class="p-10" colspan="4">
          {{
            mraDetailsInfo?.country?.country
              ? mraDetailsInfo?.country?.country
              : "N/A"
          }}
        </td>
      </tr>

      <ng-container *ngFor="let mraDetailsInfo of attachmentInfo">
        <tr>
          <td class="p-10 bold">Attachment Title:</td>
          <td class="p-10">
            {{
              mraDetailsInfo?.attachmentTitle
                ? mraDetailsInfo?.attachmentTitle
                : "N/A"
            }}
          </td>
          <td class="p-10 bold">Access Type:</td>
          <td class="p-10">
            {{
              mraDetailsInfo?.accessType?.accessTypeName
                ? mraDetailsInfo?.accessType?.accessTypeName
                : "N/A"
            }}
          </td>
          <td class="p-10">
            <a *ngIf="mraDetailsInfo?.file"
            nz-button
            nz-tooltip
            nzTooltipTitle="Preview"
            nzTooltipPlacement="topLeft"
            nzType="primary"
            (click)="setBase64FileFromUrl(mraDetailsInfo.file)"
          >
            <i nz-icon nzType="eye" nzTheme="outline"></i>
          </a>
          &nbsp;
            <a
              nz-button
              nz-tooltip
              nzTooltipTitle="Download Document"
              nzTooltipPlacement="bottomLeft"
              nzType="primary"
              target="_blank"
              routerLinkActive="router-link-active"
              (click)="
                mraDetailsInfo?.file
                  ? onDownloadClick(mraDetailsInfo?.attachmentTitle, mraDetailsInfo?.file)
                  : null
              "
            >
              <i nz-icon nzType="download" nzTheme="outline"></i>
            </a>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</nz-card>
</div>
<app-footer></app-footer>
