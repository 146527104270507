import { FinancialStorageService } from './../../../financial-application/services/financial-storage.service';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import {
  District,
  Division,
  Upazila,
} from '../../../../shared/models/common.model';
import { CommonStorageService } from '../../../../shared/services/common-storage.service';
import { TempApproveRejectStpUserDto } from '../../DTO/temp-approve-reject-stp-user-dto';
import { Furniture } from '../../models/furniture.model';
import { StpManagement } from '../../models/stp-management.model';
import { StpOccupationTool } from '../../models/stp-occupation-tool.model';
import { StpTrainer } from '../../models/stp-trainer.model';
import { STPAgency } from '../../models/stp_agency.model';
import { STPAppStatus } from '../../models/stp_app_status.model';
import { STPAppType } from '../../models/stp_app_type.model';
import { STPLocation } from '../../models/stp_location.model';
import { STPMain } from '../../models/stp_main.model';
import { STPMinistry } from '../../models/stp_ministry.model';
import { STPOwnershipType } from '../../models/stp_ownership_type.model';
import { STPOwnerType } from '../../models/stp_owner_type.model';
import { STPType } from '../../models/stp_type.model';
import { SYSCountry } from '../../models/sys_country.model';
import { SYSPostOffice } from '../../models/sys_post_office.model';
import { StpRegistrationStorageService } from '../../services/stp-registration-storage.service';
import { StpRegistrationService } from '../../services/stp-registration.service';
import { UserStorageService } from 'src/app/modules/user/services/user-storage.service';

@Component({
  selector: 'app-stp-application-view',
  templateUrl: './stp-application-view.component.html',
  styleUrls: ['./stp-application-view.component.scss'],
})
export class StpApplicationViewComponent implements OnInit {
  @Input() stpMainId: string | null;

  fullStpApplicationDetails: any;

  editable: boolean = true;
  basicProfileForm!: FormGroup;
  approveRejectForm!: FormGroup;
  physicalInfraForm!: FormGroup;
  paymentForm!: FormGroup;

  pdfBlob: File;

  financialDetailForm!: FormGroup;
  isEditMode!: boolean;
  stpMain!: STPMain;
  stptypes: STPType[] = [];
  stpTrainers: StpTrainer[] = [];
  stpOccupationTools: StpOccupationTool[] = [];
  furnitures: Furniture[] = [];
  stpManagements: StpManagement[] = [];
  stpOwnerTypes: STPOwnerType[] = [];
  stpOwnershipTypes: STPOwnershipType[] = [];
  stpMinistries: STPMinistry[] = [];
  stpAgencies: STPAgency[] = [];
  stpLocations: STPLocation[] = [];
  sysPostOffices: SYSPostOffice[] = [];
  sysCountries: SYSCountry[] = [];
  isCommitte: boolean;
  approveDate: Date;
  expiredDate: Date;
  isApplicationSubmitted: boolean = false;

  divisions: Division[] = [];
  districts: District[] = [];
  upazilas: Upazila[] = [];

  stpAppStatuses: STPAppStatus[] = [];
  stpAppTypes: STPAppType[] = [];
  stpName = '';
  stpApplicationId: number;
  totalIncomes: number;
  totalExpenses: number;
  currentUserInfo: any;
  userInfo: any;
  userId: any;
  userInfoId:any
  userEmail:any;
  isUser: boolean = false;
  isAdmin: boolean = false;
  isManualPayment = false;
  isOnlinePayment = false;
  paySlip: String;
  isComplete$: Subscription;
  transactionNumber: any;
  tempApproveRejectStpUserDto: TempApproveRejectStpUserDto = new TempApproveRejectStpUserDto();
  applicationPermissions = applicationPermissions;
  isTempRejected:any;
  approvalStatus:any;

  tempRejectReason : string;
  isShown: boolean = false ;

  stpEmail: string;
  stpContactEmail: string;
  stpExpireDate: Date;
  isStpExpired: boolean = false;

  paymentIssueForm: FormGroup;
  isVisible = false;
  checkOptions = [
    { label: 'Payment Related Issue' }
  ];

  financingApplicationList: any;

  constructor(
    // private tab: StpTabComponent,
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private fbPhyInfra: FormBuilder,
    private fbFinancial: FormBuilder,
    private stpPayment: FormBuilder,
    private commonStorageService: CommonStorageService,
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private stpRegistrationService: StpRegistrationService,
    private notification: NzNotificationService,
    private userStorageService: UserStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private financialStorageService: FinancialStorageService
  ) {
    // this.stpRegistrationStorageService.readStpOwnerType().subscribe({
    //   next: (res) => {
    //     this.stpOwnerTypes = res;
    //   },
    // });
    this.stpApplicationId = this.route.snapshot.params.id;
    if (!this.stpApplicationId) {
      this.stpApplicationId = Number(this.stpMainId);
    }

  }

  @ViewChild('basicProfileDetails') htmlData: ElementRef;
  ngOnInit() {
    this.currentUserInfo = localStorage.getItem('currentUserInfo');
    this.userInfo = JSON.parse(this.currentUserInfo) || null;
    if (this.userInfo) {
      this.userId = this.userInfo.id;
    }
    if (this.userInfo.roles[0].name.toLowerCase() === 'user' || this.userInfo.roles[0].name.toLowerCase() === 'stp_master') {
      this.isUser = true;
    }
    if (this.userInfo.roles[0].name.toLowerCase() === 'admin') {
      this.isAdmin = true;
    }

    this.basicProfileForm = this.fb.group({
      //#region Basic Profile
      stpName: [''],
      stpNameBangla: [''],
      stpTypeId: [''],
      // stpOwnerTypeId: ['', Validators.required],
      stpOwnershipTypeId: [''],
      stpMinistryId: [''],
      stpAgencyId: [''],
      establishYear: [''],
      stpLocationId: [''],

      //#endregion Basic Profile

      //#region Address of the organization

      stpCountryId: [''],
      stpPostOfficeId: [''],
      address: [''],
      contactNo: [''],
      email: [''],
      division_id: [''],
      district_id: [''],
      upazilla_id: [''],

      // don't find  (Website address) property from ERD

      //#endregion Address of the organization

      //#region Contact Person

      contactName: [''],
      contactDesignation: [''],
      contactNumber: [''],
      contactEmail: [''],

      //#endregion Contact Person

      //#region  commitee

      isCommittee: [''],
      totalMember: [''],
      totalFemaleMember: [''],
      approvalDateCommittee: [''],
      expirationDateCommittee: [''],
      // don't use my html upload tag
      //#endregion commitee

      //#region application details
      // stpApplicationStatusId: ['', Validators.required],
      // stpAppTypeId: ['', Validators.required],
      // registrationNo: ['', Validators.required],
      // approvalDate: ['', Validators.required],
      applicationDate: [''],
      stpApplicationStatus: [''],

      //#endregion application details
    });
    this.physicalInfraForm = this.fbPhyInfra.group({
      infrastructureName: ['demoName'],
      stpOwnershipStatus: [''],
      stpBuildingType: [''],
      totalArea: [''],
      noOfFloor: [''],
      noOfClassroom: [''],
      sizeOfClassroom: [''],
      noOfWorkshop: [''],
      sizeOfWorkshop: [''],
      noOfClub: [''],
      sizeOfClub: [''],
      noOfOffice: [''],
      noOfTrainerRoom: [''],
      noOfWashroom: [''],
      noOfFemaleWashroom: [''],
      isDisableFriendly: [{ value: '', disabled: true }],
      isElectricity: [{ value: '', disabled: true }],
      isInternet: [{ value: '', disabled: true }],
      isDrinkingWater: [{ value: '', disabled: true }],
      isFireEq: [{ value: '', disabled: true }],
      isLibrary: [{ value: '', disabled: true }],
      isLightingVent: [{ value: '', disabled: true }],
    });
    this.financialDetailForm = this.fbFinancial.group({
      reserveFund: [null],
      fdr: [null],
      generalFund: [''],
      availableBalanceInBankAccount: [''],
      accountNumber: [''],
      bankName: [''],
      branchName: [''],
      otherFunds: [null],
      remarks: [''],
      feesFromStudents: [''],
      incomeFromOwnAssets: [''],
      fromGovernment: [''],
      developmentCharge: [''],
      admissionFee: [''],
      examinationFee: [''],
      sessionCharge: [''],
      sellingOfProductsProduced: [''],
      others_source: [''],
      salaryOfInstructorsAndStaff: [''],
      incidentalExpenses: [''],
      repairingCost: [''],
      examinationExpenses: [''],
      travelExpenses: [''],
      procurementOfBooks: [''],
      procurementOfTrainingLearningMaterials: [''],
      procurementOfRawMaterials: [''],
      others_head: [''],
    });
    this.paymentForm = this.stpPayment.group({
      amount: [''],
      generationDate: [''],
      successDate: [''],
      payDate: [''],
      bankName: [''],
      isSuccess: [''],
      paySlip: [''],
      transactionNo: [''],
    });

    this.approveRejectForm = this.fb.group({
      //#region Basic Profile
      rejectReason: [null],
      checkOptions: [null]
    });
    this.getStpApplicationById();
    // this.getCommitteeByStpApplicationId();

    this.getFinancingApplicationNotificationByStpMainId();
  }

  getFinancingApplicationNotificationByStpMainId(){
    this.financialStorageService.getFinancingApplicationNotificationByStpMainId(this.stpApplicationId)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.financingApplicationList = res?.data;
          }
        }
      });
  }

  getUserProfile(){
    this.userStorageService.getUserProfile(this.userInfoId).subscribe({
      next: (response) => {
        this.userEmail = response.data?.email;
      },
      error: (error) =>
        this.notification.error('Failed', error.message),
    });
  }

  private getStpApplicationById() {
    this.stpRegistrationStorageService
      .readStpApplicationsbyId(this.stpApplicationId)
      .subscribe(
        (res) => {
          // this.isTempRejected = res.data.stpTrainers[0].stpMain.isTempRejected

          this.isTempRejected = res.data.stpUserInfoDto.isTempRejected;
          this.approvalStatus = res.data.stpUserInfoDto.approvalStatus;

          this.fullStpApplicationDetails = res;

          this.userInfoId = res.data.stpUserInfoDto?.userId;
          if(this.userInfoId != null){
            this.getUserProfile();
          }

          if (res.success) {

            if(res.data.stpUserInfoDto.tempRejectReason!='N/A')
            {
              this.tempRejectReason = res.data.stpUserInfoDto.tempRejectReason;
              this.isShown = true;
            }else
            {
              this.isShown = false;
            }


            this.stpEmail = res.data?.stpUserInfoDto?.orgEmail;
            this.stpContactEmail = res.data?.stpUserInfoDto?.contactEmail;
            this.stpExpireDate = res.data?.stpUserInfoDto?.stpExpireDate;

            if(this.stpExpireDate && (new Date(this.stpExpireDate) < new Date())){
              this.isStpExpired = true;
            }

            this.basicProfileForm.patchValue({
              stpName: res.data?.stpUserInfoDto?.stpName,
              stpNameBangla: res.data?.stpUserInfoDto?.stpNameBangla,
              stpTypeId: res.data?.stpUserInfoDto?.stpType,
              stpOwnershipTypeId: res.data?.stpUserInfoDto?.ownershipType,
              stpMinistryId: res.data?.stpUserInfoDto?.ministryName,
              stpAgencyId: res.data?.stpUserInfoDto?.agencyName,
              establishYear: this.datepipe.transform(
                res.data?.stpUserInfoDto?.establishYear,
                'yyyy'
              ),
              stpLocationId: res.data?.stpUserInfoDto?.locationName,

              stpCountryId: res.data?.stpUserInfoDto?.country,
              stpPostOfficeId: res.data?.stpUserInfoDto?.postOffice,
              address: res.data?.stpUserInfoDto?.address,
              contactNo: res.data?.stpUserInfoDto?.orgContact,
              email: res.data?.stpUserInfoDto?.orgEmail,
              division_id: res.data?.stpUserInfoDto?.division,
              district_id: res.data?.stpUserInfoDto?.district,
              upazilla_id: res.data?.stpUserInfoDto?.upazilla,

              contactName: res.data?.stpUserInfoDto?.contactName,
              contactDesignation: res.data?.stpUserInfoDto?.contactDesignation,
              contactNumber: res.data?.stpUserInfoDto?.contactNumber,
              contactEmail: res.data?.stpUserInfoDto?.contactEmail,

              isCommittee: res.data?.stpUserInfoDto?.isCommittee,
              totalMember: res.data?.stpUserInfoDto?.totalMember,
              totalFemaleMember: res.data?.stpUserInfoDto?.totalFemaleMember,
              // approvalDateCommittee: res.data.stpUserInfoDto.approvalDate,
              approvalDateCommittee: this.datepipe.transform(
                res.data?.stpUserInfoDto?.approvalDate,
                'dd-MM-yyyy'
              ),
              // expirationDateCommittee: res.data.stpUserInfoDto.expireDate,
              expirationDateCommittee: this.datepipe.transform(
                res.data?.stpUserInfoDto?.expireDate,
                'dd-MM-yyyy'
              ),
              // applicationDate: res.data,
              // stpApplicationStatus: res.data,
              // dateOfCommencementPC: this.datepipe.transform(res.expCommencementDate, 'dd-MM-yyyy'),
              // dateOfCompletionPC: this.datepipe.transform(res.expCompletionDate, 'dd-MM-yyyy')
            });
            if (res.data?.stpUserInfoDto?.isCommittee === true) {
              this.isCommitte = true;
            } else {
              this.isCommitte = false;
            }

            this.paymentForm.patchValue({
              amount: res.data?.stpPayments?.amount,
              generationDate: res.data?.stpPayments?.bankName,
              successDate: res.data?.stpPayments?.successDate,
              // payDate: res.data.stpPayments.payDate,
              payDate: this.datepipe.transform(
                res.data?.stpPayments?.payDate,
                'dd-MM-YYYY'
              ),
              bankName: res.data?.stpPayments?.bankName,
              isSuccess: res.data?.stpPayments?.isSuccess,
              paySlip: res.data?.stpPayments?.paySlip,
              transactionNo: res.data?.stpPayments?.transactionNo,
            });

            // if (res.data?.stpPayments?.stpPaymentType.id === 2) {
            if (res.data?.stpPayments?.stpPaymentType?.id === 2) {
              this.isManualPayment = true;
            }
            if (res.data?.stpPayments?.stpPaymentType?.id === 1) {
              this.isOnlinePayment = true;
              this.transactionNumber = res.data?.stpPayments?.transactionNo;
            }
            if (res.data?.stpPayments?.paySlip) {
              this.paySlip = res.data?.stpPayments?.paySlip;
            }
            this.physicalInfraForm.patchValue({
              stpOwnershipStatus: res.data?.stpUserInfoDto?.ownershipBuilding,
              stpBuildingType: res.data?.stpUserInfoDto?.constructionBuilding,
              totalArea: res.data?.stpUserInfoDto?.totalArea,
              noOfFloor: res.data?.stpUserInfoDto?.floor,
              noOfClassroom: res.data?.stpUserInfoDto?.classroom,
              sizeOfClassroom: res.data?.stpUserInfoDto?.classroomSize,
              noOfWorkshop: res.data?.stpUserInfoDto?.workshop,
              sizeOfWorkshop: res.data?.stpUserInfoDto?.workshopSize,
              noOfClub: res.data?.stpUserInfoDto?.noOfComputerLabs,
              sizeOfClub: res.data?.stpUserInfoDto?.sizeOfComputerLabs,
              noOfOffice: res.data?.stpUserInfoDto?.office,
              noOfTrainerRoom: res.data?.stpUserInfoDto?.trainingRoom,
              noOfWashroom: res.data?.stpUserInfoDto?.washroom,
              noOfFemaleWashroom: res.data?.stpUserInfoDto?.femaleWashroom,
              isDisableFriendly: res.data?.stpUserInfoDto?.isDisableFriendly,
              isElectricity: res.data?.stpUserInfoDto?.isElectricity,
              isInternet: res.data?.stpUserInfoDto?.isInternet,
              isDrinkingWater: res.data?.stpUserInfoDto?.isDrinkingWater,
              isFireEq: res.data?.stpUserInfoDto?.isFireEq,
              isLibrary: res.data?.stpUserInfoDto?.isLibrary,
              isLightingVent: res.data?.stpUserInfoDto?.isLightingVent,
            });

            this.financialDetailForm.patchValue({
              reserveFund: res.data?.stpUserInfoDto?.reserveFund? parseFloat(res.data?.stpUserInfoDto?.reserveFund) : "NA",
              fdr: parseFloat(res.data?.stpUserInfoDto?.fdr),
              generalFund: parseFloat(res.data?.stpUserInfoDto?.generalFund),
              availableBalanceInBankAccount: parseFloat(
                res.data?.stpUserInfoDto?.availableBalance
              ),
              accountNumber: res.data?.stpUserInfoDto?.accountNo,
              bankName: res.data?.stpUserInfoDto?.bankName,
              branchName: res.data?.stpUserInfoDto?.branchName,
              otherFunds: parseFloat(res.data?.stpUserInfoDto?.otherFund),
              remarks: res.data?.stpUserInfoDto?.remarks,

              feesFromStudents: parseFloat(
                res.data?.stpUserInfoDto?.feesFromStudent
              ),

              incomeFromOwnAssets: parseFloat(
                res.data?.stpUserInfoDto?.ownAssetIncome
              ),
              fromGovernment: parseFloat(res.data?.stpUserInfoDto?.govIncome),
              developmentCharge: parseFloat(
                res.data?.stpUserInfoDto?.developmentCharge
              ),
              admissionFee: parseFloat(res.data?.stpUserInfoDto?.admissionFee),
              examinationFee: parseFloat(res.data?.stpUserInfoDto?.examFee),
              sessionCharge: parseFloat(
                res.data?.stpUserInfoDto?.sessionCharge
              ),
              sellingOfProductsProduced: parseFloat(
                res.data?.stpUserInfoDto?.prodSell
              ),
              others_source: parseFloat(res.data?.stpUserInfoDto?.incomeOther),
              salaryOfInstructorsAndStaff: parseFloat(
                res.data?.stpUserInfoDto?.salary
              ),
              incidentalExpenses: parseFloat(
                res.data?.stpUserInfoDto?.incidental
              ),
              repairingCost: parseFloat(res.data?.stpUserInfoDto?.repairing),
              examinationExpenses: parseFloat(res.data?.stpUserInfoDto?.exam),
              travelExpenses: parseFloat(res.data?.stpUserInfoDto?.travel),
              procurementOfBooks: parseFloat(
                res.data?.stpUserInfoDto?.procBooks
              ),
              procurementOfTrainingLearningMaterials: parseFloat(
                res.data?.stpUserInfoDto?.procTraining
              ),
              procurementOfRawMaterials: parseFloat(
                res.data?.stpUserInfoDto?.procRawMat
              ),
              others_head: parseFloat(
                res.data?.stpUserInfoDto?.expenditureOthers
              ),
            });

            this.totalIncomes =
              parseFloat(res.data.stpUserInfoDto.feesFromStudent || 0) +
              parseFloat(res.data.stpUserInfoDto.ownAssetIncome || 0) +
              parseFloat(res.data.stpUserInfoDto.govIncome || 0) +
              parseFloat(res.data.stpUserInfoDto.developmentCharge || 0) +
              parseFloat(res.data.stpUserInfoDto.admissionFee || 0) +
              parseFloat(res.data.stpUserInfoDto.examFee || 0) +
              parseFloat(res.data.stpUserInfoDto.sessionCharge || 0) +
              parseFloat(res.data.stpUserInfoDto.prodSell || 0) +
              parseFloat(res.data.stpUserInfoDto.incomeOther || 0);

            this.totalExpenses =
              parseFloat(res.data.stpUserInfoDto.salary || 0) +
              parseFloat(res.data.stpUserInfoDto.incidental || 0) +
              parseFloat(res.data.stpUserInfoDto.repairing || 0) +
              parseFloat(res.data.stpUserInfoDto.exam || 0) +
              parseFloat(res.data.stpUserInfoDto.travel || 0) +
              parseFloat(res.data.stpUserInfoDto.procBooks || 0) +
              parseFloat(res.data.stpUserInfoDto.procTraining || 0) +
              parseFloat(res.data.stpUserInfoDto.procRawMat || 0) +
              parseFloat(res.data.stpUserInfoDto.expenditureOthers || 0);

            this.stpTrainers = res.data.stpTrainers;
            this.stpOccupationTools = res.data.stpTools;
            this.furnitures = res.data.stpFurnitures;
            this.stpManagements = res.data.stpStaffs;
            this.isApplicationSubmitted = res.data.isApplicationSubmitted;
            this.stpRegistrationService.setCheckIsCompleteRegistration(this.isApplicationSubmitted);

            this.isComplete$ = this.stpRegistrationService
              .getCheckIsCompleteRegistration()
              .subscribe((res) => {
                this.isApplicationSubmitted = res;

              });

          } else {
            this.notification.error('Failed', 'STP Application Not Found!');
          }
        },
        (error) => {
          this.notification.error('Failed', 'STP Application Not Found!');
        }
      );
  }

  // // tslint:disable-next-line:typedef
  // private getCommitteeByStpApplicationId(){
  //   this.stpRegistrationStorageService.readCommitteeByStpMainId(this.stpApplicationId).subscribe(res => {
  //     // this.approveDate = res.data.approvalDate.dateFormat(res.data.approvalDate, 'dd-MM-YYYY');
  //     this.basicProfileForm.patchValue({
  //       totalMember: res.data.totalNumber,
  //       totalFemaleMember: res.data.totalFemaleMember,
  //       approvalDateCommittee: res.data.approvalDate,
  //       expirationDateCommittee: res.data.expireDate,
  //
  //       // applicationDate: res.data,
  //       // stpApplicationStatus: res.data,
  //       // dateOfCommencementPC: this.datepipe.transform(res.expCommencementDate, 'dd-MM-yyyy'),
  //       // dateOfCompletionPC: this.datepipe.transform(res.expCompletionDate, 'dd-MM-yyyy')
  //     });
  //     if (res.data.isCommittee === true){
  //       this.isCommitte = true;
  //     } else{
  //       this.isCommitte = false;
  //     }
  //   });
  // }

  // nextPage(): void {
  //   this.tab.onclickButton();
  // }

  onSubmitApplication() {
    this.stpRegistrationStorageService
      .applicationSubmit(this.userId)
      .subscribe({
        next: async (response) => {
          if (response.success) {
            this.isApplicationSubmitted = response.data.applicationSubmitted;
            this.stpRegistrationService.setCheckIsCompleteRegistration(true);
            await this.onSuccessSubmitFinalMail();
            // this.notification.success(
            //   'Success!',
            //   'Application successfully submited.'
            // );
          }
        },
        error: (error) =>
          this.notification.error(
            'Failed',
            'Please! Submit the application form properly'
          ),
      });
  }

  async onSuccessSubmitFinalMail() {
    let mailingFormData = new FormData();
    mailingFormData.append(
      'subject',
      'Congratulation on Submitting your application'
    );
    mailingFormData.append(
      'message',
      'We, the authority of NSDA are very much delightful to have you here. Thank you for submitting your application'
    );
    mailingFormData.append('email', this.userInfo.email);
    mailingFormData.append('stpEmail', this.stpEmail);
    mailingFormData.append('stpContactEmail', this.stpContactEmail);
    await this.openPDF();
    mailingFormData.append('file', this.pdfBlob);

    this.stpRegistrationStorageService
      .mailingBasicProfile(mailingFormData)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.notification.success(
              'Success!',
              'Application successfully mailed. Please check your mail.'
            );
            this.router.navigate(['/home/stp-registration/stp-application-view/'+ this.stpApplicationId]);
          } else {
            this.notification.error(
              'Failed', 'Email could not be send.');
          }
        },
      });
    // .subscribe({
    //   next: (res) => {
    //     if (res.success) {
    //       this.notification.success(
    //       'Success!',
    //       'Application successfully mailed. Please check your mail.'
    //     );
    //       this.router.navigateByUrl('/home/stp-registration/stp-applications');

    //     }
    //   },
    //   error: (error) =>
    //     this.notification.error('Failed', 'Email could not be send.'),
    // });
  }

  async openPDF() {
    let data = document.getElementById('basicProfileDetails')!;
    // let pdf = new jsPDF('p', 'mm', 'a4');
    // Reduced size of pdf
    let pdf = new jsPDF('p', 'mm', 'a4', true);
    let stpName = this.fullStpApplicationDetails.data?.stpUserInfoDto?.stpName ? this.fullStpApplicationDetails.data?.stpUserInfoDto?.stpName : 'report';
    let pdfName = stpName + '.pdf';
    await html2canvas(data).then(async (canvas) => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      let pageNumber = 1;
      if (imgHeight % pageHeight != 0) {
        pageNumber = imgHeight / pageHeight + 1;
        pageNumber = Math.floor(pageNumber);
      } else {
        pageNumber = imgHeight / pageHeight;
      }

      let contentDataURL = canvas.toDataURL('image/png');
      var position = 0;
      while (pageNumber >= 0) {
        position = heightLeft - imgHeight;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        pageNumber--;

        if (pageNumber == 0) {
          break;
        }
        pdf.addPage();
      }
      // Generated PDF and downlonding PDF
      // pdf.save(pdfUrl);
      let blob = pdf.output('blob');
      // const formPdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      // const pdfDoc = await PDFDocument.load(formPdfBytes);
      // const pdfBytes = await pdfDoc.save();
      this.pdfBlob = new File([blob], pdfName, { type: 'application/pdf' });
    });
  }

  onDownload(): void {
    // @ts-ignore
    this.commonStorageService.downloadFile(this.paySlip).subscribe(
      (data: any) => {
        var fileName = 'report.pdf';
        const contentDisposition = data.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        saveAs(data.body, fileName);
      },
      (err) => {
      }
    );
  }

  async onStpDownload() {
    await this.openPDF();
    saveAs(this.pdfBlob, this.pdfBlob.name);
  }

  setRejectApplication(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  onRejectApplication(): void {
    if (!this.approveRejectForm.value.rejectReason) {
      this.notification.warning('Warning', 'Please! fill up reject Reason field');
      return
    }
    this.tempApproveRejectStpUserDto.tempRejectReason = this.approveRejectForm.value.rejectReason;
    this.tempApproveRejectStpUserDto.isTempRejected = true;
    this.tempApproveRejectStpUserDto.stpMainId = this.stpApplicationId;

    let tempCheckOptions = this.approveRejectForm.get('checkOptions')?.value;

    if(tempCheckOptions[0]?.checked){
      this.tempApproveRejectStpUserDto.isPaymentIssue = true;
    }else{
      this.tempApproveRejectStpUserDto.isPaymentIssue = false;
    }

    this.stpRegistrationStorageService
      .temporaryRejectStpUser(this.tempApproveRejectStpUserDto)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.isVisible = false;
            this.notification.success('Success!', 'Correction requested successfully ');
            this.router.navigate(['home/stp-registration/stp-applications']);
          } else {
            this.notification.warning('Warning!', res.message);
          }
        },
        error: (error) => {
          this.notification.error(
            'Failed', 'Please! Try again'
          );
        },
      });
  }

  onApproveApplication(): void {
    this.tempApproveRejectStpUserDto.isTempRejected = false;
    this.tempApproveRejectStpUserDto.stpMainId = this.stpApplicationId;
    this.stpRegistrationStorageService
      .temporaryRejectStpUser(this.tempApproveRejectStpUserDto)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.notification.success('Success!', 'Application accepted successfully');
            this.router.navigate(['home/stp-registration/stp-applications']);
          }
        },
        error: (error) => {
          this.notification.error(
            'Failed', 'Please! Try again'
          );
        },
      });
  }
}
