<div class="card">
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="form" nzTheme="outline"></i> {{this.editedItemId ? "Update Competency": "Create New Competency"}}
  </div>
  <div class="searchboxAerar p-3">
    <form nz-form [formGroup]="csForm" (ngSubmit)="submitForm()">
      <div class="col-md-9">
        <div class="mb-2 row">
          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label nzRequired>Unit Code</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control
                nzHasFeedback
                class="row"
                [nzErrorTip]="errorTplForUnitCode"
                [ngClass]="{
                  'is-loading': csForm.controls['unitCode'].pending
                }"
              >
                <input
                  nz-input
                  type="text"
                  id="unitCode"
                  placeholder="Unit Code"
                  formControlName="unitCode"
                />
                <ng-template #errorTplForUnitCode let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please input valid Unit Code!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('unitCodeExists')">
                    This Unit Code is already taken!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label>Title (Unit of Competency)</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="text"
                  id="title"
                  placeholder="Title (Unit of Competency)"
                  formControlName="title"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label>Duration (Nominal Hours)</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="text"
                  id="duration"
                  placeholder="Duration (Nominal Hours)"
                  formControlName="duration"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label>Remarks</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="text"
                  id="remark"
                  placeholder="Remarks"
                  formControlName="remark"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div class="row justify-content-center media-button">
        <div class="col-xs-6 col-sm-2">
          <button
            type="submit"
            class="btn btn-success active btn-lg btn-block cari border-redius"
            [disabled]="!csForm.valid"
          >
            <i nz-icon nzType="save" nzTheme="fill"></i> {{this.editedItemId ? "Update": "Save"}}
          </button>
        </div>
        <div class="col-xs-6 col-sm-2">
          <button
            type="button"
            class="btn btn-danger active btn-lg btn-block cari border-redius"
            (click)="resetForm()"
          >
            <i nz-icon nzType="redo" nzTheme="outline"></i> Reset
          </button>
          <br />
        </div>
      </div>
    </form>
  </div>
</div>
