<app-header></app-header>
<!-- <nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    {{ "Circular Details" | translate }}
  </div>
  <nz-table
    #basicTable
    [nzData]="['']"
    nzBordered
    [nzShowPagination]="false"
    nzAlign="middle"
  >
    <tbody *ngIf="circularInfoDetails">
      <tr>
        <td class="p-10 bold">Applicant Type:</td>
        <td class="p-10">
          {{ circularInfoDetails?.cirApplicantType?.name }}
        </td>
        <td class="p-10 bold">Max Course in Application:</td>
        <td class="p-10">{{ circularInfoDetails?.maxCourseInApplication }}</td>
      </tr>
      <tr>
        <td class="p-10 bold">Circular Start Date:</td>
        <td class="p-10">
          {{ circularInfoDetails?.circularStartDate | date : "dd-MM-YYYY" }}
        </td>
        <td class="p-10 bold">Circular End Date:</td>
        <td class="p-10">
          {{ circularInfoDetails?.circularEndDate | date : "dd-MM-YYYY" }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Payment Applicable?:</td>
        <td class="p-10">
          {{ circularInfoDetails?.isPaymentApplicable == true ? "Yes" : "No" }}
        </td>
        <td class="p-10 bold">Amount:</td>
        <td class="p-10">
          {{
            circularInfoDetails?.payment ? circularInfoDetails?.payment : "No"
          }}
        </td>
      </tr>

      <tr>
        <td class="p-10 bold">Organization Type:</td>
        <td class="p-10">{{ circularInfoDetails?.organizationType?.name }}</td>
        <td class="p-10 bold">Course Expiry Date:</td>
        <td class="p-10">
          {{ circularInfoDetails?.courseExpiryDate | date : "dd-MM-YYYY" }}
        </td>
      </tr>

      <tr>
        <td class="p-10 bold">Center Expiry Date:</td>
        <td class="p-10">
          {{ circularInfoDetails?.centerExpiryDate | date : "dd-MM-YYYY" }}
        </td>
        <td class="p-10 bold">Is Published?:</td>
        <td class="p-10">
          {{
            circularInfoDetails?.isPublished == true
              ? circularInfoDetails?.isPublished
              : "No"
          }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>
<nz-card *ngIf="cirPreventedOrganizationList.length > 0">
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    {{ "Circular Prevented Organization Info" | translate }}
  </div>
  <div nz-col [nzSpan]="24">
    <nz-table
      #basicTable
      [nzData]="cirPreventedOrganizationList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzSize="middle"
      nzAlign="middle"
      [nzFrontPagination]="false"
      [nzScroll]="{ x: '1150px' }"
    >
      <thead>
        <tr>
          <th style="text-align: center; width: 5%" rowspan="1">Sl.</th>
          <th rowspan="2" nzLeft nzColumnKey="stp_name" [nzSortFn]="true">
            Name of STP
          </th>
          <th rowspan="2" nzColumnKey="registration_no" [nzSortFn]="true">
            Registration Number
          </th>
          <th rowspan="2" nzColumnKey="approval_date" [nzSortFn]="true">
            Date of Approval
          </th>
          <th rowspan="2" nzColumnKey="expire_date" [nzSortFn]="true">
            Expire Date
          </th>
          <th rowspan="2">Type of Ownership</th>
          <th rowspan="2">Ministry</th>
          <th rowspan="2">Agency</th>
          <th rowspan="2">District</th>
          <th rowspan="2">InEligibility Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td style="text-align: center">
            {{ 1 + i }}
          </td>
          <td>{{ data.stpName }}</td>
          <td>{{ data.registrationId }}</td>
          <td>{{ data.approvalDate | date : "dd-MM-yyyy" }}</td>
          <td>{{ data.expireDate | date : "dd-MM-yyyy" }}</td>
          <td>
            {{
              data.ownerType?.typeName
                ? data.ownerType?.typeName
                : data.ownerType
            }}
          </td>
          <td>
            {{
              data.ministryName?.ministryName
                ? data.ministryName?.ministryName
                : data.ministryName
            }}
          </td>
          <td>
            {{
              data.agencyName?.agencyName
                ? data.agencyName?.agencyName
                : data.agencyName
            }}
          </td>
          <td>
            {{
              data.districtName?.districtName
                ? data.districtName?.districtName
                : data.districtName
            }}
          </td>
          <td>
            {{ data.ineligibilityReason }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>
<nz-card *ngIf="cirPreventedOccupationsList.length > 0">
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    {{ "Circular Prevented Occupation Info" | translate }}
  </div>
  <div nz-col [nzSpan]="24">
    <nz-table
      #tamOccupationBasicTable
      [nzData]="cirPreventedOccupationsList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzSize="middle"
      nzAlign="middle"
      [nzFrontPagination]="false"
      [nzScroll]="{ x: '1150px' }"
    >
      <thead>
        <tr>
          <th style="text-align: center; vertical-align: middle">SI.</th>
          <th style="text-align: center; vertical-align: middle">ID</th>
          <th
            style="text-align: center; vertical-align: middle"
            nzColumnKey="tamType.id"
            [nzSortFn]="true"
          >
            Document Type
          </th>
          <th
            style="text-align: center; vertical-align: middle"
            nzColumnKey="tamOccupation.tamIndustrialSector.id"
            [nzSortFn]="true"
          >
            Sector
          </th>
          <th
            style="text-align: center; vertical-align: middle"
            nzColumnKey="tamOccupation.id"
            [nzSortFn]="true"
          >
            Occupation
          </th>
          <th
            style="text-align: center; vertical-align: middle"
            nzColumnKey="tamLevel.id"
            [nzSortFn]="true"
          >
            Level
          </th>
          <th
            style="text-align: center; vertical-align: middle"
            nzColumnKey="tamLanguage.id"
            [nzSortFn]="true"
          >
            Language
          </th>
          <th
            style="text-align: center; vertical-align: middle"
            nzColumnKey="approvalDate"
            [nzSortFn]="true"
          >
            Date of Approval
          </th>
          <th
            style="text-align: center; vertical-align: middle"
            nzColumnKey="isPublished"
            [nzSortFn]="true"
          >
            Access Type
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of tamOccupationBasicTable.data; let i = index">
          <td style="text-align: center">
            {{ 1 + i }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.tamApplicationId }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.tamTypeName }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.tamIndustrialSectorName }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.tamOccupationName }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.tamLevelId }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.tamLanguageName }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.approvalDate | date : "dd-MM-YYYY" }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.isPublished === true ? "Public" : "Limited" }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>

<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    {{ "Circulars other budget" | translate }}
  </div>
  <div class="form-row">
    <div
      class="col-md-6"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
      "
    >
      <div class="col-md-12">
        <b>ব্যয়ের খাত</b>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
      "
    >
      <div class="col-md-12">
        <b>সংখ্যা/ইউনিট</b>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
      "
    >
      <div class="col-md-12">
        <b>একক প্রতি মূল্য/ব্যয়</b>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
      "
    >
      <div class="col-md-12">
        <b>মোট ব্যয়</b>
      </div>
    </div>

    <div
      class="col-md-12 text-center p-2"
      style="
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 40px;
      "
    >
      <b>মাসিক ভিত্তিতে ব্যয়</b>
    </div>

    <div
      class="col-md-6"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired
          >প্রশিক্ষকের বেতন/সম্মানী (প্রধান প্রশিক্ষক) (১জন)</nz-form-label
        >
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Number/Unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  readonly
                  placeholder="সংখ্যা/ইউনিট"
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="একক প্রতি মূল্য/ব্যয়"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="মোট ব্যয়"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-6"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired
          >প্রশিক্ষকের বেতন/সম্মানী (সহকারী/গেষ্ট প্রশিক্ষক)
          (১জন)</nz-form-label
        >
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Number/Unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  readonly
                  placeholder="সংখ্যা/ইউনিট"
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="একক প্রতি মূল্য/ব্যয়"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="মোট ব্যয়"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-6"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
      "
    >
      <div class="row">
        <div class="col-md-6">
          <div class="col-md-12" style="margin-top: 130px">
            <b>সহায়ক জনবলের জন্য প্রয়োজনীয় ব্যয়</b>
          </div>
        </div>
        <div class="col-md-6" style="border-left: solid 1px #000">
          <div class="col-md-12" style="height: 70px">
            <nz-form-label nzRequired style="margin-top: 20px"
              >সেন্টার ইন চার্জ</nz-form-label
            >
          </div>

          <div
            class="col-md-12"
            style="border-top: solid 1px #000; height: 70px"
          >
            <nz-form-label nzRequired style="margin-top: 20px"
              >হিসাব রক্ষক</nz-form-label
            >
          </div>

          <div
            class="col-md-12"
            style="border-top: solid 1px #000; height: 70px"
          >
            <nz-form-label nzRequired style="margin-top: 20px"
              >জব প্লেসমেন্ট অফিসার</nz-form-label
            >
          </div>

          <div
            class="col-md-12"
            style="border-top: solid 1px #000; height: 70px"
          >
            <nz-form-label nzRequired style="margin-top: 20px"
              >কম্পিউটার অপারেটর</nz-form-label
            >
          </div>

          <div
            class="col-md-12"
            style="border-top: solid 1px #000; height: 70px"
          >
            <nz-form-label nzRequired style="margin-top: 20px"
              >সাপোর্ট স্টাফ</nz-form-label
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-6"
      style="border-bottom: solid 1px #000; border-right: solid 1px #000"
    >
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4" style="height: 70px">
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Number/Unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="সংখ্যা/ইউনিট"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="border-left: solid 1px #000; height: 70px"
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Price/cost per unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="একক প্রতি মূল্য/ব্যয়"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="border-left: solid 1px #000; height: 70px"
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Total cost is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="মোট ব্যয়"
                      readonly
                      value=""
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="border-top: solid 1px #000; height: 70px"
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Number/Unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="সংখ্যা/ইউনিট"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="
              border-left: solid 1px #000;
              border-top: solid 1px #000;
              height: 70px;
            "
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Price/cost per unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="একক প্রতি মূল্য/ব্যয়"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="
              border-left: solid 1px #000;
              border-top: solid 1px #000;
              height: 70px;
            "
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Total cost is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="মোট ব্যয়"
                      readonly
                      value=""
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="border-top: solid 1px #000; height: 70px"
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Number/Unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="সংখ্যা/ইউনিট"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="
              border-left: solid 1px #000;
              border-top: solid 1px #000;
              height: 70px;
            "
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Price/cost per unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="একক প্রতি মূল্য/ব্যয়"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="
              border-left: solid 1px #000;
              border-top: solid 1px #000;
              height: 70px;
            "
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Total cost is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="মোট ব্যয়"
                      readonly
                      value=""
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="border-top: solid 1px #000; height: 70px"
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Number/Unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="সংখ্যা/ইউনিট"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="
              border-left: solid 1px #000;
              border-top: solid 1px #000;
              height: 70px;
            "
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Price/cost per unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="একক প্রতি মূল্য/ব্যয়"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="
              border-left: solid 1px #000;
              border-top: solid 1px #000;
              height: 70px;
            "
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Total cost is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="মোট ব্যয়"
                      readonly
                      value=""
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="border-top: solid 1px #000; height: 70px"
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Number/Unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="সংখ্যা/ইউনিট "
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="
              border-left: solid 1px #000;
              border-top: solid 1px #000;
              height: 70px;
            "
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Price/cost per unit is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="একক প্রতি মূল্য/ব্যয়"
                      value=""
                      readonly
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            class="col-md-4"
            style="
              border-left: solid 1px #000;
              border-top: solid 1px #000;
              height: 70px;
            "
          >
            <nz-form-item style="margin-top: 15px">
              <nz-form-control nzErrorTip="Total cost is required">
                <nz-form-item>
                  <nz-form-control class="row">
                    <input
                      nz-input
                      type="number"
                      placeholder="মোট ব্যয়"
                      readonly
                      value=""
                    />
                  </nz-form-control>
                </nz-form-item>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-6"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired>ক্লাসরুম ও ল্যাব ভাড়া</nz-form-label>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Number/Unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="সংখ্যা/ইউনিট "
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="একক প্রতি মূল্য/ব্যয়"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="মোট ব্যয়"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-6"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired
          >ইউটিলিটি ব্যয় (পানি, বিদ্যুৎ ইত্যাদি)</nz-form-label
        >
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Number/Unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="সংখ্যা/ইউনিট"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="একক প্রতি মূল্য/ব্যয়"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="মোট ব্যয়"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-12 text-center p-2"
      style="
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 40px;
      "
    >
      <b>প্রশিক্ষণার্থী প্রতি ব্যয়</b>
    </div>

    <div
      class="col-md-6"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired
          >ট্রেনিং ম্যাটেরিয়ালস ব্যয় (raw materials)</nz-form-label
        >
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control
            nzErrorTip="Expenditure on Training Materials is required"
          >
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Expenditure on Training Materials"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Price/Cost per unit"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Total Cost"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-6"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired
          >ষ্টেশনারী ব্যয় (কাগজ, নোটবুক, কলম, সিবিএলএম, টি শার্ট
          ইত্যাদি)</nz-form-label
        >
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control
            nzErrorTip="Expenditure on Training Materials is required"
          >
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Expenditure on Training Materials"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Price/Cost per unit"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Total Cost"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-6"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired
          >অ্যাসেসমেন্ট ও সার্টিফিকেশন ব্যয়</nz-form-label
        >
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control
            nzErrorTip="Expenditure on Training Materials is required"
          >
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Expenditure on Training Materials"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Price/Cost per unit"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Total Cost"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-6"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired
          >প্রশিক্ষণার্থীর যাতায়াত ও আপ্যায়ন ভাতা</nz-form-label
        >
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control
            nzErrorTip="Expenditure on Training Materials is required"
          >
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Expenditure on Training Materials"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Price/Cost per unit"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Total Cost"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-12 text-center p-2"
      style="
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 40px;
      "
    >
      <b>থোক ব্যয়</b>
    </div>

    <div
      class="col-md-6"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-label nzRequired
          >বিজ্ঞাপন ব্যয় (পত্রিকা, লিফলেট ইত্যাদি)</nz-form-label
        >
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control
            nzErrorTip="Expenditure on Training Materials is required"
          >
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Expenditure on Training Materials"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Price/cost per unit is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Price/Cost per unit"
                  value=""
                  readonly
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      class="col-md-2"
      style="
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        height: 70px;
      "
    >
      <div class="col-md-12" style="margin-top: 20px">
        <nz-form-item>
          <nz-form-control nzErrorTip="Total cost is required">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="number"
                  placeholder="Total Cost"
                  readonly
                  value=""
                />
              </nz-form-control>
            </nz-form-item>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div> -->


  <!-- <form [formGroup]="otherExpenseSectorForm">
    <div class="col-md-12 text-center pt-3">
      <h3>অন্যান্য ব্যয় (যদি থাকে) (সুনির্দিষ্ট খাত অনুযায়ী)</h3>
    </div>

    <div formArrayName="expenseSectorFormArray">
      <div
        class="mb-2 mt-4 row pt-3"
        *ngFor="
          let item of newExpenseSectorFormArray.controls;
          let formIndex = index
        "
        [formGroupName]="formIndex"
      >
        <div class="col-md-2">
          <nz-form-label style="margin-left: 25px">{{
            "Others" | translate : { index: 1 + formIndex }
          }}</nz-form-label>
        </div>
        <div class="col-md-3">
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzErrorTip]="expenseSectorErrorTpl">
              <input
                nz-input
                type="text"
                placeholder="Others"
                formControlName="expenseSector"
                readonly
              />
              <ng-template #expenseSectorErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Insert other expense please!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div class="mb-2 row">
      <div class="col-md-2">
        <p></p>
      </div>
    </div>
  </form> -->

<!-- </nz-card> -->

<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    {{ "Circular Attachment Info" | translate }}
  </div>
  <div nz-col [nzSpan]="24">
    <nz-table
      #basicTableOfAttachment
      [nzData]="attachmentList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzSize="middle"
      nzAlign="middle"
      [nzFrontPagination]="false"
    >
      <thead>
        <tr>
          <th style="text-align: center; vertical-align: middle; width: 5%">
            SI.
          </th>
          <th style="text-align: center; vertical-align: middle">File Name</th>
          <th style="text-align: center; vertical-align: middle">Preview</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of basicTableOfAttachment.data; let i = index">
          <td style="text-align: center; vertical-align: middle">
            <nz-form-control style="text-align: center; vertical-align: middle">
              {{ i + 1 }}
            </nz-form-control>
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ item?.attachmentTitle }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            <button
              nz-button
              type="button"
              nzType="default"
              [nzSize]="'small'"
              (click)="setBase64AttachmentforPreviewing('documents', i)"
            >
              <i nz-icon nzType="eye" nzTheme="fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>
<div class="row justify-content-center media-button mt-4">
  <div class="col-xs-6 col-sm-2">
    <a
      type="button"
      class="btn btn-primary active btn-lg btn-block cari border-redius"
      style="margin: 5%"
      [routerLink]="['/login']"
      target="_blank"
      routerLinkActive="router-link-active"
    >
      <i nz-icon nzType="login" nzTheme="outline"></i>
      {{ "Click To Apply" | translate }}
    </a>
  </div>
</div>
<!-- <nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    History
  </div>
  <div nz-col [nzSpan]="24">
    <nz-table
      #recHistory
      [nzData]="historyList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzSize="middle"
      nzAlign="middle"
      [nzFrontPagination]="false"
      [nzScroll]="{ x: '200px', y: '120px' }"
    >
      <thead>
        <tr style="overflow: auto">
          <th style="text-align: center" rowspan="1">Sl.</th>
          <th style="text-align: center" rowspan="2">Remarks</th>
          <th style="text-align: center" rowspan="2">Action Date</th>
          <th style="text-align: center" rowspan="2">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of recHistory.data; let i = index">
          <td style="text-align: center">
            {{ 1 + i }}
          </td>
          <td style="text-align: center">
            {{ data.remarks ? data.remarks : "N/A" }}
          </td>
          <td style="text-align: center">
            {{ data.actionDate | date : "dd-MM-yy h:mm:ss a" }}
          </td>
          <td style="text-align: center">
            {{ data.statusType?.name }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card> -->
<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzClosable]="false"
  [nzOkText]="null"
  [nzWidth]="1200"
>
  <ng-container *nzModalContent>
    <iframe
      *ngIf="!isImageLoading && pdfUrl"
      [src]="pdfUrl"
      style="width: 100%; height: 35vw"
    ></iframe>
    <div style="text-align: center">
      <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
      <ng-template #noImageFound>
        <img
          src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
          alt="Fallbackimage"
        />
      </ng-template>
    </div>
  </ng-container>
</nz-modal>
<app-footer></app-footer>
