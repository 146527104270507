import { CommonService } from './../../shared/services/common.service';
import { DatePipe } from '@angular/common';
import { ReportModel } from './models/report-model';
import { NewEventModel } from './models/new-event-model';
import { StpRegistrationService } from './../stp-registration/services/stp-registration.service';
import { Division } from './../../shared/models/common.model';
import { TrainingAndAssessmentMaterialsService } from './../training-and-assessment-materials/services/training-and-assessment-materials.service';
import { map, tap } from 'rxjs/operators';
import { TamIndustrialSector } from './../training-and-assessment-materials/models/tam-Industrial-sector.model';
import { Observable } from 'rxjs';
import { applicationUrls } from './../../shared/application-constants/application-urls.const';
import { ServerResponse } from './../../shared/models/dto/server-response.dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventModuleService {
  dateFromTemp: any;
  dateToTemp: any;

  constructor(
    private httpClient: HttpClient,
    private stpRegistrationService: StpRegistrationService,
    private datePipe: DatePipe,
    private commonService: CommonService
  ) { }

  getEventTypes() {
    return this.httpClient.get<ServerResponse>(`${applicationUrls.event.getEventTypes}`);
  }

  getOrganizer() {
    return this.httpClient.get<ServerResponse>(`${applicationUrls.event.getOrganizer}`);
  }

  getEventTypesPublic() {
    return this.httpClient.get<ServerResponse>(`${applicationUrls.event.getEventTypesPublic}`);
  }

  getOrganizersPublic() {
    return this.httpClient.get<ServerResponse>(`${applicationUrls.event.getOrganizersPublic}`);
  }

  readIndustrialSectors() {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tamIndustrialSector.read}`);
  }

  readStpDivisionsBYCountryID(countryId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpDivision}${countryId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((division: Division[]) => {
          this.stpRegistrationService.setSTPDivisions(division);
        })
      );
  }

  readStpDistrictsByDivisionId(divisionId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpDistrictByDivisionId}` +
        `districts/${divisionId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  saveNewEvent(newEvent: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.event.createUpdatetNewEvent}`, newEvent);
  }

  submitNewEvent(id?: number): Observable<any> {
    let scheduleDTO = {
      id: id,
    };
    return this.httpClient.post<any>(`${applicationUrls.event.submitNewEvent}`, scheduleDTO);
  }

  postponeNewEvent(id?: number, status?: string, remarks?: string | null): Observable<any> {
    let scheduleDTO = {
      id: id,
      status: status,
      remarks: remarks
    };
    return this.httpClient.post<any>(`${applicationUrls.event.postponeNewEvent}`, scheduleDTO);
  }

  getNewEvent(eventId: number) {
    return this.httpClient.get<any>(`
    ${applicationUrls.event.getNewEvent}?id=${eventId}`);
  }

  reviewEvent(id?: number, status?: string, remarks?: string | null): Observable<any> {
    let reviewRequestDTO = {
      id: id,
      status: status,
      remarks: remarks
    };
    return this.httpClient.post<any>(`${applicationUrls.event.reviewNewEvent}`, reviewRequestDTO);
  }

  saveReport(reportModel: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.event.createUpdateReport}`, reportModel);
  }

  submitReport(id?: number): Observable<any> {
    let scheduleDTO = {
      id: id,
    };
    return this.httpClient.post<any>(`${applicationUrls.event.submitReport}`, scheduleDTO);
  }

  getReport(id: number) {
    return this.httpClient.get<any>(`
    ${applicationUrls.event.getReport}?id=${id}`);
  }

  reviewReport(id?: number, status?: string, remarks?: string | null): Observable<any> {
    let reviewRequestDTO = {
      id: id,
      status: status,
      remarks: remarks
    };
    return this.httpClient.post<any>(`${applicationUrls.event.reviewReport}`, reviewRequestDTO);
  }

  getAllDashboard(searchData: any, page?: number | 0, size?: number | 50, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    this.dateFromTemp = this.datePipe.transform(searchData?.dateFrom, 'yyyy-MM-dd');
    this.dateToTemp = this.datePipe.transform(searchData?.dateTo, 'yyyy-MM-dd');
    let pageableRequestDTO = {
      eventType: searchData?.eventType,
      eventStatus: searchData?.eventStatus,
      orgName: searchData?.orgName,
      eventTitle: searchData?.eventTitle,
      dateFrom: this.dateFromTemp,
      dateTo: this.dateToTemp,
      isAdmin: searchData?.isAdmin,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(
      `${applicationUrls.event.getDashboard}`, pageableRequestDTO);
  }

  downloadDashboard(searchData: any, page?: number | 0, size?: number | 100, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    this.dateFromTemp = this.datePipe.transform(searchData?.dateFrom, 'yyyy-MM-dd');
    this.dateToTemp = this.datePipe.transform(searchData?.dateTo, 'yyyy-MM-dd');
    let pageableRequestDTO = {
      eventType: searchData?.eventType,
      eventStatus: searchData?.eventStatus,
      orgName: searchData?.orgName,
      eventTitle: searchData?.eventTitle,
      dateFrom: this.dateFromTemp,
      dateTo: this.dateToTemp,
      // isAdmin: searchData?.isAdmin,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(
      `${applicationUrls.event.downloadDashboard}`, pageableRequestDTO);
  }

  readDivisions(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.userLocation.readDivisions}`)
      .pipe(
        tap((response) => {
          this.commonService.setDivisions(response.data);
        })
      );
  }

  readDistricts(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.userLocation.readDistricts}`)
  }

  deleteScheduleById(scheduleId: number): Observable<ServerResponse> {
    return this.httpClient.delete<ServerResponse>(
      `${applicationUrls.event.deleteScheduleById}` + scheduleId
    );
  }

  getDashboardPublic(searchData: any, page?: number | 0, size?: number | 50, sortingKey?: string | null, sortingValue?: string | null): Observable<any> {
    this.dateFromTemp = this.datePipe.transform(searchData?.dateFrom, 'yyyy-MM-dd');
    this.dateToTemp = this.datePipe.transform(searchData?.dateTo, 'yyyy-MM-dd');
    let pageableRequestDTO = {
      eventType: searchData?.eventType,
      eventStatus: searchData?.eventStatus,
      orgName: searchData?.orgName,
      eventTitle: searchData?.eventTitle,
      dateFrom: this.dateFromTemp,
      dateTo: this.dateToTemp,
      isAdmin: searchData?.isAdmin,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue
    };
    return this.httpClient.post<any>(
      `${applicationUrls.event.getDashboardPublic}`, pageableRequestDTO);
  }

  getNewEventPublic(eventId: number) {
    return this.httpClient.get<any>(`
    ${applicationUrls.event.getNewEventPublic}?id=${eventId}`);
  }

  getReportPublic(id: number) {
    return this.httpClient.get<any>(`
    ${applicationUrls.event.getReportPublic}?id=${id}`);
  }
}

