import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RplStorageService } from 'src/app/modules/stp-operations/services/rpl-storage.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-view-reg-certificate',
  templateUrl: './view-reg-certificate.component.html',
  styleUrls: ['./view-reg-certificate.component.scss'],
})
export class ViewRegularCertificateComponent implements OnInit {
  regCandidateId: string | null;
  certificateData: any;
  competent: boolean = false;
  isLoadingData: boolean = false;
  officeText: string;

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService
  ) {
    this.regCandidateId =
      this.route.snapshot.queryParamMap.get('regCandidateId');
    if (this.regCandidateId != null) {
      this.getCertificateDataForRegularCandidate();
    }
  }

  ngOnInit(): void {}

  getCertificateDataForRegularCandidate() {
    this.commonService
      .getRegularCertificateByEncryptedId(this.regCandidateId)
      .subscribe({
        next: async (result: any) => {
          this.certificateData = await result;
          this.competent = this.certificateData.isCompetent;
          //#region change office name logic
          let actualAssessDate = this.convertStringToDate(
            this.certificateData.actualAssessDate
          );
          const cutoffDate = this.convertStringToDate('08-08-2024');
          // Compare dates
          this.officeText =
            actualAssessDate <= cutoffDate
              ? 'Prime Minister’s Office'
              : 'Chief Adviser’s Office';
          //#endregion
          this.isLoadingData = true;
        },
      });
  }
  convertStringToDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }
}
