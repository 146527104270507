<div class="card">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> STP/ SAC Registration
      Inspection Report Preview
    </div>
    <div class="searchboxAerar p-5">
      <!-- <form nz-form [formGroup]="inspectionForm" (ngSubmit)="submitForm()"> -->
      <!-- <div formArrayName="inspectionFormArray"> -->
      <div
        class="row"
        style="margin-bottom: 10px"
        *ngFor="let item of inspectionRegistrationRecords"
      >
        <div class="col-sm-4">
          <!-- <label><b>{{item.get('name')?.value}}</b> </label> -->
          <nz-form-label nzRequired>{{ item.title }}</nz-form-label>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-3">
              <button
                type="button"
                class="btn btn-success btn-sm"
                (click)="showModal(item.modalIdentity, item.title)"
              >
                View Details
              </button>
            </div>
            <div class="col-sm-4">
              <div style="margin-top: auto">
                <label
                  class="button"
                  [ngClass]="{
                    label: true,
                    other: item.isCorrect,
                    danger: !item.isCorrect
                  }"
                  >{{ item.isCorrect ? "Found Correct" : "Not Correct" }}</label
                >
              </div>
            </div>
            <div class="col-sm-4">
              <div style="margin-top: auto">
                <label
                  class="button"
                  [ngClass]="{
                    label: true,
                    other: item.remarks
                  }"
                  >{{ item.remarks }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-top: 20px">
        <hr />
        <b>Documents List</b>
        <hr />
      </div>
      <div nz-col [nzSpan]="24" class="mt-3">
        <nz-table
          nzSize="small"
          #inspectionAttachmentList
          [nzData]="inspectionAttachment"
          nzShowSizeChanger
          nzBordered
          nzAlign="middle"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          class="css-serial table-striped table"
        >
          <thead>
            <tr>
              <th style="text-align: center; vertical-align: middle">SI.</th>
              <th rowspan="2" nzColumnKey="">File Name</th>
              <th
                style="text-align: center; vertical-align: middle"
                rowspan="2"
                nzColumnKey=""
              >
                Preview
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inspectionAttachmentList.data; let i = index">
              <td style="text-align: center">{{ 1 + i }}</td>
              <td>{{ data.fileName }}</td>
              <td style="text-align: center; vertical-align: middle">
                <button
                  nz-button
                  type="button"
                  nzType="default"
                  [nzSize]="'small'"
                  (click)="previewAllImage(i, data.fileType)"
                >
                  <i nz-icon nzType="eye" nzTheme="fill"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <div *ngIf="!isScrutinyVisible" style="padding-top: 20px">
        <hr />
        <b>Remarks</b>
        <hr />
      </div>
      <div *ngIf="!isScrutinyVisible">
        <nz-descriptions nzBorderd>
          <nz-descriptions-item nzSpan="3" nzTitle="Inpsection Remarks">
            {{ inpsectorRemarks ? inpsectorRemarks : 'NA' }}
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="3" nzTitle="Scrutiny Remarks">
            {{ scrutinyFinalRemarks ? scrutinyFinalRemarks: 'NA' }}
          </nz-descriptions-item>
        </nz-descriptions>
      </div>
    </div>
    <div
      class="row justify-content-center media-button mt-4"
      *ngIf="!isScrutinyVisible"
    >
      <div class="col-xs-6 col-sm-3">
        <button
          type="button"
          class="btn btn-info active btn-lg btn-block cari border-redius"
          (click)="backToPreviousPage()"
          nz-button
        >
          <i nz-icon nzType="backward" nzTheme="fill"></i>Back to Previous Page
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="basicProfileForm != null">
    <nz-modal
      [(nzVisible)]="isVisible"
      [nzTitle]="modalTitle"
      (nzOnCancel)="handleCancelModal()"
      (nzOnOk)="handleOk()"
      [nzOkLoading]="isOkLoading"
    >
      <nz-card *ngIf="modalTabName == 'basic'">
        <div class="ng-Header col-xs-12">
          <i nz-icon nzType="form" nzTheme="outline"></i> Basic Profile
        </div>
        <div
          class="searchboxAerarWithoutShadow"
          style="background-color: #efefef"
        >
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">Name of STP</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.stpName }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">
                Name of STP in Bangla</nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ basicProfileForm.stpNameBangla }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">Type of STP</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.stpType }}
              </nz-form-label>
            </div>
          </div>
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">Type of Ownership</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.stpOwnershipType }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"> Name of Ministry</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.stpMinistry }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">
                Location of the STP</nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ basicProfileForm.stpLocation }}
              </nz-form-label>
            </div>
          </div>
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"
                >Year of Establishment</nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ basicProfileForm.establishYear }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">Name of Agency</nz-form-label>
              <nz-form-label nzNoColon style="word-break: break-all">
                {{ basicProfileForm.stpAgency }}
              </nz-form-label>
            </div>
          </div>
          <div class="background">
            <hr />
            <b style="margin-left: 10px">Address of the organization</b>
            <hr />
          </div>
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"> Country</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.stpCountry }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">Division/State</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.division }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">District/ City</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.district }}
              </nz-form-label>
            </div>
          </div>
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">
                Upazilla/Sub-district</nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ basicProfileForm.upazilla }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"
                >House, Road, Village Area</nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ basicProfileForm.address }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"> Contact number</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.contactNo }}
              </nz-form-label>
            </div>
          </div>
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"> Email address</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.contactEmail }}
              </nz-form-label>
            </div>
          </div>
          <div class="background">
            <hr />
            <b style="margin-left: 10px">Owner/Principal/Head of the STP</b>
            <hr />
          </div>
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">Name</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.contactName }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"> Designation</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.contactDesignation }}
              </nz-form-label>
            </div>
  
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"> Contact number</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.contactNumber }}
              </nz-form-label>
            </div>
  
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"> Contact Email</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.contactEmail }}
              </nz-form-label>
            </div>
          </div>
          <div class="background">
            <hr />
            <b style="margin-left: 10px">Managing Committee</b>
            <hr />
          </div>
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight"
                >Is there any committee?</nz-form-label
              >
              <span class="ml-2">
                <span *ngIf="basicProfileForm.isCommitte">Yes</span>
                <span *ngIf="!basicProfileForm.isCommitte">No</span>
              </span>
            </div>
            <div class="form-group col-md-4" *ngIf="basicProfileForm.isCommitte">
              <nz-form-label class="text-weight"> Total Member</nz-form-label>
              <nz-form-label nzNoColon>
                {{ basicProfileForm.totalMember }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4" *ngIf="basicProfileForm.isCommitte">
              <nz-form-label class="text-weight">
                Total Female Member</nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ basicProfileForm.totalFemaleMember }}
              </nz-form-label>
            </div>
          </div>
          <div
            class="form-row"
            style="margin-left: 12px"
            *ngIf="basicProfileForm.isCommitte"
          >
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">
                Date of Approval of the Committee</nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ basicProfileForm.approvalDateCommittee }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-4">
              <nz-form-label class="text-weight">
                Date of Expiry of the Committee</nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ basicProfileForm.expirationDateCommittee }}
              </nz-form-label>
            </div>
          </div>
        </div>
      </nz-card>
  
      <nz-card *ngIf="modalTabName == 'Physical'">
        <div class="ng-Header col-xs-12">
          <i nz-icon nzType="form" nzTheme="outline"></i> Physical Infrastructure
          Details
        </div>
        <div
          class="searchboxAerarWithoutShadow"
          style="background-color: #efefef"
        >
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="col-form-label"
                ><b>Construction Type of the Building</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.constructionBuilding }}
              </nz-form-label>
            </div>
  
            <div class="form-group col-md-6">
              <nz-form-label>
                <b>Ownership Status of the Building</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.ownershipBuilding }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label
                ><b>Total Area of the Center (in Square Meters)</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.totalArea }}
              </nz-form-label>
            </div>
  
            <div class="form-group col-md-6">
              <nz-form-label><b>Number of Floors</b> </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.floor }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label
                ><b>Average Size of the Classrooms (in Square Meters)</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.classroomSize }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label><b>Number of Classrooms</b> </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.classroom }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label
                ><b>Average Size of the Workshop (in Square Meters)</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.workshopSize }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label><b>Number of Workshops</b> </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.workshop }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label
                ><b>Average Size of the Computer Labs (in Square Meters)</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.sizeOfComputerLabs }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label><b>Number of Computer Labs</b> </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.noOfComputerLabs }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label><b>Number of Office Rooms</b> </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.office }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label><b>Number of Trainer's Room</b> </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.trainingRoom }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label><b>Number of Washrooms</b></nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.washroom }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label><b>Number of Female Washrooms</b></nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.femaleWashroom }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label><b>Is there internet facilities?</b></nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.isInternet == true ? "Yes" : "No" }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label
                ><b>Is there electricity connections?</b></nz-form-label
              >
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.isElectricity == true ? "Yes" : "No" }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="col-form-label"
                ><b>Is there sufficient fire fighting equipment?</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.isFireEq == true ? "Yes" : "No" }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label class="col-form-label">
                <b>Is there library facility?</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.isLibrary == true ? "Yes" : "No" }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="col-form-label"
                ><b>Is there facility for safe drinking water?</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.isDrinkingWater == true ? "Yes" : "No" }}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label class="col-form-label"
                ><b>Is there sufficient light and ventilation?</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.isLightingVent == true ? "Yes" : "No" }}
              </nz-form-label>
            </div>
          </div>
  
          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="col-form-label"
                ><b>Is the infrastructure friendly for people with disability?</b>
              </nz-form-label>
              <nz-form-label nzNoColon>
                {{ physicalInfraForm.isDisableFriendly == true ? "Yes" : "No" }}
              </nz-form-label>
            </div>
          </div>
        </div>
      </nz-card>
  
      <nz-card *ngIf="modalTabName == 'occupation'">
        <div class="ng-Header col-xs-12">
          <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Occupation
          specific trainer list
        </div>
  
        <nz-table
          #basicTable
          [nzData]="stpTrainers"
          nzTableLayout="fixed"
          nzShowSizeChanger
          nzBordered
          nzSize="middle"
          nzAlign="middle"
          class="table table-responsive"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
        >
          <ng-template
            #totalRowRangeTemplate
            let-range="range"
            let-total
            style="text-align: left"
          >
            <div style="text-align: left">
              Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
            </div>
          </ng-template>
          <thead>
            <tr>
              <th
                style="text-align: center"
                class="thableLabel"
                rowspan="2"
                [nzWidth]="'60px'"
              >
                Sl.
              </th>
              <th style="text-align: center" class="thableLabel" rowspan="2">
                Name of occupation
              </th>
              <th style="text-align: center" class="thableLabel" rowspan="2">
                Number of post for trainer
              </th>
              <th style="text-align: center" class="thableLabel" colspan="3">
                Number of available certified trainers
              </th>
              <th style="text-align: center" class="thableLabel" rowspan="2">
                Number of female trainers
              </th>
              <th style="text-align: center" class="thableLabel" rowspan="2">
                Number of guest trainers
              </th>
              <th style="text-align: center" class="thableLabel" rowspan="2">
                Number of vacant post
              </th>
            </tr>
            <tr>
              <th style="text-align: center" class="thableLabel">Full time</th>
              <th style="text-align: center" class="thableLabel">Part time</th>
              <th style="text-align: center" class="thableLabel">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data; let i = index">
              <td style="text-align: center">{{ i + 1 }}</td>
              <td style="text-align: center" class="rowOne">
                {{
                  data.stpTrainerOccupation ? data.stpTrainerOccupation : "N/A"
                }}
              </td>
              <td style="text-align: center" class="rowOne">
                {{ data.noOfPost }}
              </td>
              <td style="text-align: center" class="rowOne">
                {{ data.noOfFulltime }}
              </td>
              <td style="text-align: center" class="rowOne">
                {{ data.noOfPartime }}
              </td>
              <td style="text-align: center" class="rowOne">
                {{ data.noOfFulltime + data.noOfPartime }}
              </td>
              <td style="text-align: center" class="rowOne">
                {{ data.noOfFemale }}
              </td>
              <td style="text-align: center" class="rowOne">
                {{ data.noOfGuest }}
              </td>
              <td style="text-align: center" class="rowOne">
                {{ data.noOfVaccant }}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-card>
  
      <nz-card *ngIf="modalTabName == 'management'">
        <div class="ng-Header col-xs-12">
          <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Management and
          Supporting Staff List
        </div>
        <div nz-col [nzSpan]="24">
          <nz-table
            #stpManagementList
            [nzData]="stpManagements"
            nzTableLayout="fixed"
            nzShowSizeChanger
            nzBordered
            nzSize="middle"
            nzAlign="middle"
            [nzFrontPagination]="false"
            [nzShowPagination]="false"
          >
            <ng-template
              #totalRowRangeTemplate
              let-range="range"
              let-total
              style="text-align: left"
            >
              <div style="text-align: left">
                Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
              </div>
            </ng-template>
            <thead>
              <tr>
                <th style="text-align: center" rowspan="2" [nzWidth]="'60px'">
                  Sl.
                </th>
                <th style="text-align: center" rowspan="2">Name of Position</th>
                <th style="text-align: center" rowspan="2">Number of Post</th>
                <th style="text-align: center" colspan="3">
                  Number of Available staff
                </th>
                <th style="text-align: center" rowspan="2">
                  Number of Female staff
                </th>
                <th style="text-align: center" rowspan="2">
                  Number of Vacant post
                </th>
              </tr>
              <tr>
                <th style="text-align: center">Full Time</th>
                <th style="text-align: center">Part Time</th>
                <th style="text-align: center">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of stpManagementList.data; let i = index">
                <td style="text-align: center">{{ i + 1 }}</td>
                <td style="text-align: center">{{ data.positionName }}</td>
                <td style="text-align: center">{{ data.noOfPost }}</td>
                <td style="text-align: center">{{ data.noOfFulltime }}</td>
                <td style="text-align: center">{{ data.noOfPartime }}</td>
                <td style="text-align: center">
                  {{ data.noOfFulltime + data.noOfPartime }}
                </td>
                <td style="text-align: center">{{ data.noOfFemale }}</td>
                <td style="text-align: center">{{ data.noOfVaccant }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
  
      <nz-card *ngIf="modalTabName == 'financial'">
        <div class="card">
          <div class="ng-Header col-xs-12">
            <i nz-icon nzType="form" nzTheme="outline"></i> Financial Details
          </div>
          <div
            class="searchboxAerarWithoutShadow"
            style="background-color: #efefef"
          >
            <div class="form-row" style="margin-left: 12px">
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight"
                  >Reserve Fund (in BDT)
                </nz-form-label>
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.reserveFund }}
                </nz-form-label>
              </div>
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight">FDR (in BDT)</nz-form-label>
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.fdr }}
                </nz-form-label>
              </div>
            </div>
  
            <div class="form-row" style="margin-left: 12px">
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight"
                  >General Fund (in BDT)</nz-form-label
                >
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.generalFund }}
                </nz-form-label>
              </div>
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight"
                  >Available Balance Account (in BDT)</nz-form-label
                >
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.availableBalanceInBankAccount }}
                </nz-form-label>
              </div>
            </div>
  
            <div class="form-row" style="margin-left: 12px">
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight">Account Number</nz-form-label>
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.accountNumber }}
                </nz-form-label>
              </div>
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight">Bank Name</nz-form-label>
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.bankName }}
                </nz-form-label>
              </div>
            </div>
  
            <div class="form-row" style="margin-left: 12px">
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight">Branch Name</nz-form-label>
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.branchName }}
                </nz-form-label>
              </div>
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight">Other Funds</nz-form-label>
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.otherFunds }}
                </nz-form-label>
              </div>
            </div>
  
            <div class="form-row" style="margin-left: 12px">
              <div class="form-group col-md-6">
                <nz-form-label class="text-weight">Remarks</nz-form-label>
                <nz-form-label nzNoColon>
                  {{ financialDetailForm.remarks }}
                </nz-form-label>
              </div>
            </div>
  
            <div class="background">
              <hr />
              <h6>
                <b style="margin-left: 10px"
                  >Income And Expenditure of Last Financial Year</b
                >
              </h6>
              <hr />
            </div>
  
            <div class="row">
              <div class="col">
                <div>
                  <b style="margin-left: 10px">Sources of Income</b>
                  <hr />
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Serial No.</th>
                        <th scope="col">Sources of Income</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Fees from Students</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.feesFromStudents"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Fees from Students"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Income from Own Assets</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.incomeFromOwnAssets"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Income from Own Assets"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>From Government/Development Partners</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.fromGovernment"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="From Government/ Development Partners"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>Development Charge</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.developmentCharge"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Development Charge"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>Admission Fee</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.admissionFee"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Admission Fee"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>Examination Fee</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.examinationFee"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Examination Fee"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>Session Charge</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.sessionCharge"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Session Charge"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td>Selling of Products Produced</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="
                                financialDetailForm.sellingOfProductsProduced
                              "
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Selling of Products Produced"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">9</th>
                        <td>Others</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.others_source"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Others"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">10</th>
                        <td>Total Amount</td>
                        <td>{{ totalIncomes?.toFixed(1) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col">
                <div>
                  <b style="margin-left: 10px">Head of Expenditure</b>
                  <hr />
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Serial No.</th>
                        <th scope="col">Head of Expenditure</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Salary of Instructors And Staff</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="
                                financialDetailForm.salaryOfInstructorsAndStaff
                              "
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Salary of Instructors And Staff"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Incidental Expenses</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.incidentalExpenses"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Incidental Expenses"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Repairing Cost</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.repairingCost"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Repairing Cost"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>Examination Expenses</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.examinationExpenses"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Examination Expenses"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>Travel Expenses</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.travelExpenses"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Travel Expenses"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>Procurement of Books</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.procurementOfBooks"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Procurement of Books"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>Procurement of Training Learning Materials</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="
                                financialDetailForm.procurementOfTrainingLearningMaterials
                              "
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Procurement of Training Learning Materials"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td>Procurement of Raw Materials</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="
                                financialDetailForm.procurementOfRawMaterials
                              "
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Procurement of Raw Materials"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">9</th>
                        <td>Others</td>
                        <td>
                          <nz-form-control
                            nzValidatingTip="Validating..."
                            nzErrorTip="Input must be number"
                          >
                            <input
                              nz-input
                              [value]="financialDetailForm.others_head"
                              style="border: hidden; background-color: #eee"
                              type="number"
                              min="0"
                              placeholder="Others"
                              readonly
                            />
                          </nz-form-control>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">10</th>
                        <td>Total Amount</td>
                        <td>{{ totalExpenses?.toFixed(1) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-card>
      <nz-card *ngIf="modalTabName == 'tools'">
        <div class="card">
          <div class="ng-Header col-xs-12">
            <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
            Tools/Equipment/Training Learning Materials List
          </div>
          <nz-table
            #basicTable2
            [nzData]="stpOccupationTools"
            nzTableLayout="fixed"
            nzShowSizeChanger
            nzBordered
            nzSize="middle"
            nzAlign="middle"
            class="table table-responsive"
            [nzFrontPagination]="false"
            [nzShowPagination]="false"
          >
            <thead>
              <tr>
                <th
                  style="text-align: center"
                  nz-th
                  [nzWidth]="'60px'"
                  class="thableLabel"
                  rowspan="1"
                >
                  Sl.
                </th>
                <th style="text-align: center" class="thableLabel" colspan="2">
                  Occupation Name
                </th>
                <th style="text-align: center" class="thableLabel" colspan="2">
                  Name of Tools/Equipment/Training Learning Materials
                </th>
                <th style="text-align: center" class="thableLabel" rowspan="2">
                  Quantity
                </th>
                <th style="text-align: center" class="thableLabel" rowspan="2">
                  Unit
                </th>
                <th style="text-align: center" class="thableLabel" rowspan="2">
                  Remarks
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable2.data; let i = index">
                <td style="text-align: center">{{ i + 1 }}</td>
                <td style="text-align: center" colspan="2" class="rowOne">
                  {{ data.stpTrainerOccupation }}
                </td>
                <td style="text-align: center" colspan="2">
                  {{ data.toolName }}
                </td>
                <td style="text-align: center">{{ data.quantity }}</td>
                <td style="text-align: center" class="rowOne">
                  <!-- {{
                  data.sysUnit
                    ? data.sysUnit.unitName
                    : "N/A"
                }} -->
                  {{ data.unit ? data.unit : "N/A" }}
                </td>
                <td style="text-align: center">{{ data.remarks }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
      <nz-card *ngIf="modalTabName == 'furniture'">
        <div class="card">
          <div class="ng-Header col-xs-12">
            <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Furniture
            List
          </div>
          <nz-table
            #basicTable3
            [nzData]="furnitures"
            nzTableLayout="fixed"
            nzShowSizeChanger
            nzBordered
            nzSize="middle"
            nzAlign="middle"
            class="table table-responsive"
            [nzFrontPagination]="false"
            [nzShowPagination]="false"
          >
            <ng-template #totalRowRangeTemplate let-range="range" let-total>
              <div style="text-align: left">
                Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
              </div>
            </ng-template>
            <thead>
              <tr>
                <th nz-th [nzWidth]="'60px'" style="text-align: center">Sl.</th>
                <th style="text-align: center" rowspan="1">Name of Furniture</th>
                <th style="text-align: center" nz-th [nzWidth]="'110px'">
                  Quantity
                </th>
                <th style="text-align: center" nz-th [nzWidth]="'110px'">Unit</th>
                <th style="text-align: center" rowspan="1">Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable3.data; let i = index">
                <td style="text-align: center">{{ i + 1 }}</td>
                <td style="text-align: center">{{ data.furnitureName }}</td>
                <td style="text-align: center">{{ data.quantity }}</td>
                <td style="text-align: center">{{ data.unit }}</td>
                <td style="text-align: center">{{ data.remarks }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
    </nz-modal>
    <nz-modal
      [(nzVisible)]="isAttachmentVisible"
      (nzOnCancel)="handleAttachmentModalCancel()"
      [nzClosable]="false"
      [nzOkText]="null"
      [nzWidth]="1200"
    >
      <ng-container *nzModalContent>
        <iframe
          *ngIf="!isImageLoading && pdfUrl"
          [src]="pdfUrl"
          style="width: 100%; height: 35vw"
        ></iframe>
        <div style="text-align: center">
          <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
          <ng-template #noImageFound>
            <img
              src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
              alt="Fallbackimage"
            />
          </ng-template>
        </div>
      </ng-container>
    </nz-modal>
  </div>
  
  <div class="card" *ngIf="isScrutinyVisible">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> Scrutiny Result
    </div>
    <div class="searchboxAerar p-3">
      <!-- {{scrutinyForm.value | json}} -->
      <form nz-form [formGroup]="scrutinyForm" (ngSubmit)="submitScrutinyForm()">
        <!-- <hr />
        <b style="margin-left: 10px">Scrutiny Result</b>
        <hr /> -->
  
        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-3">
            <nz-form-label nzRequired style="margin-left: 10px"
              >Result of scrutiny</nz-form-label
            >
            <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="scrutinyErrorTpl"
                >
                  <nz-select
                    id="scrutinyResult_id"
                    nzShowSearch
                    nzAllowClear
                    formControlName="scrutinyResultId"
                    nzPlaceHolder="Select scrutiny"
                    class="row"
                  >
                    <nz-option
                      *ngFor="let scrutiny of scrutinyList"
                      [nzValue]="scrutiny.id"
                      [nzLabel]="scrutiny.name"
                    >
                    </nz-option>
                  </nz-select>
                  <ng-template #scrutinyErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      Please select result of scrutiny
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
  
          <div class="form-group col-md-3">
            <nz-form-label nzRequired style="margin-left: 10px"
              >Remarks
            </nz-form-label>
            <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="remarksErrorTpl"
                >
                  <input
                    nz-input
                    formControlName="scrutinyResultRemarks"
                    placeholder="Remarks"
                  />
                  <ng-template #remarksErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      Please input remarks!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
  
          <div class="form-group col-md-3">
            <nz-form-label nzRequired style="margin-left: 10px"
              >Final recommendation</nz-form-label
            >
            <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="recommendationErrorTpl"
                >
                  <nz-select
                    id="scrutinyRecommendation_id"
                    nzShowSearch
                    nzAllowClear
                    formControlName="scrutinyRecommendationId"
                    nzPlaceHolder="Select recommendation"
                    class="row"
                  >
                    <nz-option
                      *ngFor="let recommendation of recommendations"
                      [nzValue]="recommendation.id"
                      [nzLabel]="recommendation.name"
                    >
                    </nz-option>
                  </nz-select>
                  <ng-template #recommendationErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      Please select final recommendation!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
  
          <div class="form-group col-md-3">
            <nz-form-label nzRequired style="margin-left: 10px"
              >Remarks
            </nz-form-label>
            <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="remarksErrorTpl"
                >
                  <input
                    nz-input
                    formControlName="scrutinyRecommendationRemarks"
                    placeholder="Remarks"
                  />
                  <ng-template #remarksErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      Please input remarks!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
  
        <div class="row justify-content-center media-button">
          <div class="col-xs-6 col-sm-3">
            <button
              type="button"
              class="btn btn-info active btn-lg btn-block cari border-redius"
              (click)="backToPreviousPage()"
              nz-button
            >
              <i nz-icon nzType="backward" nzTheme="fill"></i>Back to Previous Page
            </button>
          </div>
          <div class="col-xs-6 col-sm-2">
            <button
              type="submit"
              class="btn btn-success active btn-lg btn-block cari border-redius"
              [disabled]="!scrutinyForm.valid || saveButtonDisabled"
            >
              <i nz-icon nzType="save" nzTheme="fill"></i> Save
            </button>
          </div>
          <div class="col-xs-6 col-sm-2">
            <button
              type="button"
              class="btn btn-danger active btn-lg btn-block cari border-redius"
              (click)="resetForm()"
            >
              <i nz-icon nzType="redo" nzTheme="outline"></i> Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  