<div class="container-fluid">
  <div id="basicProfileDetails">
  <nz-card>
    <div *ngIf="this.isStpExpired" style="font-size: 22px; font-weight: bolder; color:red; text-align: center; padding-bottom: 10px;">(Your STP Registration Approval Is Expired)</div>
    <div *ngIf="financingApplicationList" style="font-size: 16px; font-weight: bolder;">
      <ng-container *ngFor="let data of financingApplicationList; let i = index">
        <tr style="width: 100%;">
          <td *ngIf="financingApplicationList.length > 1" style="width: 5%; text-align: right">{{ i + 1 }}. &nbsp;</td>
          <td style="width: 95%;">Your application for the circular: {{ data?.generatedCircularId }} will be expired in <span style="color: red" class="blinking">{{ data?.dateDifference }}</span> days.</td>
        </tr>
      </ng-container>
      <br>
    </div>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> Basic Profile
    </div>
    <div class="searchboxAerarWithoutShadow">
      <form nz-form [formGroup]="basicProfileForm">

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label class="text-weight">Name of STP</nz-form-label>
            <nz-form-label nzNoColon class="wider-label"> {{basicProfileForm.controls.stpName.value}}
            </nz-form-label>
          </div>


          <div class="form-group col-md-6">
            <nz-form-label class="text-weight"> Name of STP in Bangla</nz-form-label>
            <nz-form-label nzNoColon class="wider-label"> {{basicProfileForm.controls.stpNameBangla.value}}
            </nz-form-label>
          </div>

        </div>

        <div class="form-row" style="margin-left: 12px">


          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Type of STP</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.stpTypeId.value}}
            </nz-form-label>
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Type of Ownership</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.stpOwnershipTypeId.value}}
            </nz-form-label>
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Name of Ministry/ Division</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.stpMinistryId.value}}
            </nz-form-label>
          </div>


        </div>

        <div class="form-row" style="margin-left: 12px">

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Name of Agency</nz-form-label>
            <nz-form-label nzNoColon style="word-break: break-all;"> {{basicProfileForm.controls.stpAgencyId.value}}
            </nz-form-label>
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Year of Establishment</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.establishYear.value}}
            </nz-form-label>
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Location of the STP</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.stpLocationId.value}}
            </nz-form-label>
          </div>
        </div>

        <div class="background">
          <hr>
          <b style="margin-left: 10px">Address of the organization</b>
          <hr />
        </div>


        <div class="form-row" style="margin-left: 12px">

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Country</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.stpCountryId.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzErrorTip="Please select name of country">
                  <input nz-input formControlName="stpCountryId" placeholder="STP country" readonly />
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Division/State</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.division_id.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzErrorTip="Please select Division/State">
                  <input nz-input formControlName="division_id" placeholder="STP division" readonly />
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">District/ City</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.district_id.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzErrorTip="Please select District/ City">
                  <input nz-input formControlName="district_id" placeholder="STP district" readonly />
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>

        </div>

        <div class="form-row" style="margin-left: 12px">

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Upazilla/Sub-district</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.upazilla_id.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzErrorTip="Please select name of Upazilla">
                  <input nz-input formControlName="upazilla_id" placeholder="STP upazilla" readonly />
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>

          <!-- <div class="form-group col-md-4">
            <nz-form-label >Post office</nz-form-label>
            <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzErrorTip="Please select Post office">
                  <input nz-input formControlName="stpPostOfficeId" placeholder="STP post office" readonly />
                </nz-form-control>
              </nz-form-item>

            </div>
          </div> -->
          <div class="form-group col-md-8">
            <nz-form-label class="text-weight">House, Road, Village Area</nz-form-label>
            <nz-form-label   nzNoColon class="wider-label"> 
              {{basicProfileForm.controls.address.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12"> -->
              <!-- <nz-form-item>
                <nz-form-control [nzSpan]="28" nzHasFeedback nzValidatingTip="Validating...">
                  <textarea nz-input style="margin-left: -17px" formControlName="address" placeholder="Address"
                    nzAutosize readonly>
                    {{basicProfileForm.controls.address.value}}
                  </textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>


        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Contact number</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.contactNo.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzHasFeedback>
                  <input nz-input formControlName="contactNo" placeholder="STP Contact Number" readonly />
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Email address</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.email.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control nzHasFeedback>
                  <input nz-input style="margin-left: -13px" formControlName="email" placeholder="email" readonly />
                </nz-form-control>
              </nz-form-item>

            </div> -->
          </div>
          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Registered Email</nz-form-label>
            <nz-form-label nzNoColon> {{this.userEmail}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control nzHasFeedback>
                  <input nz-input style="margin-left: -13px" formControlName="email" placeholder="email" readonly />
                </nz-form-control>
              </nz-form-item>

            </div> -->
          </div>
        </div>

        <div class="background">
          <hr>
          <b style="margin-left: 10px">Owner/Principal/Head of the STP</b>
          <hr />
        </div>

        <div class="form-row" style="margin-left: 12px">

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Name</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.contactName.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzHasFeedback nzValidatingTip="Validating...">
                  <input nz-input formControlName="contactName" placeholder="name" readonly />
                </nz-form-control>
              </nz-form-item>

            </div> -->
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Designation</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.contactDesignation.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzHasFeedback nzValidatingTip="Validating...">
                  <input nz-input formControlName="contactDesignation" placeholder="designation" readonly />
                </nz-form-control>
              </nz-form-item>

            </div> -->
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Contact number</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.contactNumber.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="20" nzHasFeedback>
                  <input nz-input formControlName="contactNumber" placeholder="Contact Number" readonly />
                </nz-form-control>
              </nz-form-item>

            </div> -->
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"> Contact Email</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.contactEmail.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control nzHasFeedback>
                  <input nz-input formControlName="contactEmail" placeholder="email" type="email" readonly />
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>

        </div>


        <div class="background">
          <hr>
          <b style="margin-left: 10px">Managing Committee</b>
          <hr />
        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-12">
            <nz-form-label class="text-weight">Is there any committee?</nz-form-label>
            <span class="ml-2">
              <span *ngIf=isCommitte>Yes</span>
              <span *ngIf=!isCommitte>No</span>
            </span>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control nzHasFeedback>
                  <nz-radio-group formControlName="isCommittee" [disabled]=isCommitte>
                    <label nz-radio [nzValue]="true">Yes</label>
                    <label nz-radio [nzValue]="false">No</label>
                  </nz-radio-group>

                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>
          <div class="form-group col-md-4" *ngIf="isCommitte">
            <nz-form-label class="text-weight"> Total Member</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.totalMember.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="22" nzHasFeedback nzValidatingTip="Validating...">
                  <input nz-input type="number" formControlName="totalMember" placeholder="Committee Total Member"
                    readonly />

                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>

          <div class="form-group col-md-4" *ngIf="isCommitte">
            <nz-form-label class="text-weight"> Total Female Member</nz-form-label>
            <nz-form-label nzNoColon> {{basicProfileForm.controls.totalFemaleMember.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-item>
                <nz-form-control [nzSpan]="22" nzHasFeedback nzValidatingTip="Validating...">
                  <input nz-input type="number" formControlName="totalFemaleMember" placeholder="Total Female Member"
                    readonly />
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>
        </div>

        <div class="form-row" style="margin-left: 12px" *ngIf="isCommitte">
          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">
              Date of Approval of the Committee</nz-form-label>
            <nz-form-label nzNoColon>
              {{basicProfileForm.controls.approvalDateCommittee.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-control [nzSpan]="20" nzHasFeedback>
                <input nz-input formControlName="approvalDateCommittee" placeholder="Date" readonly />
              </nz-form-control>
            </div> -->
          </div>

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">
              Date of Expiry of the Committee</nz-form-label>
            <nz-form-label nzNoColon>
              {{basicProfileForm.controls.expirationDateCommittee.value}}
            </nz-form-label>
            <!-- <div class="form-group col-md-12">
              <nz-form-control [nzSpan]="20" nzHasFeedback>
                <input nz-input formControlName="expirationDateCommittee" placeholder="Date" readonly />
              </nz-form-control>
            </div> -->
          </div>
        </div>
      </form>
    </div>
  </nz-card>


  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> Physical
      Infrastructure Details
    </div>
    <div class="searchboxAerarWithoutShadow">
      <form nz-form [formGroup]="physicalInfraForm">

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label class=" col-form-label"><b>Construction Type of the
                Building</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.stpBuildingType.value}}
            </nz-form-label>
          </div>


          <div class="form-group col-md-6">
            <nz-form-label> <b>Ownership Status of the
                Building</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.stpOwnershipStatus.value}}
            </nz-form-label>
          </div>
        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label><b>Total Area of the Center
                (in Square Meters)</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.totalArea.value}}
            </nz-form-label>
          </div>

          <div class="form-group col-md-6">
            <nz-form-label><b>Number of Floors</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.noOfFloor.value}}
            </nz-form-label>
          </div>
        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label><b>Average Size of
                the
                Classrooms (in Square Meters)</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.sizeOfClassroom.value}}
            </nz-form-label>
          </div>
          <div class="form-group col-md-6">
            <nz-form-label><b>Number of Classrooms</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.noOfClassroom.value}}
            </nz-form-label>
          </div>
        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label><b>Average Size of the Workshop (in Square Meters)</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.sizeOfWorkshop.value}}
            </nz-form-label>
          </div>
          <div class="form-group col-md-6">
            <nz-form-label><b>Number of Workshops</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.noOfWorkshop.value}}
            </nz-form-label>
          </div>
        </div>


        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label><b>Average Size of the
                Computer Labs (in Square
                Meters)</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.sizeOfClub.value}}
            </nz-form-label>
          </div>
          <div class="form-group col-md-6">
            <nz-form-label><b>Number of Computer Labs</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.noOfClub.value}}
            </nz-form-label>
          </div>
        </div>



        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label><b>Number of Office Rooms</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.noOfOffice.value}}
            </nz-form-label>
          </div>
          <div class="form-group col-md-6">
            <nz-form-label><b>Number of Trainer's Room</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.noOfTrainerRoom.value}}
            </nz-form-label>
          </div>
        </div>


        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label><b>Number of Washrooms</b></nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.noOfWashroom.value}}
            </nz-form-label>
          </div>
          <div class="form-group col-md-6">
            <nz-form-label><b>Number of Female Washrooms</b></nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.noOfFemaleWashroom.value}}
            </nz-form-label>
          </div>
        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label><b>Is there internet facilities?</b></nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.isInternet.value == true ? 'Yes' : 'No'}}
            </nz-form-label>
          </div>
          <div class="form-group col-md-6">
            <nz-form-label><b>Is there electricity connections?</b></nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.isElectricity.value == true ? 'Yes' : 'No'}}
            </nz-form-label>
          </div>
        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label class="col-form-label"><b>Is there sufficient fire fighting equipment?</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.isFireEq.value == true ? 'Yes' : 'No'}}
            </nz-form-label>
          </div>
          <div class="form-group col-md-6">
            <nz-form-label class="col-form-label">
              <b>Is there library
                facility?</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.isLibrary.value == true ? 'Yes' : 'No'}}
            </nz-form-label>
          </div>
        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label class="col-form-label"><b>Is there
                facility for
                safe
                drinking water?</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.isDrinkingWater.value == true ? 'Yes' : 'No'}}
            </nz-form-label>
          </div>
          <div class="form-group col-md-6">
            <nz-form-label class="col-form-label"><b>Is there
                sufficient
                light and ventilation?</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.isLightingVent.value == true ? 'Yes' : 'No'}}
            </nz-form-label>
          </div>
        </div>

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-6">
            <nz-form-label class="col-form-label"><b>Is the
                infrastructure
                friendly
                for people with
                disability?</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{physicalInfraForm.controls.isDisableFriendly.value == true ? 'Yes' : 'No'}}
            </nz-form-label>
          </div>
        </div>


      </form>
    </div>
  </nz-card>


  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Occupation
      specific trainer list
    </div>

    <nz-table #basicTable [nzData]="stpTrainers" nzTableLayout="fixed" nzShowSizeChanger nzBordered nzSize="middle"
      nzAlign="middle" class="table table-responsive" [nzFrontPagination]="false" [nzShowPagination]="false">
      <ng-template #totalRowRangeTemplate let-range="range" let-total style="text-align: left">
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
        </div>
      </ng-template>
      <thead>
        <tr>
          <th style="text-align: center;" class="thableLabel" rowspan="2" [nzWidth]="'60px'">Sl.</th>
          <th style="text-align: center;" class="thableLabel" rowspan="2">Name of occupation</th>
          <th style="text-align: center;" class="thableLabel" rowspan="2">Number of post for trainer</th>
          <th style="text-align: center;" class="thableLabel" colspan="3">Number of available certified trainers</th>
          <th style="text-align: center;" class="thableLabel" rowspan="2">Number of female trainers</th>
          <th style="text-align: center;" class="thableLabel" rowspan="2">Number of guest trainers</th>
          <th style="text-align: center;" class="thableLabel" rowspan="2">Number of vacant post</th>
        </tr>
        <tr>
          <th style="text-align: center;" class="thableLabel">Full time</th>
          <th style="text-align: center;" class="thableLabel">Part time</th>
          <th style="text-align: center;" class="thableLabel">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data; let i=index">
          <td style="text-align: center;">{{i+1}}</td>
          <td style="text-align: center;" class="rowOne">
            {{
          data.stpTrainerOccupation
            ? data.stpTrainerOccupation
            : "N/A"
          }}
          </td>
          <td style="text-align: center;" class="rowOne">{{ data.noOfPost }}</td>
          <td style="text-align: center;" class="rowOne">{{ data.noOfFulltime }}</td>
          <td style="text-align: center;" class="rowOne">{{ data.noOfPartime }}</td>
          <td style="text-align: center;" class="rowOne">{{ data.noOfFulltime + data.noOfPartime }}</td>
          <td style="text-align: center;" class="rowOne">{{ data.noOfFemale }}</td>
          <td style="text-align: center;" class="rowOne">{{ data.noOfGuest }}</td>
          <td style="text-align: center;" class="rowOne">{{ data.noOfVaccant }}</td>
        </tr>
      </tbody>
    </nz-table>
  </nz-card>

  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Management and Supporting Staff List
    </div>
    <div nz-col [nzSpan]="24">

      <nz-table #basicTable1 [nzData]="stpManagements" nzTableLayout="fixed" nzShowSizeChanger nzBordered
        nzSize="middle" nzAlign="middle" [nzFrontPagination]="false" [nzShowPagination]="false">
        <ng-template #totalRowRangeTemplate let-range="range" let-total style="text-align: left">
          <div style="text-align: left">
            Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
          </div>
        </ng-template>
        <thead>
          <tr>
            <th style="text-align: center;" rowspan="2" [nzWidth]="'60px'"> Sl.</th>
            <th style="text-align: center;" rowspan="2">Name of Position</th>
            <th style="text-align: center;" rowspan="2">Number of Post</th>
            <th style="text-align: center;" colspan="3">Number of Available staff</th>
            <th style="text-align: center;" rowspan="2">Number of Female staff</th>
            <th style="text-align: center;" rowspan="2">Number of Vacant post</th>
          </tr>
          <tr>
            <th style="text-align: center;">Full Time</th>
            <th style="text-align: center;">Part Time</th>
            <th style="text-align: center;">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable1.data; let i=index">
            <td style="text-align: center;">{{i+1}}</td>
            <td style="text-align: center;">{{data.positionName}}</td>
            <td style="text-align: center;">{{ data.noOfPost }}</td>
            <td style="text-align: center;">{{ data.noOfFulltime }}</td>
            <td style="text-align: center;">{{ data.noOfPartime }}</td>
            <td style="text-align: center;">{{ data.noOfFulltime + data.noOfPartime }}</td>
            <td style="text-align: center;">{{ data.noOfFemale }}</td>
            <td style="text-align: center;">{{ data.noOfVaccant }}</td>
          </tr>
        </tbody>
      </nz-table>

    </div>
  </nz-card>

  <nz-card>
    <div class="card">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i> Financial Details
      </div>
      <div class="searchboxAerarWithoutShadow">
        <form nz-form [formGroup]="financialDetailForm">

          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">Reserve Fund (in BDT)
              </nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.reserveFund.value}}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">FDR (in BDT)</nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.fdr.value}}
              </nz-form-label>
            </div>
          </div>

          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">General Fund (in BDT)</nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.generalFund.value}}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">Available Balance Account (in BDT)</nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.availableBalanceInBankAccount.value}}
              </nz-form-label>
            </div>
          </div>


          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">Account Number</nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.accountNumber.value}}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">Bank Name</nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.bankName.value}}
              </nz-form-label>
            </div>
          </div>

          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">Branch Name</nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.branchName.value}}
              </nz-form-label>
            </div>
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">Other Funds</nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.otherFunds.value}}
              </nz-form-label>
            </div>
          </div>


          <div class="form-row" style="margin-left: 12px">
            <div class="form-group col-md-6">
              <nz-form-label class="text-weight">Remarks</nz-form-label>
              <nz-form-label nzNoColon> {{financialDetailForm.controls.remarks.value}}
              </nz-form-label>
            </div>
          </div>




          <div class="background">
            <hr />
            <h6>
              <b style="margin-left: 10px">Income And Expenditure of Last Financial Year</b>
            </h6>
            <hr />
          </div>


          <div class="row">
            <div class="col">
              <div>
                <b style="margin-left: 10px">Sources of Income</b>
                <hr />
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Serial No.</th>
                      <th scope="col">Sources of Income</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Fees from Students</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="feesFromStudents"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Fees from Students" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Income from Own Assets</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="incomeFromOwnAssets"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Income from Own Assets" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>From Government/Development Partners</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="fromGovernment"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="From Government/ Development Partners" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>Development Charge</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="developmentCharge"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Development Charge" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>Admission Fee</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="admissionFee" style="border: hidden; background-color: #eee"
                            type="number" min="0" placeholder="Admission Fee" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td>Examination Fee</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="examinationFee"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Examination Fee" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td>Session Charge</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="sessionCharge" style="border: hidden; background-color: #eee"
                            type="number" min="0" placeholder="Session Charge" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">8</th>
                      <td>Selling of Products Produced</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="sellingOfProductsProduced"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Selling of Products Produced" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">9</th>
                      <td>Others</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="others_source" style="border: hidden; background-color: #eee"
                            type="number" min="0" placeholder="Others" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">10</th>
                      <td>Total Amount</td>
                      <td>{{totalIncomes?.toFixed(1)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col">
              <div>
                <b style="margin-left: 10px">Head of Expenditure</b>
                <hr />
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Serial No.</th>
                      <th scope="col">Head of Expenditure</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Salary of Instructors And Staff</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="salaryOfInstructorsAndStaff"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Salary of Instructors And Staff" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Incidental Expenses</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="incidentalExpenses"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Incidental Expenses" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Repairing Cost</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="repairingCost" style="border: hidden; background-color: #eee"
                            type="number" min="0" placeholder="Repairing Cost" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>Examination Expenses</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="examinationExpenses"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Examination Expenses" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>Travel Expenses</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="travelExpenses"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Travel Expenses" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td>Procurement of Books</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="procurementOfBooks"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Procurement of Books" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td>Procurement of Training Learning Materials</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="procurementOfTrainingLearningMaterials"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Procurement of Training Learning Materials" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">8</th>
                      <td>Procurement of Raw Materials</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="procurementOfRawMaterials"
                            style="border: hidden; background-color: #eee" type="number" min="0"
                            placeholder="Procurement of Raw Materials" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">9</th>
                      <td>Others</td>
                      <td>
                        <nz-form-control nzValidatingTip="Validating..." nzErrorTip="Input must be number">
                          <input nz-input formControlName="others_head" style="border: hidden; background-color: #eee"
                            type="number" min="0" placeholder="Others" readonly />
                        </nz-form-control>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">10</th>
                      <td>Total Amount</td>
                      <td>{{totalExpenses?.toFixed(1)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </nz-card>

  <nz-card>
    <div class="card">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Tools/Equipment/Training
        Learning
        Materials List
      </div>
      <nz-table #basicTable2 [nzData]="stpOccupationTools" nzTableLayout="fixed" nzShowSizeChanger nzBordered
        nzSize="middle" nzAlign="middle" class="table table-responsive" [nzFrontPagination]="false"
        [nzShowPagination]="false">
        <thead>
          <tr>
            <th style="text-align: center;" nz-th [nzWidth]="'60px'" class="thableLabel" rowspan="1">Sl.</th>
            <th style="text-align: center;" class="thableLabel" colspan="2">Occupation Name</th>
            <th style="text-align: center;" class="thableLabel" colspan="2">Name of Tools/Equipment/Training Learning
              Materials
            </th>
            <th style="text-align: center;" class="thableLabel" rowspan="2">Quantity</th>
            <th style="text-align: center;" class="thableLabel" rowspan="2">Unit</th>
            <th style="text-align: center;" class="thableLabel" rowspan="2">Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable2.data; let i=index">
            <td style="text-align: center;">{{i+1}}</td>
            <td style="text-align: center;" colspan="2" class="rowOne">{{data.stpTrainerOccupation}}</td>
            <td style="text-align: center;" colspan="2">{{ data.toolName }}</td>
            <td style="text-align: center;">{{ data.quantity }}</td>
            <td style="text-align: center;" class="rowOne">
              <!-- {{
                  data.sysUnit
                    ? data.sysUnit.unitName
                    : "N/A"
                }} -->
              {{
            data.unit
              ? data.unit
              : "N/A"
            }}
            </td>
            <td style="text-align: center;">{{ data.remarks }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>

  <nz-card>
    <div class="card">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Furniture List
      </div>

      <nz-table #basicTable3 [nzData]="furnitures" nzTableLayout="fixed" nzShowSizeChanger nzBordered nzSize="middle"
        nzAlign="middle" class="table table-responsive" [nzFrontPagination]="false" [nzShowPagination]="false">
        <ng-template #totalRowRangeTemplate let-range="range" let-total>
          <div style="text-align: left">
            Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
          </div>
        </ng-template>
        <thead>
          <tr>
            <th nz-th [nzWidth]="'60px'" style="text-align: center;">Sl.</th>
            <th style="text-align: center;" rowspan="1">Name of Furniture</th>
            <th style="text-align: center;" nz-th [nzWidth]="'110px'">Quantity</th>
            <th style="text-align: center;" nz-th [nzWidth]="'110px'">Unit</th>
            <th style="text-align: center;" rowspan="1">Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable3.data; let i=index">
            <td style="text-align: center;">{{i+1}}</td>
            <td style="text-align: center;">{{ data.furnitureName}}</td>
            <td style="text-align: center;">{{ data.quantity }}</td>
            <td style="text-align: center;">{{ data.unit}}</td>
            <td style="text-align: center;">{{ data.remarks }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>




  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> Payment Details
    </div>
    <div class="searchboxAerarWithoutShadow">
      <form nz-form [formGroup]="paymentForm">

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"><b>Amount</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{paymentForm.controls.amount.value}}
            </nz-form-label>
          </div>


          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"><b>Bank Name</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{paymentForm.controls.bankName.value}}
            </nz-form-label>
          </div>


           <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Payment Date</nz-form-label>
            <nz-form-label nzNoColon> {{paymentForm.controls.payDate.value}}
            </nz-form-label>
          </div>

          <div class="form-group col-md-4" *ngIf="isManualPayment && paySlip">
            <nz-form-label class="text-weight">Payment Slip</nz-form-label>
            <nz-form-label nzNoColon>
              <button type="button" class="btn btn-primary btn-sm" (click)="onDownload()">
                <i nz-icon nzType="download" nzTheme="outline"></i> Download Payslip
              </button>
            </nz-form-label>
          </div>

          <div class="form-group col-md-8" *ngIf="isOnlinePayment">
            <nz-form-label class="text-weight">Payment Slip</nz-form-label>
            <nz-form-label nzNoColon>
              <span *ngIf="isOnlinePayment"> Online Paid Successfully. (Reference Number : <i>{{transactionNumber}}</i> ) </span>
            </nz-form-label>
          </div>
        </div>


        <!-- <div class="form-row" style="margin-left: 12px">

          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Date</nz-form-label>
            <nz-form-label nzNoColon> {{paymentForm.controls.successDate.value}}
            </nz-form-label>
          </div>



          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"><b>Bank Name</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{paymentForm.controls.bankName.value}}
            </nz-form-label>
          </div>


          <div class="form-group col-md-4">
            <nz-form-label class="text-weight">Payment Verification</nz-form-label>
            <nz-form-label nzNoColon> {{paymentForm.controls.isSuccess.value == true ? 'Success' : 'Failed'}}
            </nz-form-label>

          </div>
        </div> -->




        <!-- <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"><b>pay Slip</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{paymentForm.controls.paySlip.value}}
            </nz-form-label>
          </div>


          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"><b>transactionNo</b>
            </nz-form-label>
            <nz-form-label nzNoColon>
              {{paymentForm.controls.transactionNo.value}}
            </nz-form-label>
          </div>



        </div> -->












      </form>
    </div>
  </nz-card>

  <nz-card *ngIf="isShown">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> Correction Remarks
    </div>
    <div class="searchboxAerarWithoutShadow">
      <form nz-form >

        <div class="form-row" style="margin-left: 12px">
          <div class="form-group col-md-4">
            <nz-form-label class="text-weight"><b>Reason</b>
            </nz-form-label>
            <span>{{tempRejectReason}}</span>
          </div>
        </div>

      </form>
    </div>
  </nz-card>

</div>

<div class="row mt-4 pb-4" *ngIf="isTempRejected ==null && approvalStatus == null && isApplicationSubmitted && fullStpApplicationDetails?.data?.stpUserInfoDto?.applicationStatus == 'Completed'" [appRequiredPermission]="applicationPermissions.stpRegistration.stpApplication.tempApproveRejectPermission" >
  <div class="col-md-6 mx-auto">
    <button
      class="btn btn-primary active btn-lg btn-block cari border-redius"
      nz-button
      nzType="primary"
      type="submit"
      title="Approve this application"
      nz-popconfirm
      nzPopconfirmTitle="Are you sure to accept this application?"
      nzPopconfirmPlacement="topCenter"
      (nzOnConfirm)="onApproveApplication()"
      [nzSize]="'small'"
    >
      <i nz-icon nzType="check" nzTheme="outline"></i> Accept
    </button>
  </div>
  <div class="col-md-6 mx-auto">

    <button
      style="background-color: red"
      class="btn btn-primary active btn-lg btn-block cari border-redius"
      nz-button
      nzType="primary"
      type="submit"
      title="Reject this application"
      nz-popconfirm
      nzPopconfirmTitle="Are you sure to request correction?"
      nzPopconfirmPlacement="topCenter"
      (nzOnConfirm)="setRejectApplication()"
      [nzSize]="'small'"
    >
      <i nz-icon nzType="close" nzTheme="outline"></i> Request for Correction
    </button>
  </div>
</div>


  <span *ngIf="isUser">
    <div class="row mt-4 pb-4" *ngIf="!isApplicationSubmitted">
      <div class="col-md-6 mx-auto">
        <a type="button" class="btn btn-info btn-block" routerLink="/home/stp-registration/dash-board">Edit
          Application</a>
      </div>
      <div class="col-md-6 mx-auto">
        <button type="button" class="btn btn-success btn-block" (click)="onSubmitApplication()">Submit
          Application</button>
      </div>
    </div>

    <div class="row mt-4 pb-4" *ngIf="isApplicationSubmitted">
      <div class="col-md-12">
        <div class="col-md-6 mx-auto alert alert-success mt-3" role="alert" style="text-align: center">
          <b>
            <h4>Application Submitted</h4>
          </b>
        </div>
        <div class="col-md-6 mx-auto">
          <button type="button" class="btn btn-success btn-block" (click)="onStpDownload()">Download Full Application</button>
        </div>
      </div>
    </div>
  </span>

  <span *ngIf="isAdmin">
    <!-- <div class="row mt-4 pb-4" *ngIf="isApplicationSubmitted">
      <div class="col-md-6 mx-auto">
        <button type="button" class="btn btn-success btn-block" (click)="onSuccessSubmitFinalMail()">Mail Full Application to Stp</button>
      </div>
    </div> -->
    <div class="row mt-4 pb-4" *ngIf="isApplicationSubmitted">
      <div class="col-md-6 mx-auto" >
        <button type="button" class="btn btn-success btn-block" (click)="onStpDownload()">Download Full Application</button>
      </div>
    </div>
  </span>

  <nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzClosable]="true"
  [nzWidth]="700"
  (nzOnOk)="onRejectApplication()"
>
  <ng-container *nzModalContent>
    <div [appRequiredPermission]="applicationPermissions.stpRegistration.stpApplication.tempApproveRejectPermission"
   *ngIf="isTempRejected ==null && approvalStatus == null && isApplicationSubmitted && fullStpApplicationDetails?.data?.stpUserInfoDto?.applicationStatus == 'Completed'">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> Required Corrections
    </div>
    <div class="searchboxAerarWithoutShadow">
      <form nz-form [formGroup]="approveRejectForm">
        <br>
        <div class="col-md-12">
          <nz-checkbox-group formControlName="checkOptions" [(ngModel)]="checkOptions"></nz-checkbox-group>
        </div>

        <br>
        <div class="col-md-12">
          <nz-form-label>Required Corrections</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <textarea
                nz-input
                formControlName="rejectReason"
                placeholder="Reason"
                nzAutosize
              ></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </form>
    </div>
  </div>
  </ng-container>
</nz-modal>
</div>
