<div>
    <div class="div-btn">
        <button class="btn btn-primary btn-alignment btn-lg" (click)="sendEmail()">Send Email</button>
    </div>
</div>





