import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StpRegistrationStorageService } from 'src/app/modules/stp-registration/services/stp-registration-storage.service';
import { TrainingAndAssessmentMaterialsStorageService } from 'src/app/modules/training-and-assessment-materials/services/training-and-assessment-materials-storage.service';
import { CircularStorageService } from '../../services/circular-storage.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { StpMain } from 'src/app/modules/course-accreditation/models/stp-main.model';
import { StpApplicationDto } from '../../models/DTO/stp_application_dto.model';
import { TamMain } from '../../models/tam-main.model';
import { TamMainSearchResultDto } from '../../models/DTO/tam_main_search_result_dto.model';
import { Attachment } from '../../models/DTO/attachment';
@Component({
  selector: 'app-circular-view',
  templateUrl: './circular-view.component.html',
  styleUrls: ['./circular-view.component.scss'],
})
export class CircularViewComponent implements OnInit {
  otherExpenseSectorForm: FormGroup;
  circularIdDecodedValue: any;
  circularId: number;
  circularInfoDetails: any;
  stpMainInfo: StpMain[] = [];
  cirPreventedOrganizationList: StpApplicationDto[] = [];
  cirPreventedOccupationsList: TamMainSearchResultDto[] = [];
  historyList: any[] = [];
  attachmentList: Attachment[] = [];
  tamMains: TamMain[] = [];

  //#region image part
  imageTypeArray: string[] = ['jpg', 'jpeg', 'png'];
  otherPdfUrl: any;
  pdfUrl: any;
  imageURL: any;
  isImageLoading: boolean;
  isVisible: boolean = false;
  //#endregion
  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private tamStorageService: TrainingAndAssessmentMaterialsStorageService,
    private circularStorageService: CircularStorageService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.translate.use(environment.language.setDefaultLang);

    this.circularId = Number(
      this.activatedRoute.snapshot.queryParamMap.get('circularId')
    );

    this.activatedRoute.queryParams.subscribe((params) => {
      const base64EncodedValue = params['circularId'];
      this.circularIdDecodedValue = atob(base64EncodedValue);
    });
  }

  getStpMainInfo() {
    this.stpRegistrationStorageService.readStpMains().subscribe({
      next: (res) => {
        this.stpMainInfo = [...res];
        this.getCircularInfoById();
      },
    });
  }
  getTamMainList() {
    this.tamStorageService.readTamMains('', 2).subscribe({
      next: (res) => {
        this.tamMains = res.data;
      },
    });
  }

  ngOnInit(): void {
    this.otherExpenseSectorForm = this.fb.group({
      expenseSectorFormArray: this.fb.array([]),
    });
    this.getStpMainInfo();
    this.getTamMainList();
  }

  get newExpenseSectorFormArray(): FormArray {
    return this.otherExpenseSectorForm.get(
      'expenseSectorFormArray'
    ) as FormArray;
  }

  // getCirExpenseSectorHeadList() {
  //   let formArray = this.newExpenseSectorFormArray as FormArray;
  //   this.circularInfoDetails.cirExpenseSectorHeadList?.forEach(
  //     (obj: any, index: number) => {
  //       formArray.push(
  //         this.fb.group({
  //           expenseSector: [obj.expenseSector],
  //         })
  //       );
  //     }
  //   );
  // }

  getCircularInfoById() {
    this.circularStorageService
      .getCircularPublicInfoById(this.circularIdDecodedValue)
      .subscribe({
        next: (res) => {
          if (res) {
            if (this.circularId != null) {
              this.circularInfoDetails = { ...res };
              this.circularId = this.circularInfoDetails?.id;
              this.circularInfoDetails?.cirPreventedOrgList?.forEach(
                (org: { stpMainId: number; ineligibilityReason: string }) => {
                  let stpMainDetail = this.stpMainInfo.find(
                    (stpMain) => stpMain.id === org.stpMainId
                  );
                  if (stpMainDetail) {
                    this.cirPreventedOrganizationList.push({
                      stpName: stpMainDetail.stpName,
                      stpMainId: stpMainDetail.id,
                      createdBy: stpMainDetail.createdBy,
                      applicationId: stpMainDetail.applicationId,
                      registrationId: stpMainDetail.registrationNo,
                      applicationDate: stpMainDetail.applicationDate,
                      approvalDate: stpMainDetail.approvalDate,
                      expireDate: stpMainDetail.expireDate,
                      ownerType: stpMainDetail.stpOwnershipType,
                      ministryName: stpMainDetail.stpMinistry,
                      agencyName: stpMainDetail.stpAgency,
                      districtName: stpMainDetail.sysDistrict,
                      ineligibilityReason: org.ineligibilityReason,
                    });
                  }
                }
              );

              this.circularInfoDetails?.cirPreventedOccupationsList?.forEach(
                (cao: { tamMainId: number }) => {
                  let tamMainDetail = this.tamMains.find(
                    (tamMain) => tamMain.id === cao.tamMainId
                  );
                  if (tamMainDetail) {
                    this.cirPreventedOccupationsList.push({
                      id: tamMainDetail.id,
                      tamApplicationId: tamMainDetail.tamApplicationId,
                      tamTypeName: tamMainDetail.tamType?.name,
                      tamIndustrialSectorName:
                        tamMainDetail.tamOccupation?.tamIndustrialSector?.name,
                      tamOccupationName: tamMainDetail.tamOccupation?.name,
                      tamLevelId: tamMainDetail.tamLevel.id,
                      tamLanguageName: tamMainDetail.tamLanguage.name,
                      approvalDate: tamMainDetail.approvalDate,
                      isPublished: tamMainDetail.isPublished,
                      docFilePath: tamMainDetail.docFilePath,
                      tamOccupationId: tamMainDetail.tamOccupation?.id,
                    });
                  }
                }
              );
              this.attachmentList =
                this.circularInfoDetails?.cirRelatedAttachmentsList;

              this.historyList = this.circularInfoDetails?.circularHistory;
              // this.getCirExpenseSectorHeadList();
            } else {
            }
          }
        },
      });
  }

  async setBase64AttachmentforPreviewing(fileName: string, i: number) {
    let fileUrl = '';
    switch (fileName) {
      case 'documents':
        fileUrl =
          this.circularInfoDetails?.cirRelatedAttachmentsList[i]
            ?.attachmentUrl ?? '';
        break;
    }
    const data = await fetch(environment.fileServiceApiUrl + fileUrl);
    const blob = await data.blob();
    let fileUrlBase64 = URL.createObjectURL(blob);
    let fileType = this.getFileTypeFromPreviousAttachment(fileUrl);
    this.previewAllImage(fileType, fileUrlBase64);
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {
    let contentType = '';
    let basename = fileUrl.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;
    let fileType = basename?.slice(pos + 1) ?? '';
    if (this.imageTypeArray.includes(fileType.toLowerCase())) {
      contentType = 'image';
    } else if (fileType == 'pdf') {
      contentType = 'pdf';
    } else if (fileType == 'doc') {
      contentType = 'doc';
    } else if (fileType == 'docx') {
      contentType = 'docx';
    } else if (fileType == 'xlsx') {
      contentType = 'xlsx';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  previewAllImage(fielType: string | undefined, fileUrl: any) {
    this.imageURL = '';
    this.pdfUrl = '';
    this.otherPdfUrl = '';
    if (fielType == 'image') {
      this.isVisible = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else if (fielType == 'pdf') {
      this.isVisible = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', fileUrl);
      link.setAttribute('download', 'file');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
  handleCancel(): void {
    this.isVisible = false;
  }
}
