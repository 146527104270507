import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthGroup } from '../models/authorization.types';
import { Designation } from '../models/designation.model';
import { PermissionDto } from '../models/permission.dto';
import { Permission } from '../models/permission.model';
import { PermissionType } from '../models/permissionType.model';
import { ProjectModule } from '../models/project-module.model';
import { Role } from '../models/role.model';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private currentUserPermissions: Permission[] = [];
  private currentUserLocations: any[] = [];

  onStartModuleEditing = new Subject<number | null>();
  onRefreshModuleList = new Subject<ProjectModule[]>();
  modules: ProjectModule[] = [];
  editingModuleId!: number | null;

  onStartDesignationEditing = new Subject<number | null>();
  onRefreshDesignationList = new Subject<Designation[]>();
  designations: Designation[] = [];
  editingDesignationId?: number | null;

  onPermissionStartEditing = new Subject<number>();
  onRefreshPermissionList = new Subject<Permission[]>();
  permissions: Permission[] = [];
  permissionTypes: PermissionType[] = [];
  onRefreshPermissionTypeList = new Subject<PermissionType[]>();

  onStartRoleEditing = new Subject<number | null>();
  onRefreshRoleList = new Subject<Role[]>();
  roles: Role[] = [];
  editingRoleId?: number | null;

  constructor() {}

  set CurrentUserPermissions(permissions: Permission[]) {
    this.currentUserPermissions = permissions;
  }

  get CurrentUserPermissions(): Permission[] {
    return this.currentUserPermissions;
  }

  setCurrentUserLocations(userLocations: any): void {
    this.currentUserLocations = userLocations;
  }

  get CurrentUserLocations(): any {
    return this.currentUserLocations;
  }

  checkIfCurrentUserHaveAnyPermission(permissionValueList: any): boolean {
    let permission: any;
    let permissionList: Permission[] = [];

    permissionValueList.forEach((permissionValue: string) => {
      if(permissionValue != ''){
        permission = this.currentUserPermissions.find(
          (p) => p.value === permissionValue
        );

        if(permission){
          permissionList.push(permission);
        }
      }
    });

    if(permissionList.length > 0){
      return true;
    }else{
      return false;
    }
  }

  checkIfCurrentUserHavePermission(permissionValue: string): boolean {
    const isPermissionFound = this.currentUserPermissions.find(
      (p) => p.value === permissionValue
    );
    return isPermissionFound !== undefined;
  }

  hasPermission(authGroup: AuthGroup) {
    if (
      this.currentUserPermissions &&
      this.currentUserPermissions.find((permission) => {
        return permission.value === authGroup;
      })
    ) {
      return true;
    }
    return false;
  }

  //#region PROJECT_MODULE
  getModules(): ProjectModule[] {
    return this.modules;
  }

  getModuleById(id: number | null): ProjectModule | undefined {
    return this.modules.find((module) => module.id === id);
  }

  setModules(modules: ProjectModule[]): void {
    this.modules = modules;
    this.onRefreshModuleList.next(this.modules.slice());
  }

  addModule(module: ProjectModule): void {
    this.modules.push(module);
    this.onRefreshModuleList.next(this.modules.slice());
  }

  upatedModule(newRecipe: ProjectModule, id: number): void {
    const index = this.modules.findIndex((module) => module.id === id);
    this.modules[index] = newRecipe;
    this.onRefreshModuleList.next(this.modules.slice());
  }

  deleteModule(id: number): void {
    this.modules = this.modules.filter((module, index) => {
      return module.id !== id;
    });
    this.onRefreshModuleList.next(this.modules.slice());
  }

  getEditingModuleId(): any {
    return this.editingModuleId;
  }

  setEditingModuleId(id: number): void {
    this.editingModuleId = id;
    this.onStartModuleEditing.next(id);
  }

  clearEditingModuleId(): void {
    this.editingModuleId = null;
    this.onStartModuleEditing.next(null);
  }
  //#endregion PROJECT_MODULE

  //#region DESIGNATION
  getDesignations(): any {
    return this.designations;
  }

  getDesignationById(id?: number | null): Designation | undefined {
    return this.designations.find((designation) => designation.id === id);
  }

  setDesignations(designations: Designation[]): void {
    this.designations = designations;
    this.onRefreshDesignationList.next(this.designations.slice());
  }

  addDesignation(designation: Designation): void {
    this.designations.push(designation);
    this.onRefreshDesignationList.next(this.designations.slice());
  }

  upatedDesignation(newRecipe: Designation, id: number): void {
    const index = this.designations.findIndex(
      (designation) => designation.id === id
    );
    this.designations[index] = newRecipe;
    this.onRefreshDesignationList.next(this.designations.slice());
  }

  deleteDesignation(id?: number | null): void {
    this.designations = this.designations.filter((designation, index) => {
      return designation.id !== id;
    });
    this.onRefreshDesignationList.next(this.designations.slice());
  }

  getEditingDesignationId(): any {
    return this.editingDesignationId;
  }

  setEditingDesignationId(id?: number): void {
    this.editingDesignationId = id;
    this.onStartDesignationEditing.next(id);
  }

  clearEditingDesignationId(): void {
    this.editingDesignationId = null;
    this.onStartDesignationEditing.next(null);
  }
  //#endregion DESIGNATION

  //#region PERMISSION
  getPermissions(): Permission[] {
    return this.permissions;
  }

  getPermissionTypes(): PermissionType[] {
    return this.permissionTypes;
  }

  setPermissions(permissions: Permission[]): void {
    this.permissions = permissions;
    this.onRefreshPermissionList.next(this.permissions.slice());
  }

  setPermissionTypes(permissionType: PermissionType[]): void {
    this.permissionTypes = permissionType;
    this.onRefreshPermissionTypeList.next(this.permissionTypes.slice());
  }

  addPermission(permission: Permission): void {
    this.permissions.push(permission);
    this.onRefreshPermissionList.next(this.permissions.slice());
  }

  updatePermission(id: number | null, updatedPermission: Permission): void {
    const index = this.permissions.findIndex((user) => user.id === id);
    this.permissions[index] = updatedPermission;
    this.onRefreshPermissionList.next(this.permissions.slice());
  }

  getPermission(index: number): Permission | undefined {
    return this.permissions.find((x) => x.id === index);
  }

  deletePermission(id: number): void {
    this.permissions = this.permissions.filter((permission, index) => {
      return permission.id !== id;
    });

    this.onRefreshPermissionList.next(this.permissions.slice());
  }

  convertPermissionToDTO(permission: Permission): PermissionDto {
    const permissionDto: PermissionDto = new PermissionDto();
    permissionDto.id = permission.id;
    permissionDto.moduleId = permission.moduleId;
    permissionDto.name = permission.name;
    permissionDto.typeId = permission.typeId;
    permissionDto.value = permission.value;
    permissionDto.url = permission.url;
    permissionDto.description = permission.description;
    return permissionDto;
  }
  //#endregion PERMISSION

  //#region ROLE
  getRoles(): Role[] {
    return this.roles;
  }

  getRoleById(id: number | null): Role | undefined {
    return this.roles.find((role) => role.id === id);
  }

  setRoles(roles: Role[]): void {
    this.roles = roles;
    this.onRefreshRoleList.next(this.roles.slice());
  }

  addRole(role: Role): void {
    this.roles.push(role);
    this.onRefreshRoleList.next(this.roles.slice());
  }

  upatedRole(newRecipe: Role, id?: number): void {
    const index = this.roles.findIndex((role) => role.id === id);
    this.roles[index] = newRecipe;
    this.onRefreshRoleList.next(this.roles.slice());
  }
  deleteRole(id?: number): void {
    this.roles = this.roles.filter((role, index) => {
      return role.id !== id;
    });
    this.onRefreshRoleList.next(this.roles.slice());
  }

  getEditingRoleId(): any {
    return this.editingRoleId;
  }
  setEditingRoleId(id?: number): void {
    this.editingRoleId = id;
    this.onStartRoleEditing.next(id);
  }

  clearEditingRoleId(): void {
    this.editingRoleId = null;
    this.onStartRoleEditing.next(null);
  }

  getRolePermissions(): any {
    throw new Error('Method not implemented.');
  }
  //#endregion ROLE
}
