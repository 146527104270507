<app-header *ngIf="!hiddingHeaderFooter"></app-header>
<div class="container-fluid">
  <nz-card>
    <div class="ng-Header col-xs-12 p-3">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Give Opinion
    </div>
    <!-- {{courseForm.value | json}} -->
    <div class="searchboxAerar p-3">
      <form
        nz-form
        [formGroup]="giveOpinionForm"
        (ngSubmit)="submitForm()"
        class="col-md-12"
        style="margin-top: 20px"
      >
        <div class="col-md-10">
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Document Type </nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzErrorTip="Please select document type"
                >
                  <input
                    nz-input
                    type="text"
                    formControlName="documentType"
                    placeholder="Document Type"
                    readonly
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Document Title</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="documentTitleErrorTpl"
                >
                  <input
                    nz-input
                    type="text"
                    formControlName="documentTitle"
                    placeholder="Document Title"
                    readonly
                  />
                  <ng-template #documentTitleErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('pattern')">
                      Please input title!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Version</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please select version">
                  <input
                    nz-input
                    type="text"
                    formControlName="version"
                    placeholder="Version"
                    readonly
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Language</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please select language">
                  <input
                    nz-input
                    type="text"
                    formControlName="languageCode"
                    placeholder="Language"
                    readonly
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label nzRequired
                >Name of person providing opinion</nz-form-label
              >
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="nameOfPersonProvidingOpinionErrorTpl"
                >
                  <input
                    nz-input
                    type="text"
                    formControlName="nameOfPersonProvidingOpinion"
                    placeholder="Name of person"
                  />
                  <ng-template
                    #nameOfPersonProvidingOpinionErrorTpl
                    let-control
                  >
                    <ng-container *ngIf="control.hasError('required')">
                      Please input name of person !
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Designation</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="designationErrorTpl"
                >
                  <input
                    nz-input
                    type="text"
                    formControlName="designation"
                    placeholder="Designation"
                  />
                  <ng-template #designationErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('pattern')">
                      Please input designation!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Organization</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="organizationErrorTpl"
                >
                  <input
                    nz-input
                    type="text"
                    formControlName="organization"
                    placeholder="Organization"
                  />
                  <ng-template #organizationErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('pattern')">
                      Please input organization!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Address</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="addressErrorTpl"
                >
                  <input
                    nz-input
                    type="text"
                    formControlName="address"
                    placeholder="Address"
                  />
                  <ng-template #addressErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('pattern')">
                      Please input address!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label nzRequired>Contact Number</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="contactNoErrorTpl">
                  <input
                    nz-input
                    formControlName="contactNo"
                    placeholder="Insert your number"
                  />
                </nz-form-control>
                <ng-template #contactNoErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please input contact number!
                  </ng-container>
                </ng-template>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label nzRequired>Email</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
                  <input
                    nz-input
                    type="email"
                    placeholder="Insert your E-mail"
                    pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                    formControlName="email"
                  />
                </nz-form-control>
                <ng-template #emailErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please input email !
                  </ng-container>
                  <ng-container *ngIf="control.hasError('whitespace')">
                    Please enter valid email !
                  </ng-container>
                  <ng-container *ngIf="control.hasError('email')">
                    Please enter valid email !
                  </ng-container>
                </ng-template>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Detailed Opinion/Feedback</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="null"
                  nzHasFeedback
                  [nzErrorTip]="opinionOrFeedbackErrorTpl"
                >
                  <input
                    nz-input
                    type="text"
                    formControlName="opinionOrFeedback"
                    placeholder="Opinion Or Feedback"
                  />
                  <ng-template #opinionOrFeedbackErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('pattern')">
                      Please input Opinion Or Feedback!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="mb-2 row">
            <div class="col-md-6 col-sm-12 text-right">
              <nz-form-label>Upload Document</nz-form-label>
            </div>
            <div class="col-md-5 col-sm-12">
              <nz-form-item>
                <nz-form-control
                  nzHasFeedback
                  nzValidatingTip="Validating..."
                  [nzErrorTip]="documentUrlErrorTpl"
                >
                  <input
                    type="file"
                    nz-input
                    #myInput1
                    (change)="onSelectDocument($event)"
                  />
                  <span style="color: red"><sup>*</sup>Note: </span>
                  &nbsp;<span>
                    Please upload .pdf .doc .excel .png and .jpg file. File size
                    maximum 30 MB.</span
                  >
                  <ng-template #documentUrlErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      Please select document!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div class="row justify-content-center media-button mt-4">
          <div class="col-xs-6 col-sm-2">
            <button
              type="submit"
              class="btn btn-success active btn-lg btn-block cari border-redius"
              [disabled]="!giveOpinionForm.valid"
            >
              <i nz-icon nzType="save" nzTheme="fill"></i>
              {{ isEditMode ? "Save" : "Save" }}
            </button>
          </div>
          <div class="col-xs-6 col-sm-2">
            <button
              type="button"
              class="btn btn-danger active btn-lg btn-block cari border-redius"
              (click)="resetForm()"
            >
              <i nz-icon nzType="redo" nzTheme="outline"></i> Reset
            </button>
          </div>
          <div class="col-xs-6 col-sm-3">
            <button
              type="button"
              class="btn btn-success active btn-lg btn-block cari border-redius"
              nz-popconfirm
              nzPopconfirmTitle="Are you sure to submit opinion?"
              (nzOnConfirm)="onSubmitDocument()"
              [disabled]="this.isDisableSubmit"
            >
              <i nz-icon nzType="check" nzTheme="outline"></i> Submit Opinion
            </button>
          </div>
        </div>
      </form>
    </div>
  </nz-card>
</div>
<app-footer *ngIf="!hiddingHeaderFooter"></app-footer>
