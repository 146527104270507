import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CadOtherRequirement } from '../../../models/cad-other-requirement.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import * as XLSX from 'xlsx';
import {UtilityService} from '../../../../../shared/services/utility.service';
import {Furniture} from '../../../../stp-registration/models/furniture.model';
import { Subscription } from 'rxjs';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';

@Component({
  selector: 'app-add-cad-other-requirement',
  templateUrl: './add-cad-other-requirement.component.html',
  styleUrls: ['./add-cad-other-requirement.component.scss']
})
export class AddCadOtherRequirementComponent implements OnInit {

  
  @Input() InputData: any;
  isEditMode: boolean = false;
  singleCad: CadOtherRequirement;
  onStartSingleCadEdit$: Subscription;
  onEditingSingleCadId!: number | null;
  cadForm: FormGroup;
  editedItem!: any;
  editedItemId!: number;
  cadList: CadOtherRequirement[] = [];
  tamMainId: number;
  onCadOtherRequirementSubscription$: Subscription;

  // cadForm: FormGroup;
  // tamMainId: string | null;
  excelData: any[] = [];
  userFile: any = File;
  allowedExtensions = '.xlsx,.xls';
  maxFormatFileSize = 3;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  total: number;
  page = 1;
  size = 10;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private utilityService: UtilityService
  ) { 
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
  this.onEditingSingleCadId = this.cadService.getEditingSingleCadOtherRequirementId();
  this.onStartSingleCadEdit$ =
    this.cadService.onStartCadOtherRequirementEditing.subscribe(
      (res: number | null) => {
        this.onEditingSingleCadId = res;
      }
    );
  this.cadList = this.cadService.getCadOtherRequirementList(this.tamMainId);
  if (this.cadList.length === 0) {
    this.cadStorageService.readCadOtherRequirementListByTamId(this.tamMainId, this.page, this.size).subscribe();
  }
  this.onCadOtherRequirementSubscription$ =
    this.cadService.onRefreshCadOtherRequirementList.subscribe((res) => {
      this.cadList = res;
    });
  }

  ngOnInit(): void {
    console.log(this.InputData);
    this.createCadForm();
    this.editedItemId = this.InputData;
    this.isEditMode = true;
    this.isEditMode = this.editedItemId !== null;
    this.editedItem = this.cadService.getCadOtherRequirementById(this.editedItemId);
    
    this.cadForm.patchValue(this.editedItem);

    // this.createCadForm();
    // this.tamMainId = this.route.snapshot.queryParamMap.get('tamMainId');

  }

  createCadForm() {
    this.cadForm = this.fb.group({
      itemName: [],
      specification: [],
      unit: [],
      minQuantityReq: [0, [Validators.pattern("^[0-9]*$")]],
      remark: []
    });
  }

  resetForm(): void {
    this.cadForm.reset();
  }

  submitForm(): void { for (const key of Object.keys(this.cadForm.controls)) {
    this.cadForm.controls[key].markAsDirty();
    this.cadForm.controls[key].updateValueAndValidity();
  }
  this.singleCad = this.cadForm.value;
  this.singleCad.tamMainId = Number(this.tamMainId);
  const isFalsy = this.cadService.getNullableFormCheck(this.singleCad, this.tamMainId);
  if (isFalsy) {
    this.notification.error('Error', 'All input blank form submit is not allowed');
    return;
  }
  if (this.editedItemId != null) {
    this.cadStorageService.updateCadOtherRequirement(this.singleCad, this.editedItemId).subscribe({
      next: (response) =>
        this.notification.success('Success!', 'Other Requirement update successfully'),
      error: (error) => this.notification.error('Failed', error),
    });
  }else {
    const cadData: CadOtherRequirement = this.cadForm.value;
    const isFalsy = this.cadService.getNullableFormCheck(cadData, this.tamMainId);
    if (isFalsy) {
      this.notification.error('Error', 'All input blank form submit is not allowed');
      return;
    }

    if(!this.tamMainId) {
      this.notification.error('Error', 'Training and Assessment Material Id not found');
      return;
    }

    cadData.tamMainId = Number(this.tamMainId);
    cadData.minQuantityReq = Number(cadData.minQuantityReq);

    this.cadStorageService
      .createCadOtherRequirement(cadData)
      .subscribe({
        next: (response) => {
          this.notification.success('Success','Added Other Requirement data');
        },
        error: (error) =>
          this.notification.error(
            'Failed',
            'Please! provide valid information'
          ),
      });
      this.cadForm.reset();
    }
    }

  //#region Excel File Upload And Read Excel Data

  onSelectFile(event: any): void {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      this.myInputVariable.nativeElement.value = '';
      return;
    }
    const file = event.target.files[0];
    this.userFile = file;

    /* wire up file reader */
    const target: DataTransfer = event.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects
      this.excelData = data;
      return data;
    };
  }

  submitExcelData(): void {
    const cadOtherRequirementList: CadOtherRequirement[] = [];

    if (this.myInputVariable.nativeElement.value == 0) {
      this.notification.error('Failed!', 'Please upload cad other requirement excel file');
      return;
    } else {
      for (const item of this.excelData) {
        const cadOtherRequirement = new CadOtherRequirement();

        cadOtherRequirement.itemName = item.ItemName;
        cadOtherRequirement.specification = item.Specification;
        cadOtherRequirement.unit = item.Unit;
        cadOtherRequirement.minQuantityReq = item.MinimumQuantityRequired;
        cadOtherRequirement.remark = item.Remarks;
        cadOtherRequirement.tamMainId = Number(this.tamMainId);

        cadOtherRequirementList.push(cadOtherRequirement);

        const existItem = cadOtherRequirementList.filter(
          (item) => item.itemName == cadOtherRequirement.itemName
        );

        for (let item of cadOtherRequirementList) {
          let msg = item.itemName;
          if (isNaN(item.minQuantityReq)) {
            this.notification.error(
              'Failed',
              `Invalid minimum quantity requierment for item name:  ${msg}, Please correct and reupload!`
            );
            this.myInputVariable.nativeElement.value = '';
            return;
          }
        }

        if (existItem.length > 1) {
          this.notification.error(
            'Failed',
            'Duplicate item name found in Excel, Please check!'
          );
          this.myInputVariable.nativeElement.value = '';
          return;
        }
      }
    }

    //#region Excel duplication value check

    // let existItem = this.excelData.filter(
    //   (item) => item.furnitureName == stpTrainer.furnitureName
    // );
    // if (existItem.length > 1) {
    //   this.notification.error(
    //     'Failed',
    //     'Duplicate Data found in Excel, Please check!'
    //   );
    //   return;
    // }
    this.cadStorageService
      .saveExcelCadOtherRequirementData(cadOtherRequirementList)
      .subscribe({
        next: (response) => {
          this.notification.success(
            'Success!',
            'Cad Other Requirement created successfully'
          );
        },
        error: (error) =>
          this.notification.error(
            'Failed',
            'Please! provide valid information'
          ),
      });

    this.myInputVariable.nativeElement.value = '';
    return;
  }

}
