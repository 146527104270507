<app-header></app-header>

<div class="card">
  <div class="container-fluid" *ngIf="contentVisble">
    <div class="row pt-3 mb-3">
      <div class="col-md-12 text-right">
      </div>
    </div>

    <nz-card>
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="unordered-list" nzTheme="outline"></i> STP Search
      </div>
      <form
        nz-form
        [formGroup]="searchingForm"
        class="ant-advanced-search-form"
        style="
          padding: 24px;
          background: #fbfbfb;
          border: 1px solid #d9d9d9;
          border-radius: 6px;
        "
        (ngSubmit)="onSearch()"
      >
        <div class="form-row">
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>STP Name</nz-form-label>
              <nz-form-control>
                <input nz-input placeholder="Stp Name" formControlName="stpName"/>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>Application ID</nz-form-label>
              <nz-form-control>
                <input nz-input placeholder="Application ID" formControlName="stpApplicationId"/>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>Ownership</nz-form-label>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="stpOwnerShipStatusId" nzPlaceHolder="Select Ownership Type">
                  <nz-option class="option"
                    *ngFor="let data of stpOwnershipTypeList"
                    [nzValue]="data.id"
                    [nzLabel]="data.typeName"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>District</nz-form-label>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="sysDistrictId" nzPlaceHolder="Select District">
                  <nz-option
                    class="option"
                    *ngFor="let data of districtList"
                    [nzValue]="data.id"
                    [nzLabel]="data.districtName"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzRequired>Occupation</nz-form-label>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="occupationId" nzPlaceHolder="Select Occupation" (ngModelChange)="onSelectOccupation()">
                  <nz-option
                    *ngFor="let occupation of occupationList"
                    [nzValue]="occupation?.tamOccupation?.id"
                    [nzLabel]="occupation?.tamOccupation?.name">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzRequired>Level</nz-form-label>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="levelId" nzPlaceHolder="Select Level">
                  <nz-option
                    *ngFor="let level of levelList"
                    [nzValue]="level?.levelId"
                    [nzLabel]="level?.level">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24" class="search-area text-right">
            <button class="mr-2" nz-button [nzType]="'primary'">Search</button>
            <button class="mr-2 btn-success" nz-button (click)="onRefresh()">Refresh Data</button>
          </div>
        </div>
      </form>
    </nz-card>
    <nz-card>
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="unordered-list" nzTheme="outline"></i> List of Registered STPs
      </div>
      <div nz-col [nzSpan]="24">
        <nz-table
          #basicTable
          [nzData]="stpApplicationDto"
          nzTableLayout="fixed"
          nzShowSizeChanger
          nzBordered
          nzSize="middle"
          nzAlign="middle"
          [nzFrontPagination]="false"
          [nzTotal]="total"
          [(nzPageSize)]="size"
          [nzShowTotal]="totalRowRangeTemplate"
          [(nzPageIndex)]="page"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzScroll]="{ x: '1150px' }"
        >
          <ng-template
            #totalRowRangeTemplate
            let-range="range"
            let-total
            style="text-align: left"
          >
            <div style="text-align: left">
              Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
            </div>
          </ng-template>
          <thead>
            <tr>
              <th style="text-align: center" rowspan="1">Sl.</th>
              <th rowspan="2" nzLeft nzColumnKey="stp_name" [nzSortFn]="true">Name of STP</th>
              <th rowspan="2" nzColumnKey="registration_no" [nzSortFn]="true">Registration Number</th>
              <th rowspan="2" nzColumnKey="approval_date" [nzSortFn]="true">Date of Approval</th>
              <th rowspan="2" nzColumnKey="expire_date" [nzSortFn]="true">Expire Date</th>
              <th rowspan="2">Type of Ownership</th>
              <th rowspan="2">Ministry</th>
              <th rowspan="2">Agency</th>
              <th rowspan="2">District</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data; let i = index">
              <td style="text-align: center">{{ (page - 1) * size + 1 + i }}</td>
              <td nzLeft><a [routerLink]="[]" (click)="onStpMainClick(data.stpMainId)">{{ data.stpName }}</a></td>
              <td>{{ data.registrationId }}</td>
              <td>{{ data.approvalDate | date: "dd-MM-yyyy" }}</td>
              <td>{{ data.expireDate | date: "dd-MM-yyyy" }}</td>
              <td>{{ data.ownerType }}</td>
              <td>{{ data.ministryName }}</td>
              <td>{{ data.agencyName }}</td>
              <td>{{ data.districtName }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-card>
  </div>
</div>
<ng-container *ngIf="!userInfo.id">
  <app-captcha (verifyStatus)="captchVerificationStatus($event)"></app-captcha>
</ng-container>

<app-footer></app-footer>

<nz-modal [nzWidth]="700" [(nzVisible)]="isStpBasicInfoModalVisible" nzTitle="STP Basic Information"
  (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <div>
      <nz-descriptions [nzColumn]="2">
        <nz-descriptions-item nzSpan="2" nzTitle="Name of STP">{{ stpBasicData.stpName }}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="2" nzTitle="Name of STP in Bangla">{{ stpBasicData.stpNameBangla }}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="2" nzTitle="Name of Ministry/ Division">{{ stpBasicData.ministryName }}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="2" nzTitle="Name of Agency">{{ stpBasicData.agencyName }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Type of STP">{{ stpBasicData.stpType }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Type of Ownership">{{ stpBasicData.ownershipType }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Year of Establishment">{{ stpBasicData.establishYear | date: 'yyyy' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Location of the STP">{{ stpBasicData.locationName }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Country">{{ stpBasicData.country }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Division/State">{{ stpBasicData.division }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="District/City">{{ stpBasicData.district }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Upazilla/Sub-district">{{ stpBasicData.upazilla }}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="2" nzTitle="House, Road, Village Area">{{ stpBasicData.address }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Contact number">{{ stpBasicData.orgContact }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Email address">{{ stpBasicData.orgEmail }}</nz-descriptions-item>
      </nz-descriptions>
    </div>
  </ng-container>
</nz-modal>
