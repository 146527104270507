import { Component, OnInit } from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Subscription} from 'rxjs';
import { StpRegistrationService } from 'src/app/modules/stp-registration/services/stp-registration.service';
import FileSaver from 'file-saver';
import { TamOccupation } from 'src/app/modules/training-and-assessment-materials/models/tam-occupation.model';
import { TrainingAndAssessmentMaterialsService } from 'src/app/modules/training-and-assessment-materials/services/training-and-assessment-materials.service';
import { TrainingAndAssessmentMaterialsStorageService } from 'src/app/modules/training-and-assessment-materials/services/training-and-assessment-materials-storage.service';
import { TciInfoDto } from '../../template-for-certificate-issuance/DTO/tciInfoDto.model';
import { TciInfoSearchingDto } from '../../template-for-certificate-issuance/DTO/tciInfoSearchingDto.model';
import { TciInfoStorageService } from '../../template-for-certificate-issuance/services/tci-info-storage.service';
import { TciInfoService } from '../../template-for-certificate-issuance/services/tci-info.service';

@Component({
  selector: 'app-shared-certificate-list',
  templateUrl: './shared-certificate-list.component.html',
  styleUrls: ['./shared-certificate-list.component.scss']
})
export class SharedCertificateListComponent implements OnInit {

  clickEventSubscription: Subscription;
  tciInfoList: TciInfoDto[] = [];
  total: number;
  size: number = 10;
  page: number = 1;
  onTciInfoSubscription$: Subscription;
  tciInfoFiltered: TciInfoDto[] = [];
  tamOccupations: TamOccupation[] = [];
  onTamOccupationSubscription$: Subscription;
  searchingForm: FormGroup = this.fb.group({
    occupationNameEnglish: [null],
    level: [''],
    registrationNo: [''],
    email: [''],
    contact: ['',
      [
        Validators.maxLength(11),
        Validators.minLength(11),
        Validators.pattern(/^[0-9]*$/),
      ]]

  });

  constructor(
    private tciInfoStorageService: TciInfoStorageService,
    private tciInfoService: TciInfoService,
    private notification: NzNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private stpRegistrationService: StpRegistrationService,
    private trainingAndAssessmentMaterialsService: TrainingAndAssessmentMaterialsService,
    private trainingAndAssessmentMaterialsStorageService: TrainingAndAssessmentMaterialsStorageService,

  ) {
    this.tciInfoList = this.tciInfoService.getTciInfos();
    this.tciInfoFiltered = [...this.tciInfoList];
    if (this.tciInfoList.length === 0) {
      this.tciInfoStorageService.readTciList(this.page, this.size).subscribe();
    }
    this.onTciInfoSubscription$ =
      this.tciInfoService.onRefreshTciInfoList.subscribe((res) => {
        this.tciInfoList = res;
        this.tciInfoFiltered = [...this.tciInfoList];
      });

    this.clickEventSubscription = this.tciInfoService.getTciInfoSaveEvent().subscribe(() => {
      this.loadData();
    });

  }

  ngOnInit(): void {
    this.loadTciInfosFromServer();
    this.getOccupationList();
  }

  // load all api data
  loadData(): void {
    this.loadTciInfosFromServer();
  }


  onSearch() {
    this.page = 1;
    this.size = 10;
    this.loadTciInfosFromServer(this.searchingForm.value);
  }

  loadTciInfosFromServer(tciFilteringRequestData?: TciInfoSearchingDto): void {
    // console.log("this.page, this.size, stpFilteringRequestData", this.page, this.size, stpFilteringRequestData);
    this.tciInfoStorageService
      .readTciList(this.page, this.size, tciFilteringRequestData)
      .subscribe({
        next: (res) => {
          this.tciInfoList = res.data;
          this.total = res.count;
        },
      });

  }

  ngOnDestroy(): void {
    this.onTciInfoSubscription$.unsubscribe();
  }

  onRefresh(){
    this.page = 1;
    this.size = 10;
    this.searchingForm.reset();
    this.loadTciInfosFromServer(this.searchingForm.value);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex;
    this.size = params.pageSize;
    const { pageSize, pageIndex, sort } = params;
    this.loadTciInfosFromServer(this.searchingForm.value);
  }


  onDownloadFreelancerCertificate(data: any){
    this.tciInfoStorageService
    .downloadFreelancerCertificate(data.id)
    .subscribe((data: any) => {
        var fileName = 'report.pdf';
        const contentDisposition = data.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        FileSaver(data.body, fileName);
    },

  );


    // const resultByte = this.tciInfoStorageService
    // .downloadFreelancerCertificate(data.id);

    // const download = new Blob([resultByte], { type: 'application/pdf', });
    // FileSaver.saveAs(download);
    // var bytes = new Uint8Array(resultByte); // pass your byte response to this constructor
    // var blob=new Blob([bytes], {type: "application/pdf"});// change resultByte to bytes
    // var link=document.createElement('a');
    // link.href=window.URL.createObjectURL(blob);
    // link.download="myFileName.pdf";
    // link.click();

    // this.tciInfoStorageService
    // .downloadFreelancerCertificate(data.id)
    // .subscribe({
    //   next: (res) => {
    //     console.log("Tci Data" ,  res);
    //     // FileSaver.saveAs(res);
    //     // this.stpRegistrationService.makeFreelancePDF(res);
    //     // console.log("loadstpApplicationsFromServer",res.data);
    //     // this.total = res.count;
    //   },
    // });
  }

  onCertificateView(data: any) {
    this.router.navigate(['certificate-view',data.id], { relativeTo: this.activatedRoute });
  }

  getOccupationList() {
    this.tamOccupations =
      this.trainingAndAssessmentMaterialsService.getTamOccupations();

    if (this.tamOccupations.length === 0) {
      this.trainingAndAssessmentMaterialsStorageService
        .readTamOccupations()
        .subscribe();
    }
    this.onTamOccupationSubscription$ =
      this.trainingAndAssessmentMaterialsService.onRefreshTamOccupationList.subscribe(
        (res) => {
          this.tamOccupations = res;
        }
      );
  }
}
