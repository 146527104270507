import { NgModule } from "@angular/core";
import { LandingModule } from "src/app/modules/landing/landing.module";
import { NgZorroAntdModule } from "src/app/ng-zorro-antd.module";
import { SharedModule } from "../shared/shared.module";
import { NAPDefaultLayoutRoutingModule } from "./NAP-default-layout-routing.module";
import { NAPDefaultLayoutComponent } from "./NAP-default-layout.component";


@NgModule({
  imports: [
    NAPDefaultLayoutRoutingModule,
    SharedModule,
    NgZorroAntdModule,
    LandingModule
  ],
  declarations: [NAPDefaultLayoutComponent],
})
export class NAPDefaultLayoutModule { }
