import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl } from '@angular/forms';
import { PasswordChange } from '../../models/password-change.dto';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  onStartResetPasswordEdit$!: Subscription;
  onEditingResetPasswordId!: number;
  validateForm!: FormGroup;
  isEditMode!: boolean;
  editedItem: any;
  editedItemId!: number;
  passwordVisible = false;
  newToken!: string;
  pwChange!: PasswordChange;

  constructor(
    private authenticationService: AuthenticationService,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.newToken = this.route.snapshot.params.newToken;}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      newPassword: [null, Validators.required],
      confirmPassword: ['', [Validators.minLength(8), this.confirmValidator]],
    });
  }

  // tslint:disable-next-line:typedef
  submitForm() {
    for (const key of Object.keys(this.validateForm.controls)) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    this.pwChange = this.validateForm.value;
    this.authenticationService
      .resetPassword(this.pwChange.newPassword, this.newToken)
      .subscribe((response) => {
        if (response.success === true) {
          this.notification.success('Success!', response.message);
          this.router.navigateByUrl('/login');
        } else {
          this.notification.error('Failed', response.message);
        }
      });
    this.resetForm();
  }

  resetForm(): void {
    this.validateForm.reset();
    this.isEditMode = false;
    for (const key of Object.keys(this.validateForm.controls)) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.validateForm.controls.confirmPassword.updateValueAndValidity()
    );
  }
  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !== this.validateForm.controls.newPassword.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };
}
