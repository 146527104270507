import { Component, OnInit, VERSION } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestData } from '../../models/stp_onlinePayment.model';
import { StpRegistrationStorageService } from '../../services/stp-registration-storage.service';

@Component({
  selector: 'app-certificate-varify-page',
  templateUrl: './certificate-varify-page.component.html',
  styleUrls: ['./certificate-varify-page.component.scss'],
})
export class CertificateVarifyPageComponent implements OnInit {
  requestData: RequestData = new RequestData();
  userInfo: any;
  stpMainId: any;
  title: any;
  name: any;
  elementType: any;
  correctionLevel: any;
  value: any;
  id: number;
  stpNameEnglish: any;
  stpRegistrationId: any;
  stpIssuedDateRaw: any;
  stpValidityDateRaw: any;
  stpIssuedDate: any;
  stpValidityDate: any;
  officeText: string;
  constructor(
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
        // console.log('from param', params);
        // console.log("id ", this.id);
      } else {
        this.id = this.route.snapshot.params.id;
        // console.log('from url');
        // console.log("id ", this.id);
      }

      this.getStpApplicationById();
    });
  }

  private getStpApplicationById() {
    this.stpRegistrationStorageService
      .getCertificateInformationByStpMainId(this.id)
      .subscribe(
        (res) => {
          if (res.success) {
            this.stpNameEnglish = res.data.stpName;
            this.stpRegistrationId = res.data.registrationNo;
            this.stpIssuedDateRaw = res.data.issuedOn;
            this.stpIssuedDate =
              this.stpIssuedDateRaw.substring(8, 10) +
              '-' +
              this.stpIssuedDateRaw.substring(5, 7) +
              '-' +
              this.stpIssuedDateRaw.substring(0, 4);
            //#region change office name logic
            let issueDate = this.convertStringToDate(this.stpIssuedDate);
            const cutoffDate = this.convertStringToDate('08-08-2024');
            // Compare dates
            this.officeText =
              issueDate <= cutoffDate
                ? 'Prime Minister’s Office'
                : 'Chief Adviser’s Office';
            //#endregion
            this.stpValidityDateRaw = res.data.validUntil;
            this.stpValidityDate =
              this.stpValidityDateRaw.substring(8, 10) +
              '-' +
              this.stpValidityDateRaw.substring(5, 7) +
              '-' +
              this.stpValidityDateRaw.substring(0, 4);
          }
        },
        (error) => {
          this.router.navigate(['not-found']);
        }
      );
  }

  convertStringToDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }
}
