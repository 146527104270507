import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { EventEmitter } from 'stream';
import { CadEquipment } from '../../../models/cad-equipment.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-cad-equipment',
  templateUrl: './add-cad-equipment.component.html',
  styleUrls: ['./add-cad-equipment.component.scss']
})
export class AddCadEquipmentComponent implements OnInit {

  // cadEquipmentForm: FormGroup;
  // tamMainId: string | null;
  // equipmentList: CadEquipment[] = [];

  @Input() InputData: any;
  isEditMode: boolean = false;
  singleCad: CadEquipment;
  onStartSingleCadEdit$: Subscription;
  onEditingSingleCadId!: number | null;
  cadForm: FormGroup;
  editedItem!: any;
  editedItemId!: number;
  cadList: CadEquipment[] = [];
  tamMainId: number;
  onCadEquipmentSubscription$: Subscription;

  //#region Excel File Upload And Read Excel Data

  excelData: any[] = [];
  userInfo: any;
  userFile: any = File;
  allowedExtensions = '.xlsx,.xls';
  maxFormatFileSize = 3;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  total: number;
  page = 1;
  size = 10;

  //#endregion Excel File Upload And Read Excel Data



  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private utilityService: UtilityService
  ) {
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
    this.onEditingSingleCadId = this.cadService.getEditingSingleCadEquipmentId();
    this.onStartSingleCadEdit$ =
      this.cadService.onStartCadEquipmentEditing.subscribe(
        (res: number | null) => {
          this.onEditingSingleCadId = res;
        }
      );
    this.cadList = this.cadService.getCadEquipmentList(this.tamMainId);
    if (this.cadList.length === 0) {
      this.cadStorageService.readCadEquipmentListByTamId(this.tamMainId).subscribe();
    }
    this.onCadEquipmentSubscription$ =
      this.cadService.onRefreshCadEquipmentList.subscribe((res) => {
        this.cadList = res;
      });
  }

  ngOnInit(): void {
    console.log(this.InputData);
    this.createForm();
    this.editedItemId = this.InputData;
    this.isEditMode = true;
    this.isEditMode = this.editedItemId !== null;
    this.editedItem = this.cadService.getCadEquipmentById(this.editedItemId);
    
    this.cadForm.patchValue(this.editedItem);

    // this.createEquipmentForm();
    // this.tamMainId = this.route.snapshot.queryParamMap.get('tamMainId');

  }

  createForm() {
    this.cadForm = this.fb.group({
      equipmentName: [],
      specification: [],
      unit: [],
      minQuantityReq: [0, [Validators.pattern("^[0-9]*$")]],
      remark: []
    });
  }

  resetForm(): void {
    this.cadForm.reset();
  }

  submitForm(): void {
    for (const key of Object.keys(this.cadForm.controls)) {
      this.cadForm.controls[key].markAsDirty();
      this.cadForm.controls[key].updateValueAndValidity();
    }
    this.singleCad = this.cadForm.value;
    this.singleCad.tamMainId = Number(this.tamMainId);
    const isFalsy = this.cadService.getNullableFormCheck(this.singleCad, this.tamMainId);
    if (isFalsy) {
      this.notification.error('Error', 'All input blank form submit is not allowed');
      return;
    }
    if (this.editedItemId != null) {
      this.cadStorageService.updateCadEquipment(this.singleCad, this.editedItemId).subscribe({
        next: (response) =>
          this.notification.success('Success!', 'Equipment update successfully'),
        error: (error) => this.notification.error('Failed', error),
      });
    }else {
      const cadData: CadEquipment = this.cadForm.value;
      const isFalsy = this.cadService.getNullableFormCheck(cadData, this.tamMainId);
      if (isFalsy) {
        this.notification.error('Error', 'All input blank form submit is not allowed');
        return;
      }

    if(!this.tamMainId) {
      this.notification.error('Error', 'Training and Assessment Material Id not found');
      return;
    }

    cadData.tamMainId = Number(this.tamMainId);
    cadData.minQuantityReq = Number(cadData.minQuantityReq);

    this.cadStorageService
      .createCadEquipment(cadData)
      .subscribe({
        next: (response) => {
          this.notification.success('Success','Added Equipment data');
        },
        error: (error) =>
          this.notification.error(
            'Failed',
            'Please! provide valid information'
          ),
      });
      this.cadForm.reset();
    }
    }

  //#region Excel File Upload And Read Excel Data

  onSelectFile(event: any): void {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      this.myInputVariable.nativeElement.value = '';
      return;
    }
    const file = event.target.files[0];
    this.userFile = file;

    /* wire up file reader */
    const target: DataTransfer = event.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data); // Data will be logged in array format containing objects
      this.excelData = data;
      return data;
    };
  }

  submitExcelData(): void {
    const cadEquipmentList: CadEquipment[] = [];

    if (this.myInputVariable.nativeElement.value == 0) {
      this.notification.error('Failed!', 'Please upload Equipment excel file');
      return;
    } else {
      for (const item of this.excelData) {
        const cadEquipment = new CadEquipment();

        cadEquipment.tamMainId = Number(this.tamMainId) ;
        cadEquipment.equipmentName = item.equipmentName;
        cadEquipment.specification = item.specification;
        cadEquipment.unit = item.Unit;
        cadEquipment.minQuantityReq = item.minQuantityReq;
        cadEquipment.remark = item.remark;

        cadEquipmentList.push(cadEquipment);

        const existItem = cadEquipmentList.filter(
          (item) => item.equipmentName == cadEquipment.equipmentName
        );


        for (let item of cadEquipmentList) {
          let msg = item.equipmentName;
          if (isNaN(item.minQuantityReq)) {
            this.notification.error(
              'Failed',
              `Invalid minimum quantity requierment for equipment name:  ${msg}, Please correct and reupload!`
            );
            this.myInputVariable.nativeElement.value = '';
            return;
          }
        }

        if (existItem.length > 1) {
          this.notification.error(
            'Failed',
            'Duplicate Equipment name found in Excel, Please check!'
          );
          this.myInputVariable.nativeElement.value = '';
          return;
        }
      }
    }

    //#region Excel duplication value check

    // let existItem = this.excelData.filter(
    //   (item) => item.furnitureName == stpTrainer.furnitureName
    // );
    // if (existItem.length > 1) {
    //   this.notification.error(
    //     'Failed',
    //     'Duplicate Data found in Excel, Please check!'
    //   );
    //   return;
    // }

    //#endregion Excel duplication value check
    // if(this.cadStorageService.isStpMainAlreadyCreated){
      this.cadStorageService
        .saveExcelCadEquipmentData(cadEquipmentList)
        .subscribe({
          next: (response) => {
            this.notification.success(
              'Success!',
              'Cad Equipment created successfully'
            );
          },
          error: (error) =>
            this.notification.error(
              'Failed',
              'Please! provide valid information'
            ),
        });

    // }
    // else {
    //   this.notification.error(
    //     'Failed',
    //     'Please fill up basic profile first.'
    //   );
    // }

    this.myInputVariable.nativeElement.value = '';
    return;
  }




  //#endregion Excel File Upload And Read Excel Data
}
