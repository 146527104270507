import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RplStorageService } from 'src/app/modules/stp-operations/services/rpl-storage.service';

@Component({
  selector: 'app-view-rpl-certificate',
  templateUrl: './view-rpl-certificate.component.html',
  styleUrls: ['./view-rpl-certificate.component.scss'],
})
export class ViewRplCertificateComponent implements OnInit {
  rplCandidateId: string | null;
  certificateData: any;
  competent: boolean = false;
  isLoadingData: boolean = false;
  officeText: string;

  constructor(
    private rplStorageService: RplStorageService,
    private route: ActivatedRoute
  ) {
    this.rplCandidateId =
      this.route.snapshot.queryParamMap.get('rplCandidateId');
    if (this.rplCandidateId != null) {
      this.getCertificateDataForRplCandidate();
    }
  }

  ngOnInit(): void {}

  getCertificateDataForRplCandidate() {
    this.rplStorageService
      .getCertificateDataForRplCandidate(this.rplCandidateId)
      .subscribe({
        next: async (result: any) => {
          this.certificateData = await result;
          this.competent = this.certificateData.isCompetent;
          //#region change office name logic
          let actualAssessDate = this.convertStringToDate(
            this.certificateData.actualAssessDate
          );
          const cutoffDate = this.convertStringToDate('08-08-2024');
          // Compare dates
          this.officeText =
            actualAssessDate <= cutoffDate
              ? 'Prime Minister’s Office'
              : 'Chief Adviser’s Office';
          //#endregion

          this.isLoadingData = true;
        },
      });
  }

  convertStringToDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }
}
