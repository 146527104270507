export class CadMeasuringInstrument {
  public id?: number;
  public measuringInsName?: string | null;
  public specification?: string | null;
  public unit?: string | null;
  public minQuantityReq: number ;
  public remark?: string | null;
  public tamMainId?: number | null;
  public tamMain?: any;
  constructor() {}
}
