import { RepresentativeOfficerList } from '../../course-accreditation/models/representative-officer-list.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleteInspection } from '../../course-accreditation/models/complete-inspection.model';
import { InspectionStorageService } from '../../course-accreditation/services/inspection-storage.service';
import { ListTamOccupation } from '../../course-accreditation/models/list-tam-occupation.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { StpRegistrationStorageService } from 'src/app/modules/stp-registration/services/stp-registration-storage.service';

@Component({
  selector: 'app-complete-inspect-preview-shared',
  templateUrl: './complete-inspect-preview-shared.component.html',
  styleUrls: ['./complete-inspect-preview-shared.component.scss']
})
export class CompleteInspectPreviewSharedComponent implements OnInit {

  inspectPreviewForm: FormGroup;
  otpForm: FormGroup;

  completeInspectionList: CompleteInspection;
  representativeOfficerList: RepresentativeOfficerList[] = [];
  listTamOccupation: ListTamOccupation[] = [];
  completeInspection: CompleteInspection = new CompleteInspection();

  otpInfoList: any[] = [];
  name: string;
  stpOrSacId: string;
  applicationId: string;
  dateOfApplication: Date;
  nsdaOfficerName: string;
  officer1Name: string;
  officer2Name: string;
  officer3Name: string;
  dateOfInspection: Date;

  isRegistrationInspectionRequired: number;

  stpMainId: number;

  inspectionId: number;
  inspectorOfficerId: number;
  inspectionIdFromUrl: string;
  inspectorOfficerIdFromUrl: string;

  submissionAttempted: boolean;

   // otp
   otp!: string;
   isVisible = false;
   display: any;
   previewBackground: boolean = false;

 //#region Constructor
 constructor(
  private fb: FormBuilder,
  private inspectionStorageService: InspectionStorageService,
  private activatedRoute: ActivatedRoute,
  private router: Router,
  private notification: NzNotificationService,
  private stpRegistrationStorageService: StpRegistrationStorageService
) {
  this.activatedRoute.queryParams.subscribe((p) => {
    this.inspectionIdFromUrl = p['inspectionId'];
    this.inspectorOfficerIdFromUrl = p['inspectorOfficerId'];
    this.showModal();  
    this.timer(5);
    this.submissionAttempted = false;
  });

  // for Otp Form
  this.otpForm = this.fb.group({
    otp: [
      '',
      [Validators.required, Validators.pattern(/^(?:\01)?(?:\d{5})$/)],
    ],
  });
 
  
}
//#endregion

//#region  ngOnInit
ngOnInit(): void {
  // this.queryParams();
}
//#endregion

//#region get id from query param
// queryParams() {
//   this.activatedRoute.queryParams.subscribe((params) => {
//     this.inspectionId = Number(params.inspectionId);
//     this.inspectorOfficerId = Number(params.inspectorOfficerId);
//   });
// }
//#endregion

//#region Submit form
submitForm() {
  this.completeInspection.inspectionId = this.inspectionId;
  this.completeInspection.inspectorOfficerId = this.inspectorOfficerId;
  this.inspectionStorageService
    .saveInspectorOfficerApprovalOnPreview(this.completeInspection)
    .subscribe({
      next: (response) => {
        if (response.success) {
          this.notification.success('Success!', response.message);
        } else {
          this.notification.error('Failed!', response.message);
        }
      },
      error: (error) => this.notification.error('Failed', error),
    });
}
//#endregion

//#region on click checkbox
checkbox(event: any) {
  if (event.target?.checked) {
    this.submissionAttempted = true;
  } else {
    this.submissionAttempted = false;
  }
}
//#endregion

//#region OTP Modal
showModal(): void {
  if (
    !this.checkLocalStorageForInspectorVerification(
      encodeURIComponent(this.inspectorOfficerIdFromUrl.trim())
    )
  ) {
    this.inspectionStorageService
      .sendOtp(this.inspectorOfficerIdFromUrl)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.notification.success('Success!', res.message);
          } else {
            this.notification.error('Failed!', res.message);
          }
        },
      });
    this.isVisible = true;
    setTimeout(() => {
      this.isVisible = false;
    }, 300000);
  } else {
    this.previewBackground = true;
    this.onCompleteInspectionByInspectionIdForVerifiedOtp();
  }
}

handleOk(): void {
  this.isVisible = false;
}

handleCancel(): void {
  this.isVisible = false;
}
//#endregion

onCompleteInspectionByInspectionIdForVerifiedOtp() {
  this.inspectionStorageService
    .getCompleteInspectionByInspectionIdForVerifiedOtp(
      this.inspectionIdFromUrl,
      this.inspectorOfficerIdFromUrl
    )
    .subscribe({
      next: (res) => {
        if (res.success) {
          this.notification.success('Success!', res.message);
          this.previewBackground = true;
          this.completeInspectionList = res.data;
          this.representativeOfficerList =
            res.data?.representativeOfficerList;
          this.listTamOccupation = res.data?.listTamOccupation;
          this.name = res.data?.name;
          this.stpMainId = res.data?.stpMainId;
          this.stpOrSacId = res.data?.registrationNo;
          this.applicationId = res.data?.applicationId;
          this.dateOfApplication = res.data?.applicationDate;
          this.nsdaOfficerName = res.data?.nsdaOfficerName;
          this.officer1Name = res.data?.officer1Name;
          this.officer2Name = res.data?.officer2Name;
          this.officer3Name = res.data?.officer3Name;
          this.dateOfInspection = res.data?.inspectionConductedDate;
          if (res.data?.listTamOccupation?.length > 0) {
            this.inspectionId = res.data?.listTamOccupation[0]?.inspectionId;
          }
          this.inspectorOfficerId = res.data?.inspectorOfficerId;
          this.listTamOccupation?.forEach(
            (item: ListTamOccupation, index: number) => {
              item['type'] = 'Occupation';
            }
          );
          this.isRegistrationInspectionRequired =
            res.data?.isRegistrationInspectionRequired;
          if (this.isRegistrationInspectionRequired) {
            this.listTamOccupation?.unshift({
              applicationType: 'NA',
              occupationName: 'STP/SAC Registration',
              inspectionResultId: null,
              occupationLevel: '',
              stpMainId: this.stpMainId,
              type: 'STP',
            });
          }
        } else {
          this.notification.error('Failed!', res.message);
        }
      },
    });
}

//#region submit otp
submitOtp(): void {
  this.previewBackground = false;
  this.otp = this.otpForm.get('otp')?.value;
  this.inspectionStorageService
    .verifyOtp(
      this.otp,
      this.inspectionIdFromUrl,
      this.inspectorOfficerIdFromUrl
    )
    .subscribe({
      next: (response) => {
        if (response.success) {
          this.notification.success('Success!', response.message);
          this.isVisible = false;
          this.previewBackground = true;
          this.inspectionIdFromUrl = encodeURIComponent(
            this.inspectionIdFromUrl.trim()
          );
          let inspectorOfficerId = encodeURIComponent(this.inspectorOfficerIdFromUrl.trim());
          let getOtpInfo = {
            //inspectorId: this.inspectionIdFromUrl,
            inspectorId:inspectorOfficerId,
            otpVerified: true,
            duration: new Date().getTime() + 5 * 60 * 1000,
          };
          this.otpInfoList.push(getOtpInfo);
          localStorage.setItem('otpInfo', JSON.stringify(this.otpInfoList));
          this.completeInspectionList = response.data;
          this.representativeOfficerList =
            response.data?.representativeOfficerList;
          this.listTamOccupation = response.data?.listTamOccupation;
          this.name = response.data?.name;
          this.stpMainId = response.data?.stpMainId;
          this.stpOrSacId = response.data?.registrationNo;
          this.applicationId = response.data?.applicationId;
          this.dateOfApplication = response.data?.applicationDate;
          this.nsdaOfficerName = response.data?.nsdaOfficerName;
          this.officer1Name = response.data?.officer1Name;
          this.officer2Name = response.data?.officer2Name;
          this.officer3Name = response.data?.officer3Name;
          this.dateOfInspection = response.data?.inspectionConductedDate;
          if (response.data?.listTamOccupation?.length > 0) {
            this.inspectionId =
              response.data?.listTamOccupation[0]?.inspectionId;
          }
          this.inspectorOfficerId = response.data?.inspectorOfficerId;
          this.listTamOccupation?.forEach(
            (item: ListTamOccupation, index: number) => {
              item['type'] = 'Occupation';
            }
          );
          this.isRegistrationInspectionRequired =
            response.data?.isRegistrationInspectionRequired;
          if (this.isRegistrationInspectionRequired) {
            this.listTamOccupation?.unshift({
              applicationType: 'NA',
              occupationName: 'STP/SAC Registration',
              inspectionResultId: null,
              occupationLevel: '',
              stpMainId: this.stpMainId,
              type: 'STP',
            });
          }
        } else {
          this.notification.error('Failed!', response.message);
        }
      },
      error: (error) => this.notification.error('Failed', 'OTP is not valid'),
    });
  this.otpForm.reset();
  // this.validateForm.reset();
}
//#endregion

//#region OTP timer
timer(minute: any) {
  // let minute = 1;
  let seconds: number = minute * 60;
  let textSec: any = '0';
  let statSec: number = 60;
  const prefix = minute < 10 ? '0' : '';
  const timer = setInterval(() => {
    seconds--;
    if (statSec != 0) statSec--;
    else statSec = 59;
    if (statSec < 10) {
      textSec = '0' + statSec;
    } else textSec = statSec;
    this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
    if (seconds == 0) {
      clearInterval(timer);
    }
  }, 1000);
}
//#endregion

onViewStpSac(inspectionId: number): void {
  let decodeUri = decodeURIComponent(this.inspectionIdFromUrl);
  this.router.navigate(
    [`home/course-accreditation/stp-sac-inspection-report-preview-for-otp`],
    { queryParams: { inspectionId: decodeUri } }
  );
}
onView(inspectionResultId: number | null): void {
  this.router.navigate([
    `home/course-acc-inspection-report/inspection-report-preview-for-otp/${inspectionResultId}`,
  ]);
}

checkLocalStorageForInspectorVerification(inspectorId: string): boolean {
  let isInspectorVerified = false;
  let otpInfo = localStorage.getItem('otpInfo');
  if (otpInfo != null) {
    let jsonParsedOtpInfo = JSON.parse(otpInfo);
    let indexArray: number[] = [];
    jsonParsedOtpInfo.forEach((item: any, index: number) => {
      if (item.duration < new Date().getTime()) {
        indexArray.push(index);
      }
    });
    for (let i = 0; i < indexArray.length; i++) {
      jsonParsedOtpInfo.splice(indexArray[i], 1);
    }
    this.otpInfoList = jsonParsedOtpInfo;
    console.log(inspectorId);
    let inspector = this.otpInfoList.find(
      (x) => x.inspectorId == inspectorId
    );
    console.log(inspector);
    if (inspector != null) {
      isInspectorVerified = true;
    } else {
      isInspectorVerified = false;
    }
    localStorage.setItem('otpInfo', JSON.stringify(this.otpInfoList));
  } else {
    isInspectorVerified = false;
  }
  return isInspectorVerified;
}
}
