import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Candidate } from '../components/RPL/components/models/candidate.model';
import { RplAssessmentAppl } from '../components/RPL/components/models/RplAssessmentAppl.model';
import { RplAssessmentApplicationLRequestDto } from '../components/RPL/components/models/RplAssessmentApplicationLRequestDto.model';
import { ApplyForRPLBatchAssessmentDTO } from '../components/RPL/components/models/DTO/apply-for-rpl-bath-assessment-DTO';
import { RplRegularAssessApply } from '../components/RPL/components/models/rpl-regular-assess-apply.model';
import { AssessorInfo } from '../components/RPL/components/models/assessor-info.model';
import { UploadResults } from '../components/assessor/models/upload-results-DTO';

@Injectable({
  providedIn: 'root',
})
export class RplService {
  candidate: Candidate[] = [];
  onRefreshCandidateList = new Subject<Candidate[]>();

  rplAssessmentAppl: RplAssessmentAppl[] = [];
  onRefreshAssessmentApplist = new Subject<RplAssessmentAppl[]>();

  applyForRPLBatchAssessmentDTO: ApplyForRPLBatchAssessmentDTO[] = [];
  onRefreshRPLBatchAssessmentList = new Subject<
    ApplyForRPLBatchAssessmentDTO[]
  >();

  rplRegularAssessApply: RplRegularAssessApply[] = [];
  onRefreshRplRegularAssessApplyList = new Subject<RplRegularAssessApply[]>();

  assessor: AssessorInfo[] = [];
  onRefreshAssessorList = new Subject<AssessorInfo[]>();

  uploadResults: UploadResults[] = [];
  onRefreshResultList = new Subject<UploadResults[]>();

  constructor() {}

  setCandidate(candidate: Candidate[]): void {
    this.candidate = candidate;
    this.onRefreshCandidateList.next(this.candidate.slice());
  }

  setAssessmentAppl(rplAssessmentAppl: RplAssessmentAppl[]): void {
    this.rplAssessmentAppl = rplAssessmentAppl;
    this.onRefreshAssessmentApplist.next(this.rplAssessmentAppl.slice());
  }

  updatedRPLBatchAssessment(
    newRecipe: ApplyForRPLBatchAssessmentDTO,
    id: number
  ): void {
    const index = this.applyForRPLBatchAssessmentDTO.findIndex(
      (rplBatch) => rplBatch.id === id
    );
    this.applyForRPLBatchAssessmentDTO[index] = newRecipe;
    this.onRefreshRPLBatchAssessmentList.next(
      this.applyForRPLBatchAssessmentDTO.slice()
    );
  }

  setRPLAssessment(rplRegularAssessmentApply: RplRegularAssessApply[]): void {
    this.rplRegularAssessApply = rplRegularAssessmentApply;
    this.onRefreshRplRegularAssessApplyList.next(
      this.rplRegularAssessApply.slice()
    );
  }

  setAssessor(assessorInfo: AssessorInfo[]): void {
    this.assessor = assessorInfo;
    this.onRefreshAssessorList.next(this.assessor.slice());
  }

  updateResults(newRecipe: UploadResults, id: number): void {
    const index = this.uploadResults.findIndex((results) => results.id === id);
    this.uploadResults[index] = newRecipe;
    this.onRefreshResultList.next(this.uploadResults.slice());
  }
}
