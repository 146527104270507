import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { CompetencyStandard } from '../../../models/competency-standard.model';
import { CsGeneric } from '../../../models/cs-generic.model';
import { CompetencyStandardStorageService } from '../../../services/competency-standard-storage.service';
import { CompetencyStandardService } from '../../../services/competency-standard.service';

@Component({
  selector: 'app-add-cs-generic',
  templateUrl: './add-cs-generic.component.html',
  styleUrls: ['./add-cs-generic.component.scss'],
})
export class AddCsGenericComponent implements OnInit {
  @Input() InputData: any;
  isEditMode: boolean = false;
  singleCs: CsGeneric;
  onStartSingleCsEdit$: Subscription;
  onEditingSingleCsId!: number | null;
  csForm: FormGroup;
  editedItem!: any;
  editedItemId!: number;
  csList: CsGeneric[] = [];
  tamMainId: number;
  onCsGenericSubscription$: Subscription;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private csStorageService: CompetencyStandardStorageService,
    private csService: CompetencyStandardService
  ) {
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
    this.onEditingSingleCsId = this.csService.getEditingSingleCsGenericId();
    this.onStartSingleCsEdit$ =
      this.csService.onStartCsGenericEditing.subscribe(
        (res: number | null) => {
          this.onEditingSingleCsId = res;
        }
      );
    this.csList = this.csService.getCsGenericList(this.tamMainId);
    if (this.csList.length === 0) {
      this.csStorageService.readCsGenericListByTamId(this.tamMainId).subscribe();
    }
    this.onCsGenericSubscription$ =
      this.csService.onRefreshCsGenericList.subscribe((res) => {
        this.csList = res;
      });
    
    // if (csService.getCsGenericList(Number(this.tamMainId)).length === 0) {
    //   this.csStorageService
    //     .readCsGenericListByTamId(Number(this.tamMainId))
    //     .subscribe();
    // }
    // if (
    //   csService.getCsOccupationSpecificList(Number(this.tamMainId)).length === 0
    // ) {
    //   this.csStorageService
    //     .readCsOccupationSpecificListByTamId(Number(this.tamMainId))
    //     .subscribe();
    // }
    // if (
    //   csService.getCsSectorSpecificList(Number(this.tamMainId)).length === 0
    // ) {
    //   this.csStorageService
    //     .readCsSectorSpecificListByTamId(Number(this.tamMainId))
    //     .subscribe();
    // }
    // this.csService.addAllCsList(Number(this.tamMainId));
  }

  ngOnInit(): void {
    console.log(this.InputData);
    this.createForm();
    this.editedItemId = this.InputData;
    this.isEditMode = true;
    this.isEditMode = this.editedItemId !== null;
    this.editedItem = this.csService.getCsGenericById(this.editedItemId);
    
    this.csForm.patchValue(this.editedItem);

    // this.onStartSingleCsEdit$ =
    // this.csService.onStartCsGenericEditing.subscribe(
    //   (id: number | null) => {
    //     this.onEditingSingleCsId = id;
    //     this.editedItemId = id;
    //     this.isEditMode = this.editedItemId !== null;
    //     this.editedItem = this.csService.getCsGenericById(this.editedItemId);
    //     console.log("this.editedItem",this.editedItem);
    //     this.csForm.patchValue({
    //       unitCode: this.editedItem.unitCode,
    //       title: this.editedItem.title,
    //       duration: this.editedItem.duration,
    //       remark: this.editedItem.remark,
    //     });
    //   }
    // );
  }

  createForm() {
    this.csForm = this.fb.group({
      unitCode: ['', Validators.required],
      title: [],
      duration: [],
      remark: [],
    });
  }

  resetForm(): void {
    this.csForm.reset();
  }

  submitForm(): void {
    for (const key of Object.keys(this.csForm.controls)) {
      this.csForm.controls[key].markAsDirty();
      this.csForm.controls[key].updateValueAndValidity();
    }
    this.singleCs = this.csForm.value;
    this.singleCs.tamMainId = Number(this.tamMainId);
    if (this.editedItemId != null) {
      this.csStorageService.updateCsGeneric(this.singleCs, this.editedItemId).subscribe({
        next: (response) =>
          this.notification.success('Success!', 'Generic Competency update successfully'),
        error: (error) => this.notification.error('Failed', error),
      });
    }else {
      const csData: CsGeneric = this.csForm.value;
      const isFalsy = this.csService.getNullableFormCheck(csData, this.tamMainId);
      if (isFalsy) {
        this.notification.error('Error', 'All input blank form submit is not allowed');
        return;
      }

      if (!this.tamMainId) {
        this.notification.error(
          'Error',
          'Training and Assessment Material Id not found'
        );
        return;
      }
  
      csData.tamMainId = Number(this.tamMainId);
      this.csForm.reset();
      this.csStorageService.createCsGeneric(csData).subscribe({
        next: (response) => {
          this.notification.success('Success', 'Added Generic Competency');
        },
        error: (error) =>
          this.notification.error('Failed', 'Please! provide valid information'),
      });
    }
    this.csForm.reset();
  }
}
