<app-header [hidden]="hiddingHeaderFooter"></app-header>
<nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Training & Assessment Materials Search
    </div>
    <form
      nz-form
      [formGroup]="searchForm"
      class="ant-advanced-search-form"
      (ngSubmit)="submitSearchForm()"
      style="
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      "
    >
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="5">
          <nz-form-item>
            <nz-form-label>ID</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="ID"
                formControlName="tamApplicationId"
                id="tamApplicationId"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Document Type</nz-form-label>
            <nz-form-control>
              <nz-select
                nzShowSearch
                nzAllowClear
                id="tamTypeId"
                formControlName="tamTypeId"
                nzPlaceHolder="Select Document Type"
              >
                <nz-option
                  class="option"
                  *ngFor="let data of tamTypes"
                  [nzValue]="data.id"
                  [nzLabel]="data.name"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="7">
          <nz-form-item>
            <nz-form-label>Access Type</nz-form-label>
            <nz-form-control>
              <nz-select
                nzShowSearch
                nzAllowClear
                id="isPublished"
                formControlName="isPublished"
                nzPlaceHolder="Select Access Type">
                <nz-option class="option" nzValue="true" nzLabel="Public">
                </nz-option>
                <nz-option
                  class="option"
                  nzValue="false"
                  nzLabel="Limited">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Sector</nz-form-label>
            <nz-form-control>
              <nz-select
                nzShowSearch
                nzAllowClear
                id="industrialSectorId"
                formControlName="industrialSectorId"
                nzPlaceHolder="Select Sector"
              >
                <nz-option
                  class="option"
                  *ngFor="let data of tamIndustrialSectors"
                  [nzValue]="data.id"
                  [nzLabel]="data.name"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Occupation</nz-form-label>
            <nz-form-control>
              <nz-select
                nzShowSearch
                nzAllowClear
                id="tamOccupationId"
                formControlName="tamOccupationId"
                nzPlaceHolder="Select Occupation"
              >
                <nz-option
                  class="option"
                  *ngFor="let data of tamOccupations"
                  [nzValue]="data.id"
                  [nzLabel]="data.name"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="24" class="search-area">
          <button nz-button [nzType]="'primary'">Search</button>
          <button nz-button (click)="resetForm()">Refresh Data</button>
          <button class="btn-dark" nz-button (click)="onDownloadReport()">
            Download Report
          </button>
        </div>
      </div>
    </form>
  </nz-card>
  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Training & Assessment Materials List
    </div>
    <div nz-col [nzSpan]="24">
      <nz-table
        #basicTable
        [nzData]="tamMainSearchResultDto"
        nzTableLayout="fixed"
        nzShowSizeChanger
        nzBordered
        nzSize="middle"
        nzAlign="middle"
        [nzFrontPagination]="false"
        [nzTotal]="total"
        [(nzPageSize)]="pageSize"
        [nzShowTotal]="totalRowRangeTemplate"
        [(nzPageIndex)]="currentPage"
        (nzQueryParams)="onQueryParamsChange($event)"
        [nzScroll]="{ x: '1150px' }"
      >
        <ng-template
          #totalRowRangeTemplate
          let-range="range"
          let-total
          style="text-align: left"
        >
          <div style="text-align: left">
            Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
          </div>
        </ng-template>
  <!-- <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Training & Assessment Materials List
    </div>
    <div class="searchboxAerar p-3">
      <nz-table
        nzSize="small"
        #basicTable
        [nzData]="tamMains"
        nzShowSizeChanger
        nzBordered
        nzAlign="middle"
        [nzFrontPagination]="false"
        [nzShowPagination]="false"
        class="css-serial table-striped table"
      > -->
        <thead>
          <tr>
            <th style="text-align: center; vertical-align: middle">SI.</th>
            <th style="text-align: center; vertical-align: middle">ID</th>
            <th style="text-align: center; vertical-align: middle" nzColumnKey="tamType.id" [nzSortFn]="true">
              Document Type
            </th>
            <th style="text-align: center; vertical-align: middle" nzColumnKey="tamOccupation.tamIndustrialSector.id" [nzSortFn]="true">Sector</th>
            <th style="text-align: center; vertical-align: middle" nzColumnKey="tamOccupation.id" [nzSortFn]="true">
              Occupation
            </th>
            <th style="text-align: center; vertical-align: middle" nzColumnKey="tamLevel.id" [nzSortFn]="true">Level</th>
            <!-- <th style="text-align: center; vertical-align: middle">
              Document Title
            </th> -->
            <th style="text-align: center; vertical-align: middle" nzColumnKey="tamLanguage.id" [nzSortFn]="true">Language</th>
            <th style="text-align: center; vertical-align: middle" nzColumnKey="approvalDate" [nzSortFn]="true">
              Date of Approval
            </th>
            <th style="text-align: center; vertical-align: middle" nzColumnKey="isPublished" [nzSortFn]="true">Access Type</th>
            <th style="text-align: center; vertical-align: middle"> {{((this.userInfo?.roles == null) || (this.userInfo?.roles[0].name === 'admin')) ? "Download" : "Action" }} </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i = index">
            <td style="text-align: center">{{ (currentPage - 1) * pageSize + 1 + i }}</td>
            <td style="text-align: center; vertical-align: middle" (click)="selectedCourseAccreditationDocument(data.id)">
              <a style="color:#007bff!important">
                {{ data.tamApplicationId }}</a>
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.tamTypeName }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.tamIndustrialSectorName }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.tamOccupationName }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.tamLevelId }}
            </td>
            <!-- <td style="text-align: center; vertical-align: middle">
              {{ data.docTitle }}
            </td> -->
            <td style="text-align: center; vertical-align: middle">
              {{ data.tamLanguageName }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.approvalDate | date: "dd-MM-YYYY" }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.isPublished === true ? "Public" : "Limited" }}
            </td>
            <td style="text-align: center; vertical-align: middle">
                <div
                  [appRequiredPermission]="applicationPermissions.tam.tam.updatePermission"
                >
                  <button
                    [appRequiredPermission]="applicationPermissions.tam.tam.updatePermission"
                    title="Edit"
                    (click)="onEdit(data.id)"
                    [hidden]="((this.userInfo?.roles == null) || (this.userInfo?.roles[0].name === 'admin'))"
                    nz-button
                    class="mr-1"
                    nzType="primary"
                    [nzSize]="'small'"
                  >
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                  </button>
                </div>
                <div>
                  <button
                  class="mr-1 mt-1"
                  title="Download"
                  (click)="onDownload(data)"
                  [hidden]="(!data?.isPublished == true) && ((this.userInfo?.roles == null) || (this.userInfo?.roles[0].name === 'User'))"
                  nz-button
                  nzType="primary"
                  [nzSize]="'small'"
                >
                  <i nz-icon nzType="download" nzTheme="outline"></i>
                </button>
                </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <!-- <nz-pagination
        [nzPageIndex]="currentPage"
        [nzTotal]="totalData"
        [nzPageSize]="pageSize"
        (nzPageIndexChange)="onPageIndexChange($event)"
        [nzShowTotal]="rangeTemplate"
      ></nz-pagination> -->
      <!-- <ng-template #totalTemplate let-total
        >Total {{ totalData }} items</ng-template> -->
      <!-- <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} of {{ totalData }} items
      </ng-template> -->
    </div>
</nz-card>

<app-footer [hidden]="hiddingHeaderFooter"></app-footer>
