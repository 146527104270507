import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { HttpService } from 'src/app/shared/services/http.service';
import { fileDeleteRequest } from '../../course-acc-inspection-report/DTO/fileDeleteRequest';
import { CourseAccreditationSearchingDto } from '../DTO/courseAccreditationSearchingDto';
import { InspectionCadDto } from '../DTO/InspectionCadDto';
import { SaveInspectionDecisionDto } from '../DTO/saveInspectionDecisionDto';
import { BasicInformation } from '../models/BasicInformation';
import { CompleteInspection } from '../models/complete-inspection.model';
import { InspectionOfficer } from '../models/inspectionOfficer';
import { ScheduleInspection } from '../models/schedule-inspection.model';
import { InspectionService } from './inspection.service';

@Injectable()
export class InspectionStorageService {
  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    private inspectionService: InspectionService
  ) { }

  //#region All tab save Info (Inspection)

  createBasicInformation(
    basicInformation: BasicInformation
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.inspection.saveBasicInspection}`,
        basicInformation
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.inspectionService.addBasicInformation(res.data);
          }
        })
      );
  }

  saveAllCadForCourseAccreditation(
    inspectionCadDto: InspectionCadDto
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.inspection.saveInspectionCad}`,
        inspectionCadDto
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.inspectionService.addBasicInformation(res.data);
          }
        })
      );
  }
  //#endregion All tab save Info (Inspection)

  //#region get all inspection by acl user id(Inspection)

  searchInspectionList(page: number, size: number): Observable<ServerResponse> {
    const startPage = page - 1;
    let param: string = '';
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getAllInspectionbyAclUserId}` +
      '?page=' +
      `${startPage}` +
      '&size=' +
      `${size}`
    );
  }

  //#region get all inspection for admin view

  // searchInspectionAdminList(inspectionListSearch: InspectionListSearch): Observable<ServerResponse> {
  //   console.log("inspectionListSearch", inspectionListSearch);
  //   inspectionListSearch.disabled = false;
  //   return this.httpClient.post<ServerResponse>(
  //     `${applicationUrls.inspection.getAllInspectionForAdmin}` ,
  //     inspectionListSearch
  //   );
  //   // return this.httpClient.get<ServerResponse>(
  //   //   `${applicationUrls.inspection.getAllInspectionForAdmin}`,
  //   //   inspectionListSearch
  //   // );
  // }

  searchInspectionAdminList(
    page: number,
    size: number,
    dataSet: any,
    sortingKey?: string,
    sortingValue?: string
  ): Observable<ServerResponse> {
    let applicantRequestDTO = {
      pageNumber: page - 1,
      pageSize: size,
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null,
      tamMainId: (dataSet?.tamMainId ? dataSet?.tamMainId : null),
      districtId: (dataSet?.districtId ? dataSet?.districtId : null),
      applicationTypeId: (dataSet?.applicationTypeId ? dataSet?.applicationTypeId : null),
      assignedToInspector: (dataSet?.assignedToInspector ? dataSet?.assignedToInspector : false),
      fromDate: (dataSet?.fromDate ? dataSet.fromDate : null),
      toDate: (dataSet?.toDate ? dataSet?.toDate : null),
      stpName: (dataSet?.stpName ? dataSet?.stpName : null),
      applicationId: (dataSet?.applicationId ? dataSet?.applicationId : null),
      disabled: false
    };
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.inspection.getAllInspectionForAdmin}`,
      applicantRequestDTO
    );
    // return this.httpClient.get<ServerResponse>(
    //   `${applicationUrls.inspection.getAllInspectionForAdmin}`,
    //   inspectionListSearch
    // );
  }

  saveCctv(cctv: any): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.cctv.saveCctv}`,
      cctv
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.inspectionService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  checkAllOccupationStatusByInspectionId(inspectionId: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.checkAllOccupationStatusByInspectionId}` + inspectionId);
  }

  getAllInspectionbyAclUserId(page?: number | 0, size?: number | 10, sortingKey?: string | null, sortingValue?: string | null): Observable<ServerResponse> {
    let applicantRequestDTO = {
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.inspection.getAllInspectionbyAclUserId}`, applicantRequestDTO
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.inspectionService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  getSummaryOccupationListByInspectionId(
    id: number
  ): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getSummaryOccupationListByInspectionId}` +
      id
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.inspectionService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  getCourseAccreditationByResultId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getCourseAccreditationByResultId}` + id
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.inspectionService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  // getAllApplicationType() {
  //   return this.httpClient.get<ServerResponse>(
  //     `${applicationUrls.inspection.getAllApplicationTypes}`
  //   );
  //   // .pipe(
  //   //   tap((res) => {
  //   //     if (res.data) {
  //   //       this.inspectionService.addBasicInformation(res.data);
  //   //     }
  //   //   })
  //   // );
  // }

  getStpUserInfo(){
    return this.httpClient.get<ServerResponse>(`${applicationUrls.user.getStpUserInfo}`);
  }

  getAllApplicationTypeByAccreditation(type: string) {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getAllApplicationTypeByAccreditation}?type=${type}`
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.courseAccInspectionReportService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  submitCourseAccreditationApplication(id: number) {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.submitApplication}`,
      { id: id }
    );
  }
  //#endregion get all inspection by acl user id(Inspection)

  //#region Schedule Inspection

  getNSDAOfficer(tag: string): Observable<ServerResponse> {
    let params = new HttpParams();
    params = params.append('tag', tag);

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.inspection.getNSDAOfficer}`, { params: params })
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setInspectionOfficer(response.data);
          }
        })
      );
  }

  //#region NSDA Officer
  getInspectionOfficer(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.inspection.getInspectionOfficer}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setInspectionOfficer(response.data);
          }
        })
      );
  }

  //#region Save inspection officer
  saveInspectionOfficer(
    inspectionOfficer: InspectionOfficer
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.inspection.saveInspectionOfficer}`,
        inspectionOfficer
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.inspectionService.addInspectionOfficer(res.data);
            this.inspectionService.addInspectionOfficers(res.data);
          }
        })
      );
  }
  //#endregion

  //#region getScheduleInspectionByInspectionId
  getScheduleInspectionByInspectionId(
    inspectionId: number
  ): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.inspection.getScheduleInspectionByInspectionId}?inspectionId=${inspectionId}`
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setScheduleInspection(response.data);
          }
        })
      );
  }
  //#endregion

  //#region Save Schedule Inspection
  saveScheduleInspection(
    scheduleInspection: ScheduleInspection
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.inspection.saveScheduleInspection}`,
        scheduleInspection
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.inspectionService.addScheduleInspection(res.data);
          }
        })
      );
  }
  //#endregion

  //#region Submit Schedule Inspection
  submitSceduleInspection(inspectionId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.submitScheduleInspection}?inspectionId=${inspectionId}`,
      inspectionId
    );
  }
  //#endregion

  //#region getCompleteInspectionByInspectionId
  getCompleteInspectionByInspectionId(
    inspectionId: number
  ): Observable<ServerResponse> {
    const params = new HttpParams().append('inspectionId', `${inspectionId}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.inspection.getCompleteInspectionByInspectionId}`,
        {
          params,
        }
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setCompleteInspection(response.data);
          }
        })
      );
  }
  //#endregion

  //#region getCompleteInspectionByInspectionIdForVerifiedOtp
  getCompleteInspectionByInspectionIdForVerifiedOtp(
    inspectionIdFromUrl: string,
    inspectorOfficerIdFromUrl: string
  ): Observable<ServerResponse> {
    inspectionIdFromUrl = encodeURIComponent(inspectionIdFromUrl.trim());
    inspectorOfficerIdFromUrl = encodeURIComponent(
      inspectorOfficerIdFromUrl.trim()
    );
    console.log(inspectionIdFromUrl);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.inspection.getCompleteInspectionByInspectionIdForVerifiedOtp}?inspectionId=${inspectionIdFromUrl}&inspectorOfficerId=${inspectorOfficerIdFromUrl}`,
        {}
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setCompleteInspection(response.data);
          }
        })
      );
  }
  //#endregion

  //#region getCompleteInspectionPreviewByInspectionId (query param)
  getCompleteInspectionPreviewByInspectionId(
    inspectionId: number,
    inspectorOfficerId: number
  ): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.inspection.getCompleteInspectionByInspectionId}`,
        {
          params: this.httpService.constructParam({
            inspectionId,
            inspectorOfficerId,
          }),
        }
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setCompleteInspection(response.data);
          }
        })
      );
  }
  //#endregion

  //#region Save Complete Inspection
  saveCompleteInspection(
    completeInspection: CompleteInspection
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.inspection.saveCompleteInspection}`,
        completeInspection
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.inspectionService.addCompleteInspection(res.data);
          }
        })
      );
  }
  //#endregion

  //#region Save inspector officer approval
  saveInspectorOfficerApprovalOnPreview(
    completeInspection: CompleteInspection
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.inspection.saveInspectorOfficerApprovalOnPreview}`,
        completeInspection
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.inspectionService.addCompleteInspection(res.data);
          }
        })
      );
  }
  //#endregion

  //#region getCompleteInspectionByInspectionType
  getCompleteInspectionByInspectionType(
    type: string
  ): Observable<ServerResponse> {
    const params = new HttpParams().append('type', `${type}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.inspection.getCompleteInspectionByInspectionType}`,
        {
          params,
        }
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setCompleteInspection(response.data);
          }
        })
      );
  }
  //#endregion

  //#region Submit complete Inspection
  submitCompleteInspection(inspectionId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.submitCompleteInspection}?inspectionId=${inspectionId}`,
      inspectionId
    );
  }
  //#endregion

  deleteInspectionById(id?: number | null): Observable<ServerResponse> {
    const url = applicationUrls.inspection.deleteInspection + id;

    return this.httpClient.delete<ServerResponse>(url);
  }

  //#region occupation delete
  deleteOccupation(id?: number | null): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.deleteOccupation}`,
      { inspectionResultId: id }
    );
    // .pipe(
    //   map((serverResponse: ServerResponse) => {
    //     return serverResponse.data;
    //   }),
    //   tap((updatedRole) => {
    //     this.authorizationService.deleteDesignation(id);
    //   })
    // );
  }
  //#endregion occupation delete

  //#region STP-SAC Inspection Report

  getRecommendationByType(type: string): Observable<ServerResponse> {
    let params = new HttpParams();
    params = params.append('type', type);

    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.recommendation.getRecommendationByType}`,
        { params: params }
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setRecommandation(response.data);
          }
        })
      );
  }

  saveStpAndSacRequestInspectionReport(
    inspectionReport: any,
    fileList: File[]
  ): Observable<ServerResponse> {
    let formData = new FormData();
    fileList.forEach((item: File, index: number) => {
      formData.append('file', item);
    });
    formData.append(
      'inspectionRegistrationRecords',
      JSON.stringify(inspectionReport.inspectionFormArray)
    );
    formData.append(
      'inspectionRegistration',
      JSON.stringify(inspectionReport.recommendation)
    );
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.courseAccInspectionReport.saveStpSacRegistrationInspectionReport}`,
      formData
    );
  }

  saveScrutinyForStpStacInspectionReport(
    stpSacScrutinyResultDto: any
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.scrutiny.saveScrutinyForStpSacInspecctionReport}`,
      stpSacScrutinyResultDto
    );
  }
  //#endregion STP-SAC Inspection Report
  getScrutinyResultByInspectionId(
    inspectionId: number
  ): Observable<ServerResponse> {
    const params = new HttpParams().append('inspectionId', `${inspectionId}`);
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.scrutiny.getByInspectionId}`,
      {
        params,
      }
    );
  }

  //#region payment course accreditation
  savePaymentInfoForCourseAccriditation(
    formData: FormData
  ): Observable<ServerResponse> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.payment.paymentForCourseAccreditation}`,
        formData
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  getPaymentInfoForCourseAccByInspectionId(
    id: number
  ): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.payment.getPaymentInfoForCourseAccByInspectionId}/${id}`
    );
  }

  //#endregion payment course accreditation

  saveScrutinyResultByInspectionId(
    inspectionId: number
  ): Observable<ServerResponse> {
    const url =
      applicationUrls.inspection.scrutiny.saveByInspectionId +
      '?inspectionId=' +
      inspectionId;
    return this.httpClient.post<ServerResponse>(url, {});
  }

  rollBackScrutinyResultByInspectionId(
    inspectionId: number, upDatedRemarks: string
  ): Observable<ServerResponse> {
    const url =
      applicationUrls.inspection.scrutiny.rollBackByInspectionId +
      '?inspectionId=' +
      inspectionId + '&upDatedRemarks='+upDatedRemarks;
    return this.httpClient.put<ServerResponse>(url, {});
  }

  getInspectionRecommendationByType(type: string): Observable<ServerResponse> {
    const params = new HttpParams().append('type', `${type}`);
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.inspectionRecommendationType.getAllInspectionRecommendationByType}`,
      {
        params,
      }
    );
  }

  getScrutinyDecisionByInspectionId(
    inspectionId: number
  ): Observable<ServerResponse> {
    const params = new HttpParams().append('inspectionId', `${inspectionId}`);
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.decision.getDecisionByInspectionId}`,
      {
        params,
      }
    );
  }

  saveScrutinyDecision(
    saveInspectionDecisionDtoList: SaveInspectionDecisionDto[]
  ): Observable<ServerResponse> {
    const url = applicationUrls.inspection.decision.save;
    return this.httpClient.post<ServerResponse>(
      url,
      saveInspectionDecisionDtoList
    );
  }

  submitScrutinyDecisionByInspectionId(
    inspectionId: number
  ): Observable<ServerResponse> {
    const url =
      applicationUrls.inspection.decision.submitByInspectionId +
      '?inspectionId=' +
      inspectionId;
    return this.httpClient.post<ServerResponse>(url, {});
  }

  rollBackScrutinyDecisionByInspectionId(
    inspectionId: number, upDatedRemarks :string
  ): Observable<ServerResponse> {
    const url =
      applicationUrls.inspection.decision.rollBackDecisionByInspectionId +
      '?inspectionId=' +
      inspectionId +'&upDatedRemarks='+upDatedRemarks;
    return this.httpClient.put<ServerResponse>(url, {});
  }

  //#region getStpSacInspectionPreviewByInspectionId
  getStpSacInspectionPreviewByInspectionId(
    inspectionId: number
  ): Observable<ServerResponse> {
    console.log(inspectionId);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.inspection.getStpSacInspectionPreviewByInspectionId}?inspectionId=${inspectionId}`
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setStpSacInspection(response.data);
          }
        })
      );
  }
  //#endregion

  //#region getStpSacInspectionPreviewByEncryptedInspectionId
  getStpSacInspectionPreviewByEncryptedInspectionId(
    inspectionIdFromUrl: string
  ): Observable<ServerResponse> {
    inspectionIdFromUrl = encodeURIComponent(inspectionIdFromUrl.trim());
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.inspection.getStpSacInspectionPreviewByEncryptedInspectionId}?inspectionId=${inspectionIdFromUrl}`
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.inspectionService.setStpSacInspection(response.data);
          }
        })
      );
  }
  //#endregion

  //#region check existing contact number to add representative
  checkContactNumberExist(phone: string): Observable<ServerResponse> {
    const params = new HttpParams().append('phone', `${phone}`);
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.inspection.checkPhoneExist}`, {
        params,
      })
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region  otp verification
  sendOtp(inspectorOfficerId: string): Observable<ServerResponse> {
    inspectorOfficerId = encodeURIComponent(inspectorOfficerId.trim());
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.otp.sendOTP}?inspectorOfficerId=${inspectorOfficerId}`,
        {}
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region verify otp
  verifyOtp(
    otp: string,
    inspectionIdFromUrl: string,
    inspectorOfficerIdFromUrl: string
  ): Observable<ServerResponse> {
    inspectionIdFromUrl = encodeURIComponent(inspectionIdFromUrl.trim());
    inspectorOfficerIdFromUrl = encodeURIComponent(
      inspectorOfficerIdFromUrl.trim()
    );
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.otp.verifyOTP}?otp=${otp}&inspectionId=${inspectionIdFromUrl}&inspectorOfficerId=${inspectorOfficerIdFromUrl}`,
        {}
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region UserInfo
  getUserByUserId(id: number) {
    return this.httpClient.get<ServerResponse>(`${applicationUrls.user.readUserById}${id}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion

  //#region Rollback to Stp
  rollbackInspectionToStp(data:any){
    return this.httpClient.post<ServerResponse>(`${applicationUrls.inspection.rollbackInspectionToStp}`,data)
  }
  //endregion

  getAllCourseAccreditationInfo(CASearchingDto: CourseAccreditationSearchingDto): Observable<ServerResponse> {
    const pageableRequestBodyDTO = {
      districtId: (CASearchingDto?.districtId ? CASearchingDto?.districtId : null),
      applicationTypeId: (CASearchingDto?.applicationTypeId ? CASearchingDto?.applicationTypeId : null),
      applicationId: (CASearchingDto?.applicationId ? CASearchingDto?.applicationId : null),
      toDate: (CASearchingDto?.toDate ? CASearchingDto?.toDate : null),
      stpName: (CASearchingDto?.stpName ? CASearchingDto?.stpName : null),
      fromDate: (CASearchingDto?.fromDate ? CASearchingDto?.fromDate : null)
    };

    return this.httpClient.post<ServerResponse>(`${applicationUrls.inspection.report.getAllCourseAccreditationInfo}`,
    pageableRequestBodyDTO
    );
  }

  deleteStpInspectionAttachmentReport(data : fileDeleteRequest) : Observable<ServerResponse>{
    return this.httpClient.post<ServerResponse>(`${applicationUrls.courseAccInspectionReport.deleteStpRegInspectionReportAttachment}`, data);
   }


  //#region Submit complete Inspection
  getInspectionReportForDownload(
    inspectionId: number
  ): Observable<ServerResponse> {
    const params = new HttpParams().append('inspectionId', `${inspectionId}`);
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getInspectionReportsRecommendationDetailsForDownload}`,
      {
        params,
      }
    );
  }
  //#endregion

}
