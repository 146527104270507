import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { CadTool } from '../../../models/cad-tool.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import * as XLSX from 'xlsx';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-cad-tool',
  templateUrl: './add-cad-tool.component.html',
  styleUrls: ['./add-cad-tool.component.scss']
})
export class AddCadToolComponent implements OnInit {
  
  @Input() InputData: any;
  isEditMode: boolean = false;
  singleCad: CadTool;
  onStartSingleCadEdit$: Subscription;
  onEditingSingleCadId!: number | null;
  cadForm: FormGroup;
  editedItem!: any;
  editedItemId!: number;
  cadList: CadTool[] = [];
  tamMainId: number;
  onCadToolSubscription$: Subscription;


  // cadForm: FormGroup;
  // tamMainId: string | null;
  //#region Excel File Upload And Read Excel Data

  excelData: any[] = [];
  userInfo: any;
  userFile: any = File;
  allowedExtensions = '.xlsx,.xls';
  maxFormatFileSize = 3;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  total: number;
  page = 1;
  size = 10;

  //#endregion Excel File Upload And Read Excel Data
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private utilityService: UtilityService

  ) {
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
  this.onEditingSingleCadId = this.cadService.getEditingSingleCadToolId();
  this.onStartSingleCadEdit$ =
    this.cadService.onStartCadToolEditing.subscribe(
      (res: number | null) => {
        this.onEditingSingleCadId = res;
      }
    );
  this.cadList = this.cadService.getCadToolList(this.tamMainId);
  if (this.cadList.length === 0) {
    this.cadStorageService.readCadToolListByTamId(this.tamMainId, this.page, this.size).subscribe();
  }
  this.onCadToolSubscription$ =
    this.cadService.onRefreshCadToolList.subscribe((res) => {
      this.cadList = res;
    }); 
  }

  ngOnInit(): void {

    console.log(this.InputData);
    this.createCadForm();
    this.editedItemId = this.InputData;
    this.isEditMode = true;
    this.isEditMode = this.editedItemId !== null;
    this.editedItem = this.cadService.getCadToolById(this.editedItemId);
    
    this.cadForm.patchValue(this.editedItem);
    // this.createCadForm();
    // this.tamMainId = this.route.snapshot.queryParamMap.get('tamMainId');

  }

  createCadForm() {
    this.cadForm = this.fb.group({
      toolsName: [],
      specification: [],
      unit: [],
      minQuantityReq: [0, [Validators.pattern("^[0-9]*$")]],
      remark: []
    });
  }

  resetForm(): void {
    this.cadForm.reset();
  }

  submitForm(): void {
    for (const key of Object.keys(this.cadForm.controls)) {
      this.cadForm.controls[key].markAsDirty();
      this.cadForm.controls[key].updateValueAndValidity();
    }
    this.singleCad = this.cadForm.value;
    this.singleCad.tamMainId = Number(this.tamMainId);
    const isFalsy = this.cadService.getNullableFormCheck(this.singleCad, this.tamMainId);
    if (isFalsy) {
      this.notification.error('Error', 'All input blank form submit is not allowed');
      return;
    }
    if (this.editedItemId != null) {
      this.cadStorageService.updateCadTool(this.singleCad, this.editedItemId).subscribe({
        next: (response) =>
          this.notification.success('Success!', 'Tools update successfully'),
        error: (error) => this.notification.error('Failed', error),
      });
    }else {
      const cadData: CadTool = this.cadForm.value;
      const isFalsy = this.cadService.getNullableFormCheck(cadData, this.tamMainId);
      if (isFalsy) {
        this.notification.error('Error', 'All input blank form submit is not allowed');
        return;
      }

    if(!this.tamMainId) {
      this.notification.error('Error', 'Training and Assessment Material Id not found');
      return;
    }

    cadData.tamMainId = Number(this.tamMainId);
    cadData.minQuantityReq = Number(cadData.minQuantityReq);

    this.cadStorageService
      .createCadTool(cadData)
      .subscribe({
        next: (response) => {
          this.notification.success('Success','Added Tools data');
        },
        error: (error) =>
          this.notification.error(
            'Failed',
            'Please! provide valid information'
          ),
      });
      this.cadForm.reset();
    }
    }




  //#region Excel File Upload And Read Excel Data

  onSelectFile(event: any): void {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      this.myInputVariable.nativeElement.value = '';
      return;
    }
    const file = event.target.files[0];
    this.userFile = file;

    /* wire up file reader */
    const target: DataTransfer = event.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data); // Data will be logged in array format containing objects
      this.excelData = data;
      return data;
    };
  }

  submitExcelData(): void {
    const cadToolList: CadTool[] = [];

    if (this.myInputVariable.nativeElement.value == 0) {
      this.notification.error('Failed!', 'Please upload cad tool excel file');
      return;
    } else {
      for (const item of this.excelData) {
        const cadTool = new CadTool();

        cadTool.tamMainId = Number(this.tamMainId) ;
        cadTool.toolsName = item.toolsName;
        cadTool.specification = item.specification;
        cadTool.unit = item.unit;
        cadTool.minQuantityReq = item.minQuantityReq;
        cadTool.remark = item.remark;

        cadToolList.push(cadTool);

        const existItem = cadToolList.filter(
          (item) => item.toolsName == cadTool.toolsName
        );


        for (let item of cadToolList) {
          let msg = item.toolsName;
          if (isNaN(item.minQuantityReq)) {
            this.notification.error(
              'Failed',
              `Invalid minimum quantity requierment for tools name:  ${msg}, Please correct and reupload!`
            );
            this.myInputVariable.nativeElement.value = '';
            return;
          }
        }

        if (existItem.length > 1) {
          this.notification.error(
            'Failed',
            'Duplicate cad tool name found in Excel, Please check!'
          );
          this.myInputVariable.nativeElement.value = '';
          return;
        }
      }
    }

    //#region Excel duplication value check

    // let existItem = this.excelData.filter(
    //   (item) => item.ToolName == stpTrainer.ToolName
    // );
    // if (existItem.length > 1) {
    //   this.notification.error(
    //     'Failed',
    //     'Duplicate Data found in Excel, Please check!'
    //   );
    //   return;
    // }

    //#endregion Excel duplication value check
    // if(this.cadStorageService.isStpMainAlreadyCreated){
      this.cadStorageService
        .saveExcelCadToolData(cadToolList)
        .subscribe({
          next: (response) => {
            this.notification.success(
              'Success!',
              'Cad tool created successfully'
            );
          },
          error: (error) =>
            this.notification.error(
              'Failed',
              'Please! provide valid information'
            ),
        });

    // }
    // else {
    //   this.notification.error(
    //     'Failed',
    //     'Please fill up basic profile first.'
    //   );
    // }

    this.myInputVariable.nativeElement.value = '';
    return;
  }




  //#endregion Excel File Upload And Read Excel Data







}
