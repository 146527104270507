<app-header *ngIf="!hiddingHeaderFooter"></app-header>
<!-- new design -->
<div class="card">
  <div class="container-fluid" *ngIf="contentVisble">
    <div class="row pt-3 mb-3">
      <div class="col-md-12 text-right">
      </div>
    </div>

    <nz-card>
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="unordered-list" nzTheme="outline"></i> STP Search
      </div>
      <form
        nz-form
        [formGroup]="searchingForm"
        class="ant-advanced-search-form"
        style="
          padding: 24px;
          background: #fbfbfb;
          border: 1px solid #d9d9d9;
          border-radius: 6px;
        "
        (ngSubmit)="onSearch()"
      >
      <div class="form-row">
        <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>STP Name</nz-form-label>
              <!-- <nz-form-label>STP Name</nz-form-label> -->
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Stp Name"
                  formControlName="stpName"
                  id="stpName"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>Application ID</nz-form-label>
              <!-- <nz-form-label>STP Application ID</nz-form-label> -->
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Application ID"
                  formControlName="stpApplicationId"
                  id="stpApplicationId"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <!-- <div nz-col [nzSpan]="12" [hidden]="hiddingHeaderFooter">
            <nz-form-item>
              <nz-form-label>STP Registration Number</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Registration Number"
                  formControlName="registrationNo"
                  id="registrationNo"
                />
              </nz-form-control>
            </nz-form-item>
          </div> -->
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>Ownership</nz-form-label>
              <!-- <nz-form-label>Ownership Type</nz-form-label> -->
              <nz-form-control>
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  id="stpOwnerShipStatusId"
                  formControlName="stpOwnerShipStatusId"
                  nzPlaceHolder="Select Ownership Type"
                >
                  <nz-option
                    class="option"
                    *ngFor="let data of stpOwnershipTypeList"
                    [nzValue]="data.id"
                    [nzLabel]="data.typeName"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>District</nz-form-label>
              <!-- <nz-form-label>District List</nz-form-label> -->
              <nz-form-control>
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  id="sysDistrictId"
                  formControlName="sysDistrictId"
                  nzPlaceHolder="Select District"
                >
                  <nz-option
                    class="option"
                    *ngFor="let data of districtList"
                    [nzValue]="data.id"
                    [nzLabel]="data.districtName"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>Occupation</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Occupation Name"
                  formControlName="occupationName"
                  id="occupationName"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="form-group col-md-6" [hidden]="!hiddingHeaderFooter">
          <!-- <div nz-col [nzSpan]="12" [hidden]="!hiddingHeaderFooter"> -->
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>Submission</nz-form-label>
              <!-- <nz-form-label>Application Submission Status</nz-form-label> -->
              <nz-form-control>
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  id="submissionStatusId"
                  formControlName="submissionStatusId"
                  nzPlaceHolder="Select Submission Status"
                >
                  <nz-option
                    class="option"
                    *ngFor="let data of applicationSubmissionStatusList"
                    [nzValue]="data.id"
                    [nzLabel]="data.name"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6" [hidden]="!hiddingHeaderFooter">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>Review</nz-form-label>
              <!-- <nz-form-label>Application Review Status</nz-form-label> -->
              <nz-form-control>
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  [(ngModel)]="selectedReviewStatus"
                  [ngModelOptions]="{standalone: true}"
                  id="reviewStatusId"
                  nzPlaceHolder="Select Review Status"
                  (ngModelChange)="onReviewStatusChange($event)"
                >
                  <nz-option
                    class="option"
                    *ngFor="let data of applicationReviewList"
                    [nzValue]="data.code"
                    [nzLabel]="data.name"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="form-group col-md-6" [hidden]="!hiddingHeaderFooter">
            <nz-form-item>
              <nz-form-label [nzSm]="8" nzFor="name" nzRequired>Approval</nz-form-label>
              <!-- <nz-form-label>Approval Status</nz-form-label> -->
              <nz-form-control>
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  [(ngModel)]="selectedApprovalStatus"
                  [ngModelOptions]="{standalone: true}"
                  id="reviewStatusId"
                  nzPlaceHolder="Select Approval Status"
                  (ngModelChange)="onApprovalStatusChange($event)"
                >
                  <nz-option
                    class="option"
                    *ngFor="let data of approvalList"
                    [nzValue]="data.code"
                    [nzLabel]="data.name"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="24" class="search-area text-right">
            <button class="mr-2" nz-button [nzType]="'primary'">Search</button>
            <button class="mr-2 btn-success" nz-button (click)="onRefresh()">
              Refresh Data
            </button>
            <button
              class="btn-dark"
              nz-button
              (click)="onDownloadReport()"
              [hidden]="!hiddingHeaderFooter"
            >
              Download Report
            </button>
          </div>
        </div>
      </form>
    </nz-card>
    <nz-card>
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="unordered-list" nzTheme="outline"></i> List of
        Registered STPs
      </div>
      <div nz-col [nzSpan]="24">
        <nz-table
          #basicTable
          [nzData]="stpApplicationDto"
          nzTableLayout="fixed"
          nzShowSizeChanger
          nzBordered
          nzSize="middle"
          nzAlign="middle"
          [nzFrontPagination]="false"
          [nzTotal]="total"
          [(nzPageSize)]="size"
          [nzShowTotal]="totalRowRangeTemplate"
          [(nzPageIndex)]="page"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzScroll]="{ x: '1150px' }"
        >
          <ng-template
            #totalRowRangeTemplate
            let-range="range"
            let-total
            style="text-align: left"
          >
            <div style="text-align: left">
              Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
            </div>
          </ng-template>
          <thead>
            <tr>
              <th style="text-align: center" rowspan="1">Sl.</th>
              <th rowspan="2" nzLeft nzColumnKey="stp_name" [nzSortFn]="true">
                Name of STP
              </th>
              <th *ngIf="hiddingHeaderFooter"  rowspan="2" nzColumnKey="application_id" [nzSortFn]="true">
                Application ID
              </th>
              <th *ngIf="hiddingHeaderFooter"  rowspan="2" nzColumnKey="created_at" [nzSortFn]="true">
                Date of Application
              </th>
              <th *ngIf="!hiddingHeaderFooter"  rowspan="2" nzColumnKey="registration_no" [nzSortFn]="true">
                Registration Number
              </th>
              <th *ngIf="!hiddingHeaderFooter"  rowspan="2" nzColumnKey="approval_date" [nzSortFn]="true">
                Date of Approval
              </th>
              <th rowspan="2">Type of Ownership</th>
              <th rowspan="2">Ministry</th>
              <th rowspan="2">Agency</th>
              <th rowspan="2">District</th>
              <th *ngIf="hiddingHeaderFooter" rowspan="2">Application Status</th>
              <th *ngIf="hiddingHeaderFooter" rowspan="2">Approval Status</th>
              <th *ngIf="hiddingHeaderFooter" rowspan="2">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data; let i = index">
              <td style="text-align: center">{{ (page - 1) * size + 1 + i }}</td>
              <td nzLeft>
                <span *ngIf="!this.hiddingHeaderFooter">
                  <a [routerLink]="[]"
                  (click)="onStpMainClick(data.stpMainId)"
                  >{{ data.stpName }}</a>
                </span>
                <span *ngIf="this.hiddingHeaderFooter">
                  <a [routerLink]="['/home/stp-registration/stp-application-view/' + data.stpMainId]"
                  target="_blank"
                  routerLinkActive="router-link-active"
                  >{{ data.stpName }}</a>
                </span>
              </td>
              <td *ngIf="hiddingHeaderFooter" >
                <a
                  [routerLink]="['/home/stp-registration/stp-application-view/', data.stpMainId]"
                  target="_blank"
                  routerLinkActive="router-link-active"
                  >{{ data.applicationId }}</a>
              </td>
              <td *ngIf="hiddingHeaderFooter" >{{ data.applicationDate | date: "dd-MM-yyyy" }}</td>

              <td *ngIf="!hiddingHeaderFooter" >{{ data.registrationId }}</td>
              <td *ngIf="!hiddingHeaderFooter" >{{ data.approvalDate | date: "dd-MM-yyyy" }}</td>
              <td>{{ data.ownerType }}</td>
              <td>{{ data.ministryName }}</td>
              <td>{{ data.agencyName }}</td>
              <td>{{ data.districtName }}</td>
              <td *ngIf="hiddingHeaderFooter">
                <span
                class="badge {{
                  data.applicationStatus
                    ? data.applicationStatus.toLowerCase() == 'completed'
                      ? 'badge-success'
                      : 'badge-primary'
                    : 'badge-primary'
                }}"
                style="font-size: 10px"
                >{{
                  data.applicationStatus
                    ? data.applicationStatus
                    : "In Progress"
                }}
              </span>
                <span
                  *ngIf="data.isTempRejected == false"
                  class="badge badge-success"
                  style="font-size: 10px"
                >
                  Accepted
                </span>
                <span
                  *ngIf="data.isTempRejected == true"
                  class="badge badge-danger"
                  style="font-size: 10px"
                >
                Correction Requested
                </span>
              </td>
              <td *ngIf="hiddingHeaderFooter">
                <span
                  class="badge {{
                    data.approval == true ? 'badge-success' : 'badge-warning'
                  }}"
                  style="font-size: 10px"
                  >{{ data.approval == true ? "Approved" : "Not Approved" }}
                </span>
              </td>
              <td *ngIf="hiddingHeaderFooter">
                <button
                  [appRequiredPermission]="
                    applicationPermissions.stpRegistration.stpApplication
                      .updateSTPNamePermission
                  "
                  class="mr-1"
                  nz-button
                  type="submit"
                  nzType="primary"
                  title="Edit"
                  [routerLink]="[
                    '/home/stp-registration/stp-name-update/',
                    data.stpMainId
                  ]"
                  [nzSize]="'small'"
                >
                  <i nz-icon nzType="edit" nzTheme="outline"></i>
                </button>
                <button

                  class="mr-1"
                  nz-button
                  type="submit"
                  nzType="primary"
                  title="Download Certificate"
                  [disabled]="!data.approval"
                  (click)="downloadCertificate(data.stpMainId)"
                  [nzSize]="'small'"
                >
                  <i nz-icon nzType="container" nzTheme="outline"></i>
                </button>
                <button
                  [appRequiredPermission]="
                    applicationPermissions.stpRegistration.stpApplication
                      .updateSTPNamePermission
                  "
                  class="mr-1 mt-1"
                  nz-button
                  nzType="primary"
                  type="submit"
                  title="RollBack this STP User"
                  [disabled]="
                    !(
                      data.applicationStatus?.toLowerCase() == 'completed' &&
                      !data.approval == true
                    ) || rollbackButtonDisabled
                  "
                  nz-popconfirm
                  nzPopconfirmTitle="Are you sure to rollback this STP?"
                  nzPopconfirmPlacement="topCenter"
                  (nzOnConfirm)="onRollBack(data)"
                  [nzSize]="'small'"
                >
                  <i nz-icon nzType="highlight" nzTheme="outline"></i>
                </button>
                <!-- <button
                  [appRequiredPermission]="
                    applicationPermissions.stpRegistration.stpApplication
                      .mailCertificatePermission
                  "
                  class="mr-1 mt-1"
                  nz-button
                  nzType="primary"
                  type="submit"
                  title="Send this certificate to STP User"
                  [disabled]="!data.approval"
                  nz-popconfirm
                  nzPopconfirmTitle="Are you sure about sending this certificate to STP User?"
                  nzPopconfirmPlacement="topCenter"
                  (nzOnConfirm)="mailCertificate(data.stpMainId)"
                  [nzSize]="'small'"
                >
                  <i nz-icon nzType="mail" nzTheme="outline"></i>
                </button> -->
                <button
                  [appRequiredPermission]="
                    applicationPermissions.stpRegistration.stpApplication
                      .approveSTPPermission
                  "
                  class="mr-1 mt-1"
                  nz-button
                  nzType="primary"
                  type="submit"
                  title="Approve this STP User"
                  [disabled]="data.approval"
                  (click)="setApproved(data)"
                  [nzSize]="'small'"
                >
                  <i nz-icon nzType="check" nzTheme="outline"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-card>
  </div>
</div>
<ng-container *ngIf="!userInfo.id">
  <app-captcha (verifyStatus)="captchVerificationStatus($event)"></app-captcha>
</ng-container>

<app-footer *ngIf="!hiddingHeaderFooter"></app-footer>

<nz-modal [nzWidth]="700" [(nzVisible)]="isStpBasicInfoModalVisible" nzTitle="STP Basic Information"
  (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <div>
      <nz-descriptions [nzColumn]="2">
        <nz-descriptions-item nzSpan="2" nzTitle="Name of STP">{{ stpBasicData.stpName }}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="2" nzTitle="Name of STP in Bangla">{{ stpBasicData.stpNameBangla }}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="2" nzTitle="Name of Ministry/ Division">{{ stpBasicData.ministryName }}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="2" nzTitle="Name of Agency">{{ stpBasicData.agencyName }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Type of STP">{{ stpBasicData.stpType }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Type of Ownership">{{ stpBasicData.ownershipType }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Year of Establishment">{{ stpBasicData.establishYear | date: 'yyyy' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Location of the STP">{{ stpBasicData.locationName }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Country">{{ stpBasicData.country }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Division/State">{{ stpBasicData.division }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="District/City">{{ stpBasicData.district }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Upazilla/Sub-district">{{ stpBasicData.upazilla }}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="2" nzTitle="House, Road, Village Area">{{ stpBasicData.address }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Contact number">{{ stpBasicData.orgContact }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Email address">{{ stpBasicData.orgEmail }}</nz-descriptions-item>
      </nz-descriptions>
    </div>
  </ng-container>
</nz-modal>


<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzClosable]="true"
  [nzOkText]="modalOkText"
  [nzWidth]="700"
  nzTitle="Please select an approval date"
  (nzOnOk)="submitApprovalDate()"
>
  <ng-container *nzModalContent>

    <form nz-form [formGroup]="approvalForm" (onSubmit)="submitApprovalDate()">
      <nz-date-picker
                    nzFormat="dd-MM-yyyy"
                    [nzDisabledDate]="disabledDate"
                    placeholder="Approval Start From"
                    formControlName="approvalDateFromAdmin"
                  ></nz-date-picker>
    </form>

  </ng-container>
</nz-modal>
