import { Component, OnInit } from '@angular/core';
import { MraStorageService } from 'src/app/modules/mra/services/mra-storage.service';
import { MRA } from 'src/app/modules/mra/components/model/mra.model';
import { Attachments } from '../model/attachment.model';
import { ActivatedRoute } from '@angular/router';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { environment } from 'src/environments/environment';
import { Attachment } from '../../models/DTO/public-view-dto';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-mra-veiw-details-public',
  templateUrl: './mra-veiw-details-public.component.html',
  styleUrls: ['./mra-veiw-details-public.component.scss'],
})
export class MraVeiwDetailsPublicComponent implements OnInit {

  attachmentList: Attachments[] = [];
  Country: any;
  agreementType: any;
  documentUrl: any;
  hasPermission: boolean;
  attachemntFile: any;
  applicationPermissions = applicationPermissions;
  mraId: any;
  mraList: MRA[] = [];
  mraDetailsInfo: any; // To store general MRA details
  attachmentInfo: Attachment[] = []; // To store attachment details

  imageURL: SafeUrl;
  pdfUrl: any;
  allowedFileExtensions = '.png,.jpg,.docx,.doc,.xlsx,.pdf';
  imageTypeArray: string[] = ['jpg', 'png', 'jpeg'];
  isVisibleForAttachment = false;
  isImageLoading: boolean = false;

  constructor(
    private mraStorageService: MraStorageService,
    private activatedRoute: ActivatedRoute,
    private notification: NzNotificationService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    const mraParam = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.mraId = Number(mraParam);

    if(this.mraId){
      this.getDetailsInfoById();
    }
  }

  getDetailsInfoById() {
    this.mraStorageService.getMraById(this.mraId).subscribe({
      next: (res: MRA) => {
        if (res) {
          this.mraDetailsInfo = { ...res }; // Assign the response to mraDetailsInfo
          this.getAttachmentInfoByMraId();
        }
      },
      error: (error) => {
        console.error('Error fetching MRA details by ID:', error);
      }
    });
  }

  getAttachmentInfoByMraId() {
    this.mraStorageService.getPublicAttachmentByMraId(this.mraId)
      .subscribe((res: ServerResponse) => {
        if (res?.data) {
          this.attachmentInfo = res?.data;
          // this.mraDetailsInfo = this.attachmentInfo[0]?.mra;
        } else {
          this.attachmentInfo = []; // If no attachments, assign an empty array
        }
      });
  }

  handleOkFile(): void {
    this.isVisibleForAttachment = false;
  }

  handleCancelFile(): void {
    this.isVisibleForAttachment = false;
  }

  async onDownloadClick(fileName: any, fileUrl: any) {
    try {
      // Fetch the file as a blob
      const response = await fetch(environment.fileServiceApiUrl + fileUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      let fileType = this.getFileTypeFromPreviousAttachment(fileUrl);

      const link = document.createElement('a');
      const blobUrl = URL.createObjectURL(blob);

      if (fileType == 'image') {
        link.href = blobUrl;
        link.download = fileName;
      } else if (fileType == 'pdf') {
        link.href = blobUrl;
        link.download = fileName;
      } else{
        link.setAttribute('target', '_blank');
        link.setAttribute('href', blobUrl);
        link.setAttribute('download', fileName +'.' + fileType);
      }

      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the blob URL and removing the link
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download the file. Please try again.');
    }
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {
    let contentType = '';
    let basename = fileUrl.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;

    let fileType = basename?.slice(pos + 1) ?? '';
    if (this.imageTypeArray.includes(fileType)) {
      contentType = 'image';
    } else if (fileType == 'pdf') {
      contentType = 'pdf';
    } else if (fileType == 'doc') {
      contentType = 'doc';
    } else if (fileType == 'docx') {
      contentType = 'docx';
    } else if (fileType == 'xlsx') {
      contentType = 'xlsx';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  async setBase64FileFromUrl(fileUrl: string){
    const data = await fetch(environment.fileServiceApiUrl + fileUrl);
    const blob = await data.blob();
    let fileUrlBase64 = URL.createObjectURL(blob);
    let fileType = this.getFileTypeFromPreviousAttachment(fileUrl);
    this.previewImage(fileType, fileUrlBase64);
  }

  previewImage(fileType: any, fileUrl: any) {
    this.imageURL = '';
    this.pdfUrl = '';
    if (fileType == 'image') {
      this.isVisibleForAttachment = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else if (fileType == 'pdf') {
      this.isVisibleForAttachment = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', fileUrl);
      link.setAttribute('download', 'uploaded_document.' + fileType);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  async downloadZipAttachment(attachmentList: any) {
    const zip = new JSZip();
    const name = 'Attachments.zip';

    for (let i = 0; i < attachmentList.length; i++) {
      let basename = attachmentList[i]?.file.split(/[\\/]/).pop();
      let pos = basename?.lastIndexOf('.') ?? 0;
      let fileType = basename?.slice(pos + 1) ?? '';

      const data = await fetch(environment.fileServiceApiUrl + attachmentList[i]?.file);

      if (data?.status == 200) {
        const blob = await data.blob();
        zip.file(attachmentList[i]?.attachmentTitle + '.' + fileType, blob);
      }
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });
  }

  public export(data: any) {
    let pdfData: any | null = window.document.getElementById(data);
    if (pdfData) {
      this.notification.info('Info!', 'Downloading MRA information.. Please Wait!');
      pdfData.style.display = 'block';
      this.generatePDF(pdfData);
      pdfData.style.display = 'none';
    } else {
      this.notification.error('error!', 'Failed to download MRA information!');
    }
  }

  generatePDF(htmlContent: any) {
    html2canvas(htmlContent, { scale: 3 }).then((canvas) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm', 'a4');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          canvas,
          'PNG',
          0,
          position,
          imgWidth,
          imgHeight,
          '',
          'FAST'
        );
        heightLeft -= pageHeight;
      }
      doc.save('mra_info' + '.pdf');
    });
  }
}
