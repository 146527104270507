import { NAPLoginComponent } from './components/NAP-login/NAP-login.component';
import { StpRegistrationComponent } from './components/stp-registration/stp-registration.component';
import { SharedModule } from './../../shared/modules/shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { NgZorroAntdModule } from './../../ng-zorro-antd.module';
import { NgModule } from '@angular/core';
import { AuthenticationRoutes } from './authentication.routing';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LandingModule } from '../landing/landing.module';
import { UserVerificationComponent } from './components/user-verification/user-verification.component';
import { ApplicantProfileRegistrationComponent } from './components/applicant-profile-registration/applicant-profile-registration.component';
import { RegistrationCategoryComponent } from './components/registration-category/registration-category.component';
import { NAPChangePasswordComponent } from './components/nap-change-password/nap-change-password.component';
import { AssessorRegistrationComponent } from './components/assessor-registration/assessor-registration.component';
import { NapForgotPasswordComponent } from './components/nap-forgot-password/nap-forgot-password.component';
import { NapResetPasswordComponent } from './components/nap-reset-password/nap-reset-password.component';

@NgModule({
  imports: [
    SharedModule,
    AuthenticationRoutes,
    NgZorroAntdModule,
    LandingModule,
  ],
  declarations: [
    LoginComponent,
    StpRegistrationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    UserVerificationComponent,
    ApplicantProfileRegistrationComponent,
    RegistrationCategoryComponent,
    NAPLoginComponent,
    NapForgotPasswordComponent,
    NAPChangePasswordComponent,
    NapResetPasswordComponent,
    AssessorRegistrationComponent
  ],
})
export class AuthenticationModule {}
