export class CadTrainerAndStaff {
  public id?: number;
  public position?: string | null;
  public qualification?: string | null;
  public unit?: string | null;
  public minQuantityReq: number;
  public remark?: string | null;
  public tamMainId?: number | null;
  public tamMain?: any;
  constructor() {}
}
