import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-otp-for-shared-preview-inspection-report',
  templateUrl: './otp-for-shared-preview-inspection-report.component.html',
  styleUrls: ['./otp-for-shared-preview-inspection-report.component.scss']
})
export class OtpForSharedPreviewInspectionReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
