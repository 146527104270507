import { Injectable } from '@angular/core';
import { ResearchAndStudiesExcelReport } from '../models/DTO/research-and-studies-excel-report';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResearchAndStudiesService {

  researchAndStudiesExcel: ResearchAndStudiesExcelReport[] = [];
  onRefreshResearchAndStudiesExcelList = new Subject<ResearchAndStudiesExcelReport[]>();

  constructor() {}

  setResearchAndStudiesInfoForReport(researchAndStudiesExcelReport: ResearchAndStudiesExcelReport[]): void {
    this.researchAndStudiesExcel = researchAndStudiesExcelReport;
    this.onRefreshResearchAndStudiesExcelList.next(this.researchAndStudiesExcel.slice());
  }
}
