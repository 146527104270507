<header>
  <div class="container-fluid d-flex justify-content-between bd-highlight">
    <div class="p-2 bd-highlight">
      <div class="d-flex flex-row">
        <div>
          <img src="assets/images/gov-modify.png" />
        </div>
        <div>
          <a href="#">
            <img src="assets/images/Group 98.png" style="margin-left: 20px" />
          </a>
        </div>
      </div>
    </div>
    <div class="header-content text-wrap hide-at-mobile">
      National Skills Development Authority (NSDA), Chief Adviser's Office,
      Bangladesh
    </div>

    <div>
      <!-- <img
        src="assets/images/1200px-Mujib_100_Logo.svg.png"
        style="width: 90px"
      /> -->
    </div>
  </div>

  <div class="header-content text-wrap show-at-mobile">
    National Skills Development Authority (NSDA), Chief Adviser's Office,
    Bangladesh
  </div>

  <div class="mobile-menu">
    <img
      (click)="toggleMobileMenu()"
      src="assets/images/burger-menu.svg"
      alt="Menu"
    />
  </div>

  <nav class="navbar navbar-expand-md navbar-dark bg-dark menu-bar">
    <div class="container-fluid">
      <ng-container *ngIf="visible">
        <div
          class="navbar-collapse justify-content-between"
          id="navbarCollapse"
        >
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <!-- <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li> -->
          </ul>

          <div class="d-flex">
            <!-- <nz-dropdown-menu #certificatemenu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item>
                <a
                  class="nav-link active"
                  aria-current="page"
                  (click)="tciCertificate()"
                  >List</a
                >
              </li>
            </ul>
          </nz-dropdown-menu> -->

            <!-- <a
          nz-dropdown
          class="mt-2"
          style="color: green"
          [nzDropdownMenu]="assessCertificate"
        >
           Assessment Certificate
          <i nz-icon nzType="down"></i>
        </a> -->

            <nz-dropdown-menu #assessCertificate="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li nz-menu-item>
                  <a
                    class="nav-link active"
                    aria-current="page"
                    (click)="downloadCertificate()"
                    >Download</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
            <a
              nz-dropdown
              class="mt-2"
              style="margin: 10px; color: green"
              [nzDropdownMenu]="menu"
              [hidden]="isProfileHidden"
            >
              Profile
              <i nz-icon nzType="down"></i>
            </a>

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li nz-menu-item>
                  <a
                    class="nav-link active"
                    aria-current="page"
                    (click)="onLogout()"
                    >Logout</a
                  >
                </li>

                <li
                  nz-menu-item
                  *ngIf="
                    userInfo?.roles[0].name === 'User' ||
                    userInfo?.roles[0].name === 'STP_Master'
                  "
                >
                  <a
                    class="nav-link active"
                    aria-current="page"
                    routerLink="/home/stp-registration/dash-board"
                    >Dashboard</a
                  >
                </li>
                <li nz-menu-item *ngIf="userInfo?.roles[0].name === 'Tam_User'">
                  <a
                    class="nav-link active"
                    aria-current="page"
                    routerLink="/home/training-and-assessment-materials/tam-list"
                    >Dashboard</a
                  >
                </li>
                <li
                  nz-menu-item
                  *ngIf="userInfo?.roles[0].name === 'Applicant'"
                >
                  <a
                    class="nav-link active"
                    aria-current="page"
                    routerLink="/home"
                    >Dashboard</a
                  >
                </li>
                <li nz-menu-item *ngIf="userInfo?.roles[0].name === 'Admin'">
                  <a
                    class="nav-link active"
                    aria-current="page"
                    routerLink="/home/stp-registration/stp-applications"
                    >Dashboard</a
                  >
                </li>
                <li nz-menu-item *ngIf="userInfo?.roles[0].name === 'Assessor'">
                  <a
                    class="nav-link active"
                    aria-current="page"
                    routerLink="/home"
                    >Dashboard</a
                  >
                </li>
                <li nz-menu-item *ngIf="userInfo?.roles[0].name === 'ISC'">
                  <a
                    class="nav-link active"
                    aria-current="page"
                    routerLink="/home"
                    >Dashboard</a
                  >
                </li>
                <li nz-menu-item *ngIf="napUserInfo?.userId > 0">
                  <a
                    class="nav-link active"
                    aria-current="page"
                    routerLink="/NAP-home"
                    >Dashboard</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
            <ul class="navbar-nav me-auto mb-md-0">
              <li class="nav-item">
                <a
                  class="nav-link btn btn-outline-custom"
                  (click)="tciCertificate()"
                >
                  National Skills Certificates
                </a>
              </li>

              <li class="nav-item" [hidden]="isLoginHidden">
                <a
                  class="nav-link active btn btn-outline-custom"
                  aria-current="page"
                  routerLink="/login"
                  >Login</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link btn btn-outline-custom"
                  nz-dropdown
                  [nzDropdownMenu]="helping"
                  >Help <i nz-icon nzType="down"></i
                ></a>
                <nz-dropdown-menu #helping="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/Password_Recovery_Or_Update.pdf"
                        >Password Recovery or Update</a
                      >
                    </li>
                    <!-- <li nz-menu-item>
                  <a
                    class="nav-link active"
                    aria-current="page"
                    target="_blank"
                    href="/assets/file/16_07_23_IndividualRPLBatchAssessment.pdf">Assessment Application (RPL Individual Batch)</a>
                </li> -->
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/RegularBatchAssessment.pdf"
                        >Assessment Application (Regular Batch)</a
                      >
                    </li>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/16_07_23_InstructionforSTPregistrationApplicationThroughNSP.pdf"
                        >STP Registration</a
                      >
                    </li>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/17_12_23_CourseAndAssessmentCenterAccreditation.pdf"
                        >Course and Assessment Center Accreditation</a
                      >
                    </li>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/16_07_23_InstructionForTrainingManagementAndGraduateTrackingThroughNSP.pdf"
                        >STP Training Management</a
                      >
                    </li>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/UserManualOfGraduateTracking(V-1.00).pdf"
                        >Graduate Tracking User Manual</a
                      >
                    </li>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/National_Action_Plan_Monitoring_V2.pdf"
                        >National Action Plan Monitoring</a
                      >
                    </li>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/AssessorUserManualModule6(Assessor_Pool).pdf"
                        >Assessor User Manual</a
                      >
                    </li>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/AssessorResultUpload.pdf"
                        >Assessor Result Upload</a
                      >
                    </li>
                    <li nz-menu-item>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        target="_blank"
                        href="/assets/file/nhrdf_financing_user_manual_v1.0.pdf"
                        >NHRDF Financing User Manual</a
                      >
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link btn btn-outline-custom"
                  href="http://103.48.19.22/open.php"
                  target="_blank"
                  >Technical Support</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link btn btn-outline-custom"
                  href="http://www.nsda.gov.bd/"
                  >Visit to NSDA</a
                >
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </nav>
</header>
