export class PermissionDto {
  id: number | null;
  moduleId: number | null;
  name: string | null;
  value: string | null;
  typeId: number | null;
  url: string | null;
  description: string | null;

  constructor() {
    this.id = null;
    this.moduleId = null;
    this.name = null;
    this.value = null;
    this.typeId = null;
    this.url = null;
    this.description = null;
  }
}
