<nz-card nzTitle="Change Password" [nzExtra]="extraTemplate">
  <form nz-form [formGroup]="changePasswordForm" (ngSubmit)="submitForm()" [nzLayout]="'vertical'">

    <nz-form-item>
      <nz-form-label [nzSpan]="6" nzRequired> Old Password</nz-form-label>
      <nz-form-control [nzSpan]="6" nzHasFeedback [nzErrorTip]="oldPasswordErrorTpl">
        <nz-input-group [nzSuffix]="suffixTemplateOldPassword">
          <input nz-input [type]="oldPasswordVisible ? 'text' : 'password'" formControlName="oldPassword" placeholder="Old Password" />
        </nz-input-group>
        <ng-template #oldPasswordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please input old password!
          </ng-container>
        </ng-template>
      </nz-form-control>

      <ng-template #suffixTemplateOldPassword>
        <i nz-icon [nzType]="oldPasswordVisible ? 'eye-invisible' : 'eye'" (click)="oldPasswordVisible = !oldPasswordVisible"></i>
      </ng-template>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="6" nzRequired> New Password</nz-form-label>
      <nz-form-control [nzSpan]="6" nzHasFeedback [nzErrorTip]="newPasswordErrorTpl">
        <nz-input-group [nzSuffix]="suffixTemplateNewPassword">
          <input nz-input [type]="newPasswordVisible ? 'text' : 'password'" formControlName="newPassword" placeholder="New Password"
          (ngModelChange)="validateConfirmPassword()"/>
        </nz-input-group>
        <ng-template #newPasswordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please input new password!
          </ng-container>
          <ng-container *ngIf="control.hasError('pattern')">               
            Please avoid blank space and password should be
            minimum 8 digit and must include one uppercase,
            one lowercase, one number and one special
            character
         </ng-container>
        </ng-template>
      </nz-form-control>

      <ng-template #suffixTemplateNewPassword>
        <i nz-icon [nzType]="newPasswordVisible ? 'eye-invisible' : 'eye'" (click)="newPasswordVisible = !newPasswordVisible"></i>
      </ng-template>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="6" nzRequired>Confirm New Password</nz-form-label>
      <nz-form-control [nzSpan]="6"  nzHasFeedback [nzErrorTip]="confirmPasswordErrorTpl">
        <nz-input-group [nzSuffix]="suffixTemplateConfirmPassword">
          <input nz-input [type]="confirmPasswordVisible ? 'text' : 'password'" formControlName="confirmPassword" placeholder="Confirm Password" />
        </nz-input-group>
        <ng-template #confirmPasswordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please confirm your password!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            Password is inconsistent!
          </ng-container>
          <ng-container *ngIf="control.hasError('pattern')">               
            Please avoid blank space and password should be
            minimum 8 digit and must include one uppercase,
            one lowercase, one number and one special
            character
         </ng-container>
        </ng-template>
      </nz-form-control>

      <ng-template #suffixTemplateConfirmPassword>
        <i nz-icon [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'" (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
      </ng-template>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzOffset]="5" [nzSpan]="14">
        <button class="mr-3" nz-button type="button" nzType="primary" nzDanger (click)="resetForm()">Reset</button>
        <button nz-button type="submit" nzType="primary"  [disabled]="changePasswordForm.invalid"><i nz-icon nzType="save"
            nzTheme="fill"></i>{{isEditMode?'Update':'Submit'}}</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-card>
<ng-template #extraTemplate>
  <a></a>
</ng-template>
