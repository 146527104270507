<app-header></app-header>
<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    <ng-container *ngIf="this.publishedIscDirectorList != null">
      Board Of Directors ( ISC : {{ this.publishedIscDirectorList[0]?.iscMain?.iscNameEng }} )
    </ng-container>
    <ng-container *ngIf="this.publishedIscDirectorList == null">
      Board Of Directors
    </ng-container>
  </div>
  <ng-container style="padding: 24px; background: #fbfbfb; border: 1px solid #d9d9d9; border-radius: 6px;">
    <nz-table #basicTable
    [nzData]="publishedIscDirectorList"
    nzTableLayout="fixed"
    nzBordered
    nzSize="middle"
    nzAlign="middle"
    [nzShowPagination]="false"
    [nzScroll]="{ x: '2250px' }">
      <thead>
        <tr style="overflow: auto">
          <th style="width: 4%; text-align: center;">Sl.</th>
          <th style="width: 12%; text-align: center;" nzLeft>Name</th>
          <th style="width: 7%; text-align: center;">Designation</th>
          <th style="width: 7%; text-align: center;">Mobile No</th>
          <th style="width: 7%; text-align: center;">Email</th>
          <th style="width: 7%; text-align: center;">Division</th>
          <th style="width: 7%; text-align: center;">District</th>
          <th style="width: 7%; text-align: center;">Upazila</th>
          <th style="width: 7%; text-align: center;">City Corporation</th>
          <th style="width: 15%; text-align: center;">Address</th>
          <th style="width: 10%; text-align: center;">Representing Industry</th>
          <th style="width: 10%; text-align: center;">Representing Association</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of basicTable.data; let i = index">
          <tr>
            <td style="text-align: center;">{{ 1 + i }}</td>
            <td style="text-align: center;" nzLeft>{{ data?.nameEng }}</td>
            <td style="text-align: center;">{{ data?.designation }}</td>
            <td style="text-align: center;">{{ data?.mobileNo }}</td>
            <td style="text-align: center;">{{ data?.email }}</td>
            <td style="text-align: center;">{{ data?.iscDivision?.name }}</td>
            <td style="text-align: center;">{{ data?.iscDistrict?.name }}</td>
            <td style="text-align: center;">{{ data?.iscUpazila?.name }}</td>
            <td style="text-align: center;">{{ data?.iscCityCorporation?.name }}</td>
            <td style="text-align: center;">{{ data?.addressDetails }}</td>
            <td style="text-align: center;">{{ data?.representingIndustry }}</td>
            <td style="text-align: center;">{{ data?.representingAssociation }}</td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </ng-container>
</nz-card>
<app-footer></app-footer>
