import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Furniture } from 'src/app/modules/stp-registration/models/furniture.model';
import { StpManagement } from 'src/app/modules/stp-registration/models/stp-management.model';
import { StpOccupationTool } from 'src/app/modules/stp-registration/models/stp-occupation-tool.model';
import { StpTrainer } from 'src/app/modules/stp-registration/models/stp-trainer.model';
import { environment } from 'src/environments/environment';
import { Attachment } from '../../course-accreditation/models/attachment';
import { InspectionAttachment } from '../../course-accreditation/models/inspection-attachment.model';
import { InspectionRegistrationRecord } from '../../course-accreditation/models/inspection-registration-record.model';
import { InspectionRegistrationReport } from '../../course-accreditation/models/inspection-registration-report.model';
import { StpSacInspectionRegistrationReport } from '../../course-accreditation/models/stp-sac-inspection-registration-report.model';
import { InspectionStorageService } from '../../course-accreditation/services/inspection-storage.service';
import { Recommendation } from 'src/app/modules/course-acc-inspection-report/models/recommendation';
import { StpSacScrutinyResultDto } from '../../course-accreditation/DTO/StpSacScrutinyResultDto';
import { Location } from '@angular/common';

@Component({
  selector: 'app-stp-sac-preview-shared',
  templateUrl: './stp-sac-preview-shared.component.html',
  styleUrls: ['./stp-sac-preview-shared.component.scss']
})
export class StpSacPreviewSharedComponent implements OnInit {

  inspectionForm: FormGroup;
  scrutinyForm: FormGroup;
  //#region define form value
  basicProfileForm: any;
  physicalInfraForm: any;
  financialDetailForm: any;
  stpTrainers: StpTrainer[] = [];
  stpOccupationTools: StpOccupationTool[] = [];
  furnitures: Furniture[] = [];
  stpManagements: StpManagement[] = [];
  //#endregion

  //#region for total financial details calculation
  totalIncomes: number;
  totalExpenses: number;
  //#endregion

  inspectionId: number;

  //#region for modal
  modalTabName: string | undefined;
  modalTitle: string;
  isVisible = false;
  //#endregion

  isVisibleForAttachment = false;
  isOkLoading = false;
  isCommitte: boolean;
  imageTypeArray: string[] = ['jpg', 'jpeg', 'png'];
  scrutinyList: { id: number; name: string }[] = [];
  recommendations: Recommendation[] = [];
  stpSacScrutinyResult: StpSacScrutinyResultDto;
  inspectionRegistrationReportId: number | null;
  stpMainId: number | null;
  isScrutinyVisible: boolean = false;
  saveButtonDisabled:boolean = false;

  //#region preview headline
  stpInformations: { name: string; modalIdentity: string }[] = [
    { name: 'Basic Profile', modalIdentity: 'basic' },
    { name: 'Physical Infrastructure Details', modalIdentity: 'Physical' },
    { name: 'Occupation Specific Trainer', modalIdentity: 'occupation' },
    { name: 'Management and Supporting Staff', modalIdentity: 'management' },
    { name: 'Financial Details', modalIdentity: 'financial' },
    {
      name: 'Tools/Equipment/Training Learning Materials',
      modalIdentity: 'tools',
    },
    { name: 'Furniture', modalIdentity: 'furniture' },
  ];
  //#endregion

  //#region file preview and upload
  previewAttachments: Attachment[] = [];
  stpSacInspectionRegistrationReportList: StpSacInspectionRegistrationReport[] =
    [];
  inspectionRegistrationReport: InspectionRegistrationReport[] = [];
  inspectionRegistrationRecords: InspectionRegistrationRecord[] = [];
  inspectionAttachment: InspectionAttachment[] = [];
  imageURL: any;
  isImageLoading: boolean;
  pdfUrl: any;
  otherPdfUrl: any;
  isAttachmentVisible: boolean = false;

  inspectionIdFromQueryParam: string;

  inspectionIdFromRouteParam: number;

  // remarks from inspection
  inpsectorRemarks: string;

  // remarks from scrutiny
  scrutinyFinalRemarks: string;

  //#endregion file preview and upload

  //#region constructor
  constructor(
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private sanitizer: DomSanitizer,
    private inspectionStorageService: InspectionStorageService,
    private location: Location
  ) {
    this.inspectionStorageService.getRecommendationByType('rs').subscribe({
      next: (res) => {
        this.recommendations = res.data;
      },
    });

    this.inspectionStorageService.getRecommendationByType('s').subscribe({
      next: (res) => {
        this.scrutinyList = res.data;
      },
    });

    // this.route.queryParams.subscribe((params: any) => {
    //   let type = params['type'];
    //   console.log(type);
    //   if (type == 'edit') {
    //     this.isScrutinyVisible = true;
    //   }
    // });
  }
  //#endregion

  //#region onInit
  ngOnInit(): void {
    this.inspectionIdFromRouteParam = this.route.snapshot.params.inspectionId;
    this.route.queryParams.subscribe((p) => {
      this.inspectionIdFromQueryParam = p['inspectionId'];
      if (this.inspectionIdFromRouteParam != null) {
        this.onStpSacInspectionPreviewByInspectionId(
          this.inspectionIdFromRouteParam
        );
      } else if (this.inspectionIdFromQueryParam != null) {
        this.onStpSacInspectionPreviewByEncryptedInspectionId();
      }
      let type = p['type'];
      console.log(type);
      if (type == 'edit') {
        this.isScrutinyVisible = true;
      }
    });
    this.scrutinyForm = this.fb.group({
      inspectionRegistrationReportId: [], // FIXME: Hardcode inspectionRegistrationReportId
      scrutinyResultId: [null,Validators.required],
      scrutinyResultRemarks: [null],
      scrutinyRecommendationId: [null,Validators.required],
      scrutinyRecommendationRemarks: [null],
    });

    this.scrutinyForm.get('scrutinyRecommendationId')?.valueChanges?.subscribe((value)=>{
      if(value==2){
        this.scrutinyForm.get('scrutinyRecommendationRemarks')?.setValidators([Validators.required]);
        this.scrutinyForm.get('scrutinyRecommendationRemarks')?.updateValueAndValidity();
      }else{
        this.scrutinyForm.get('scrutinyRecommendationRemarks')?.clearValidators();
        this.scrutinyForm.get('scrutinyRecommendationRemarks')?.updateValueAndValidity();
      }
    })

    this.scrutinyForm.get('scrutinyResultId')?.valueChanges?.subscribe((value)=>{
      if(value==5){
        this.scrutinyForm.get('scrutinyResultRemarks')?.setValidators([Validators.required]);
        this.scrutinyForm.get('scrutinyResultRemarks')?.updateValueAndValidity();
      }else{
        this.scrutinyForm.get('scrutinyResultRemarks')?.clearValidators();
        this.scrutinyForm.get('scrutinyResultRemarks')?.updateValueAndValidity();
      }
    })

    this.route.queryParams.subscribe((params) => {
      this.stpMainId = parseInt(params['stpMainId'] ?? '0');
      this.inspectionRegistrationReportId = parseInt(
        params['inspectionRegistrationReportId'] ?? '0'
      );
      this.inspectionForm
        ?.get('recommendation.stpMainId')
        ?.setValue(this.stpMainId === 0 ? null : this.stpMainId);
      // this.setStpRegistrationFormGroup();
      this.scrutinyForm
        ?.get('inspectionRegistrationReportId')
        ?.setValue(
          this.inspectionRegistrationReportId === 0
            ? null
            : this.inspectionRegistrationReportId
        );
    });
  }

  //#endregion

  //#region get id from query param
  queryParams() {
    this.route.queryParams.subscribe((params) => {
      this.inspectionId = Number(params.inspectionId);
      this.onStpSacInspectionPreviewByInspectionId(this.inspectionId);
    });
  }
  //#endregion

  //#region Stp Sac Inspection Preview by inspection id
  onStpSacInspectionPreviewByInspectionId(inspectionId: number) {
    this.inspectionStorageService
      .getStpSacInspectionPreviewByInspectionId(inspectionId)
      .subscribe({
        next: (res) => {
          if (res.success) {
            //this.notification.success('Success!', res.message);
            this.inspectionRegistrationReportId =
              res?.data?.inspectionRegistrationReport?.id;
            this.scrutinyForm
              .get('inspectionRegistrationReportId')
              ?.patchValue(this.inspectionRegistrationReportId);

            this.setScrutinyData( res?.data?.inspectionRegistrationReport);

            this.stpSacInspectionRegistrationReportList = res.data;
            this.inspectionRegistrationReport =
              res.data?.inspectionRegistrationReport;

            this.inspectionRegistrationRecords =
              res.data?.inspectionRegistrationReport.inspectionRegistrationRecords;
            this.inspectionRegistrationRecords?.forEach(
              (item: InspectionRegistrationRecord, index: number) => {
                item['modalIdentity'] =
                  this.stpInformations[index].modalIdentity;
              }
            );
            let stpHistoryBasicProfile =
              res.data?.inspectionRegistrationReport
                ?.inspectionRegistrationRecords[0]?.stpHistory;

            let stpHistoryForBasicProfile = stpHistoryBasicProfile
              ? JSON.parse(stpHistoryBasicProfile)
              : null;

            let stpHistoryPhysicalInfrastructureDetails =
              res.data?.inspectionRegistrationReport
                ?.inspectionRegistrationRecords[1]?.stpHistory;

            let stpHistoryForPhysicalInfrastructureDetails =
              stpHistoryPhysicalInfrastructureDetails
                ? JSON.parse(stpHistoryPhysicalInfrastructureDetails)
                : null;

            let stpHistoryOccupationSpecificTrainer =
              res.data?.inspectionRegistrationReport
                ?.inspectionRegistrationRecords[2]?.stpHistory;

            let stpHistoryForOccupationSpecificTrainer =
              stpHistoryOccupationSpecificTrainer
                ? JSON.parse(stpHistoryOccupationSpecificTrainer)
                : null;

            let stpHistoryManagementAndSupportingStaff =
              res.data?.inspectionRegistrationReport
                ?.inspectionRegistrationRecords[3]?.stpHistory;

            let stpHistoryForManagementAndSupportingStaff =
              stpHistoryManagementAndSupportingStaff
                ? JSON.parse(stpHistoryManagementAndSupportingStaff)
                : null;

            let stpHistoryFinancialDetail =
              res.data?.inspectionRegistrationReport
                ?.inspectionRegistrationRecords[4]?.stpHistory;

            let stpHistoryForFinancialDetail = stpHistoryFinancialDetail
              ? JSON.parse(stpHistoryFinancialDetail)
              : null;

            let stpHistoryOccupationTools =
              res.data?.inspectionRegistrationReport
                ?.inspectionRegistrationRecords[5]?.stpHistory;
            let stpHistoryForOccupationTools = stpHistoryOccupationTools
              ? JSON.parse(stpHistoryOccupationTools)
              : null;

            let stpHistoryfurnitures =
              res.data?.inspectionRegistrationReport
                ?.inspectionRegistrationRecords[6]?.stpHistory;

            let stpHistoryForfurnitures = stpHistoryfurnitures
              ? JSON.parse(stpHistoryfurnitures)
              : null;

            if (stpHistoryForBasicProfile?.isCommittee === true) {
              this.isCommitte = true;
            } else {
              this.isCommitte = false;
            }

            this.basicProfileForm = {
              stpName: stpHistoryForBasicProfile?.stpName,
              stpNameBangla: stpHistoryForBasicProfile?.stpNameBangla,
              stpType: stpHistoryForBasicProfile?.stpType,
              stpOwnershipType: stpHistoryForBasicProfile?.stpOwnershipType,
              stpMinistry: stpHistoryForBasicProfile?.stpMinistry,
              stpLocation: stpHistoryForBasicProfile?.stpLocation,
              establishYear: this.datepipe.transform(
                stpHistoryForBasicProfile?.establishYear,
                'yyyy'
              ),
              stpAgency: stpHistoryForBasicProfile?.stpAgency,

              stpCountry: stpHistoryForBasicProfile?.stpCountry,
              division: stpHistoryForBasicProfile?.division,
              district: stpHistoryForBasicProfile?.district,
              upazilla: stpHistoryForBasicProfile?.upazilla,
              address: stpHistoryForBasicProfile?.address,
              contactNo: stpHistoryForBasicProfile?.contactNo,
              email: stpHistoryForBasicProfile?.email,

              contactName: stpHistoryForBasicProfile?.contactName,
              contactDesignation: stpHistoryForBasicProfile?.contactDesignation,
              contactNumber: stpHistoryForBasicProfile?.contactNumber,
              contactEmail: stpHistoryForBasicProfile?.contactEmail,

              isCommittee: stpHistoryForBasicProfile?.isCommittee,
              totalMember: stpHistoryForBasicProfile?.totalMember,
              totalFemaleMember: stpHistoryForBasicProfile?.totalFemaleMember,
              approvalDateCommittee: this.datepipe.transform(
                stpHistoryForBasicProfile?.approvalDate,
                'dd-MM-yyyy'
              ),
              expirationDateCommittee: this.datepipe.transform(
                stpHistoryForBasicProfile?.expireDate,
                'dd-MM-yyyy'
              ),
            };

            this.physicalInfraForm = {
              ownershipBuilding:
                stpHistoryForPhysicalInfrastructureDetails?.ownershipBuilding,
              constructionBuilding:
                stpHistoryForPhysicalInfrastructureDetails?.constructionBuilding,
              totalArea: stpHistoryForPhysicalInfrastructureDetails?.totalArea,
              floor: stpHistoryForPhysicalInfrastructureDetails?.floor,
              classroom: stpHistoryForPhysicalInfrastructureDetails?.classroom,
              classroomSize:
                stpHistoryForPhysicalInfrastructureDetails?.classroomSize,
              workshop: stpHistoryForPhysicalInfrastructureDetails?.workshop,
              workshopSize:
                stpHistoryForPhysicalInfrastructureDetails?.workshopSize,
              noOfComputerLabs:
                stpHistoryForPhysicalInfrastructureDetails?.noOfComputerLabs,
              sizeOfComputerLabs:
                stpHistoryForPhysicalInfrastructureDetails?.sizeOfComputerLabs,
              office: stpHistoryForPhysicalInfrastructureDetails?.office,
              trainingRoom:
                stpHistoryForPhysicalInfrastructureDetails?.trainingRoom,
              washroom: stpHistoryForPhysicalInfrastructureDetails?.washroom,
              femaleWashroom:
                stpHistoryForPhysicalInfrastructureDetails?.femaleWashroom,
              isDisableFriendly:
                stpHistoryForPhysicalInfrastructureDetails?.isDisableFriendly,
              isElectricity:
                stpHistoryForPhysicalInfrastructureDetails?.isElectricity,
              isInternet:
                stpHistoryForPhysicalInfrastructureDetails?.isInternet,
              isDrinkingWater:
                stpHistoryForPhysicalInfrastructureDetails?.isDrinkingWater,
              isFireEq: stpHistoryForPhysicalInfrastructureDetails?.isFireEq,
              isLibrary: stpHistoryForPhysicalInfrastructureDetails?.isLibrary,
              isLightingVent:
                stpHistoryForPhysicalInfrastructureDetails?.isLightingVent,
            };

            this.stpManagements = stpHistoryForManagementAndSupportingStaff;

            this.financialDetailForm = {
              reserveFund: parseFloat(
                stpHistoryForFinancialDetail?.reserveFund
              ),
              fdr: parseFloat(stpHistoryForFinancialDetail?.fdr),
              generalFund: parseFloat(
                stpHistoryForFinancialDetail?.generalFund
              ),
              availableBalanceInBankAccount: parseFloat(
                stpHistoryForFinancialDetail?.availableBalanceInBankAccount
              ),
              accountNumber: stpHistoryForFinancialDetail?.accountNumber,
              bankName: stpHistoryForFinancialDetail?.bankName,
              branchName: stpHistoryForFinancialDetail?.branchName,
              otherFunds: parseFloat(stpHistoryForFinancialDetail?.otherFunds),
              remarks: stpHistoryForFinancialDetail?.remarks,
              feesFromStudents: parseFloat(
                stpHistoryForFinancialDetail?.feesFromStudents
              ),
              incomeFromOwnAssets: parseFloat(
                stpHistoryForFinancialDetail?.incomeFromOwnAssets
              ),
              fromGovernment: parseFloat(
                stpHistoryForFinancialDetail?.fromGovernment
              ),
              developmentCharge: parseFloat(
                stpHistoryForFinancialDetail?.developmentCharge
              ),
              admissionFee: parseFloat(
                stpHistoryForFinancialDetail?.admissionFee
              ),
              examinationFee: parseFloat(
                stpHistoryForFinancialDetail?.examinationFee
              ),
              sessionCharge: parseFloat(
                stpHistoryForFinancialDetail?.sessionCharge
              ),
              sellingOfProductsProduced: parseFloat(
                stpHistoryForFinancialDetail?.sellingOfProductsProduced
              ),
              others_source: parseFloat(
                stpHistoryForFinancialDetail?.others_source
              ),
              salaryOfInstructorsAndStaff: parseFloat(
                stpHistoryForFinancialDetail?.salaryOfInstructorsAndStaff
              ),
              incidentalExpenses: parseFloat(
                stpHistoryForFinancialDetail?.incidentalExpenses
              ),
              repairingCost: parseFloat(
                stpHistoryForFinancialDetail?.repairingCost
              ),
              examinationExpenses: parseFloat(
                stpHistoryForFinancialDetail?.examinationExpenses
              ),
              travelExpenses: parseFloat(
                stpHistoryForFinancialDetail?.travelExpenses
              ),
              procurementOfBooks: parseFloat(
                stpHistoryForFinancialDetail?.procurementOfBooks
              ),
              procurementOfTrainingLearningMaterials: parseFloat(
                stpHistoryForFinancialDetail?.procurementOfTrainingLearningMaterials
              ),
              procurementOfRawMaterials: parseFloat(
                stpHistoryForFinancialDetail?.procurementOfRawMaterials
              ),
              others_head: parseFloat(
                stpHistoryForFinancialDetail?.others_head
              ),
            };

            this.totalIncomes =
              parseFloat(stpHistoryForFinancialDetail?.feesFromStudents || 0) +
              parseFloat(
                stpHistoryForFinancialDetail?.incomeFromOwnAssets || 0
              ) +
              parseFloat(stpHistoryForFinancialDetail?.fromGovernment || 0) +
              parseFloat(stpHistoryForFinancialDetail?.developmentCharge || 0) +
              parseFloat(stpHistoryForFinancialDetail?.admissionFee || 0) +
              parseFloat(stpHistoryForFinancialDetail?.examinationFee || 0) +
              parseFloat(stpHistoryForFinancialDetail?.sessionCharge || 0) +
              parseFloat(
                stpHistoryForFinancialDetail?.sellingOfProductsProduced || 0
              ) +
              parseFloat(stpHistoryForFinancialDetail?.others_source || 0);

            this.totalExpenses =
              parseFloat(
                stpHistoryForFinancialDetail?.salaryOfInstructorsAndStaff || 0
              ) +
              parseFloat(
                stpHistoryForFinancialDetail?.incidentalExpenses || 0
              ) +
              parseFloat(stpHistoryForFinancialDetail?.repairingCost || 0) +
              parseFloat(
                stpHistoryForFinancialDetail?.examinationExpenses || 0
              ) +
              parseFloat(stpHistoryForFinancialDetail?.travelExpenses || 0) +
              parseFloat(
                stpHistoryForFinancialDetail?.procurementOfBooks || 0
              ) +
              parseFloat(
                stpHistoryForFinancialDetail?.procurementOfTrainingLearningMaterials ||
                  0
              ) +
              parseFloat(
                stpHistoryForFinancialDetail?.procurementOfRawMaterials || 0
              ) +
              parseFloat(stpHistoryForFinancialDetail?.others_head || 0);

            this.stpTrainers = stpHistoryForOccupationSpecificTrainer;

            this.stpOccupationTools = stpHistoryForOccupationTools;

            this.furnitures = stpHistoryForfurnitures;

            this.inspectionAttachment =
              res?.data?.inspectionRegistrationReport?.inspectionAttachment;
            
            this.inpsectorRemarks = res?.data?.inspectionRegistrationReport?.inspectorRemarks;
            this.scrutinyFinalRemarks = res?.data?.inspectionRegistrationReport?.scrutinyFinalRemarks;

            this.setPreviousAttachments();
          } else {
            this.notification.error('Failed!', res.message);
          }
        },
      });
  }
  //#endregion

  //#region submit form
  submitForm(): void {}
  //#endregion

  //#region Modal
  showModal(modalName: string | undefined, modalTitle: string = ''): void {
    this.modalTabName = modalName;
    this.modalTitle = modalTitle;
    this.isVisible = true;
  }
  //#endregion

  handleOk(): void {
    this.isOkLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isOkLoading = false;
    }, 3000);
  }

  handleCancelModal(): void {
    this.isVisible = false;
  }

  //#region Image Preview
  handleOkFile(): void {
    this.isVisibleForAttachment = false;
  }

  handleCancelFile(): void {
    this.isVisibleForAttachment = false;
  }

  setPreviousAttachments() {
    this.inspectionAttachment?.forEach((item: Attachment, index: number) => {
      let obj = { ...item };
      let fileType = this.getExtensionFromUrl(item.fileUrl);
      item.fileType = this.getFileTypeFromPreviousAttachment(fileType);
      this.setBase64AttachmentforPreviewing(index, item.fileUrl);
    });
  }

  async setBase64AttachmentforPreviewing(index: number, fileUrl: string) {
    const data = await fetch(environment.fileServiceApiUrl + fileUrl);
    const blob = await data.blob();
    this.inspectionAttachment[index].fileUrl = URL.createObjectURL(blob);
  }

  previewAllImage(index: number, fielType: string | undefined) {
    this.imageURL = '';
    this.pdfUrl = '';
    this.otherPdfUrl = '';
    if (fielType == 'image') {
      this.isAttachmentVisible = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.inspectionAttachment[index].fileUrl
      );
    } else if (fielType == 'pdf') {
      this.isAttachmentVisible = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.inspectionAttachment[index].fileUrl
      );
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.inspectionAttachment[index].fileUrl);
      link.setAttribute('download', this.inspectionAttachment[index].fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  getExtensionFromUrl(url: string) {
    let fileType: string = '';
    let basename = url.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;

    fileType = basename?.slice(pos + 1) ?? '';
    return fileType;
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {
    let contentType = '';
    let basename = fileUrl.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;

    let fileType = basename?.slice(pos + 1) ?? '';
    if (this.imageTypeArray.includes(fileType)) {
      contentType = 'image';
    } else if (fileType == 'pdf') {
      contentType = 'pdf';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  getFileTypeFromNewAttachment(fileType: string) {
    let contentType = '';
    if (fileType.match(/image/)) {
      contentType = 'image';
    } else if (fileType == 'application/pdf') {
      contentType = 'pdf';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  handleAttachmentModalCancel(): void {
    this.isAttachmentVisible = false;
  }
  //#endregion

  resetForm(): void {}

  //#region scrutiny

  submitScrutinyForm(): void {
    this.saveButtonDisabled = true;
    this.stpSacScrutinyResult = this.scrutinyForm.value;
    this.inspectionStorageService
      .saveScrutinyForStpStacInspectionReport(this.stpSacScrutinyResult)
      .subscribe({
        next: (response) => {
          if (response.success) {
            this.notification.success(
              'Success!',
              'Scrutiny Information saved successfully'
            );
          }else{
            this.notification.error('Failed', response?.message);
          }
          this.saveButtonDisabled = false;
        },
        error: (error) => {
          this.notification.error('Failed', error);
          this.saveButtonDisabled = false;
        },
      });
  }
  //#endregion scrutiny

  //#region Stp Sac Inspection Preview by inspection id
  onStpSacInspectionPreviewByEncryptedInspectionId() {
    this.inspectionStorageService
      .getStpSacInspectionPreviewByEncryptedInspectionId(
        this.inspectionIdFromQueryParam
      )
      .subscribe({
        next: (res) => {
          this.inspectionRegistrationReportId =
            res?.data?.inspectionRegistrationReport?.id;
          this.scrutinyForm
            .get('inspectionRegistrationReportId')
            ?.patchValue(this.inspectionRegistrationReportId);          
          this.stpSacInspectionRegistrationReportList = res.data;
          this.inspectionRegistrationReport =
            res.data?.inspectionRegistrationReport;
          this.inspectionRegistrationRecords =
            res.data?.inspectionRegistrationReport.inspectionRegistrationRecords;
          this.inspectionRegistrationRecords?.forEach(
            (item: InspectionRegistrationRecord, index: number) => {
              item['modalIdentity'] = this.stpInformations[index].modalIdentity;
            }
          );
          let stpHistoryBasicProfile =
            res.data?.inspectionRegistrationReport
              ?.inspectionRegistrationRecords[0]?.stpHistory;
          let stpHistoryForBasicProfile = stpHistoryBasicProfile
            ? JSON.parse(stpHistoryBasicProfile)
            : null;

          let stpHistoryPhysicalInfrastructureDetails =
            res.data?.inspectionRegistrationReport
              ?.inspectionRegistrationRecords[1]?.stpHistory;
          let stpHistoryForPhysicalInfrastructureDetails =
            stpHistoryPhysicalInfrastructureDetails
              ? JSON.parse(stpHistoryPhysicalInfrastructureDetails)
              : null;

          let stpHistoryOccupationSpecificTrainer =
            res.data?.inspectionRegistrationReport
              ?.inspectionRegistrationRecords[2]?.stpHistory;
          let stpHistoryForOccupationSpecificTrainer =
            stpHistoryOccupationSpecificTrainer
              ? JSON.parse(stpHistoryOccupationSpecificTrainer)
              : null;

          let stpHistoryManagementAndSupportingStaff =
            res.data?.inspectionRegistrationReport
              ?.inspectionRegistrationRecords[3]?.stpHistory;
          let stpHistoryForManagementAndSupportingStaff =
            stpHistoryManagementAndSupportingStaff
              ? JSON.parse(stpHistoryManagementAndSupportingStaff)
              : null;

          let stpHistoryFinancialDetail =
            res.data?.inspectionRegistrationReport
              ?.inspectionRegistrationRecords[4]?.stpHistory;
          let stpHistoryForFinancialDetail = stpHistoryFinancialDetail
            ? JSON.parse(stpHistoryFinancialDetail)
            : null;

          let stpHistoryOccupationTools =
            res.data?.inspectionRegistrationReport
              ?.inspectionRegistrationRecords[5]?.stpHistory;
          let stpHistoryForOccupationTools = stpHistoryOccupationTools
            ? JSON.parse(stpHistoryOccupationTools)
            : null;

          let stpHistoryfurnitures =
            res.data?.inspectionRegistrationReport
              ?.inspectionRegistrationRecords[6]?.stpHistory;
          let stpHistoryForfurnitures = stpHistoryfurnitures
            ? JSON.parse(stpHistoryfurnitures)
            : null;

          if (stpHistoryForBasicProfile?.isCommittee === true) {
            this.isCommitte = true;
          } else {
            this.isCommitte = false;
          }

          this.basicProfileForm = {
            stpName: stpHistoryForBasicProfile?.stpName,
            stpNameBangla: stpHistoryForBasicProfile?.stpNameBangla,
            stpType: stpHistoryForBasicProfile?.stpType,
            stpOwnershipType: stpHistoryForBasicProfile?.stpOwnershipType,
            stpMinistry: stpHistoryForBasicProfile?.stpMinistry,
            stpLocation: stpHistoryForBasicProfile?.stpLocation,
            establishYear: this.datepipe.transform(
              stpHistoryForBasicProfile?.establishYear,
              'yyyy'
            ),
            stpAgency: stpHistoryForBasicProfile?.stpAgency,

            stpCountry: stpHistoryForBasicProfile?.stpCountry,
            division: stpHistoryForBasicProfile?.division,
            district: stpHistoryForBasicProfile?.district,
            upazilla: stpHistoryForBasicProfile?.upazilla,
            address: stpHistoryForBasicProfile?.address,
            contactNo: stpHistoryForBasicProfile?.contactNo,
            email: stpHistoryForBasicProfile?.email,

            contactName: stpHistoryForBasicProfile?.contactName,
            contactDesignation: stpHistoryForBasicProfile?.contactDesignation,
            contactNumber: stpHistoryForBasicProfile?.contactNumber,
            contactEmail: stpHistoryForBasicProfile?.contactEmail,

            isCommittee: stpHistoryForBasicProfile?.isCommittee,
            totalMember: stpHistoryForBasicProfile?.totalMember,
            totalFemaleMember: stpHistoryForBasicProfile?.totalFemaleMember,
            approvalDateCommittee: this.datepipe.transform(
              stpHistoryForBasicProfile?.approvalDate,
              'dd-MM-yyyy'
            ),
            expirationDateCommittee: this.datepipe.transform(
              stpHistoryForBasicProfile?.expireDate,
              'dd-MM-yyyy'
            ),
          };

          this.physicalInfraForm = {
            ownershipBuilding:
              stpHistoryForPhysicalInfrastructureDetails?.ownershipBuilding,
            constructionBuilding:
              stpHistoryForPhysicalInfrastructureDetails?.constructionBuilding,
            totalArea: stpHistoryForPhysicalInfrastructureDetails?.totalArea,
            floor: stpHistoryForPhysicalInfrastructureDetails?.floor,
            classroom: stpHistoryForPhysicalInfrastructureDetails?.classroom,
            classroomSize:
              stpHistoryForPhysicalInfrastructureDetails?.classroomSize,
            workshop: stpHistoryForPhysicalInfrastructureDetails?.workshop,
            workshopSize:
              stpHistoryForPhysicalInfrastructureDetails?.workshopSize,
            noOfComputerLabs:
              stpHistoryForPhysicalInfrastructureDetails?.noOfComputerLabs,
            sizeOfComputerLabs:
              stpHistoryForPhysicalInfrastructureDetails?.sizeOfComputerLabs,
            office: stpHistoryForPhysicalInfrastructureDetails?.office,
            trainingRoom:
              stpHistoryForPhysicalInfrastructureDetails?.trainingRoom,
            washroom: stpHistoryForPhysicalInfrastructureDetails?.washroom,
            femaleWashroom:
              stpHistoryForPhysicalInfrastructureDetails?.femaleWashroom,
            isDisableFriendly:
              stpHistoryForPhysicalInfrastructureDetails?.isDisableFriendly,
            isElectricity:
              stpHistoryForPhysicalInfrastructureDetails?.isElectricity,
            isInternet: stpHistoryForPhysicalInfrastructureDetails?.isInternet,
            isDrinkingWater:
              stpHistoryForPhysicalInfrastructureDetails?.isDrinkingWater,
            isFireEq: stpHistoryForPhysicalInfrastructureDetails?.isFireEq,
            isLibrary: stpHistoryForPhysicalInfrastructureDetails?.isLibrary,
            isLightingVent:
              stpHistoryForPhysicalInfrastructureDetails?.isLightingVent,
          };

          this.stpManagements = stpHistoryForManagementAndSupportingStaff;

          this.financialDetailForm = {
            reserveFund: parseFloat(stpHistoryForFinancialDetail?.reserveFund),
            fdr: parseFloat(stpHistoryForFinancialDetail?.fdr),
            generalFund: parseFloat(stpHistoryForFinancialDetail?.generalFund),
            availableBalanceInBankAccount: parseFloat(
              stpHistoryForFinancialDetail?.availableBalanceInBankAccount
            ),
            accountNumber: stpHistoryForFinancialDetail?.accountNumber,
            bankName: stpHistoryForFinancialDetail?.bankName,
            branchName: stpHistoryForFinancialDetail?.branchName,
            otherFunds: parseFloat(stpHistoryForFinancialDetail?.otherFunds),
            remarks: stpHistoryForFinancialDetail?.remarks,
            feesFromStudents: parseFloat(
              stpHistoryForFinancialDetail?.feesFromStudents
            ),
            incomeFromOwnAssets: parseFloat(
              stpHistoryForFinancialDetail?.incomeFromOwnAssets
            ),
            fromGovernment: parseFloat(
              stpHistoryForFinancialDetail?.fromGovernment
            ),
            developmentCharge: parseFloat(
              stpHistoryForFinancialDetail?.developmentCharge
            ),
            admissionFee: parseFloat(
              stpHistoryForFinancialDetail?.admissionFee
            ),
            examinationFee: parseFloat(
              stpHistoryForFinancialDetail?.examinationFee
            ),
            sessionCharge: parseFloat(
              stpHistoryForFinancialDetail?.sessionCharge
            ),
            sellingOfProductsProduced: parseFloat(
              stpHistoryForFinancialDetail?.sellingOfProductsProduced
            ),
            others_source: parseFloat(
              stpHistoryForFinancialDetail?.others_source
            ),
            salaryOfInstructorsAndStaff: parseFloat(
              stpHistoryForFinancialDetail?.salaryOfInstructorsAndStaff
            ),
            incidentalExpenses: parseFloat(
              stpHistoryForFinancialDetail?.incidentalExpenses
            ),
            repairingCost: parseFloat(
              stpHistoryForFinancialDetail?.repairingCost
            ),
            examinationExpenses: parseFloat(
              stpHistoryForFinancialDetail?.examinationExpenses
            ),
            travelExpenses: parseFloat(
              stpHistoryForFinancialDetail?.travelExpenses
            ),
            procurementOfBooks: parseFloat(
              stpHistoryForFinancialDetail?.procurementOfBooks
            ),
            procurementOfTrainingLearningMaterials: parseFloat(
              stpHistoryForFinancialDetail?.procurementOfTrainingLearningMaterials
            ),
            procurementOfRawMaterials: parseFloat(
              stpHistoryForFinancialDetail?.procurementOfRawMaterials
            ),
            others_head: parseFloat(stpHistoryForFinancialDetail?.others_head),
          };

          this.totalIncomes =
            parseFloat(stpHistoryForFinancialDetail?.feesFromStudents || 0) +
            parseFloat(stpHistoryForFinancialDetail?.incomeFromOwnAssets || 0) +
            parseFloat(stpHistoryForFinancialDetail?.fromGovernment || 0) +
            parseFloat(stpHistoryForFinancialDetail?.developmentCharge || 0) +
            parseFloat(stpHistoryForFinancialDetail?.admissionFee || 0) +
            parseFloat(stpHistoryForFinancialDetail?.examinationFee || 0) +
            parseFloat(stpHistoryForFinancialDetail?.sessionCharge || 0) +
            parseFloat(
              stpHistoryForFinancialDetail?.sellingOfProductsProduced || 0
            ) +
            parseFloat(stpHistoryForFinancialDetail?.others_source || 0);

          this.totalExpenses =
            parseFloat(
              stpHistoryForFinancialDetail?.salaryOfInstructorsAndStaff || 0
            ) +
            parseFloat(stpHistoryForFinancialDetail?.incidentalExpenses || 0) +
            parseFloat(stpHistoryForFinancialDetail?.repairingCost || 0) +
            parseFloat(stpHistoryForFinancialDetail?.examinationExpenses || 0) +
            parseFloat(stpHistoryForFinancialDetail?.travelExpenses || 0) +
            parseFloat(stpHistoryForFinancialDetail?.procurementOfBooks || 0) +
            parseFloat(
              stpHistoryForFinancialDetail?.procurementOfTrainingLearningMaterials ||
                0
            ) +
            parseFloat(
              stpHistoryForFinancialDetail?.procurementOfRawMaterials || 0
            ) +
            parseFloat(stpHistoryForFinancialDetail?.others_head || 0);

          this.stpTrainers = stpHistoryForOccupationSpecificTrainer;

          this.stpOccupationTools = stpHistoryForOccupationTools;

          this.furnitures = stpHistoryForfurnitures;

          this.inspectionAttachment =
            res?.data?.inspectionRegistrationReport?.inspectionAttachment;
          this.setPreviousAttachments();
        },
      });
  }
  //#endregion

  setScrutinyData(inspectionRegistrationReport:any){
    this.scrutinyForm.patchValue({
      scrutinyResultId : inspectionRegistrationReport?.scrutinyResult?.id,
      scrutinyResultRemarks:inspectionRegistrationReport?.scrutinyRemarks,
      scrutinyRecommendationId: inspectionRegistrationReport?.scrutinyRecommendation?.id,
      scrutinyRecommendationRemarks: inspectionRegistrationReport?.scrutinyFinalRemarks
    })
  }

  backToPreviousPage() {
    this.location.back();
  }

}
