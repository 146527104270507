import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';


@Injectable({
  providedIn: 'root'
})
export class ExcelDownloadService {

  private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private EXCEL_EXTENSION = '.xlsx';

  constructor() { }

  exportExcel(json: any, excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // this.saveAsExcelFile(excelBuffer, excelFileName);
    const data: Blob = new Blob([excelBuffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, excelFileName + this.EXCEL_EXTENSION);
  }

  // user this version 2 export excel function to properly accomodate date type data
  exportExcelV2(json: any, excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {cellDates: true, dateNF: 'dd-mm-yyyy'});
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, excelFileName + this.EXCEL_EXTENSION);
  }

  // exportPdf(param: any, fileNameTitle: string) {

  //   // const doc = new jsPDF();

  //   // var doc = new jspdf("p", "pt", "a4");  // For Portrait   

  //   const doc = new jsPDF('l', 'pt', "a4");  // For landscape
  //   const dataList = [];

  //   const columns = [["SL#", "Call Date", "ID", "Status", "Caller Name", "Mobile No",
  //     "Pond Address", "Call Type", "Rejection Reason"]];
  //   let i = 1;
  //   for (const data of param) {
  //     let obj = {};
  //     obj[0] = i;
  //     obj[1] = data.callDate;
  //     obj[2] = data.uniqueId;
  //     obj[3] = data.ticketStatus;
  //     obj[4] = data.callerName;
  //     obj[5] = data.contactNo;
  //     obj[6] = data.permanentAddress;
  //     obj[7] = data.callType;
  //     obj[8] = data.rejectionReason;
  //     // obj[5] = data.presentAddress;
  //     // obj[8] = data.questions;
  //     // obj[9] = data.responseSolution;
  //     dataList.push(obj);
  //     i++;
  //   }

  //   autoTable(doc, {
  //     head: columns,
  //     body: dataList,
  //     didDrawPage: dataArg => {
  //       doc.setFontSize(15);
  //       doc.setTextColor(40);
  //       doc.setFont("normal");
  //       // doc.text(fileNameTitle, dataArg.settings.margin.left, 10);
  //       doc.text(fileNameTitle, dataArg.settings.margin.top, 35);
  //     },
  //     margin: { top: 45 },
  //   });
  //   // doc.save("Master Report.pdf");
  //   doc.save(`${fileNameTitle}.pdf`);
  // }
}
