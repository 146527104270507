<app-header></app-header>
<nz-card *ngIf="contentVisble">
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Search Criteria
  </div>
  <form nz-form [formGroup]="searchingForm" (ngSubmit)="onSearch()" class="ant-advanced-search-form" style="
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      ">
    <div class="row">
      <div class="form-group textfield1">
        <div class="form-group col-md-12">
          <nz-form-label>Registration No</nz-form-label>
          <input nz-input type="text" placeholder="Provide Registration No please" formControlName="registrationNo" />
        </div>
      </div>
      <div>
        <span class="input-group-text" style="margin-top:38px">OR</span>
      </div>

      <div class="form-group textfield2">
        <div class="form-group col-md-12">
          <nz-form-label>Mobile Number</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzErrorTip]="contactNumberErrorTpl">
              <input nz-input type="text" placeholder="Provide Contact No please" formControlName="contact" />
              <ng-template #contactNumberErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Please input valid contact number!
                </ng-container>
                <ng-container *ngIf="control.hasError('maxlength')">
                  Maximum digit should be 11
                </ng-container>
                <ng-container *ngIf="control.hasError('minlength')">
                  Contact number must be 11 digit
                </ng-container>
                <ng-container *ngIf="control.hasError('pattern')">
                  <div>Only digits are allowed</div>
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div>
        <span class="input-group-text" style="margin-top:38px">OR</span>
      </div>

      <div class="form-group textfield2">
        <div class="form-group col-md-12">
          <nz-form-label>Email</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
              <input nz-input type="text" placeholder="Provide Email please" formControlName="email" />
              <ng-template #emailErrorTpl let-control> </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div>
        <span class="input-group-text" style="margin-top:38px">OR</span>
      </div>

      <div class="form-group textfield1">
        <div class="form-group col-md-12">
          <nz-form-label>Certificate Serial No</nz-form-label>
          <input nz-input type="text" placeholder="Provide Certificate Serial No please" formControlName="certificateSerialNo" />
        </div>
      </div>
    </div>

    <div nz-row>
      <div nz-col [nzSpan]="24" style="text-align: right">
        <button nz-button [nzType]="'primary'" [disabled]="!searchingForm.valid" style="margin-right: 7px">
          Search
        </button>
        <button type="button" nz-button class="btn-success" (click)="onRefresh()">
          Refresh Data
        </button>
      </div>
    </div>
  </form>
</nz-card>
<nz-card *ngIf="contentVisble">
  <h6 style="color: red;">**Use latest version of Adobe Reader to see certificates properly (সার্টিফিকেট সঠিকভাবে দেখতে Adobe Reader এর সর্বশেষ সংস্করণ ব্যবহার করুন)</h6>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="form" nzTheme="outline"></i> List of Certificates Issued
  </div>
  <div class="searchboxAerar p-3">
    <nz-table
    nzSize="small"
    #basicTable
    [nzData]="tciInfoList"
    nzTableLayout="fixed"
    nzShowSizeChanger="false"
    nzBordered
    nzAlign="middle"
    [nzFrontPagination]="false"
    class="css-serial table-striped table">
      <thead>
        <tr>
          <th style="text-align: center; vertical-align: middle; width: 4%;">SL.</th>
          <th style="text-align: center; vertical-align: middle; width: 10%;">Application ID</th>
          <th style="text-align: center; vertical-align: middle; width: 10%;">Occupation</th>
          <th style="text-align: center; vertical-align: middle; width: 5%;">Level</th>
          <th style="text-align: center; vertical-align: middle; width: 13%;">Name of Competent Person</th>
          <th style="text-align: center; vertical-align: middle; width: 14%;">Registration Number</th>
          <th style="text-align: center; vertical-align: middle; width: 14%;">Certificate Serial No</th>
          <th style="text-align: center; vertical-align: middle; width: 10%;">Email ID</th>
          <th style="text-align: center; vertical-align: middle; width: 10%;">Contact</th>
          <th style="text-align: center; vertical-align: middle; width: 10%;">Certificate View/Download</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td style="text-align: center; vertical-align: middle">
            {{ (page - 1) * size + 1 + i }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.applicationId }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.occupationNameEnglish }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.level }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.nameEnglish }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.registrationNo }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.certificateSerialNo }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.email }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.contact }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            <button class="mr-1" (click)="onDownloadCertificate(data)" nz-button type="submit"
              nzType="primary" title="Download Certificate" [nzSize]="'small'">
              <i nz-icon nzType="container" nzTheme="outline"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>
<app-captcha (verifyStatus)="captchVerificationStatus($event)"></app-captcha>
<app-footer></app-footer>
