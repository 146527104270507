import { Injectable } from '@angular/core';
import { CircularPreventedOrg } from '../models/DTO/circular-prevented-org';
import { Subject } from 'rxjs';
import { CircularApplicableOccupations } from '../models/DTO/circular-applicable-occupations';
import { CircularReport } from '../models/DTO/circular-report';

@Injectable({
  providedIn: 'root',
})
export class CircularService {
  constructor() {}

  circularPreventedOrg: CircularPreventedOrg[] = [];
  onRefreshCircularPreventedOrgList = new Subject<CircularPreventedOrg[]>();

  circularApplicableOccupations: CircularApplicableOccupations[] = [];
  onRefreshCircularApplicableOccupationsList = new Subject<
    CircularApplicableOccupations[]
  >();


  circularReportExcel: CircularReport[] = [];
  onRefreshCircularExcelList = new Subject<CircularReport[]>();

  updatePreventedOrg(newRecipe: CircularPreventedOrg, id: number): void {
    const index = this.circularPreventedOrg.findIndex(
      (circularPreventedOrg) => circularPreventedOrg.circularId === id
    );
    this.circularPreventedOrg[index] = newRecipe;
    this.onRefreshCircularPreventedOrgList.next(
      this.circularPreventedOrg.slice()
    );
  }

  updateApplicableOccupations(
    newRecipe: CircularApplicableOccupations,
    id: number
  ): void {
    const index = this.circularApplicableOccupations.findIndex(
      (circularApplicableOccupations) =>
        circularApplicableOccupations.circularId === id
    );
    this.circularApplicableOccupations[index] = newRecipe;
    this.onRefreshCircularApplicableOccupationsList.next(
      this.circularApplicableOccupations.slice()
    );
  }


  setCircularReport(circularReport: CircularReport[]): void {
    this.circularReportExcel = circularReport;
    this.onRefreshCircularExcelList.next(this.circularReportExcel.slice());
  }
}
