import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PgStorageService } from 'src/app/modules/policy-guidelines/services/pg-storage.service';
import { RequestData } from 'src/app/modules/stp-registration/models/stp_onlinePayment.model';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { CommonStorageService } from 'src/app/shared/services/common-storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-give-opinion-create',
  templateUrl: './give-opinion-create.component.html',
  styleUrls: ['./give-opinion-create.component.scss'],
})
export class GiveOpinionCreateComponent implements OnInit {
  giveOpinionForm: FormGroup;
  documentId: number;
  documentTypeId: number;

  //#region image setup
  @ViewChild('myInput1')
  myInputVariable1: ElementRef;

  isImageLoading: boolean;
  imageURL: any;

  imageTypeArray: string[] = ['jpg', 'jpeg', 'png'];
  allowedFileExtensions = '.png,.jpg,.jpeg,.pdf,.xlsx';
  maxFormatDetailsFileSize = 30;
  documentFile: any;
  pdfUrl: any;
  documentFileShow: boolean = false;
  isVisible: boolean = false;
  //#endregion

  isEditMode: boolean = false;
  opinionNotes: boolean = false;
  editedItem: any;
  isDisableSubmit: boolean = true;

  documentTypeList: any;
  versionList: any;
  languageList: any;
  accessTypeList: any;

  hiddingHeaderFooter: boolean = true;
  userInfo: any;
  requestData: RequestData = new RequestData();
  contentVisible: boolean = true;

  constructor(
    private fb: FormBuilder,
    private pgStorageService: PgStorageService,
    private notification: NzNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private commonStorageService: CommonStorageService,
    private sanitizer: DomSanitizer
  ) {
    this.userId();
    window.location.href.includes('home')
      ? (this.hiddingHeaderFooter = true)
      : !(this.userInfo?.roles == null)
      ? (this.hiddingHeaderFooter = false)
      : (this.hiddingHeaderFooter = false);


    //#region document id
    this.documentId = Number(
      this.activatedRoute.snapshot.queryParamMap.get('documentId')
    );
    //#endregion
  }

  ngOnInit(): void {
    this.createForm();
    this.getOpinionInfoById();
  }

  //#region form builder
  createForm() {
    this.giveOpinionForm = this.fb.group({
      documentType: [],
      documentTitle: [],
      version: [],
      languageCode: [],
      nameOfPersonProvidingOpinion: ['', [Validators.required]],
      designation: [''],
      organization: [''],
      address: [''],
      contactNo: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      opinionOrFeedback: [''],
      documentUrl: [''],
    });
  }

  //#region get document info by document id
  getOpinionInfoById() {
    this.pgStorageService
      .readOpinionInfoById(this.documentId)
      .subscribe((res: ServerResponse) => {
        // this.createForm(res?.data);
        this.editedItem = res?.data;
        this.documentTypeId = res?.data?.documentType?.id;
        if (this.editedItem) {
          this.giveOpinionForm.patchValue({
            documentType: this.editedItem.documentType.documentName,
            documentTitle: this.editedItem.documentTitle,
            version: this.editedItem.version.versionName,
            languageCode: this.editedItem.language.languageName,
            nameOfPersonProvidingOpinion:
              this.editedItem.nameOfPersonProvidingOpinion,
            designation: this.editedItem.designation,
            organization: this.editedItem.organization,
            address: this.editedItem.address,
            contactNo: this.editedItem.contactNo,
            email: this.editedItem.email,
            opinionOrFeedback: this.editedItem.opinionOrFeedback,
          });
          this.giveOpinionForm.controls.documentUrl.clearValidators();
          this.giveOpinionForm.controls.documentUrl.updateValueAndValidity();

          this.isEditMode = true;

          this.fileShowButtonEnable();
        } else {
          this.isEditMode = false;
        }
      });
  }
  //#endregion

  submitForm(): void {
    for (const key of Object.keys(this.giveOpinionForm.controls)) {
      this.giveOpinionForm.controls[key].markAsDirty();
      this.giveOpinionForm.controls[key].updateValueAndValidity();
    }
    const formData = new FormData();

    formData.append('documentId', this.documentId.toString());
    formData.append('documentTypeId', this.documentTypeId.toString());
    formData.append(
      'opinionBy',
      this.giveOpinionForm.controls.nameOfPersonProvidingOpinion.value
    );
    formData.append(
      'designation',
      this.giveOpinionForm.controls.designation.value
    );
    formData.append(
      'organization',
      this.giveOpinionForm.controls.organization.value
    );
    formData.append('address', this.giveOpinionForm.controls.address.value);
    formData.append('contactNo', this.giveOpinionForm.controls.contactNo.value);
    formData.append('email', this.giveOpinionForm.controls.email.value);
    formData.append(
      'detailedOpinion',
      this.giveOpinionForm.controls.opinionOrFeedback.value
    );
    if (this.giveOpinionForm.controls.documentUrl.value) {
      formData.append(
        'documentUrl',
        this.giveOpinionForm.controls.documentUrl.value
      );
    }
    if (this.isEditMode) {
      this.pgStorageService.updateOpinion(formData).subscribe({
        next: (response?) => {
          if (response?.success == false) {
            this.notification.warning('Warning!', response.message);
            // this.resetForm();
          } else {
            this.notification.success('Success!', response.message);
            this.isDisableSubmit = false;
            // if (response.data?.documentUrl) {
            //   this.documentFileShow = true;
            // } else {
            //   this.documentFileShow = false;
            // }
          }
        },
        error: (error) => {
          this.notification.error('Failed', error.error.message);
        },
      });
    } else {
      this.pgStorageService.saveOpinion(formData).subscribe({
        next: (response) => {
          if (response?.success) {
            this.notification.success('Success!', response.message);
            this.isDisableSubmit = false;
          }
          // this.resetForm();
        },
        error: (error) => {
          this.notification.error('Failed', error.error.message);
        },
      });
    }
  }

  //#region select document
  onSelectDocument(event: any): void {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedFileExtensions,
      this.maxFormatDetailsFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      this.myInputVariable1.nativeElement.value = '';
      return;
    }
    const file = event.target.files[0];
    const target: DataTransfer = event.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    this.documentFile = file;
    this.documentFileShow = false;
    if (this.documentFile) {
      this.giveOpinionForm.controls.documentUrl.setValue(this.documentFile);
    }
  }
  //#endregion
  //#region image preview functionality
  fileShowButtonEnable(): void {
    if (this.editedItem.documentUrl) {
      this.documentFileShow = true;
    }
  }

  createFileFromBlob(blob: Blob): void {
    if (blob) {
      blob = new Blob([blob], { type: 'application/pdf' });
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(
        URL.createObjectURL(blob) as any
      );
    }
  }

  createImageFromBlob(image: Blob): void {
    if (image) {
      this.imageURL = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(image)
      );
    }
  }

  getImageFromService(imageUrl: string, tag: string): void {
    this.isImageLoading = true;
    this.commonStorageService.getImage(imageUrl).subscribe(
      (data) => {
        if (tag === 'file') {
          this.createFileFromBlob(data);
        } else {
          this.createImageFromBlob(data);
        }

        this.isImageLoading = false;
      },
      (error) => {
        this.isImageLoading = false;
      }
    );
  }

  async setBase64AttachmentforPreviewing(fileName: string) {
    let fileUrl = '';
    switch (fileName) {
      case 'document':
        fileUrl = this.editedItem.documentUrl ?? '';
        break;
    }
    const data = await fetch(environment.fileServiceApiUrl + fileUrl);
    const blob = await data.blob();
    let fileUrlBase64 = URL.createObjectURL(blob);
    let fileType = this.getFileTypeFromPreviousAttachment(fileUrl);
    this.previewAllImage(fileType, fileUrlBase64);
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {
    let contentType = '';
    let basename = fileUrl.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;
    let fileType = basename?.slice(pos + 1) ?? '';
    if (this.imageTypeArray.includes(fileType.toLowerCase())) {
      contentType = 'image';
    } else if (fileType == 'pdf') {
      contentType = 'pdf';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  previewAllImage(fielType: string | undefined, fileUrl: any) {
    this.imageURL = '';
    this.pdfUrl = '';
    if (fielType == 'image') {
      this.isVisible = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else if (fielType == 'pdf') {
      this.isVisible = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', fileUrl);
      link.setAttribute('download', 'file');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
  //#endregion

  //#region reset
  resetForm(): void {
    this.giveOpinionForm.reset();
    this.myInputVariable1.nativeElement.value = '';
  }
  //#endregion

  //#region submit assessment result
  onSubmitDocument() {
    this.pgStorageService.onSubmitDocument(this.documentId).subscribe({
      next: (res) => {
        if (res.success) {
          this.notification.success('Success!', res.message);
          this.router.navigate(['policy-guidelines/document-list']);
        } else {
          this.notification.error('Failed!', res.message);
        }
      },
      error: (error) => {
        this.notification.error('Failed', error);
      },
    });
  }
  //#endregion

  userId() {
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo') || '{}');
    this.requestData.id = this.userInfo.id;
    if (this.userInfo.id) this.contentVisible = true;
  }
}
