import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { CadMeasuringInstrument } from '../../../models/cad-measuring-instrument.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';
import { AddCadMeasuringInstrumentComponent } from '../add-cad-measuring-instrument/add-cad-measuring-instrument.component';

@Component({
  selector: 'app-cad-measuring-instrument-table',
  templateUrl: './cad-measuring-instrument-table.component.html',
  styleUrls: ['./cad-measuring-instrument-table.component.scss']
})
export class CadMeasuringInstrumentTableComponent implements OnInit, OnDestroy {

  // @Input() tamMainId: string | null;
  // @Input() createButton: boolean;

  // modalRef: NzModalRef;

  // cadList: CadMeasuringInstrument[] = [];
  // onCadMeasuringInstrumentSubscription$: Subscription;

  @Input() tamMainId: number | null;
  @Input() createButton: boolean;
  showingAction: boolean | null;

  modalRef: NzModalRef;
  cadList: CadMeasuringInstrument[] = [];
  cadListFiltered: CadMeasuringInstrument[] = [];
  onCadMeasuringInstrumentSubscription$: Subscription;

  page = 1;
  size = 10;
  total: number;

  constructor(
    private modal: NzModalService,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private route: ActivatedRoute
  ) { 
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
    const urlPath = String(this.route.snapshot.url[0].path);
    (urlPath.includes("tam-view")) ? this.showingAction = true : this.showingAction = false;
    this.cadList = this.cadService.getCadMeasuringInstrumentList(this.tamMainId);
    this.cadListFiltered = [...this.cadList];
      if (this.cadList.length === 0) {
        this.cadStorageService.readCadMeasuringInstrumentListByTamId(this.tamMainId,this.page, this.size).subscribe();
      }
      this.onCadMeasuringInstrumentSubscription$ =
        this.cadService.onRefreshCadMeasuringInstrumentList.subscribe(
          (res) => {
            this.cadList = res;
            this.cadListFiltered = [...this.cadList];
          }
        ); 
    }

  ngOnInit(): void {
    this.getCadList();
  }

  getCadList() {
    if(!this.tamMainId) {
      this.notification.error('Error', 'Training and Assessment Material Id not found');
    }

    // this.cadList = this.cadService.getCadMeasuringInstrumentList(Number(this.tamMainId));

    // if(this.cadList.length == 0) {
    //   this.cadStorageService.readCadMeasuringInstrumentListByTamId(Number(this.tamMainId), this.page, this.size).
    //   subscribe(
    //     {
    //             next: (res) => {
    //               this.cadList = res.data;
    //               this.total = res.count;
    //             },
    //           });
    // }

    // this.onCadMeasuringInstrumentSubscription$ = this.cadService.onRefreshCadMeasuringInstrumentList.subscribe(
    //   (res) => {
    //     this.cadList = res.filter(
    //       (item) =>
    //         item.tamMainId == this.tamMainId ||
    //         item.tamMain.id == this.tamMainId
    //     );

    //     if(this.modalRef) {
    //       this.modalRef.close();
    //     }
    //   }
    // );
  }

  createCustomButtonModal(data? : any): void {
    this.modalRef = this.modal.create({
      nzContent: AddCadMeasuringInstrumentComponent,
      nzClosable: false,
      nzOkText: null,
      nzWidth: 1000,
      nzComponentParams: {
        InputData: data
      }
    });
  }

  ngOnDestroy(): void {
      this.onCadMeasuringInstrumentSubscription$.unsubscribe();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params);
    this.page = params.pageIndex;
    this.size = params.pageSize;
    const { pageSize, pageIndex, sort } = params;
    this.getCadList();
  }

  onEdit(id: number): void {
    this.cadService.setEditingCadMeasuringInstrumentId(id);
    this.createCustomButtonModal(id);
  }

  onDelete(id: number): void {
    this.cadStorageService.deleteCadMeasuringInstrument(id).subscribe({
      next: (response) => {
        this.notification.success('Success!', 'Staff deleted successfully');
        this.getCadList();
      },
      error: (error) => this.notification.error('Failed!', error),
    });
  }

}
