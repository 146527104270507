export class CadFurniture {
  public id?: number;
  public furnitureName?: string | null;
  public specification?: string | null;
  public unit?: string | null;
  public minQuantityReq: number;
  public remark?: string | null;
  public tamMainId?: number | null;
  public tamMain?: any;
  constructor() {}
}
