<app-header></app-header>
<main>
  <div class="container-fluid marketing">
    <section class="h-100">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col">
            <div class="card card-registration my-4">
              <div class="row g-0">
                <div class="col-xl-6 d-none d-xl-block">
                  <img src="assets/img/Chloropath-mapcolor.gif" alt="Sample photo" class="img-fluid" style="
                      margin-top: 150px;
                      height: 600px;
                      border-top-left-radius: 0.25rem;
                      border-bottom-left-radius: 0.25rem;
                    " />
                </div>
                <div class="col-xl-6">
                  <div class="card-body p-md-5 text-black">
                    <h3 class="mb-5">
                      Skills Training Provider (STP)<br />
                      &nbsp;&nbsp;&nbsp; User Registration Form
                    </h3>
                    <form [formGroup]="registrationForm" (ngSubmit)="submitForm()">

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form3Example1m"><strong class="required">*</strong> Name of
                          STP</label>
                        <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="stpName">
                            <input type="text" id="form3Example1m" formControlName="stpName" placeholder="Name of STP"
                              class="form-control form-control-lg" (keyup)=" checkStpNameExist($event.target)" />
                            <div *ngIf="stpNameAlredyExist" class="text-danger">
                              <p>This STP name is already registered. Please use different
                                STP name</p>
                            </div>
                            <ng-template #stpName let-control>
                              <ng-container *ngIf="control.hasError('pattern') || control.hasError('whitespace')">
                                Please input valid STP name!!
                              </ng-container>
                              <!-- <ng-container *ngIf="control.hasError('whitespace')">
                                Please input valid STP name!
                              </ng-container> -->
                            </ng-template>
                          </nz-form-control>
                        </nz-form-item>

                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form3Example1n"><strong class="required">*</strong> Name of User (Only english with no special characters) </label>
                        <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="stpUser">
                            <input type="text" id="form3Example1n" formControlName="stpAuthorizeName"
                              placeholder="Name of User" class="form-control form-control-lg" />
                            <ng-template #stpUser let-control>
                              <ng-container *ngIf="control.hasError('required')">
                                Please input user name!
                              </ng-container>
                            </ng-template>
                          </nz-form-control>
                        </nz-form-item>

                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form3Example1m1"><strong class="required">*</strong>STP User
                          NID</label>
                        <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="nidErrorTpl">
                            <input type="text" id="form3Example1m1" formControlName="nid" placeholder="NID"
                              class="form-control form-control-lg" />
                            <ng-template #nidErrorTpl let-control>
                              <ng-container *ngIf="control.hasError('required')">
                                Please input your NID!
                              </ng-container>
                              <ng-container *ngIf="control.hasError('pattern')">
                                <div>NID must be 10 or 13 or 17 digits</div>
                              </ng-container>
                            </ng-template>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form3Example1n1"><strong class="required">*</strong>
                          Designation</label>
                        <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="designation">
                            <input type="text" id="form3Example1n1" formControlName="stpDesignation"
                              placeholder="Designation" class="form-control form-control-lg" />
                            <ng-template #designation let-control>
                              <ng-container *ngIf="control.hasError('required')">
                                Please input designation!
                              </ng-container>
                            </ng-template>
                          </nz-form-control>
                        </nz-form-item>

                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form3Example9"><strong class="required">*</strong> E-mail</label>
                        <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="email">
                            <input type="text" id="form3Example9" formControlName="email" placeholder="E-mail"
                              (keyup)=" checkEmailExist($event.target)" class="form-control form-control-lg" />
                          </nz-form-control>
                          <div *ngIf="emailAlredyExist" class="text-danger">
                            <p>This E-mail is already registered. Please use different E-mail</p>
                          </div>

                          <ng-template #email let-control>
                            <ng-container *ngIf="control.hasError('required')">
                              Please input your E-mail !
                            </ng-container>
                          </ng-template>

                          <ng-template #email let-control>
                            <ng-container *ngIf="control.hasError('incorrect')">
                              Please input your E-mail 1!
                            </ng-container>
                          </ng-template>

                        </nz-form-item>

                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form3Example9"><strong class="required">*</strong>
                          Password</label>
                        <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                            <nz-input-group [nzSuffix]="suffixTemplatePassword">
                              <input nz-input [type]="passwordVisible ? 'text' : 'password'" id="form3Example9" formControlName="password" placeholder="Password"
                              (ngModelChange)="validateConfirmPassword()" class="form-control form-control-lg" />
                            </nz-input-group>
                          </nz-form-control>
                          <ng-template #passwordErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                              Please input your password!
                            </ng-container>
                            <ng-container *ngIf="control.hasError('minlength')">
                              Password minimum 8 digit
                            </ng-container>
                            <ng-container *ngIf="control.hasError('pattern')">
                              Your password must contain at least one uppercase, one lowercase, one special character and one number
                            </ng-container>
                          </ng-template>
                        </nz-form-item>

                        <ng-template #suffixTemplatePassword>
                          <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                        </ng-template>
                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form3Example9"><strong class="required">*</strong> Confirm
                          Password</label>
                        <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                            <nz-input-group [nzSuffix]="suffixTemplateConfirmPassword">
                              <input nz-input [type]="confirmPasswordVisible ? 'text' : 'password'" id="form3Example9" formControlName="confirmPassword"
                              placeholder="Confirm Password" class="form-control form-control-lg" />
                            </nz-input-group>
                            <ng-template #passwordErrorTpl let-control>
                              <ng-container *ngIf="control.hasError('required')">
                                Please confirm your password!
                              </ng-container>
                              <ng-container *ngIf="control.hasError('confirm')">
                                Password is inconsistent!
                              </ng-container>
                            </ng-template>
                          </nz-form-control>
                        </nz-form-item>

                        <ng-template #suffixTemplateConfirmPassword>
                          <i nz-icon [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'" (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                        </ng-template>
                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form3Example9"><strong class="required">*</strong> Contact
                          Number</label>
                        <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="contactNumberErrorTpl">
                            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                              <ng-template #addOnBeforeTemplate>
                                <nz-select formControlName="contactNumberPrefix" class="phone-select">
                                  <!-- <nz-option nzLabel="+966" nzValue="+966"></nz-option> -->
                                  <nz-option nzLabel="+880" nzValue="+880"></nz-option>
                                </nz-select>
                              </ng-template>
                              <input formControlName="contactNumber" id="'contactNumber'" placeholder="Contact Number"
                                nz-input class="form-control form-control-lg" />
                            </nz-input-group>
                            <ng-template #contactNumberErrorTpl let-control>
                              <ng-container *ngIf="control.hasError('required')">
                                Please input your contact number!
                              </ng-container>
                              <ng-container *ngIf="control.hasError('maxlength')">
                                Max length reached
                              </ng-container>
                              <ng-container *ngIf="control.hasError('minlength')">
                                Contact number must be 10 digit
                              </ng-container>
                              <ng-container *ngIf="control.hasError('pattern')">
                                <div>Only valid digits are allowed</div>
                              </ng-container>
                            </ng-template>
                          </nz-form-control>
                        </nz-form-item>

                      </div>

                      <div class="d-flex justify-content-center pt-3">
                        <!-- <button
                          type="primary"
                          routerLink="/login"
                          class="btn btn-light btn-lg"
                        >
                          Back
                        </button> -->
                        <button class="btn btn-danger btn-lg ms-2" (click)="resetForm($event)" nzDanger type="primary">
                          Reset
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button type="button" class="btn btn-primary" (click)="submitForm()"
                          [disabled]="!registrationForm.valid">
                          Sign Up
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- for Otp -->
                <div>
                  <nz-modal [(nzVisible)]="isVisible" nzTitle="Please submit your OTP within 5 mintues"
                    (nzOnCancel)="handleCancel()">
                    <ng-container *nzModalContent>
                      <form nz-form [formGroup]="otpForm" (ngSubmit)="submitOtp()">
                        <nz-form-item>
                          <nz-form-label [nzSpan]="6">OTP</nz-form-label>
                          <nz-form-control [nzSpan]="14" [nzErrorTip]="contactNumberErrorTpl">
                            <input nz-input formControlName="otp" type="text" placeholder="Enter 5 digit otp" />
                          </nz-form-control>
                          <ng-template #contactNumberErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                              Please input your OTP!
                            </ng-container>
                            <ng-container *ngIf="control.hasError('pattern')">
                              OTP Number Must be 5 digits
                            </ng-container>
                          </ng-template>
                          <div class = "ml-3">
                            <button nz-button nzType="primary" nzShape="round" [disabled]="!otpForm.valid">
                              Submit
                            </button>
                          </div>
                        </nz-form-item>
                      </form>
                      <div class="d-flex justify-content-center" style="opacity: 0.4;">
                        <label>OTP send within:  </label>
                        <label>{{display}}</label>
                      </div>

                    </ng-container>
                    <div *nzModalFooter style="display: none"></div>
                  </nz-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</main>
<app-footer></app-footer>
