import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompetencyStandardService } from './competency-standard.service';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { CompetencyStandard } from '../models/competency-standard.model';
import { Observable } from 'rxjs';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompetencyStandardStorageService {

  constructor(
    private competencyStandardService: CompetencyStandardService,
    private httpClient: HttpClient,
  ) { }

  createCsGeneric(data: CompetencyStandard): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.csGeneric.create}`,
        data
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.competencyStandardService.addCsGeneric(
              res.data
            );
          }
        })
      );
  }

  readCsGenericListByTamId(tamMainId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.csGeneric.readByTamId}` + tamMainId)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((csList: CompetencyStandard[]) => {
          this.competencyStandardService.setCsGenericList(csList);
        })
      );
  }

  updateCsGeneric(data: CompetencyStandard, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.csGeneric.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.competencyStandardService.upatedCsGeneric(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
  

  deleteCsGeneric(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.csGeneric.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((deletedStpManagement) => {
          this.competencyStandardService.deleteCsGeneric(id);
        })
      );
  }


  createCsSectorSpecific(data: CompetencyStandard): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.csSepcificSector.create}`,
        data
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.competencyStandardService.addCsSectorSpecific(
              res.data
            );
          }
        })
      );
  }

  readCsSectorSpecificListByTamId(tamMainId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.csSepcificSector.readByTamId}` + tamMainId)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((csList: CompetencyStandard[]) => {
          this.competencyStandardService.setCsSectorSpecificList(csList);
        })
      );
  }

  
  updateCsSectorSpecific(data: CompetencyStandard, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.csSepcificSector.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.competencyStandardService.upatedCsSectorSpecific(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  
  deleteCsSectorSpecific(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.csSepcificSector.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((deleteStpManagement) => {
          this.competencyStandardService.deleteCsSectorSpecific(id);
        })
      );
  }

  createCsOccupationSpecific(data: CompetencyStandard): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.csSpecificOccupation.create}`,
        data
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.competencyStandardService.addCsOccupationSpecific(
              res.data
            );
          }
        })
      );
  }

  readCsOccupationSpecificListByTamId(tamMainId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.csSpecificOccupation.readByTamId}` + tamMainId)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((csList: CompetencyStandard[]) => {
          this.competencyStandardService.setCsOccupationSpecificList(csList);
        })
      );
  }

   
  deleteCsOccupationSpecific(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.csSpecificOccupation.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((deleteStpManagement) => {
          this.competencyStandardService.deleteCsOccupationSpecific(id);
        })
      );
  }

    
  updateCsOccupationSpecific(data: CompetencyStandard, id: number): Observable<ServerResponse> {
    
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.csSpecificOccupation.update}` + id, data)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.competencyStandardService.upatedCsOccupationSpecific(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  

//#region read cs count
readCSCount(): Observable<any> {
  return this.httpClient
    .get<ServerResponse>(`${applicationUrls.landingPageCounter.readCSCount}`)
    .pipe(
      map((serverResponse: ServerResponse) => {
        return serverResponse.data;
      })
    );
}
//#endregion

}
