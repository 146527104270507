<app-header></app-header>
<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Research & Studies
    Details
  </div>
  <nz-table
    #basicTable
    [nzData]="['']"
    nzBordered
    [nzShowPagination]="false"
    nzAlign="middle"
  >
    <tbody *ngIf="researchStudiesMainInfo">
      <tr>
        <td class="p-10 bold">Research Type:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.researchType?.name }}
        </td>
        <td class="p-10 bold">Related Research Type:</td>
        <td class="p-10">
          <ng-container
            *ngFor="
              let relatedType of researchStudiesMainInfo?.relatedResearchTypeList;
              let last = last
            "
          >
            {{ relatedType?.researchType?.name }}{{ last ? "" : ", " }}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Title:</td>
        <td class="p-10">{{ researchStudiesMainInfo?.title }}</td>
        <td class="p-10 bold">Abstract:</td>
        <td class="p-10">{{ researchStudiesMainInfo?.abstracts }}</td>
      </tr>
      <tr>
        <td class="p-10 bold">Area of Scope:</td>
        <td class="p-10">{{ researchStudiesMainInfo?.areaOfScope }}</td>
        <td class="p-10 bold">Outcome:</td>
        <td class="p-10">{{ researchStudiesMainInfo?.outcome }}</td>
      </tr>

      <tr>
        <td class="p-10 bold">Future Scope:</td>
        <td class="p-10">{{ researchStudiesMainInfo?.futureScope }}</td>
        <td class="p-10 bold">Conducted by:</td>
        <td class="p-10">{{ researchStudiesMainInfo?.conductedByName }}</td>
      </tr>

      <tr>
        <td class="p-10 bold">Funded By:</td>
        <td class="p-10">{{ researchStudiesMainInfo?.fund?.name }}</td>
        <td class="p-10 bold">Fund Description:</td>
        <td class="p-10">{{ researchStudiesMainInfo?.fundDescription }}</td>
      </tr>
      <tr>
        <td class="p-10 bold">Researcher Organization:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.researcherOrganizationName }}
        </td>
        <td class="p-10 bold">Objective:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.objectives }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Supported By:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.supportedBy }}
        </td>
        <td class="p-10 bold">Report Language:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.language?.name }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Research Start Date:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.researchFromDate | date : "dd-MM-YYYY" }}
        </td>
        <td class="p-10 bold">Research End Date:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.researchToDate | date : "dd-MM-YYYY" }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Validation Conducted:</td>
        <td class="p-10">
          {{
            researchStudiesMainInfo?.isValidationConducted == true
              ? "Yes"
              : "No"
          }}
        </td>
        <td class="p-10 bold">Date of Validation:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.dateOfValidation | date : "dd-MM-YYYY" }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Date of Publication:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.dateOfPublication | date : "dd-MM-YYYY" }}
        </td>
        <td class="p-10 bold">Specific to Any Sector:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.isSectorSpecify == true ? "Yes" : "No" }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Name of Sector:</td>
        <td class="p-10">
          <ng-container
            *ngFor="
              let sectors of researchStudiesMainInfo?.sectorDetails;
              let last = last
            "
          >
            {{ sectors?.sectorName }}{{ last ? "" : ", " }}
          </ng-container>
        </td>
        <td class="p-10 bold">Access Type:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.accessType?.name }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Remarks:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.remarks }}
        </td>
        <td class="p-10 bold">Open for Collecting Opinion:</td>
        <td class="p-10">
          {{
            researchStudiesMainInfo?.isOpinionOpenToCollect == true
              ? "Yes"
              : "No"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Opinion Start Date:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.opinionStartDate | date : "dd-MM-YYYY" }}
        </td>
        <td class="p-10 bold">Opinion End Date:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.opinionEndDate | date : "dd-MM-YYYY" }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Related Research:</td>
        <td class="p-10">
          {{ researchStudiesMainInfo?.relatedResearch }}
        </td>
        <td class="p-10 bold">Related Research Details:</td>
        <td class="p-10">
          {{
            researchStudiesMainInfo?.relatedResearch == "external"
              ? researchStudiesMainInfo?.relatedResearchDetails
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td class="p-10 bold">Other Fund:</td>
        <td class="p-10">
          {{
            researchStudiesMainInfo?.fund?.id == 3
              ? researchStudiesMainInfo?.otherFund
              : "N/A"
          }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>
<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Attachment Info
  </div>
  <div nz-col [nzSpan]="24">
    <nz-table
      #basicTableOfAttachment
      [nzData]="attachmentList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzSize="middle"
      nzAlign="middle"
      [nzShowTotal]="totalRowRangeTemplateAttachments"
      [(nzPageSize)]="pageSizeAttachment"
      [(nzPageIndex)]="currentPageAttachment"
      [nzShowPagination]="true"
      [nzFrontPagination]="true"
    >
      <ng-template
        #totalRowRangeTemplateAttachments
        let-range="range"
        let-total
        style="text-align: left"
      >
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ totalAttachment }} items
        </div>
      </ng-template>
      <thead>
        <tr>
          <th style="text-align: center; vertical-align: middle; width: 5%">
            SI.
          </th>
          <th style="text-align: center; vertical-align: middle">File Name</th>
          <th style="text-align: center; vertical-align: middle">Preview</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of basicTableOfAttachment.data; let i = index">
          <td style="text-align: center; vertical-align: middle">
            <nz-form-control style="text-align: center; vertical-align: middle">
              {{ i + 1 }}
            </nz-form-control>
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ item?.fileName }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            <button
              nz-button
              type="button"
              nzType="default"
              [nzSize]="'small'"
              (click)="setBase64AttachmentforPreviewing('documents', i)"
            >
              <i nz-icon nzType="eye" nzTheme="fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>
<nz-card *ngIf="this.authorList.length > 0">
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Author Info
  </div>
  <div nz-col [nzSpan]="24">
    <nz-table
      #basicTableAthor
      [nzData]="authorList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzSize="middle"
      nzAlign="middle"
      [nzShowTotal]="totalRowRangeTemplateAuthor"
      [(nzPageSize)]="pageSizeAuthor"
      [(nzPageIndex)]="currentPageAuthor"
      [nzShowPagination]="true"
      [nzFrontPagination]="true"
    >
      <ng-template
        #totalRowRangeTemplateAuthor
        let-range="range"
        let-total
        style="text-align: left"
      >
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ totalAuthor }} items
        </div>
      </ng-template>
      <thead>
        <tr>
          <th style="width: 50px; text-align: center" rowspan="1">SI.</th>
          <th style="text-align: center" rowspan="1">Name</th>
          <th style="text-align: center" rowspan="1">Designation</th>
          <th style="text-align: center" rowspan="1">Organization</th>
          <th style="text-align: center" rowspan="1">Email</th>
          <th style="text-align: center" rowspan="1">Contact No</th>
          <th style="text-align: center" rowspan="1">Gender</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTableAthor.data; let i = index">
          <td style="text-align: center">
            {{ 1 + i }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.name }}
          </td>

          <td style="text-align: center; vertical-align: middle">
            {{ data.designation }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.organization }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.email }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.contactNo }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data?.gender == "male" ? "Male" : "Female" }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>

<nz-card *ngIf="this.relatedResearchList.length > 0">
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Related Research
    Info
  </div>
  <div nz-col [nzSpan]="24">
    <nz-table
      #basicTableRelatedResearch
      [nzData]="relatedResearchList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzSize="middle"
      nzAlign="middle"
      [nzShowTotal]="totalRowRangeTemplateRr"
      [(nzPageSize)]="pageSizeRr"
      [(nzPageIndex)]="currentPageRr"
      [nzShowPagination]="true"
      [nzFrontPagination]="true"
    >
      <ng-template
        #totalRowRangeTemplateRr
        let-range="range"
        let-total
        style="text-align: left"
      >
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ totalAuthor }} items
        </div>
      </ng-template>
      <thead>
        <tr>
          <th style="width: 50px; text-align: center" rowspan="1">SI.</th>
          <th style="text-align: center" rowspan="1">Name</th>
          <th style="text-align: center" rowspan="1">Title</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTableRelatedResearch.data; let i = index">
          <td style="text-align: center">
            {{ 1 + i }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.documentId }}
          </td>

          <td style="text-align: center; vertical-align: middle">
            {{ data.title }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>
<nz-card *ngIf="this.demandSurveyList.length > 0">
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> List of Demand
    Survey
  </div>
  <div nz-col [nzSpan]="17">
    <nz-table
      #basicTable3
      [nzData]="demandSurveyList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzSize="middle"
      nzAlign="middle"
      [nzFrontPagination]="false"
      [nzScroll]="{ x: '2000px' }"
    >
      <thead>
        <tr>
          <th style="width: 70px; text-align: center">SI.</th>
          <th class="th-Class">Name of Occupation</th>
          <th class="th-Class">Industrial Sector</th>
          <th class="th-Class">Sub Sector</th>

          <th class="th-Class">Skill Level</th>
          <th class="th-Class">Equvalent to BNQF level</th>

          <th class="th-Class">Monthly Average Wage Level</th>
          <th class="th-Class">Required Minimum Education Level</th>
          <th class="th-Class">Potential to employ females</th>

          <th class="th-Class">
            Potential to Employ PWD (persons with disability)
          </th>
          <th class="th-Class">Potential to Employ Other Gender</th>
          <th class="th-Class">Current Vacant Positions</th>

          <th class="th-Class">Projected Demand (in next year)</th>
          <th class="th-Class">Projected Demand (in next 3 years)</th>
          <th class="th-Class">Projected Demand (in next 5 years)</th>

          <th class="th-Class">Projected Demand (in next 10 years)</th>
          <th class="th-Class">Details of Required Competencies</th>
          <th class="th-Class">Other Competencies</th>
          <th class="th-Class">Remarks</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of basicTable3.data; let i = index">
          <td class="td-Class">{{ i + 1 }}</td>
          <td class="td-Class">{{ item.occupationName }}</td>
          <td class="td-Class">{{ item.tamIndustrialSectorName }}</td>
          <td class="td-Class">{{ item.subSectorName }}</td>
          <td class="td-Class">{{ item.skillLevel?.name }}</td>
          <td class="td-Class">{{ item.bnqfLevel?.levelNo }}</td>
          <td class="td-Class">{{ item.monthlyAverageWageLevel }}</td>
          <td class="td-Class">{{ item.educationLevel?.name }}</td>
          <td class="td-Class">{{ item.potentialToEmployFemales?.name }}</td>
          <td class="td-Class">{{ item.potentialToEmployPwd?.name }}</td>
          <td class="td-Class">
            {{ item.potentialToEmployOtherGender?.name }}
          </td>
          <td class="td-Class">{{ item.currentVacantPosition }}</td>
          <td class="td-Class">{{ item.projectedDemandInNextYear }}</td>
          <td class="td-Class">{{ item.projectedDemandInNext3Years }}</td>
          <td class="td-Class">{{ item.projectedDemandInNext5Years }}</td>

          <td class="td-Class">{{ item.projectedDemandInNext10Years }}</td>
          <td class="td-Class">{{ item.competencies?.name }}</td>
          <td class="td-Class">{{ item.otherCompetencies }}</td>
          <td class="td-Class">{{ item.remarks }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>
<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzClosable]="false"
  [nzOkText]="null"
  [nzWidth]="1200"
>
  <ng-container *nzModalContent>
    <iframe
      *ngIf="!isImageLoading && pdfUrl"
      [src]="pdfUrl"
      style="width: 100%; height: 35vw"
    ></iframe>
    <div style="text-align: center">
      <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
      <ng-template #noImageFound>
        <img
          src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
          alt="Fallbackimage"
        />
      </ng-template>
    </div>
  </ng-container>
</nz-modal>
<app-footer></app-footer>
