<header *ngIf="previewBackground">
    <div class="container-fluid d-flex justify-content-between bd-highlight">
      <div class="p-2 bd-highlight">
        <div class="d-flex flex-row">
          <div>
            <img src="assets/images/gov-modify.png" />
          </div>
          <div>
            <a href="#">
              <img src="assets/images/Group 98.png" style="margin-left: 20px" />
            </a>
          </div>
        </div>
      </div>
      <div class="header-content text-wrap">
        National Skills Development Authority (NSDA), Chief Adviser's Office,
        Bangladesh
      </div>
      <div>
        <!-- <img
          src="assets/images/1200px-Mujib_100_Logo.svg.png"
          style="width: 90px"
        /> -->
      </div>
    </div>
  </header>
  <div class="card" *ngIf="previewBackground">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> Inspection Preview
    </div>
    <div class="searchboxAerar p-3">
      <form nz-form class="ant-advanced-search-form" (ngSubmit)="submitForm()">
        <div>
          <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24 }">
            <div class="col-md-4" style="line-height: 35px">
              <p>
                <strong class="pr-2">Application ID:</strong> {{ applicationId }}
              </p>
              <p><strong class="pr-2">Name of STP/SAC:</strong> {{ name }}</p>
              <p>
                <strong class="pr-2">Inspection conducted by-3:</strong
                >{{ officer2Name }}
              </p>
            </div>
            <div class="col-md-4" style="line-height: 35px">
              <p>
                <strong class="pr-2">Date of Application:</strong
                >{{ dateOfApplication | date: "dd/MM/yyyy" }}
              </p>
              <p>
                <strong class="pr-2">Inspection conducted by-1:</strong
                >{{ nsdaOfficerName }}
              </p>
              <p>
                <strong class="pr-2">Inspection conducted by-4:</strong
                >{{ officer3Name }}
              </p>
            </div>
            <div class="col-md-4" style="line-height: 35px">
              <p><strong class="pr-2">STP/SAC ID:</strong> {{ stpOrSacId }}</p>
              <p>
                <strong class="pr-2">Inspection conducted by-2:</strong
                >{{ officer1Name }}
              </p>
              <p>
                <strong class="pr-2">Date of Inspection:</strong
                >{{ dateOfInspection }}
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <div style="margin-top: 5px">
            <b>List of representatives</b>
          </div>
        </div>
        <hr />
        <div nz-col [nzSpan]="24" class="mt-3">
          <nz-table
            #inspectionPreview
            [nzData]="representativeOfficerList"
            nzSize="small"
            nzShowSizeChanger
            nzBordered
            nzAlign="middle"
            [nzFrontPagination]="false"
            [nzShowPagination]="false"
            class="css-serial table-striped table"
          >
            <thead>
              <tr>
                <th>SI.</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Contact Number</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of inspectionPreview.data; let i = index">
                <td>{{ 1 + i }}</td>
                <td>{{ data.name }}</td>
                <td>{{ data.designation }}</td>
                <td>{{ data.phone }}</td>
                <td>{{ data.email }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <hr />
        <b style="margin-left: 10px">Inspection Reports</b>
        <hr />
        <div nz-col [nzSpan]="24">
          <nz-table
            #detailedInspectionReport
            [nzData]="listTamOccupation"
            nzTableLayout="fixed"
            nzBordered
            [nzFrontPagination]="false"
            [nzShowPagination]="false"
            nzSize="middle"
            nzAlign="middle"
          >
            <thead>
              <tr>
                <th
                  style="text-align: center; vertical-align: middle"
                  rowspan="2"
                >
                  SI.
                </th>
                <th
                  style="text-align: center; width: 25%; vertical-align: middle"
                  rowspan="2"
                  nzColumnKey=""
                >
                  Application for
                </th>
                <th
                  style="text-align: center; width: 25%; vertical-align: middle"
                  rowspan="2"
                  nzColumnKey=""
                >
                  Application Type
                </th>
                <th
                  style="text-align: center; vertical-align: middle"
                  rowspan="2"
                >
                  Action
                </th>
                <th colspan="4">Report Submitted by</th>
              </tr>
              <tr>
                <th
                  style="text-align: center; vertical-align: middle"
                  rowspan="2"
                >
                  Assigned officer-1?
                </th>
                <th
                  style="text-align: center; vertical-align: middle"
                  rowspan="2"
                >
                  Assigned officer-2?
                </th>
                <th
                  style="text-align: center; vertical-align: middle"
                  rowspan="2"
                >
                  Assigned officer-3?
                </th>
                <th
                  style="text-align: center; vertical-align: middle"
                  rowspan="2"
                >
                  Assigned officer-4?
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let data of detailedInspectionReport.data; let i = index"
              >
                <td style="text-align: center">{{ 1 + i }}</td>
                <td>
                  {{ data.occupationName }}
                </td>
                <td>{{ data.applicationType }}</td>
                <td style="text-align: center; vertical-align: middle">
                  <div *ngIf="data.type == 'Occupation'">
                    <button
                      type="button"
                      (click)="onView(data.inspectionResultId)"
                      nz-button
                      nzType="default"
                      [nzSize]="'small'"
                    >
                      <i nz-icon nzType="eye" nzTheme="fill"></i>
                    </button>
                  </div>

                  <div *ngIf="data.type == 'STP'">
                    <button
                      type="button"
                      (click)="onViewStpSac(inspectionId)"
                      nz-button
                      nzType="default"
                      [nzSize]="'small'"
                    >
                      <i nz-icon nzType="eye" nzTheme="fill"></i>
                    </button>
                  </div>
                </td>
                <td style="text-align: center; vertical-align: middle">
                  {{ completeInspectionList.isNsdaApproved ? "Yes" : "No" }}
                </td>
                <td style="text-align: center; vertical-align: middle">
                  {{ completeInspectionList.isOfficer1Approved ? "Yes" : "No" }}
                </td>
                <td style="text-align: center; vertical-align: middle">
                  {{ completeInspectionList.isOfficer2Approved ? "Yes" : "NA" }}
                </td>
                <td style="text-align: center; vertical-align: middle">
                  {{ completeInspectionList.isOfficer3Approved ? "Yes" : "NA" }}
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <p class="note pt-3">
          <input
            type="checkbox"
            name="active"
            (click)="checkbox($event)"
            style="width: 18px; height: 18px"
          />
          <span style="font-size: 15px; color: red">
            <span class="mandatory">*</span>The above informations are correct!
          </span>
        </p>
        <div class="row justify-content-center media-button pt-3">
          <div class="col-sm-5 col-md-2 col-xs-2">
            <button
              type="submit"
              class="btn btn-success active btn-lg btn-block cari border-redius"
              [disabled]="!submissionAttempted"
            >
              <i nz-icon nzType="save" nzTheme="fill"></i> Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- for Otp -->
  <div>
    <nz-modal
      [(nzVisible)]="isVisible"
      nzTitle="Please submit your OTP within 5 mintues"
      (nzOnCancel)="handleCancel()"
    >
      <ng-container *nzModalContent>
        <form nz-form [formGroup]="otpForm" (ngSubmit)="submitOtp()">
          <nz-form-item>
            <nz-form-label [nzSpan]="6">OTP</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="contactNumberErrorTpl">
              <input
                nz-input
                formControlName="otp"
                type="text"
                placeholder="Enter 5 digit otp"
              />
            </nz-form-control>
            <ng-template #contactNumberErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Please input your OTP!
              </ng-container>
              <ng-container *ngIf="control.hasError('pattern')">
                OTP Number Must be 5 digits
              </ng-container>
            </ng-template>
            <div class="ml-3">
              <button nz-button nzType="primary" nzShape="round">Submit</button>
            </div>
          </nz-form-item>
        </form>
        <div class="d-flex justify-content-center" style="opacity: 0.4">
          <label>OTP send within : </label>
          <label>{{ display }}</label>
        </div>
      </ng-container>
      <div *nzModalFooter style="display: none"></div>
    </nz-modal>
  </div>
