<nz-layout class="app-layout">
  <nz-sider
    style="background: white"
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="container-fluid d-flex justify-content-between bd-highlight">
      <div class="p-2 bd-highlight">
        <div class="d-flex flex-row">
          <div>
            <a href="#">
              <img src="assets/images/Group 98.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nz-menu-item>
        <span nz-icon nzType="home"></span>
        <span routerLink="/">Home</span>
      </li>

      <li
        nz-submenu
        nzClose
        nzTitle="Authorization"
        nzIcon="lock"
        [appRequiredPermission]="
          applicationPermissions.authorization.role.menuPermission +
          ',' +
          applicationPermissions.authorization.projectModule.menuPermission +
          ',' +
          applicationPermissions.authorization.permission.menuPermission +
          ',' +
          applicationPermissions.authorization.role.rolePermission
            .menuPermission +
          ',' +
          applicationPermissions.authorization.featurePermission
            .menuFeaturePermission
        "
      >
        <ul>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.authorization.role.menuPermission
            "
          >
            <a routerLink="/home/authorization/role"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Role</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.authorization.projectModule.menuPermission
            "
          >
            <a routerLink="/home/authorization/project-module"
              ><i nz-icon nzType="project" nzTheme="outline"></i>Project
              Module</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.authorization.permission.menuPermission
            "
          >
            <a routerLink="/home/authorization/permission"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Permission</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.authorization.role.rolePermission
                .menuPermission
            "
          >
            <a routerLink="/home/authorization/role/permission"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Role Permission</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.authorization.featurePermission
                .menuFeaturePermission
            "
          >
            <a routerLink="/home/authorization/feature-permission"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Feature
              Permission</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzTitle="User"
        nzIcon="user"
        [appRequiredPermission]="
          applicationPermissions.user.userList.menuPermission +
          ',' +
          applicationPermissions.user.userList.menuCreateUserPermission
        "
      >
        <ul>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.user.userList.menuPermission
            "
          >
            <a routerLink="/home/user"
              ><i nz-icon nzType="user" nzTheme="outline"></i>User List</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.user.userList.menuCreateUserPermission
            "
          >
            <a routerLink="/home/user/create"
              ><i nz-icon nzType="user" nzTheme="outline"></i>Create User</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzIcon="appstore"
        nzTheme="outline"
        nzClose
        nzTitle="STP Application"
        [appRequiredPermission]="
          applicationPermissions.stpRegistration.stpApplication
            .menuListPermission
        "
      >
        <ul>
          <li nz-menu-item>
            <a routerLink="/home/stp-registration/stp-applications"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
              >Application List</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="snippets"
        nzTheme="outline"
        nzTitle="TAM"
        [appRequiredPermission]="
          applicationPermissions.tam.tam.menuListPermission +
          ',' +
          applicationPermissions.tam.archive.menuListPermission +
          ',' +
          applicationPermissions.tam.occupation.menuListPermission +
          ',' +
          applicationPermissions.tam.tam.menuCreatePermission +
          ',' +
          applicationPermissions.tam.occupation.menuCreatePermission
        "
      >
        <ul>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.tam.tam.menuListPermission
            "
          >
            <a routerLink="/home/training-and-assessment-materials/tam-list"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>TAM
              List</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.tam.archive.menuListPermission
            "
          >
            <a
              routerLink="/home/training-and-assessment-materials/tam-archive-list"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>TAM
              Archive List</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.tam.occupation.menuListPermission
            "
          >
            <a
              routerLink="/home/training-and-assessment-materials/occupation-list"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>Occupation
              List
            </a>
          </li>

          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.tam.tam.menuCreatePermission
            "
          >
            <a routerLink="/home/training-and-assessment-materials/tam-create"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Add TAM</a
            >
          </li>

          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.tam.occupation.menuCreatePermission
            "
          >
            <a
              routerLink="/home/training-and-assessment-materials/occupation-create"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Add Occupation
            </a>
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzIcon="safety-certificate"
        nzTheme="outline"
        nzClose
        nzTitle="Certificate"
        [appRequiredPermission]="applicationPermissions.tci.menuPermission"
      >
        <ul>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.tci.tciList
                .menuUploadCertificateListPermission
            "
          >
            <a routerLink="/home/tci/competent-person-list"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>Upload
              Certificate List</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="applicationPermissions.tci.menuSendEmail"
          >
            <a routerLink="/home/tci/send-email"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>Send
              Email</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzIcon="reconciliation"
        nzTheme="outline"
        nzClose
        nzTitle="Course Accreditation"
        [appRequiredPermission]="
          applicationPermissions.courseAccreditation.courseAccreditation
            .menuApplyForCourseAccreditation +
          ',' +
          applicationPermissions.courseAccreditation.courseAccreditationList
            .menuAppliedForCourseList +
          ',' +
          applicationPermissions.courseAccreditation.courseAccreditationList
            .menuPermission +
          ',' +
          applicationPermissions.courseAccreditation.courseAccreditationList
            .menuNsdaPermission +
          ',' +
          applicationPermissions.settings.inspectionOfficer.menuPermission +
          ',' +
          applicationPermissions.stpOperation.rollback.rollbackStatus.menuPermission
        "
      >
        <ul>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.courseAccreditation.courseAccreditation
                .menuApplyForCourseAccreditation
            "
          >
            <a routerLink="/home/course-accreditation/course-accreditation"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Apply for Course
              Accreditation</a
            >
          </li>

          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.courseAccreditation.courseAccreditationList
                .menuAppliedForCourseList
            "
          >
            <a routerLink="/home/course-accreditation/course-accreditation-list"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>Applied
              Course List</a
            >
          </li>

          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.courseAccreditation.courseAccreditationList
                .menuPermission
            "
          >
            <a
              routerLink="/home/course-accreditation/course-accreditation-admin-list"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Course
              Accreditation List</a
            >
          </li>
          <li
            *ngIf="this.userInfo.tag == 'nsda'"
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.courseAccreditation.courseAccreditationList
                .menuNsdaPermission
            "
          >
            <a
              routerLink="/home/course-accreditation/course-accreditation-inspector-list"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Course
              Accreditation NSDA List</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.settings.inspectionOfficer.menuPermission
            "
          >
            <a routerLink="/home/stp-registration/inspection-officer-list">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Inspection
              Officer (ISC) List</a
            >
          </li>
          <li nz-menu-item
            [appRequiredPermission]="applicationPermissions.stpOperation.rollback.rollbackStatus.menuPermission">
            <a routerLink="/home/rollback/rollback-status"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Rollback Status</a>
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzIcon="application"
        nzClose
        nzIcon="money-collect"
        nzTitle="Payment"
        [appRequiredPermission]="applicationPermissions.payment.menuPermission"
      >
        <ul>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.payment.menuPermission
            "
          >
            <a routerLink="/home/payment/payment-list"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>Payment
              List</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.payment.paymentList.menuGatewayPermission
            "
          >
            <a href="https://admin.ekpay.gov.bd/"
              ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>Payment
              Gateway</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzTitle="Profile View"
        nzIcon="profile"
        [appRequiredPermission]="
          applicationPermissions.stpRegistration.stpApplicationView
            .menuEditPermission +
          ',' +
          applicationPermissions.stpRegistration.stpApplicationView
            .menuFullViewPermission +
          ',' +
          applicationPermissions.stpRegistration.stpApplicationView
            .menuStpDownloadCertificate +
          ',' +
          applicationPermissions.stpRegistration.stpApplicationView
            .menuUpdateContactInformation
        "
      >
        <ul>
          <li
            nz-menu-item
            *ngIf="!isComplete"
            [appRequiredPermission]="
              applicationPermissions.stpRegistration.stpApplicationView
                .menuEditPermission
            "
          >
            <a routerLink="/home/stp-registration/dash-board"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Edit
              Application</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.stpRegistration.stpApplicationView
                .menuFullViewPermission
            "
          >
            <a
              [routerLink]="[
                '/home/stp-registration/stp-application-view/',
                stpMainId
              ]"
            >
              <span nz-icon nzType="profile" nzTheme="outline"></span>Full
              Application View</a
            >
          </li>

          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.stpRegistration.stpApplicationView
                .menuStpDownloadCertificate
            "
          >
            <a (click)="showModal()"
              ><span nz-icon nzType="download" nzTheme="outline"></span>Download
              Certificate</a
            >
          </li>

          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.stpRegistration.stpApplicationView
                .menuUpdateContactInformation
            "
          >
            <a
              [routerLink]="[
                '/home/stp-registration/stp-organization-address-update/',
                stpMainId
              ]"
            >
              <span nz-icon nzType="form" nzTheme="outline"></span>Update
              Contact Information</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="translation"
        nzTheme="outline"
        nzTitle="Training Management"
        [appRequiredPermission]="
          applicationPermissions.stpOperation.menuPermission
        "
      >
        <ul>
          <li
            nz-menu-item
            nzClose
            nzIcon="file"
            nzTitle="STP List View"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.stpListAdminView
                .menuPermission
            "
          >
            <a routerLink="/home/stp-operations/stp-list/stp-list-view">
              <i nz-icon nzType="form" nzTheme="outline"></i>STP List View</a
            >
          </li>

          <!-- <li nz-submenu nzIcon="application" nzClose nzIcon="file" nzTitle="Approved Occupations"
            [appRequiredPermission]="
             applicationPermissions.stpOperation.approvedOccupations.readApprovedOccupations
            ">
            <ul>
              <li nz-menu-item [appRequiredPermission]="
              applicationPermissions.stpOperation.approvedOccupations.readApprovedOccupations
              ">
                <a routerLink="/home/stp-operations/approved-occupation/approved-occupation-list"><i nz-icon
                    nzType="form" nzTheme="outline"></i>Approved
                  Occupation List
                </a>
              </li>
            </ul>
          </li> -->

          <li>
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.approvedOccupations
                    .menuApprovedOccupationList
                "
              >
                <a
                  routerLink="/home/stp-operations/approved-occupation/approved-occupation-list"
                >
                  <i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Approved Occupation List
                </a>
              </li>
            </ul>
          </li>

          <li
            nz-submenu
            nzIcon="application"
            nzClose
            nzIcon="file"
            nzTitle="Project"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.project.menuPermission
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.project.createPermission
                "
              >
                <a routerLink="/home/stp-operations/project/create-project">
                  <i nz-icon nzType="form" nzTheme="outline"></i>Create
                  Project</a
                >
              </li>
            </ul>
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.project.menuListPermission
                "
              >
                <a routerLink="/home/stp-operations/project/project-list">
                  <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Project
                  List</a
                >
              </li>
            </ul>
          </li>

          <li
            nz-submenu
            nzClose
            nzIcon="file"
            nzTitle="Course"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.course.menuPermission
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.course.menuCeatePermission
                "
              >
                <a routerLink="/home/stp-operations/course/create-course"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Create Course
                </a>
              </li>

              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.course.menuListPermission
                "
              >
                <a routerLink="/home/stp-operations/course/course-list"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>
                  Course List</a
                >
              </li>
            </ul>
          </li>

          <li
            nz-submenu
            nzClose
            nzIcon="file"
            nzTitle="Batch"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.batch.menuPermission
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.batch.menuCreatePermission
                "
              >
                <a routerLink="/home/stp-operations/batch/batch-create"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Create
                  Batch</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.batch.menuListPermission
                "
              >
                <a routerLink="/home/stp-operations/batch/batch-list"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>Batch
                  List</a
                >
              </li>
            </ul>
          </li>
          <li
            nz-submenu
            nzClose
            nzIcon="user"
            nzTitle="Trainer"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.trainer.menuPermission
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.trainer
                    .menuCreatePermission
                "
              >
                <a routerLink="/home/stp-operations/trainer/create-trainer"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Add Trainer</a
                >
              </li>
            </ul>
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.trainer.menuListPermission
                "
              >
                <a routerLink="/home/stp-operations/trainer/trainer-list"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Trainer List</a
                >
              </li>
            </ul>
          </li>
          <li
            nz-submenu
            nzIcon="application"
            nzClose
            nzIcon="user"
            nzTitle="Trainee"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.trainee.menuPermission
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.trainee
                    .menuCreatePermission
                "
              >
                <a routerLink="/home/stp-operations/trainee/trainee-create"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Add Trainee</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.trainee.menuListPermission
                "
              >
                <a routerLink="/home/stp-operations/trainee/trainee-list"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>
                  Trainee List</a
                >
              </li>
            </ul>
          </li>
          <li
            nz-submenu
            nzIcon="application"
            nzClose
            nzIcon="file"
            nzTitle="RPL-Batch"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.rplCandidate.menuRplCandidate
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.rplCandidate
                    .menuRegisterRplCandidate
                "
              >
                <a routerLink="/home/stp-operations/rpl/Add-candidate-for-rpl"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Register
                  Candidate</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.rplCandidate
                    .menuCandidateList
                "
              >
                <a routerLink="/home/stp-operations/rpl/candidateList"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Candidate
                  List</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.rplCandidate
                    .menuAssessmentApply
                "
              >
                <a
                  routerLink="/home/stp-operations/rpl/apply-for-rpl-batch-assessment"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Assessment
                  Apply</a
                >
              </li>
            </ul>
          </li>
          <li
            nz-submenu
            nzIcon="application"
            nzClose
            nzIcon="file"
            nzTitle="Assessment"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.asseessmentStp
                .menuAssessmentStp
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.commonForRplRegAssessment
                    .menuAssessmentApplicationList
                "
              >
                <a
                  routerLink="/home/stp-operations/rpl-individual/assessment-application-list"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Application List</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.asseessmentStp
                    .menuAssessmentResultStp
                "
              >
                <a
                  routerLink="/home/stp-operations/assessment/assessment-result-stp"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Assessment Result</a
                >
              </li>
            </ul>
          </li>
          <li
            nz-submenu
            nzIcon="split-cells"
            nzClose
            nzIcon="split-cells"
            nzTitle="Re-Assessment"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.specialBatch.menuSpecialBatch
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.specialBatch
                    .menuSpecialBatchApply
                "
              >
                <a routerLink="/home/stp-operations/special-batch/apply"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Apply</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.specialBatch
                    .menuSpecialBatchApplicationList
                "
              >
                <a
                  routerLink="/home/stp-operations/special-batch/application-list"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Application List</a
                >
              </li>
            </ul>
          </li>
          <li
            [appRequiredPermission]="
              applicationPermissions.stpOperation.newStpUser.menuPermission
            "
            nz-submenu
            nzIcon="user-switch"
            nzClose
            nzTitle="STP User"
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.newStpUser
                    .menuCreatePermission
                "
              >
                <a routerLink="/home/stp-operations/new-user/add-user"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Add User
                </a>
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.newStpUser.newStpUserList
                "
              >
                <a routerLink="/home/stp-operations/new-user/stp-user-list"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>User List
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li
        nz-submenu
        nzClose
        nzIcon="exception"
        nzTheme="outline"
        nzTitle="Assessment Application"
        [appRequiredPermission]="
          applicationPermissions.stpOperation.asseessmentApplication
            .menuPermission
        "
      >
        <ul>
          <li
            [appRequiredPermission]="
              applicationPermissions.stpOperation.regularAssessment
                .menuPermission
            "
            nz-submenu
            nzClose
            nzIcon="file"
            nzTitle="Regular Assessment"
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.regularAssessment
                    .menuListPermission
                "
              >
                <a
                  routerLink="/home/stp-operations/assessment/assessment-app-list-view"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Assessment List</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.regularAssessment
                    .menuRegularCertificatesPending
                "
              >
                <a
                  routerLink="/home/stp-operations/reg/regular-certificates-pending-assessment-app-list-view"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Certificates Pending</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.regularAssessment
                    .menuRegularCertificatesIssued
                "
              >
                <a
                  routerLink="/home/stp-operations/reg/regular-certificates-issued-assessment-app-list-view"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Certificates Issued</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li
            [appRequiredPermission]="
              applicationPermissions.stpOperation.rplAssessment.menuPermission
            "
            nz-submenu
            nzClose
            nzIcon="usergroup-add"
            nzTitle="RPL-Batch Assessment"
          >
            <ul
              [appRequiredPermission]="
                applicationPermissions.stpOperation.rplAssessment
                  .menuListPermission
              "
            >
              <li nz-menu-item>
                <a
                  routerLink="/home/stp-operations/rpl/rpl-assessment-app-list-view"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Assessment List</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.rplAssessment
                    .menuRplCertificatesPending
                "
              >
                <a
                  routerLink="/home/stp-operations/rpl/rpl-certificates-pending-assessment-app-list-view"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Certificates Pending</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.rplAssessment
                    .menuRplCertificatesIssue
                "
              >
                <a
                  routerLink="/home/stp-operations/rpl/rpl-certificates-issued-assessment-app-list-view"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Certificates Issued</a
                >
              </li>
            </ul>
          </li>
        </ul>

        <ul>
          <li
            nz-submenu
            nzIcon="application"
            nzClose
            nzIcon="user"
            nzTitle="RPL-Individual Assessment"
            [appRequiredPermission]="
              applicationPermissions.applicant.menuPermission
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.applicant.applicantForm.menuPermission
                "
              >
                <a routerLink="/home/applicant/application-form"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Application
                  Form</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.applicant.applicantShortList
                    .menuPermission
                "
              >
                <a
                  routerLink="/home/stp-operations/rpl/rpl-individual-batch-create"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>Create
                  RPL Individual Batch of Application</a
                >
              </li>

              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.applicant.applicantShortList
                    .menuPermission
                "
              >
                <a
                  routerLink="/home/stp-operations/rpl/rpl-individual-batch-list-view"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>RPL
                  Individual Batch List</a
                >
              </li>

              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.applicant.applicantShortList
                    .menuPermission
                "
              >
                <a routerLink="/home/applicant/shortlist"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i>List
                  of Application</a
                >
              </li>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.applicant.applicantList.menuPermission
                "
              >
                <a routerLink="/home/applicant/application-list"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Application List</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li
            nz-submenu
            nzIcon="split-cells"
            nzClose
            nzIcon="split-cells"
            nzTitle="Re-Assessment"
            [appRequiredPermission]="
              applicationPermissions.stpOperation.specialBatch
                .menuSpecialBatchAdmin
            "
          >
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.specialBatch
                    .menuSpecialBatchApplicationListAdmin
                "
              >
                <a
                  routerLink="/home/stp-operations/special-batch/application-list-admin"
                  ><i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Application List</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            <ul>
              <li
                nz-menu-item
                [appRequiredPermission]="
                  applicationPermissions.stpOperation.asseessmentApplication
                    .menuAssessmentResultAdmin
                "
              >
                <a
                  routerLink="/home/stp-operations/assessment/assessment-result-admin"
                >
                  <i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Assessment Result
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <!-- <ul>
          <li nz-submenu nzIcon="application" nzClose nzIcon="file" nzTitle="RPL-Individual">
            <ul>
              <li nz-menu-item [appRequiredPermission]="
              applicationPermissions.stpOperation.rplIndividualAssessment.submitRplIndivScheduleAssessment">
                <a routerLink="/home/stp-operations/rpl-individual/schedule-assessment-individual"><i nz-icon
                    nzType="form" nzTheme="outline"></i>Schedule Assessment for RPL-Individual Assessment</a>
              </li>
              <li nz-menu-item [appRequiredPermission]="
              applicationPermissions.stpOperation.rplIndividualAssessment.submitRplIndivScheduleAssessment">
                <a routerLink="/home/stp-operations/rpl-individual/application-list"><i nz-icon nzType="form"
                    nzTheme="outline"></i>Application List</a>
              </li>
            </ul>
          </li>
        </ul> -->
      </li>
      <li
        nz-submenu
        nzClose
        nzIcon="file-search"
        nzTitle="Research & Studies"
        [appRequiredPermission]="
          applicationPermissions.researchAndStudies
            .menuResearchAndStudiesPermission
        "
      >
        <ul
          [appRequiredPermission]="
            applicationPermissions.researchAndStudies
              .menuAddResearchAndStudiesPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/research-and-studies/add">
              <i nz-icon nzType="form" nzTheme="outline"></i>Add</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.researchAndStudies
              .menuListResearchAndStudiesAdminPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/research-and-studies/list-nsda">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i
              >List-NSDA</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.researchAndStudies
              .menuListExternalUsersPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/research-and-studies/list-external-users">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Document
              List</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.researchAndStudies.menuListIscUsersPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/research-and-studies/list-isc-users">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Document
              List</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.researchAndStudies.menuExternalUserRegFormFunctionPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/research-and-studies/external-user-reg-form-manager">
              <i nz-icon nzType="form" nzTheme="outline"></i>Manage Registration</a
            >
          </li>
        </ul>
      </li>
      <li
        [appRequiredPermission]="
          applicationPermissions.stpOperation.assessor
            .menuAssignedAssessmentList
        "
        nz-submenu
        nzClose
        nzIcon="file"
        nzTitle="Assigned
        Assessment List"
      >
        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.assessor
              .menuRegularAssignedAssessmentList
          "
        >
          <li nz-menu-item>
            <a
              routerLink="/home/stp-operations/assessor/regular-assigned-assessment-list"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i
              >Regular-Batch</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.assessor
              .menuRegularAssignedAssessmentList
          "
        >
          <li nz-menu-item>
            <a
              routerLink="/home/stp-operations/assessor/rpl-individual-assigned-assessment-list"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>RPL
              Individual</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.assessor
              .menuRplAssignedAssessmentList
          "
        >
          <li nz-menu-item>
            <a
              routerLink="/home/stp-operations/assessor/assigned-assessment-list"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i
              >RPL-Batch</a
            >
          </li>
        </ul>

        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.specialBatch
              .menuSpecialBatchAssignedAssessmentList
          "
        >
          <li nz-menu-item>
            <a
              routerLink="/home/stp-operations/special-batch/sb-assigned-assessment-list"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i
              >Re-Assessment</a
            >
          </li>
        </ul>
      </li>

      <!-- <li
        nz-submenu
        nzIcon="rollback"
        nzClose
        nzTitle="Rollback"
        [appRequiredPermission]="
          applicationPermissions.stpOperation.rollback.menuPermission
        "
      >
        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.rollback.rollbackStatus
              .menuPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/rollback/rollback-status"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Rollback Status</a
            >
          </li>
        </ul>
      </li> -->

      <li
        nz-submenu
        nzClose
        nzIcon="monitor"
        nzTheme="outline"
        nzTitle="NAP"
        [appRequiredPermission]="
          applicationPermissions.nap.menuNapDashboard +
          ',' +
          applicationPermissions.nap.menuNapCreateAgency +
          ',' +
          applicationPermissions.nap.menuNapAgencyUserList +
          ',' +
          applicationPermissions.nap.menuNapTargetReview +
          ',' +
          applicationPermissions.nap.menuTrainingTargetReview +
          ',' +
          applicationPermissions.nap.menuNapReportReview +
          ',' +
          applicationPermissions.nap.menuTrainingReportReview +
          ',' +
          applicationPermissions.nap.menuNapTargetAdjReview +
          ',' +
          applicationPermissions.nap.menuTrainingTargetAdjReview
        "
      >
        <ul
          [appRequiredPermission]="applicationPermissions.nap.menuNapDashboard"
          nz-menu-item
          nzTitle="Dashboard"
          routerLink="/home/national-action-plan/nap-admin-dashboard"
        >
          <i nz-icon nzType="profile" nzTheme="outline"></i
          >Dashboard
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.nap.menuNapCreateAgency +
            ',' +
            applicationPermissions.nap.menuNapAgencyUserList
          "
          nz-submenu
          nzClose
          nzIcon="user-switch"
          nzTitle="Agency Users"
        >
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.nap.menuNapCreateAgency
            "
          >
            <a
              routerLink="/home/national-action-plan/agency-user/create-agency-user"
            >
              <i nz-icon nzType="form" nzTheme="outline"></i>Create Agency
              User</a
            >
          </li>
          <li
            nz-menu-item
            [appRequiredPermission]="
              applicationPermissions.nap.menuNapAgencyUserList
            "
          >
            <a
              routerLink="/home/national-action-plan/agency-user/agency-user-list"
            >
              <i nz-icon nzType="form" nzTheme="outline"></i>Agency User List</a
            >
          </li>
        </ul>

        <ul
          nz-submenu
          nzClose
          nzIcon="project"
          nzTitle="Review"
          [appRequiredPermission]="
            applicationPermissions.nap.menuNapTargetReview +
            ',' +
            applicationPermissions.nap.menuTrainingTargetReview +
            ',' +
            applicationPermissions.nap.menuNapReportReview +
            ',' +
            applicationPermissions.nap.menuTrainingReportReview +
            ',' +
            applicationPermissions.nap.menuNapTargetAdjReview +
            ',' +
            applicationPermissions.nap.menuTrainingTargetAdjReview
          "
        >
          <ul
            [appRequiredPermission]="
              applicationPermissions.nap.menuNapTargetReview
            "
          >
            <li nz-menu-item>
              <a
                routerLink="/home/national-action-plan/reports/review-nap-target"
              >
                <i nz-icon nzType="ordered-list" nzTheme="outline"></i>NAP
                Target Review</a
              >
            </li>
          </ul>
          <ul
            [appRequiredPermission]="
              applicationPermissions.nap.menuTrainingTargetReview
            "
          >
            <li nz-menu-item>
              <a
                routerLink="/home/national-action-plan/reports/review-training-target"
              >
                <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Training
                Target Review</a
              >
            </li>
          </ul>
          <ul
            [appRequiredPermission]="
              applicationPermissions.nap.menuNapReportReview
            "
          >
            <li nz-menu-item>
              <a
                routerLink="/home/national-action-plan/reports/review-nap-report"
              >
                <i nz-icon nzType="ordered-list" nzTheme="outline"></i>NAP
                Report Review</a
              >
            </li>
          </ul>
          <ul
            [appRequiredPermission]="
              applicationPermissions.nap.menuTrainingReportReview
            "
          >
            <li nz-menu-item>
              <a
                routerLink="/home/national-action-plan/reports/review-training-report"
              >
                <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Training
                Report Review</a
              >
            </li>
          </ul>
          <ul
            [appRequiredPermission]="
              applicationPermissions.nap.menuNapTargetAdjReview
            "
          >
            <li nz-menu-item>
              <a
                routerLink="/home/national-action-plan/reports/review-adjustment-nap-target"
              >
                <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Nap
                Target Adj. Review</a
              >
            </li>
          </ul>
          <ul
            [appRequiredPermission]="
              applicationPermissions.nap.menuTrainingTargetAdjReview
            "
          >
            <li nz-menu-item>
              <a
                routerLink="/home/national-action-plan/reports/review-adjustment-training-target"
              >
                <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Training
                Target Adj. Review</a
              >
            </li>
          </ul>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="flag"
        nzTheme="outline"
        nzTitle="Policies & Guidelines"
        [appRequiredPermission]="
          applicationPermissions.policiesAndGuidelines.menuAddDocument +
          ',' +
          applicationPermissions.policiesAndGuidelines.menuDocumentListNsda +
          ',' +
          applicationPermissions.policiesAndGuidelines.menuArchivedList
        "
      >
        <ul>
          <li
            nz-submenu
            nzIcon="application"
            nzClose
            nzIcon="file"
            nzTitle="Documents"
            [appRequiredPermission]="
              applicationPermissions.policiesAndGuidelines.menuAddDocument +
              ',' +
              applicationPermissions.policiesAndGuidelines.menuDocumentListNsda
            "
          >
            <ul
              [appRequiredPermission]="
                applicationPermissions.policiesAndGuidelines.menuAddDocument
              "
            >
              <li nz-menu-item>
                <a
                  routerLink="/home/policy-guidelines/documents/document-create"
                >
                  <i nz-icon nzType="form" nzTheme="outline"></i>Add Document
                </a>
              </li>
            </ul>
            <ul
              [appRequiredPermission]="
                applicationPermissions.policiesAndGuidelines
                  .menuDocumentListNsda
              "
            >
              <li nz-menu-item>
                <a
                  routerLink="/home/policy-guidelines/documents/document-list-nsda"
                >
                  <i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Document List</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li
            nz-submenu
            nzIcon="application"
            nzClose
            nzIcon="file"
            nzTitle="Archived"
            [appRequiredPermission]="
              applicationPermissions.policiesAndGuidelines.menuArchivedList
            "
          >
            <ul>
              <li nz-menu-item>
                <a routerLink="/home/policy-guidelines/archived/archived-list">
                  <i nz-icon nzType="ordered-list" nzTheme="outline"></i
                  >Archived List</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="setting"
        nzTheme="outline"
        nzTitle="Settings"
        [appRequiredPermission]="
          applicationPermissions.settings.ministry.menuCreatePermission +
          ',' +
          applicationPermissions.settings.ministry.menuListPermission +
          ',' +
          applicationPermissions.settings.agency.menuCreatePermission +
          ',' +
          applicationPermissions.settings.agency.menuListPermission
        "
      >
        <ul
          nz-submenu
          nzClose
          nzIcon="project"
          nzTitle="Ministry"
          [appRequiredPermission]="
            applicationPermissions.settings.ministry.menuCreatePermission +
            ',' +
            applicationPermissions.settings.ministry.menuListPermission
          "
        >
          <ul
            [appRequiredPermission]="
              applicationPermissions.settings.ministry.menuCreatePermission
            "
          >
            <li nz-menu-item>
              <a routerLink="/home/stp-registration/add-ministry">
                <i nz-icon nzType="form" nzTheme="outline"></i>Add Ministry</a
              >
            </li>
          </ul>
          <ul
            [appRequiredPermission]="
              applicationPermissions.settings.ministry.menuListPermission
            "
          >
            <li nz-menu-item>
              <a routerLink="/home/stp-registration/ministry-list">
                <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Ministry
                List</a
              >
            </li>
          </ul>
        </ul>
        <ul
          nz-submenu
          nzClose
          nzIcon="user-switch"
          nzTitle="Agency"
          [appRequiredPermission]="
            applicationPermissions.settings.agency.menuCreatePermission +
            ',' +
            applicationPermissions.settings.agency.menuListPermission
          "
        >
          <ul
            [appRequiredPermission]="
              applicationPermissions.settings.agency.menuCreatePermission
            "
          >
            <li nz-menu-item>
              <a routerLink="/home/stp-registration/add-agency">
                <i nz-icon nzType="form" nzTheme="outline"></i>Add Agency
              </a>
            </li>
          </ul>
          <ul
            [appRequiredPermission]="
              applicationPermissions.settings.agency.menuListPermission
            "
          >
            <li nz-menu-item>
              <a routerLink="/home/stp-registration/agency-list">
                <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Agency
                List</a
              >
            </li>
          </ul>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="bell"
        nzTheme="outline"
        nzTitle="Event"
        [appRequiredPermission]="
          applicationPermissions.event.menuEventScheduleCreate +
          ',' +
          applicationPermissions.event.menuEventListDashboard
        "
      >
        <ul
          [appRequiredPermission]="
            applicationPermissions.event.menuEventScheduleCreate
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/event/add-new-event">
              <i nz-icon nzType="plus" nzTheme="outline"></i>Schedule New
              Event</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.event.menuEventListDashboard
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/event/user-dashboard">
              <i nz-icon nzType="unordered-list" nzTheme="outline"></i>Event
              List Dashboard</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="profile"
        nzTitle="Assessor Pool"
        [appRequiredPermission]="
          applicationPermissions.stpOperation.assessor.menuAssessorAccessView +
          ',' +
          applicationPermissions.stpOperation.assessor.menuEntryAssessorPool +
          ',' +
          applicationPermissions.stpOperation.assessor
            .menuAssessorApplicationListNsda +
          ',' +
          applicationPermissions.stpOperation.assessor.menuNsdaAssessorPool +
          ',' +
          applicationPermissions.settings.addAssessor.menuAddAssessor
        "
      >
        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.assessor.menuAssessorAccessView
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/assessor-pool/access-view">
              <i nz-icon nzType="form" nzTheme="outline"></i>Assessor Access
              View</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.assessor.menuEntryAssessorPool
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/assessor-pool/apply-assessor-pool">
              <i nz-icon nzType="form" nzTheme="outline"></i>Entry Assessor
              Pool</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.assessor
              .menuAssessorApplicationListNsda
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/assessor-pool/nsda-application-list">
              <i nz-icon nzType="form" nzTheme="outline"></i>Application List
            </a>
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.stpOperation.assessor.menuNsdaAssessorPool
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/assessor-pool/nsda-assessor-pool">
              <i nz-icon nzType="form" nzTheme="outline"></i>Approved Assessors
            </a>
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.settings.addAssessor.menuAddAssessor
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/stp-operations/assessor-pool/add-assessor">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Add
              Assessor</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="dollar"
        nzTitle="Financing"
        [appRequiredPermission]="
          applicationPermissions.financialApplication.menuFinancing
        "
      >
        <ul
          [appRequiredPermission]="
            applicationPermissions.financialApplication.menuCreateCircular
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/financial/create-circular">
              <i nz-icon nzType="form" nzTheme="outline"></i>Create Circular</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.financialApplication.menuCircularListAdmin
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/financial/circular-list">
              <i nz-icon nzType="unordered-list" nzTheme="outline"></i>Circular
              List</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.financialApplication.menuCircularListStp
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/financial/circular-list-stp">
              <i nz-icon nzType="unordered-list" nzTheme="outline"></i>Circular
              List</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.financialApplication.menuApplicationListStp
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/financial/application-list-stp">
              <i nz-icon nzType="form" nzTheme="outline"></i>Application List</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.financialApplication.menuApplicationListAdmin
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/financial/application-list-admin">
              <i nz-icon nzType="unordered-list" nzTheme="outline"></i
              >Application List</a
            >
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.financialApplication.menuApplicationListNhrdf
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/financial/application-list-nhrdf">
              <i nz-icon nzType="form" nzTheme="outline"></i>Application List (NHRDF)</a
            >
          </li>
        </ul>
        <ul [appRequiredPermission]="applicationPermissions.financialApplication.menuResolution">
          <li nz-menu-item>
            <a routerLink="/home/financial/resolution-process">
              <i nz-icon nzType="unordered-list" nzTheme="outline"></i>Resolution
            </a>
          </li>
        </ul>
        <ul [appRequiredPermission]="applicationPermissions.financialApplication.menuResolutionList">
          <li nz-menu-item>
            <a routerLink="/home/financial/resolution-list">
              <i nz-icon nzType="unordered-list" nzTheme="outline"></i>Resolution List
            </a>
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="schedule"
        nzTheme="outline"
        nzTitle="Industry Skills Council"
        [appRequiredPermission]="
          applicationPermissions.isc.menuCreateIscPermission +
          ',' +
          applicationPermissions.isc.menuNsdaIscListPermission +
          ',' +
          applicationPermissions.isc.menuRepresentativeIscPermission +
          ',' +
          applicationPermissions.isc.menuRepresentativeIscListPermission
        "
      >
        <ul
          [appRequiredPermission]="
            applicationPermissions.isc.menuCreateIscPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/industry-skills-council/create-isc">
              <i nz-icon nzType="form" nzTheme="outline"></i>Create ISC
            </a>
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.isc.menuNsdaIscListPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/industry-skills-council/nsda-isc-list">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>ISC List
            </a>
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.isc.menuRepresentativeIscPermission
          "
        >
          <li nz-menu-item>
            <a routerLink="/home/industry-skills-council/representative-isc">
              <i nz-icon nzType="form" nzTheme="outline"></i>Representative ISC
            </a>
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.isc.menuRepresentativeIscListPermission
          "
        >
          <li nz-menu-item>
            <a
              routerLink="/home/industry-skills-council/representative-isc-list"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>ISC List
            </a>
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="schedule"
        nzTheme="outline"
        nzTitle="MRA/MOU"
        [appRequiredPermission]="
          applicationPermissions.mra.mraMenuPermission
        "
      >
        <ul>
          <li [appRequiredPermission]="applicationPermissions.mra.mraAgreementTypeAddPermission" nz-menu-item>
            <a routerLink="/home/mra/create-agreement-type">
              <i nz-icon nzType="form" nzTheme="outline"></i>Add Agreement Type
            </a>
          </li>
          <li [appRequiredPermission]="applicationPermissions.mra.menuAddCountryPermission" nz-menu-item>
            <a routerLink="/home/mra/create-country">
              <i nz-icon nzType="form" nzTheme="outline"></i>Add Country
            </a>
          </li>
          <li [appRequiredPermission]="applicationPermissions.mra.menuCreateMraPermission" nz-menu-item>
            <a routerLink="/home/mra/create-mra-mou">
              <i nz-icon nzType="form" nzTheme="outline"></i>Create MRA/MoU
            </a>
          </li>
          <li [appRequiredPermission]="applicationPermissions.mra.menuMraListPermission" nz-menu-item>
            <a routerLink="/home/mra/mra-list-admin">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>MRA/MOU
              List
            </a>
          </li>
        </ul>
      </li>

      <li nz-submenu nzClose nzIcon="schedule" nzTheme="outline" nzTitle="Program & Project"
      [appRequiredPermission]="
        applicationPermissions.programAndProjects.programAndProjectsMenuPermission
      ">
        <ul>
          <li nz-menu-item>
            <a routerLink="/home/program-project/create-project-enlistment">
              <i nz-icon nzType="form" nzTheme="outline"></i>Project Enlistment Form
            </a>
          </li>
          <li nz-menu-item>
            <a routerLink="/home/program-project/project-enlistment-list">
              <i nz-icon nzType="form" nzTheme="outline"></i>Project Enlistment List
            </a>
          </li>

          <li nz-menu-item>
            <a routerLink="/home/program-project/create-monthly-report">
              <i nz-icon nzType="form" nzTheme="outline"></i>Monthly Report Form
            </a>
          </li>
          <li nz-menu-item>
            <a routerLink="/home/program-project/monthly-report-list">
              <i nz-icon nzType="form" nzTheme="outline"></i>Monthly Report List
            </a>
          </li>

          <li nz-menu-item>
            <a routerLink="/home/program-project/create-project-closing-report">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Project Completion Report
            </a>
          </li>
          <li nz-menu-item>
            <a routerLink="/home/program-project/project-closing-report-list">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Project Completion List
            </a>
          </li>
          <li nz-menu-item>
            <a routerLink="/home/program-project/monitoring-enlistment">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Monitoring Enlistment
            </a>
          </li>
          <li nz-menu-item>
            <a routerLink="/home/program-project/monitoring-report">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Monitoring Monthely Report
            </a>
          </li>
          <li nz-menu-item>
            <a routerLink="/home/program-project/monitoring-completion">
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Monitoring Completion
            </a>
          </li>
        </ul>

      </li>

      <li
        nz-submenu
        nzClose
        nzIcon="solution"
        nzTitle="Reports"
        [appRequiredPermission]="
          applicationPermissions.reports.menuReportPermission
        "
      >
        <ul
          [appRequiredPermission]="
            applicationPermissions.reports.menuPaymentCollection
          "
        >
          <li nz-menu-item>
            <a
              href="https://skillsportal.gov.bd/metabase/public/dashboard/307bf373-501a-44ef-9a0d-587f1f269170"
              target="_blank"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Payment
              Collection
            </a>
          </li>
        </ul>
        <ul
          [appRequiredPermission]="
            applicationPermissions.reports.menuIssuedCertificates
          "
        >
          <li nz-menu-item>
            <a
              href="https://skillsportal.gov.bd/metabase/public/dashboard/ef48d15e-945e-42de-ac91-12204d5eecf0"
              target="_blank"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>Issued
              Certificates
            </a>
          </li>
        </ul>
        <ul
          [appRequiredPermission]="applicationPermissions.reports.menuStpInfo"
        >
          <li nz-menu-item>
            <a
              href="https://skillsportal.gov.bd/metabase/public/dashboard/d40a117c-3cb9-48b8-8c63-607928f1c68d"
              target="_blank"
            >
              <i nz-icon nzType="ordered-list" nzTheme="outline"></i>STP Info
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <i
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          ></i>
        </span>
        <ul
          class="mr-10"
          nz-menu
          nzMode="horizontal"
          style="float: right; margin-right: 80px"
        >
        <!-- Badge next to Profile -->
        <!-- <nz-badge [nzCount]="100" [nzOverflowCount]="99" style="margin-right: 8px;">
          <a class="head-example"></a>
        </nz-badge> -->
          <li nz-submenu nzClose nzTitle="Profile" nzIcon="user">
            <ul style="float: left; margin-right: 2px">
              <li nz-menu-item (click)="onChangePassword()">
                <i nz-icon nzType="setting" nzTheme="outline"></i>Change
                Password
              </li>
            </ul>

            <ul>
              <li nz-menu-item (click)="userProfile()">
                <i nz-icon nzType="user" nzTheme="outline"></i>Profile
              </li>
            </ul>
            <ul>
              <li nz-menu-item (click)="onLogout()">
                <i nz-icon nzType="logout" nzTheme="outline"></i>Logout
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <!-- <nz-footer>Synesis It ©2021 </nz-footer> -->
    <app-footer></app-footer>
  </nz-layout>
</nz-layout>
<nz-modal
  [(nzVisible)]="isCertificateModalVisible"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
>
  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="safety-certificate" nzTheme="outline"></i> Cautions!!
    </div>
    <h6 style="color: red">
      **Use latest version of Adobe Reader to see certificates properly
      (সার্টিফিকেট সঠিকভাবে দেখতে Adobe Reader এর সর্বশেষ সংস্করণ ব্যবহার করুন)
    </h6>
    <div *nzModalFooter>
      <button nz-button nzType="primary" (click)="handleCancel()">
        Cancel
      </button>
      <button nz-button nzType="primary" (click)="handleOk()">Download</button>
    </div>
  </nz-card>
</nz-modal>
