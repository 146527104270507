<!--
<div style="text-align:center">
    <h1>
      QR Code Generation
    </h1>
  </div>
   <div class="center">
    <ngx-qrcode
        [elementType]="elementType"
        [value] = "value"
        cssClass = "aclass"
        correctionLevel = "L">
    </ngx-qrcode>

   </div> -->

   <div class="container-fluid">
       <div class="row">
           <div class="container">
               <div class="row">


                <!-- Header starts -->


                    <div class="col-md-3 col-6 ">
                        <img class="img-fluid" src="assets/images/gov-modify.png" id="bd-logo">
                    </div>

                       <div class="col-md-3 col-6 offset-md-6  text-right" >
                        <img class="img-fluid" id="nsda-logo" src="assets/images/Group 98.png" >
                       </div>


                <!-- Header ends -->


                <!-- main content starts -->



                    <!-- certificate title -->

                    <div class="col-md-12 text-center certificate-center">

                        <h4>Government of the People’s Republic of Bangladesh</h4>
                        <h2 class="text-success">{{ officeText }}</h2>
                        <h4><b>National Skills Development Authority (NSDA)</b></h4>

                    </div>

                    <!--certificate title -->


                        <!--certificate body -->
                        <div class="certificate-type col-md-12 text-center">

                            <h1 class="certificate-font">Certificate of Registration</h1>

                        </div>

                        <div class="stp-reg-name col-md-12 text-center">

                            <!-- <h2><b>UCEP Mirpur Technical School, Dhaka</b></h2> -->
                            <h2><b>{{this.stpNameEnglish}}</b></h2>
                            <h4>is registered as a Skills Training Provider under</h4>
                            <h4>section 16(1) of NSDA Act, 2018.</h4>

                        </div>
                        <!-- certficate body -->


                        <!-- certificate footer -->



                            <div class="col-md-5  certificate-footer-right">
                                <!-- <p>Registration No. : STP-DHK-000001</p> -->
                                <p>Registration No. : {{this.stpRegistrationId}}</p>
                                <p>Issued on : {{this.stpIssuedDate}}</p>
                                <p>Valid until : {{this.stpValidityDate}}</p>
                            </div>

                               <div class="col-md-4 offset-md-3  text-right certificate-footer-left" >
                               <!-- <p>Executive Chairman, NSDA</p> -->
                               </div>






                        <!-- certificate footer -->


                <!-- main content ends -->



                </div>
           </div>
       </div>
   </div>
