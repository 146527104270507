import { ListTamOccupation } from './list-tam-occupation.model';
import { RepresentativeOfficerList } from './representative-officer-list.model';

export class CompleteInspection {
  public inspectionId: number;

  public representatives1Id: number;

  public representatives2Id: number;

  public representatives3Id: number;

  public registrationNo: string;

  public name: string;

  public address: string;

  public district: string;

  public applicationId: string;

  public applicationDate: Date;

  public amount: number;

  public paymentDate: Date;

  public bankName: string;

  public paymentType: string;

  public applicationProof?: boolean;

  public isRegistrationInspectionRequired?: boolean;

  public isNsdaApproved?: boolean;

  public isOfficer1Approved?: boolean;

  public isOfficer2Approved?: boolean;

  public isOfficer3Approved?: boolean;
  
  public nsdaOfficerName: string;

  public officer1Id: number;

  public officer1Name: string;

  public officer2Id: number;

  public officer2Name: string;

  public officer3Id: number;

  public officer3Name: string;

  public listTamOccupation: ListTamOccupation[];
  
  public representativeOfficerList: RepresentativeOfficerList[];

  public inspectorOfficerId: number;

  constructor() {}
}
