<app-header></app-header>
<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Event List Dashboard
  </div>
  <form [formGroup]="searchUserDashboardForm" (ngSubmit)="search()" style="padding: 24px; background: #fbfbfb; border: 1px solid #d9d9d9; border-radius: 6px;">
    <br>
    <div class="mb-2 row">
      <div class="col-md-2 col-sm-12 text-right">
        <nz-form-label>Event Type
        </nz-form-label>
      </div>
      <div class="col-md-4 col-sm-12">
        <nz-form-item>
          <nz-form-control class="row">
            <nz-select nzShowSearch nzAllowClear formControlName="eventType" nzPlaceHolder="Select One">
              <nz-option *ngFor="let item of eventTypesList" [nzValue]="item.typeName" [nzLabel]="item.typeName">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-2 col-sm-12 text-right">
        <nz-form-label>Event Status
        </nz-form-label>
      </div>
      <div class="col-md-4 col-sm-12">
        <nz-form-item>
          <nz-form-control class="row">
            <nz-select nzShowSearch nzAllowClear formControlName="eventStatus" nzPlaceHolder="Select One">
              <nz-option *ngFor="let item of eventStatus" [nzValue]="item.type" [nzLabel]="item.name">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div class="mb-2 row">
      <div class="col-md-2 col-sm-12 text-right">
        <nz-form-label>Organised By
        </nz-form-label>
      </div>
      <div class="col-md-4 col-sm-12">
        <nz-form-item>
          <nz-form-control class="row">
            <nz-select nzShowSearch nzAllowClear formControlName="orgName" nzPlaceHolder="Select One">
              <nz-option *ngFor="let item of organizedByList" [nzValue]="item.organiserName"
                [nzLabel]="item.organiserName">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-2 col-sm-12 text-right">
        <nz-form-label>Event Title
        </nz-form-label>
      </div>
      <div class="col-md-4 col-sm-12">
        <nz-form-item>
          <nz-form-control class="row">
            <input nz-input type="text" placeholder="Event Title" formControlName="eventTitle" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div class="mb-2 row">
      <div class="col-md-2 col-sm-12 text-right">
        <nz-form-label>Date From
        </nz-form-label>
      </div>
      <div class="col-md-4 col-sm-12">
        <nz-form-item>
          <nz-form-control class="row">
            <nz-date-picker id="dateFrom" formControlName="dateFrom" placeholder="Date" class="col-md-12">
            </nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-2 col-sm-12 text-right">
        <nz-form-label>Date To
        </nz-form-label>
      </div>
      <div class="col-md-4 col-sm-12">
        <nz-form-item>
          <nz-form-control class="row">
            <nz-date-picker id="dateTo" formControlName="dateTo" placeholder="Date" class="col-md-12">
            </nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>

    </div>
    <div nz-row>
      <div nz-col [nzSpan]="24" class="search-area text-right">
        <button class="mr-2" nz-button [nzType]="'primary'">Search</button>
        <button type="button" class="mr-2 btn-success" nz-button (click)="reset()">Refresh Data</button>
        <!-- <button class="mr-2" class="mr-2 btn-dark" nz-button (click)="downloadDashboard()">Download Report in Excel</button> -->
      </div>
    </div>
    <br>
  </form>
</nz-card>

<nz-card>
  <div class="ng-Header">
    <i nzTheme="outline"></i>
  </div>
  <ng-container style="padding: 24px; background: #fbfbfb; border: 1px solid #d9d9d9; border-radius: 6px;">
    <nz-table #basicTable [nzData]="dashboardDataList" nzTableLayout="fixed" nzShowSizeChanger nzBordered nzSize="middle"
      nzAlign="middle" [nzFrontPagination]="false" [nzTotal]="total" [(nzPageSize)]="pageSize"
      [nzShowTotal]="totalRowRangeTemplate" [(nzPageIndex)]="currentPage" (nzQueryParams)="onPageIndexChange($event)"
      [nzScroll]="{ x: '1250px', y: '70vh' }">
      <ng-template #totalRowRangeTemplate let-range="range" let-total style="text-align: left">
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
        </div>
      </ng-template>
      <thead>
        <tr>
          <th style="height: 100px; text-align: center; ">Sl.</th>
          <th colspan="3" style="height: 100px; text-align: center;" nzColumnKey="eventId" [nzSortFn]="true">Event ID</th>
          <th colspan="2" style="height: 100px; text-align: center;" nzColumnKey="eventType" [nzSortFn]="true">Event Type
          </th>
          <th colspan="4" style="height: 100px; text-align: center;" nzColumnKey="eventTitle" [nzSortFn]="true">Event
            Title</th>
          <th colspan="2" style="height: 100px; text-align: center;" nzColumnKey="orgName" [nzSortFn]="true">Organized By
          </th>
          <th colspan="2" style="height: 100px; text-align: center;" nzColumnKey="dateRange" [nzSortFn]="true">Event Date
          </th>
          <th colspan="2" style="height: 100px; text-align: center;" nzColumnKey="overallStatus" [nzSortFn]="true">Event
            Status</th>
          <th colspan="4" style="height: 100px; text-align: center;">Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of dashboardDataList; let i = index">
          <tr [style.background-color]="getBgColor(data?.overallStatus)">
            <td style="text-align: center;">{{ (currentPage - 1) * pageSize + 1 + i }}</td>
            <td colspan="3" style="text-align: center;">{{data?.eventId}}</td>
            <td colspan="2" style="text-align: center;">{{data?.eventType}}</td>
            <td colspan="4" style="text-align: center;">{{data?.eventTitle}}</td>
            <td colspan="2" style="text-align: center;">{{data?.orgName}}</td>
            <td colspan="2" style="text-align: center;">{{data?.dateRange}}</td>
            <td colspan="2" style="text-align: center;">{{data?.overallStatus}}</td>

            <td colspan="4" style="text-align: center;">
              <ng-container *ngIf="data?.overallStatus == 'Schedule Submitted'">
                <button class="ml-2" title="View Schedule" nz-button nzType="default"
                  (click)="viewSchedule(data?.scheduleId, data?.reportId, data?.overallStatus)">View Schedule
                </button>
              </ng-container>

              <ng-container *ngIf="data?.overallStatus == 'Schedule Approved'">
                <button class="ml-2" title="View Schedule" nz-button nzType="default"
                  (click)="viewSchedule(data?.scheduleId, data?.reportId, data?.overallStatus)">View Schedule
                </button>
              </ng-container>

              <ng-container *ngIf="data?.overallStatus == 'Schedule Need Correction'">
                <button class="ml-2" title="View Schedule" nz-button nzType="default"
                  (click)="viewSchedule(data?.scheduleId, data?.reportId, data?.overallStatus)">View Schedule
                </button>
              </ng-container>

              <ng-container *ngIf="data?.overallStatus == 'Report Submitted'">
                <button class="ml-2" title="View Report" nz-button nzType="default"
                  (click)="viewSchedule(data?.scheduleId, data?.reportId, data?.overallStatus)">View Report
                </button>
              </ng-container>

              <ng-container *ngIf="data?.overallStatus == 'Report Need Correction'">
                <button class="ml-2" title="View Report" nz-button nzType="default"
                  (click)="viewSchedule(data?.scheduleId, data?.reportId, data?.overallStatus)">View Report
                </button>
              </ng-container>

              <ng-container *ngIf="data?.overallStatus == 'Completed'">
                <button class="ml-2" title="View Report" nz-button nzType="default"
                  (click)="viewReport(data?.scheduleId, data?.reportId, data?.overallStatus)">View Report
                </button>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </ng-container>
</nz-card>
<app-footer></app-footer>
