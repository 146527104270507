import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Division, District, Upazila, Ghat } from '../models/common.model';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  onDivisionLoad = new Subject<Division[]>();
  onDistrictLoad = new Subject<District[]>();
  onUpazilaLoad = new Subject<Upazila[]>();
  onUpdateGhatList = new Subject<Ghat[]>();

  divisions: Division[] = [];
  districts: District[] = [];
  upazilas: Upazila[] = [];
  ghats: Ghat[] = [];
  constructor() {}

  setGhats(ghats: Ghat[]): void {
    this.ghats = ghats;
    this.onUpdateGhatList.next(this.ghats.slice());
  }

  getGhats(): Ghat[] {
    return this.ghats;
  }

  setDivisions(divisions: Division[]): void {
    this.divisions = divisions;
    this.onDivisionLoad.next(this.divisions.slice());
  }

  getDivisions(): Division[] {
    return this.divisions;
  }

  setDistricts(districts: District[]): void {
    this.districts = districts;
    this.onDistrictLoad.next(this.districts.slice());
  }

  getDistricts(): District[] {
    return this.districts;
  }

  getDistrictsByDivisionIds(ids: number[]): District[] {
    const districts: District[] = [];
    for (const id of ids) {
      districts.push(...this.districts.filter((q) => q.divisionId === id));
    }
    return districts;
  }

  setUpazilas(upazilas: Upazila[]): void {
    this.upazilas = upazilas;
    this.onUpazilaLoad.next(this.upazilas.slice());
  }

  getUpazilas(): Upazila[] {
    return this.upazilas;
  }

  getUpazilasByDistrictIds(ids: number[]): Upazila[] {
    const upazilas: Upazila[] = [];
    for (const id of ids) {
      upazilas.push(...this.upazilas.filter((q) => q.districtId === id));
    }
    return upazilas;
  }
}
