import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { CadFurniture } from '../../../models/cad-furniture.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';
import { AddCadFurnitureComponent } from '../add-cad-furniture/add-cad-furniture.component';

@Component({
  selector: 'app-cad-furniture-table',
  templateUrl: './cad-furniture-table.component.html',
  styleUrls: ['./cad-furniture-table.component.scss']
})
export class CadFurnitureTableComponent implements OnInit, OnDestroy {

  // @Input() tamMainId: string | null;
  // @Input() createButton: boolean;

  // modalRef: NzModalRef;
  // cadFurnitureList: CadFurniture[];
  // onCadFurnitureSubscription$: Subscription;

  @Input() tamMainId: number | null;
  @Input() createButton: boolean;
  showingAction: boolean | null;

  modalRef: NzModalRef;
  cadList: CadFurniture[] = [];
  cadListFiltered: CadFurniture[] = [];
  onCadFurnitureSubscription$: Subscription;

  page = 1;
  size = 10;
  total: number;

  constructor(
    private modal: NzModalService,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private route: ActivatedRoute
  ) {
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
    const urlPath = String(this.route.snapshot.url[0].path);
    (urlPath.includes("tam-view")) ? this.showingAction = true : this.showingAction = false ;
  this.cadList = this.cadService.getCadFurnitureList(this.tamMainId);
  this.cadListFiltered = [...this.cadList];
  if (this.cadList.length === 0) {
    this.cadStorageService.readCadFurnitureListByTamId(this.tamMainId,this.page, this.size).subscribe();
  }
  this.onCadFurnitureSubscription$ =
    this.cadService.onRefreshCadFurnitureList.subscribe(
      (res) => {
        this.cadList = res;
        this.cadListFiltered = [...this.cadList];
      }
    ); 
  }

  ngOnInit(): void {
    this.loadCadFromServer();
  }

  loadCadFromServer() {

    if(!this.tamMainId) {
      this.notification.error('Error', 'Training and Assessment Material Id not found');
    }

    // this.cadFurnitureList = this.cadService.getCadFurnitureList(Number(this.tamMainId));

    // if(this.cadFurnitureList.length == 0) {
    //   this.cadStorageService.readCadFurnitureListByTamId(Number(this.tamMainId), this.page, this.size)
    //   .subscribe(  {
    //     next: (res) => {
    //       this.cadFurnitureList = res.data;
    //       this.total = res.count;
    //     },
    //   });
    // }

    // this.onCadFurnitureSubscription$ = this.cadService.onRefreshCadFurnitureList.subscribe(
    //   (res) => {
    //     this.cadFurnitureList = res.filter(
    //       (item) =>
    //         item.tamMainId == this.tamMainId ||
    //         item.tamMain.id == this.tamMainId
    //     );

    //     if(this.modalRef) {
    //       this.modalRef.close();
    //     }
    //   }
    // );
  }

  createCustomButtonModal(data? : any): void {
    this.modalRef = this.modal.create({
      nzContent: AddCadFurnitureComponent,
      nzClosable: false,
      nzOkText: null,
      nzWidth: 1000,
      nzComponentParams: {
        InputData: data
      }
    });
  }


  // onQueryParamsChange(params: NzTableQueryParams): void {
  //   this.page = params.pageIndex;
  //   this.size = params.pageSize;
  //   const { pageSize, pageIndex, sort } = params;
  //   this.loadCadFromServer();
  // }

  
  onEdit(id: number): void {
    this.cadService.setEditingCadFurnitureId(id);
    this.createCustomButtonModal(id);
  }

  onDelete(id: number): void {
    this.cadStorageService.deleteCadFurniture(id).subscribe({
      next: (response) => {
        this.notification.success('Success!', 'Staff deleted successfully');
        this.loadCadFromServer();
      },
      error: (error) => this.notification.error('Failed!', error),
    });
  }

  ngOnDestroy(): void {
      this.onCadFurnitureSubscription$.unsubscribe();
  }
}
