<div class="container-fluid">
  <div class="row">
    <div class="container">
      <div class="row">
        <!-- Header starts -->
        
        <!-- <button (click)="onDownloadRegistrationCard()"></button> -->

        <div class="col-md-3 col-6">
          <img
            class="img-fluid"
            src="assets/images/gov-modify.png"
            id="bd-logo"
          />
        </div>

        <div class="col-md-3 col-6 offset-md-6 text-right">
          <img
            class="img-fluid"
            id="nsda-logo"
            src="assets/images/Group 98.png"
          />
        </div>

        <!-- Header ends -->

        <!-- main content starts -->

        <!-- certificate title -->

        <div class="col-md-12 text-center certificate-center">
          <h4>Government of the People’s Republic of Bangladesh</h4>
          <h2>Prime Minister’s Office</h2>
          <h4 class="text-success">
            <b>National Skills Development Authority (NSDA)</b>
          </h4>
          <h6>www.nsda.gov.bd</h6>
          <h6>Registration Card for Assessment</h6>
        </div>

        <!--certificate title -->

        <!--certificate body -->
        <div class="certificate-center col-md-12">
          <div class="container stp-reg-name" style="margin-top: 50px">
            <div class="row">
              <div class="col-md-8">
                <div>
                  <p>Registration Number : {{ this.registrationCardDetails?.registrationNo ? this.registrationCardDetails?.registrationNo  : "N/A" }}</p>
                  <p>Date of Registration : {{ this.registrationCardDetails?.registrationDate ? this.registrationCardDetails?.registrationDate  : "N/A"}}</p>
                  <p>
                    Name of Occupation & Level :
                    {{
                      this.registrationCardDetails?.occupationName ? this.registrationCardDetails?.occupationName  : "N/A" 
                    }} {{ " - Level " }} {{this.registrationCardDetails?.occupationLevel ? this.registrationCardDetails?.occupationLevel  : "N/A" }}
                  </p>
                  <p>
                    Name of Applicant :
                    {{ this.registrationCardDetails?.nameOfApplicant ? this.registrationCardDetails?.nameOfApplicant  : "N/A" }}
                  </p>
                  <p>
                    Mother’s Name :
                    {{ this.registrationCardDetails?.motherName ? this.registrationCardDetails?.motherName  : "N/A" }}
                  </p>
                  <p>
                    Father’s Name :
                    {{ this.registrationCardDetails?.fatherName ? this.registrationCardDetails?.fatherName  : "N/A" }}
                  </p>
                  <p>NID/BR Number : {{ this.registrationCardDetails?.nid ? this.registrationCardDetails?.nid : this.registrationCardDetails?.birthRegistration ? this.registrationCardDetails?.birthRegistration : "N/A"  }}</p>
                  <p>
                    Date and venue of assessment :
                    {{ this.registrationCardDetails?.venu ? this.registrationCardDetails?.venu  : "N/A"}}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div>
                  <img
                    class="img-thumbnail"
                    [src]="imageURL"
                    alt="Profile photo"
                    *ngIf="!isImageLoading; else noImageFound"
                    style="margin-bottom: 20px; width: 150px; height: 200px"
                  /><br />
                  <ng-template #noImageFound>
                    <img
                      src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
                      alt="Fallbackimage"
                    />
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="container" style="margin-top: 50px">
            <div class="row">
              <div class="col-md-4">
                <img
                    class="img-thumbnail"
                    [src]="signatureURL"
                    alt="SignatureURL photo"
                    *ngIf="!isImageLoading; else noImageFound"
                    style="margin-bottom: 20px; width: 150px; height: 200px"
                  /><br />
                <br />
                <ng-template #noImageFound>

                  <img
                    src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
                    alt="Fallbackimage"
                  />
                </ng-template>
                <input
                  type="text"
                  style="border: 0; border-bottom: 1px solid #000"
                />
                <p>Candidate's Signature</p>
                <p>Downloading Date: {{this.dateOfAssessment}}</p>
              </div>

              <div class="col-md-4"></div>

              <div class="col-md-4">
                <img
                  class="img-thumbnail"
                  alt="Profile photo"
                  src="assets/images/sig_bg_AD.png"
                  style="margin-bottom: 20px; border:0; width: 150px; height: 200px"
                />
                <br />
                <ng-template #noImageFound>
                  <img
                    src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
                    alt="Fallbackimage"
                  />
                </ng-template>
                <input
                  type="text"
                  style="border: 0; border-bottom: 1px solid #000"
                />
                <p>Assistant Director(Assessment), NSDA</p>
              </div>
            </div>
          </div>
          <!-- certficate body -->

          <!-- certificate footer -->

          <div class="container col-md-12" style="margin-top: 50px">
            <div class="col-md-10">
              <ul style="list-style-position: outside">
                <li>
                  Candidates must show their registration cards during the
                  assessment
                </li>
                <li>
                  Photograph attached in this registration card will be matched
                  with the candidate during the assessment
                </li>
                <li>
                  NSDA reserves all rights to take any kind of punitive measure
                  against any candidate who adopts unfair means or misconduct
                  during the assessment
                </li>
                <li>
                  No TA/DA will be admissible for attending the assessment
                </li>
                <li>
                  This registration card will be considered as admit card for
                  assessment
                </li>
              </ul>
            </div>
          </div>

          <!-- <div class="col-md-12">
            <div
              style="
                width: 1000px;
                height: 200px;
                border: solid 2px rgb(26, 21, 21);
              "
            ></div>
          </div> -->
        </div>

        <!-- certificate footer -->

        <!-- main content ends -->
      </div>
    </div>
  </div>
</div>
