import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsersComponentConfig } from '../models/users-component-config.config';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  onRefreshUserList = new Subject<User[]>();

  usersComponentConfigState: null | UsersComponentConfig = null;
  users: User[] = [];

  private baseUrl = environment.baseApiUrl;
  constructor(private httpClient: HttpClient) {}

  getUsers(): any {
    return this.users;
  }

  getUserById(id: number): User | undefined {
    return this.users.find((user) => user.id === Number(id));
  }

  setUsers(users: User[]): void {
    this.users = users;
    this.onRefreshUserList.next(this.users.slice());
  }

  addUser(user: User): void {
    this.users.push(user);
    this.onRefreshUserList.next(this.users.slice());
  }

  upatedUser(updatedUser: User, id: number): void {
    const index = this.users.findIndex((user) => user.id === id);
    this.users[index] = updatedUser;
    this.onRefreshUserList.next(this.users.slice());
  }

  deleteUser(id: number): void {
    const index = this.users.findIndex((user) => user.id === id);
    this.users.splice(index, 1);
    this.onRefreshUserList.next(this.users.slice());
  }
}
