import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CadEquipment } from '../models/cad-equipment.model';
import { CadFurniture } from '../models/cad-furniture.model';
import { CadLearningMaterial } from '../models/cad-learning-material.model';
import { CadMaterialConsumable } from '../models/cad-material-consumable.model';
import { CadMeasuringInstrument } from '../models/cad-measuring-instrument.model';
import { CadOtherRequirement } from '../models/cad-other-requirement.model';
import { CadPpe } from '../models/cad-ppe.model';
import { CadTool } from '../models/cad-tool.model';
import { CadTrainerAndStaff } from '../models/cad-trainer-and-staff.model';
import { CadTrainingFacility } from '../models/cad-training-facility.model';

@Injectable({
  providedIn: 'root'
})
export class CourseAccreditationDocumentService {

  onRefreshCadEquipmentList = new Subject<CadEquipment[]>();
  cadEquipmentList: CadEquipment [] = [];
  editingCadEquipmentId!: number | null;
  onStartCadEquipmentEditing = new Subject<number | null>();

  onRefreshCadFurnitureList = new Subject<CadFurniture[]>();
  cadFurnitureList: CadFurniture [] = [];
  editingCadFurnitureId!: number | null;
  onStartCadFurnitureEditing = new Subject<number | null>();

  onRefreshCadMeasuringInstrumentList = new Subject<CadMeasuringInstrument[]>();
  cadMeasuringInstrumentList: CadMeasuringInstrument [] = [];
  editingCadMeasuringInstrumentId!: number | null;
  onStartCadMeasuringInstrumentEditing = new Subject<number | null>();

  onRefreshCadToolList = new Subject<CadTool[]>();
  cadToolList: CadTool [] = [];
  editingCadToolId!: number | null;
  onStartCadToolEditing = new Subject<number | null>();

  onRefreshCadMaterialConsumableList = new Subject<CadMaterialConsumable[]>();
  cadMaterialConsumableList: CadMaterialConsumable [] = [];
  editingCadMaterialConsumableId!: number | null;
  onStartCadMaterialConsumableEditing = new Subject<number | null>();

  onRefreshCadPpeList = new Subject<CadPpe[]>();
  cadPpeList: CadPpe [] = [];
  editingCadPpeId!: number | null;
  onStartCadPpeEditing = new Subject<number | null>();

  onRefreshCadLearningMaterialList = new Subject<CadLearningMaterial[]>();
  cadLearningMaterialList: CadLearningMaterial [] = [];
  editingCadLearningMaterialId!: number | null;
  onStartCadLearningMaterialEditing = new Subject<number | null>();

  onRefreshCadTrainingFacilityList = new Subject<CadTrainingFacility[]>();
  cadTrainingFacilityList: CadTrainingFacility [] = [];
  editingCadTrainingFacilityId!: number | null;
  onStartCadTrainingFacilityEditing = new Subject<number | null>();

  onRefreshCadTrainerAndStaffList = new Subject<CadTrainerAndStaff[]>();
  cadTrainerAndStaffList: CadTrainerAndStaff [] = [];
  editingCadTrainerAndStaffId!: number | null;
  onStartCadTrainerAndStaffEditing = new Subject<number | null>();

  onRefreshCadOtherRequirementList = new Subject<CadOtherRequirement[]>();
  cadOtherRequirementList: CadOtherRequirement [] = [];
  editingCadOtherRequirementId!: number | null;
  onStartCadOtherRequirementEditing = new Subject<number | null>();


  constructor() { }

  //#region Cad Equipment
  addCadEquipment(cadEquipment: CadEquipment): void {
    this.cadEquipmentList.push(cadEquipment);
    this.onRefreshCadEquipmentList.next(this.cadEquipmentList.slice());
  }
  getCadEquipmentList(tamMainId: number) {
    return this.cadEquipmentList.filter(item => item.tamMainId == tamMainId);
  }

  getCadEquipmentById(id: number | null): CadEquipment | undefined  {
    return this.cadEquipmentList.find((item) => item.id === id );
  }

  getEditingSingleCadEquipmentId(): any {
    return this.editingCadEquipmentId;
  }

  getUpdateCadEquipmentList(): Observable<any> {
    return this.onRefreshCadEquipmentList.asObservable();
  }
  
  setCadEquipmentList(cadEquipmentList: CadEquipment[]): void {
    this.cadEquipmentList = cadEquipmentList;
    this.onRefreshCadEquipmentList.next(this.cadEquipmentList.slice());
  }

  setEditingCadEquipmentId(id: number): void {
    this.editingCadEquipmentId = id;
    this.onStartCadEquipmentEditing.next(id);
  }
    
  upatedCadEquipment(csGeneric: CadEquipment, id?: number): void {
    const index = this.cadEquipmentList.findIndex((singleCs) => singleCs.id === id);
    this.cadEquipmentList[index] = csGeneric;
    this.onRefreshCadEquipmentList.next(this.cadEquipmentList.slice());
  }
  
  clearEditingCadEquipmentId(): void {
    this.editingCadEquipmentId = null;
    this.onStartCadEquipmentEditing.next(null);
  }

  deleteCadEquipment(id: number): void {
    this.cadEquipmentList = this.cadEquipmentList.filter(
      (cadEquipmentList, _index) => {
        return cadEquipmentList.id !== id;
      }
    );
    this.onRefreshCadEquipmentList.next(this.cadEquipmentList.slice());
  }

  //#endregion 

  //#region Cad Furniture
  
  addCadFurniture(cadFurniture: CadFurniture): void {
    this.cadFurnitureList.push(cadFurniture);
    this.onRefreshCadFurnitureList.next(this.cadFurnitureList.slice());
  }

  getCadFurnitureList(tamMainId: number) {
    return this.cadFurnitureList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCadFurnitureById(id: number | null): CadFurniture | undefined  {
    return this.cadFurnitureList.find((item) => item.id === id );
  }

  getEditingSingleCadFurnitureId(): any {
    return this.editingCadFurnitureId;
  }
  
  getUpdateCadFurnitureList(): Observable<any> {
    return this.onRefreshCadFurnitureList.asObservable();
  }

  setCadFurnitureList(cadFurnitureList: CadFurniture[]): void {
    this.cadFurnitureList = cadFurnitureList;
    this.onRefreshCadFurnitureList.next(this.cadFurnitureList.slice());
  }
  
  setEditingCadFurnitureId(id: number): void {
    this.editingCadFurnitureId = id;
    this.onStartCadFurnitureEditing.next(id);
  }

  upatedCadFurniture(csGeneric: CadFurniture, id?: number): void {
    const index = this.cadFurnitureList.findIndex((singleCs) => singleCs.id === id);
    this.cadFurnitureList[index] = csGeneric;
    this.onRefreshCadFurnitureList.next(this.cadFurnitureList.slice());
  }
  
  clearEditingCadFurnitureId(): void {
    this.editingCadFurnitureId = null;
    this.onStartCadFurnitureEditing.next(null);
  }

  deleteCadFurniture(id: number): void {
    this.cadFurnitureList = this.cadFurnitureList.filter(
      (cadFurnitureList, _index) => {
        return cadFurnitureList.id !== id;
      }
    );
    this.onRefreshCadFurnitureList.next(this.cadFurnitureList.slice());
  }
   //#endregion

     //#region Cad MeasuringInstrument
  
  addCadMeasuringInstrument(cadMeasuringInstrument: CadMeasuringInstrument): void {
    this.cadMeasuringInstrumentList.push(cadMeasuringInstrument);
    this.onRefreshCadMeasuringInstrumentList.next(this.cadMeasuringInstrumentList.slice());
  }

  getCadMeasuringInstrumentList(tamMainId: number) {
    return this.cadMeasuringInstrumentList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCadMeasuringInstrumentById(id: number | null): CadMeasuringInstrument | undefined  {
    return this.cadMeasuringInstrumentList.find((item) => item.id === id );
  }

  getEditingSingleCadMeasuringInstrumentId(): any {
    return this.editingCadMeasuringInstrumentId;
  }
  
  getUpdateCadMeasuringInstrumentList(): Observable<any> {
    return this.onRefreshCadMeasuringInstrumentList.asObservable();
  }

  setCadMeasuringInstrumentList(cadMeasuringInstrumentList: CadMeasuringInstrument[]): void {
    this.cadMeasuringInstrumentList = cadMeasuringInstrumentList;
    this.onRefreshCadMeasuringInstrumentList.next(this.cadMeasuringInstrumentList.slice());
  }
  
  setEditingCadMeasuringInstrumentId(id: number): void {
    this.editingCadMeasuringInstrumentId = id;
    this.onStartCadMeasuringInstrumentEditing.next(id);
  }

  upatedCadMeasuringInstrument(csGeneric: CadMeasuringInstrument, id?: number): void {
    const index = this.cadMeasuringInstrumentList.findIndex((singleCs) => singleCs.id === id);
    this.cadMeasuringInstrumentList[index] = csGeneric;
    this.onRefreshCadMeasuringInstrumentList.next(this.cadMeasuringInstrumentList.slice());
  }
  
  clearEditingCadMeasuringInstrumentId(): void {
    this.editingCadMeasuringInstrumentId = null;
    this.onStartCadMeasuringInstrumentEditing.next(null);
  }

  deleteCadMeasuringInstrument(id: number): void {
    this.cadMeasuringInstrumentList = this.cadMeasuringInstrumentList.filter(
      (cadMeasuringInstrumentList, _index) => {
        return cadMeasuringInstrumentList.id !== id;
      }
    );
    this.onRefreshCadMeasuringInstrumentList.next(this.cadMeasuringInstrumentList.slice());
  }
   //#endregion

  //#region Cad Tool
  
  addCadTool(cadTool: CadTool): void {
    this.cadToolList.push(cadTool);
    this.onRefreshCadToolList.next(this.cadToolList.slice());
  }

  getCadToolList(tamMainId: number) {
    return this.cadToolList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCadToolById(id: number | null): CadTool | undefined  {
    return this.cadToolList.find((item) => item.id === id );
  }

  getEditingSingleCadToolId(): any {
    return this.editingCadToolId;
  }
  
  getUpdateCadToolList(): Observable<any> {
    return this.onRefreshCadToolList.asObservable();
  }

  setCadToolList(cadToolList: CadTool[]): void {
    this.cadToolList = cadToolList;
    this.onRefreshCadToolList.next(this.cadToolList.slice());
  }
  
  setEditingCadToolId(id: number): void {
    this.editingCadToolId = id;
    this.onStartCadToolEditing.next(id);
  }

  upatedCadTool(csGeneric: CadTool, id?: number): void {
    const index = this.cadToolList.findIndex((singleCs) => singleCs.id === id);
    this.cadToolList[index] = csGeneric;
    this.onRefreshCadToolList.next(this.cadToolList.slice());
  }
  
  clearEditingCadToolId(): void {
    this.editingCadToolId = null;
    this.onStartCadToolEditing.next(null);
  }

  deleteCadTool(id: number): void {
    this.cadToolList = this.cadToolList.filter(
      (cadToolList, _index) => {
        return cadToolList.id !== id;
      }
    );
    this.onRefreshCadToolList.next(this.cadToolList.slice());
  }
  //#endregion

  
  //#region Cad MaterialConsumable
  
  addCadMaterialConsumable(cadMaterialConsumable: CadMaterialConsumable): void {
    this.cadMaterialConsumableList.push(cadMaterialConsumable);
    this.onRefreshCadMaterialConsumableList.next(this.cadMaterialConsumableList.slice());
  }

  getCadMaterialConsumableList(tamMainId: number) {
    return this.cadMaterialConsumableList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCadMaterialConsumableById(id: number | null): CadMaterialConsumable | undefined  {
    return this.cadMaterialConsumableList.find((item) => item.id === id );
  }

  getEditingSingleCadMaterialConsumableId(): any {
    return this.editingCadMaterialConsumableId;
  }
  
  getUpdateCadMaterialConsumableList(): Observable<any> {
    return this.onRefreshCadMaterialConsumableList.asObservable();
  }

  setCadMaterialConsumableList(cadMaterialConsumableList: CadMaterialConsumable[]): void {
    this.cadMaterialConsumableList = cadMaterialConsumableList;
    this.onRefreshCadMaterialConsumableList.next(this.cadMaterialConsumableList.slice());
  }
  
  setEditingCadMaterialConsumableId(id: number): void {
    this.editingCadMaterialConsumableId = id;
    this.onStartCadMaterialConsumableEditing.next(id);
  }

  upatedCadMaterialConsumable(csGeneric: CadMaterialConsumable, id?: number): void {
    const index = this.cadMaterialConsumableList.findIndex((singleCs) => singleCs.id === id);
    this.cadMaterialConsumableList[index] = csGeneric;
    this.onRefreshCadMaterialConsumableList.next(this.cadMaterialConsumableList.slice());
  }
  
  clearEditingCadMaterialConsumableId(): void {
    this.editingCadMaterialConsumableId = null;
    this.onStartCadMaterialConsumableEditing.next(null);
  }

  deleteCadMaterialConsumable(id: number): void {
    this.cadMaterialConsumableList = this.cadMaterialConsumableList.filter(
      (cadMaterialConsumableList, _index) => {
        return cadMaterialConsumableList.id !== id;
      }
    );
    this.onRefreshCadMaterialConsumableList.next(this.cadMaterialConsumableList.slice());
  }
  //#endregion
  
  //#region Cad Ppe
  
  addCadPpe(cadPpe: CadPpe): void {
    this.cadPpeList.push(cadPpe);
    this.onRefreshCadPpeList.next(this.cadPpeList.slice());
  }

  getCadPpeList(tamMainId: number) {
    return this.cadPpeList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCadPpeById(id: number | null): CadPpe | undefined  {
    return this.cadPpeList.find((item) => item.id === id );
  }

  getEditingSingleCadPpeId(): any {
    return this.editingCadPpeId;
  }
  
  getUpdateCadPpeList(): Observable<any> {
    return this.onRefreshCadPpeList.asObservable();
  }

  setCadPpeList(cadPpeList: CadPpe[]): void {
    this.cadPpeList = cadPpeList;
    this.onRefreshCadPpeList.next(this.cadPpeList.slice());
  }
  
  setEditingCadPpeId(id: number): void {
    this.editingCadPpeId = id;
    this.onStartCadPpeEditing.next(id);
  }

  upatedCadPpe(csGeneric: CadPpe, id?: number): void {
    const index = this.cadPpeList.findIndex((singleCs) => singleCs.id === id);
    this.cadPpeList[index] = csGeneric;
    this.onRefreshCadPpeList.next(this.cadPpeList.slice());
  }
  
  clearEditingCadPpeId(): void {
    this.editingCadPpeId = null;
    this.onStartCadPpeEditing.next(null);
  }

  deleteCadPpe(id: number): void {
    this.cadPpeList = this.cadPpeList.filter(
      (cadPpeList, _index) => {
        return cadPpeList.id !== id;
      }
    );
    this.onRefreshCadPpeList.next(this.cadPpeList.slice());
  }
  //#endregion

   
  //#region Cad LearningMaterial
  
  addCadLearningMaterial(cadLearningMaterial: CadLearningMaterial): void {
    this.cadLearningMaterialList.push(cadLearningMaterial);
    this.onRefreshCadLearningMaterialList.next(this.cadLearningMaterialList.slice());
  }

  getCadLearningMaterialList(tamMainId: number) {
    return this.cadLearningMaterialList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCadLearningMaterialById(id: number | null): CadLearningMaterial | undefined  {
    return this.cadLearningMaterialList.find((item) => item.id === id );
  }

  getEditingSingleCadLearningMaterialId(): any {
    return this.editingCadLearningMaterialId;
  }
  
  getUpdateCadLearningMaterialList(): Observable<any> {
    return this.onRefreshCadLearningMaterialList.asObservable();
  }

  setCadLearningMaterialList(cadLearningMaterialList: CadLearningMaterial[]): void {
    this.cadLearningMaterialList = cadLearningMaterialList;
    this.onRefreshCadLearningMaterialList.next(this.cadLearningMaterialList.slice());
  }
  
  setEditingCadLearningMaterialId(id: number): void {
    this.editingCadLearningMaterialId = id;
    this.onStartCadLearningMaterialEditing.next(id);
  }

  upatedCadLearningMaterial(csGeneric: CadLearningMaterial, id?: number): void {
    const index = this.cadLearningMaterialList.findIndex((singleCs) => singleCs.id === id);
    this.cadLearningMaterialList[index] = csGeneric;
    this.onRefreshCadLearningMaterialList.next(this.cadLearningMaterialList.slice());
  }
  
  clearEditingCadLearningMaterialId(): void {
    this.editingCadLearningMaterialId = null;
    this.onStartCadLearningMaterialEditing.next(null);
  }

  deleteCadLearningMaterial(id: number): void {
    this.cadLearningMaterialList = this.cadLearningMaterialList.filter(
      (cadLearningMaterialList, _index) => {
        return cadLearningMaterialList.id !== id;
      }
    );
    this.onRefreshCadLearningMaterialList.next(this.cadLearningMaterialList.slice());
  }
  //#endregion


    //#region Cad TrainingFacility
  
    addCadTrainingFacility(cadTrainingFacility: CadTrainingFacility): void {
      this.cadTrainingFacilityList.push(cadTrainingFacility);
      this.onRefreshCadTrainingFacilityList.next(this.cadTrainingFacilityList.slice());
    }
  
    getCadTrainingFacilityList(tamMainId: number) {
      return this.cadTrainingFacilityList.filter(item => item.tamMainId == tamMainId);
    }
   
    getCadTrainingFacilityById(id: number | null): CadTrainingFacility | undefined  {
      return this.cadTrainingFacilityList.find((item) => item.id === id );
    }
  
    getEditingSingleCadTrainingFacilityId(): any {
      return this.editingCadTrainingFacilityId;
    }
    
    getUpdateCadTrainingFacilityList(): Observable<any> {
      return this.onRefreshCadTrainingFacilityList.asObservable();
    }
  
    setCadTrainingFacilityList(cadTrainingFacilityList: CadTrainingFacility[]): void {
      this.cadTrainingFacilityList = cadTrainingFacilityList;
      this.onRefreshCadTrainingFacilityList.next(this.cadTrainingFacilityList.slice());
    }
    
    setEditingCadTrainingFacilityId(id: number): void {
      this.editingCadTrainingFacilityId = id;
      this.onStartCadTrainingFacilityEditing.next(id);
    }
  
    upatedCadTrainingFacility(csGeneric: CadTrainingFacility, id?: number): void {
      const index = this.cadTrainingFacilityList.findIndex((singleCs) => singleCs.id === id);
      this.cadTrainingFacilityList[index] = csGeneric;
      this.onRefreshCadTrainingFacilityList.next(this.cadTrainingFacilityList.slice());
    }
    
    clearEditingCadTrainingFacilityId(): void {
      this.editingCadTrainingFacilityId = null;
      this.onStartCadTrainingFacilityEditing.next(null);
    }
  
    deleteCadTrainingFacility(id: number): void {
      this.cadTrainingFacilityList = this.cadTrainingFacilityList.filter(
        (cadTrainingFacilityList, _index) => {
          return cadTrainingFacilityList.id !== id;
        }
      );
      this.onRefreshCadTrainingFacilityList.next(this.cadTrainingFacilityList.slice());
    }
    //#endregion
     
  //#region Cad TrainerAndStaff
  
  addCadTrainerAndStaff(cadTrainerAndStaff: CadTrainerAndStaff): void {
    this.cadTrainerAndStaffList.push(cadTrainerAndStaff);
    this.onRefreshCadTrainerAndStaffList.next(this.cadTrainerAndStaffList.slice());
  }

  getCadTrainerAndStaffList(tamMainId: number) {
    return this.cadTrainerAndStaffList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCadTrainerAndStaffById(id: number | null): CadTrainerAndStaff | undefined  {
    return this.cadTrainerAndStaffList.find((item) => item.id === id );
  }

  getEditingSingleCadTrainerAndStaffId(): any {
    return this.editingCadTrainerAndStaffId;
  }
  
  getUpdateCadTrainerAndStaffList(): Observable<any> {
    return this.onRefreshCadTrainerAndStaffList.asObservable();
  }

  setCadTrainerAndStaffList(cadTrainerAndStaffList: CadTrainerAndStaff[]): void {
    this.cadTrainerAndStaffList = cadTrainerAndStaffList;
    this.onRefreshCadTrainerAndStaffList.next(this.cadTrainerAndStaffList.slice());
  }
  
  setEditingCadTrainerAndStaffId(id: number): void {
    this.editingCadTrainerAndStaffId = id;
    this.onStartCadTrainerAndStaffEditing.next(id);
  }

  upatedCadTrainerAndStaff(csGeneric: CadTrainerAndStaff, id?: number): void {
    const index = this.cadTrainerAndStaffList.findIndex((singleCs) => singleCs.id === id);
    this.cadTrainerAndStaffList[index] = csGeneric;
    this.onRefreshCadTrainerAndStaffList.next(this.cadTrainerAndStaffList.slice());
  }
  
  clearEditingCadTrainerAndStaffId(): void {
    this.editingCadTrainerAndStaffId = null;
    this.onStartCadTrainerAndStaffEditing.next(null);
  }

  deleteCadTrainerAndStaff(id: number): void {
    this.cadTrainerAndStaffList = this.cadTrainerAndStaffList.filter(
      (cadTrainerAndStaffList, _index) => {
        return cadTrainerAndStaffList.id !== id;
      }
    );
    this.onRefreshCadTrainerAndStaffList.next(this.cadTrainerAndStaffList.slice());
  }
  //#endregion

   
  //#region Cad OtherRequirement
  
  addCadOtherRequirement(cadOtherRequirement: CadOtherRequirement): void {
    this.cadOtherRequirementList.push(cadOtherRequirement);
    this.onRefreshCadOtherRequirementList.next(this.cadOtherRequirementList.slice());
  }

  getCadOtherRequirementList(tamMainId: number) {
    return this.cadOtherRequirementList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCadOtherRequirementById(id: number | null): CadOtherRequirement | undefined  {
    return this.cadOtherRequirementList.find((item) => item.id === id );
  }

  getEditingSingleCadOtherRequirementId(): any {
    return this.editingCadOtherRequirementId;
  }
  
  getUpdateCadOtherRequirementList(): Observable<any> {
    return this.onRefreshCadOtherRequirementList.asObservable();
  }

  setCadOtherRequirementList(cadOtherRequirementList: CadOtherRequirement[]): void {
    this.cadOtherRequirementList = cadOtherRequirementList;
    this.onRefreshCadOtherRequirementList.next(this.cadOtherRequirementList.slice());
  }
  
  setEditingCadOtherRequirementId(id: number): void {
    this.editingCadOtherRequirementId = id;
    this.onStartCadOtherRequirementEditing.next(id);
  }

  upatedCadOtherRequirement(csGeneric: CadOtherRequirement, id?: number): void {
    const index = this.cadOtherRequirementList.findIndex((singleCs) => singleCs.id === id);
    this.cadOtherRequirementList[index] = csGeneric;
    this.onRefreshCadOtherRequirementList.next(this.cadOtherRequirementList.slice());
  }
  
  clearEditingCadOtherRequirementId(): void {
    this.editingCadOtherRequirementId = null;
    this.onStartCadOtherRequirementEditing.next(null);
  }

  deleteCadOtherRequirement(id: number): void {
    this.cadOtherRequirementList = this.cadOtherRequirementList.filter(
      (cadOtherRequirementList, _index) => {
        return cadOtherRequirementList.id !== id;
      }
    );
    this.onRefreshCadOtherRequirementList.next(this.cadOtherRequirementList.slice());
  }
  //#endregion

  setCadOtherRequirementForExcel(cadOtherRequirements: CadOtherRequirement[]): void {
    this.cadOtherRequirementList = [...this.cadOtherRequirementList, ...cadOtherRequirements];
    this.onRefreshCadOtherRequirementList.next(this.cadOtherRequirementList.slice());
  }

  setCadTrainerAndStaffForExcel(cadTrainerAndStaffs: CadTrainerAndStaff[]): void {
    this.cadTrainerAndStaffList = [...this.cadTrainerAndStaffList, ...cadTrainerAndStaffs];
    this.onRefreshCadTrainerAndStaffList.next(this.cadTrainerAndStaffList.slice());
  }

  setCadTrainingFacilityForExcel(cadTrainingFacilities: CadTrainingFacility[]): void {
    this.cadTrainingFacilityList = [...this.cadTrainingFacilityList, ...cadTrainingFacilities];
    this.onRefreshCadTrainingFacilityList.next(this.cadTrainingFacilityList.slice());
  }

  setCadLearningMaterialForExcel(cadLearningMaterials: CadLearningMaterial[]): void {
    this.cadLearningMaterialList = [...this.cadLearningMaterialList, ...cadLearningMaterials];
    this.onRefreshCadLearningMaterialList.next(this.cadLearningMaterialList.slice());
  }

  setCadRequiredFurnitureForExcel(cadFurnitures: CadFurniture[]): void {
    this.cadFurnitureList = [...this.cadFurnitureList, ...cadFurnitures];
    this.onRefreshCadFurnitureList.next(this.cadFurnitureList.slice());
  }
  //#region Excel upload for TAM

  setCadEquipmentsForExcel(cadEquipments: CadEquipment[]): void {
    this.cadEquipmentList = [...this.cadEquipmentList, ...cadEquipments];
    this.onRefreshCadEquipmentList.next(this.cadEquipmentList.slice());
  }
  setCadMeasuringInstrumentForExcel(cadMeasuringInstruments: CadMeasuringInstrument[]): void {
    this.cadMeasuringInstrumentList = [...this.cadMeasuringInstrumentList, ...cadMeasuringInstruments];
    this.onRefreshCadMeasuringInstrumentList.next(this.cadMeasuringInstrumentList.slice());
  }


  setCadToolForExcel(cadTools: CadTool[]): void {
    this.cadToolList = [...this.cadToolList, ...cadTools];
    this.onRefreshCadToolList.next(this.cadToolList.slice());
  }

  setCadMaterialConsumableForExcel(cadMaterialConsumable: CadMaterialConsumable[]): void {
    this.cadMaterialConsumableList = [...this.cadMaterialConsumableList, ...cadMaterialConsumable];
    this.onRefreshCadMaterialConsumableList.next(this.cadMaterialConsumableList.slice());
  }


  setCadPpesForExcel(cadPpes: CadPpe[]): void {
    this.cadPpeList = [...this.cadPpeList, ...cadPpes];
    this.onRefreshCadPpeList.next(this.cadPpeList.slice());
  }


  //#endregion Excel upload for TAM

getNullableFormCheck(cadData: any, tamMainId: number): boolean{
  const isFalsy =Object.values(cadData).every((value: any) => {
    if (!value || !value.toString().trim() || value == 0 || value == tamMainId) {
      return true;
    }
    return false;
  });
  return isFalsy;
}


}
