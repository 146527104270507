import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { CadMeasuringInstrument } from '../../../models/cad-measuring-instrument.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';

@Component({
  selector: 'app-add-cad-measuring-instrument',
  templateUrl: './add-cad-measuring-instrument.component.html',
  styleUrls: ['./add-cad-measuring-instrument.component.scss']
})
export class AddCadMeasuringInstrumentComponent implements OnInit {

  @Input() InputData: any;
  isEditMode: boolean = false;
  singleCad: CadMeasuringInstrument;
  onStartSingleCadEdit$: Subscription;
  onEditingSingleCadId!: number | null;
  cadForm: FormGroup;
  editedItem!: any;
  editedItemId!: number;
  cadList: CadMeasuringInstrument[] = [];
  tamMainId: number;
  onCadMeasuringInstrumentSubscription$: Subscription;


  // cadMeasuringInstrumentForm: FormGroup;
  // tamMainId: string | null;
  
  //#region Excel File Upload And Read Excel Data

  excelData: any[] = [];
  userInfo: any;
  userFile: any = File;
  allowedExtensions = '.xlsx,.xls';
  maxFormatFileSize = 3;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  total: number;
  page = 1;
  size = 10;

  //#endregion Excel File Upload And Read Excel Data
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private utilityService: UtilityService

  ) { 
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
  this.onEditingSingleCadId = this.cadService.getEditingSingleCadMeasuringInstrumentId();
  this.onStartSingleCadEdit$ =
    this.cadService.onStartCadMeasuringInstrumentEditing.subscribe(
      (res: number | null) => {
        this.onEditingSingleCadId = res;
      }
    );
  this.cadList = this.cadService.getCadMeasuringInstrumentList(this.tamMainId);
  if (this.cadList.length === 0) {
    this.cadStorageService.readCadMeasuringInstrumentListByTamId(this.tamMainId, this.page, this.size).subscribe();
  }
  this.onCadMeasuringInstrumentSubscription$ =
    this.cadService.onRefreshCadMeasuringInstrumentList.subscribe((res) => {
      this.cadList = res;
    });
}

  ngOnInit(): void {
    console.log(this.InputData);
    this.createForm();
    this.editedItemId = this.InputData;
    this.isEditMode = true;
    this.isEditMode = this.editedItemId !== null;
    this.editedItem = this.cadService.getCadMeasuringInstrumentById(this.editedItemId);
    
    this.cadForm.patchValue(this.editedItem);

    // this.createEquipmentForm();
    // this.tamMainId = this.route.snapshot.queryParamMap.get('tamMainId');

  }

  createForm() {
    this.cadForm = this.fb.group({
      measuringInsName: [],
      specification: [],
      unit: [],
      minQuantityReq: [0, [Validators.pattern("^[0-9]*$")]],
      remark: []
    });
  }

  resetForm(): void {
    this.cadForm.reset();
  }

  submitForm(): void {
    
    for (const key of Object.keys(this.cadForm.controls)) {
      this.cadForm.controls[key].markAsDirty();
      this.cadForm.controls[key].updateValueAndValidity();
    }
    this.singleCad = this.cadForm.value;
    this.singleCad.tamMainId = Number(this.tamMainId);
    const isFalsy = this.cadService.getNullableFormCheck(this.singleCad, this.tamMainId);
    if (isFalsy) {
      this.notification.error('Error', 'All input blank form submit is not allowed');
      return;
    }
    if (this.editedItemId != null) {
      this.cadStorageService.updateCadMeasuringInstrument(this.singleCad, this.editedItemId).subscribe({
        next: (response) =>
          this.notification.success('Success!', 'Measuring Instruments update successfully'),
        error: (error) => this.notification.error('Failed', error),
      });
    }else {
      const cadData: CadMeasuringInstrument = this.cadForm.value;
      const isFalsy = this.cadService.getNullableFormCheck(cadData, this.tamMainId);
      if (isFalsy) {
        this.notification.error('Error', 'All input blank form submit is not allowed');
        return;
      }

    if(!this.tamMainId) {
      this.notification.error('Error', 'Training and Assessment Material Id not found');
      return;
    }

    cadData.tamMainId = Number(this.tamMainId);
    cadData.minQuantityReq = Number(cadData.minQuantityReq);

    this.cadStorageService
      .createCadMeasuringInstrument(cadData)
      .subscribe({
        next: (response) => {
          this.notification.success('Success','Added Measuring Instruments data');
        },
        error: (error) =>
          this.notification.error(
            'Failed',
            'Please! provide valid information'
          ),
      });
      this.cadForm.reset();
    }
    }

  //#region Excel File Upload And Read Excel Data

  onSelectFile(event: any): void {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      this.myInputVariable.nativeElement.value = '';
      return;
    }
    const file = event.target.files[0];
    this.userFile = file;

    /* wire up file reader */
    const target: DataTransfer = event.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data); // Data will be logged in array format containing objects
      this.excelData = data;
      return data;
    };
  }

  submitExcelData(): void {
    const cadMeasuringInstrumentList: CadMeasuringInstrument[] = [];

    if (this.myInputVariable.nativeElement.value == 0) {
      this.notification.error('Failed!', 'Please upload measuring instrument excel file');
      return;
    } else {
      for (const item of this.excelData) {
        const cadMeasuringInstrument = new CadMeasuringInstrument();

        cadMeasuringInstrument.tamMainId = Number(this.tamMainId) ;
        cadMeasuringInstrument.measuringInsName = item.measuringInsName;
        cadMeasuringInstrument.specification = item.specification;
        cadMeasuringInstrument.unit = item.unit;
        cadMeasuringInstrument.minQuantityReq = item.minQuantityReq;
        cadMeasuringInstrument.remark = item.remark;

        cadMeasuringInstrumentList.push(cadMeasuringInstrument);

        const existItem = cadMeasuringInstrumentList.filter(
          (item) => item.measuringInsName == cadMeasuringInstrument.measuringInsName
        );


        for (let item of cadMeasuringInstrumentList) {
          let msg = item.measuringInsName;
          if (isNaN(item.minQuantityReq)) {
            this.notification.error(
              'Failed',
              `Invalid minimum quantity requierment for measuring instruments name:  ${msg}, Please correct and reupload!`
            );
            this.myInputVariable.nativeElement.value = '';
            return;
          }
        }

        if (existItem.length > 1) {
          this.notification.error(
            'Failed',
            'Duplicate measuring instrument name found in Excel, Please check!'
          );
          this.myInputVariable.nativeElement.value = '';
          return;
        }
      }
    }

    //#region Excel duplication value check

    // let existItem = this.excelData.filter(
    //   (item) => item.MeasuringInstrumentName == stpTrainer.MeasuringInstrumentName
    // );
    // if (existItem.length > 1) {
    //   this.notification.error(
    //     'Failed',
    //     'Duplicate Data found in Excel, Please check!'
    //   );
    //   return;
    // }

    //#endregion Excel duplication value check
    // if(this.cadStorageService.isStpMainAlreadyCreated){
      this.cadStorageService
        .saveExcelCadMeasurInginstrumentData(cadMeasuringInstrumentList)
        .subscribe({
          next: (response) => {
            this.notification.success(
              'Success!',
              'Cad measuring instrument created successfully'
            );
          },
          error: (error) =>
            this.notification.error(
              'Failed',
              'Please! provide valid information'
            ),
        });

    // }
    // else {
    //   this.notification.error(
    //     'Failed',
    //     'Please fill up basic profile first.'
    //   );
    // }

    this.myInputVariable.nativeElement.value = '';
    return;
  }




  //#endregion Excel File Upload And Read Excel Data



}
