import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { TamMain } from 'src/app/modules/training-and-assessment-materials/models/tam-main.model';
import { TrainingAndAssessmentMaterialsStorageService } from 'src/app/modules/training-and-assessment-materials/services/training-and-assessment-materials-storage.service';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { StpApplicationDto } from '../../DTO/stp_application_dto.model';
import { RequestData } from '../../models/stp_onlinePayment.model';
import { STPOwnershipType } from '../../models/stp_ownership_type.model';
import { StpRegistrationStorageService } from '../../services/stp-registration-storage.service';

@Component({
  selector: 'app-public-stp-applications-list',
  templateUrl: './public-stp-applications-list.component.html',
  styleUrls: ['./public-stp-applications-list.component.scss']
})
export class PublicStpApplicationsListComponent implements OnInit {

  stpApplicationDto: StpApplicationDto[] = [];
  stpBasicData: any;
  requestData: RequestData = new RequestData();
  userInfo: any;

  searchingForm: FormGroup = this.fb.group({
    stpApplicationId: [null],
    stpName: [null],
    stpOwnerShipStatusId: [null],
    sysDistrictId: [null],
    occupationId: [null],
    levelId: [null]
  });

  stpOwnershipTypeList: STPOwnershipType[] = [];
  districtList: any[];
  occupationList: any;
  levelList: any;
  tamMainList: TamMain[] = [];

  total: number;
  size: number = 10;
  page: number = 1;
  sortingKey: string;
  sortingValue: string;

  contentVisble = false;
  isStpBasicInfoModalVisible = false;
  isVisible = false;
  applicationPermissions = applicationPermissions;

  constructor(
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private tamStorageService: TrainingAndAssessmentMaterialsStorageService
  ) {
    this.userId();
  }

  ngOnInit(): void {
    this.getOwnerShipTypeList();
    this.getStpDistrictsList();
    this.getAllOccupation();
    this.loadstpApplicationsFromServer();
  }

  userId() {
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo') || '{}');
    this.requestData.id = this.userInfo.id;
    if(this.userInfo.id)
      this.contentVisble = true;
  }

  loadstpApplicationsFromServer(): void {
    this.stpRegistrationStorageService
      .getAllRegisteredStp(
        this.page,
        this.size,
        this.searchingForm.value,
        this.sortingKey,
        this.sortingValue
      )
      .subscribe({
        next: (res) => {
          this.stpApplicationDto = res?.data?.content;
          this.total = res?.data?.totalElements;
        },
      });
  }

  onSearch() {
    this.page = 1;
    this.size = 10;
    this.loadstpApplicationsFromServer();
  }

  onRefresh() {
    this.searchingForm.reset();
    this.onSearch();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex;
    this.size = params.pageSize;
    params.sort.forEach((element) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });
    this.loadstpApplicationsFromServer();
  }

  getOwnerShipTypeList() {
    this.stpRegistrationStorageService.readStpOwnerShipType().subscribe({
      next: (res) => {
        this.stpOwnershipTypeList = res;
      },
    });
  }

  getStpDistrictsList() {
    this.stpRegistrationStorageService.readStpDistricts().subscribe({
      next: (res) => {
        this.districtList = res;
      },
    });
  }

  getAllOccupation(){
    this.tamStorageService.readTamMains('', 2).subscribe({
      next: (res) => {
        this.tamMainList = res.data;
        this.occupationList = this.filterDistinctOccupations(this.tamMainList);
      },
    });
  }

  filterDistinctOccupations(tamMains: TamMain[]): any[] {
    let distinctArr: TamMain[] = [];

    tamMains.forEach(function (item: TamMain, index: number) {
      let i = distinctArr.findIndex(
        (x) => x.tamOccupation?.id === item.tamOccupation?.id
      );
      if (i == -1) {
        distinctArr.push(item);
      }
    });
    return distinctArr;
  }

  onSelectOccupation() {
    let occupationId = this.searchingForm.get('occupationId')?.value;

    this.levelList = [];
    this.searchingForm.get('levelId')?.setValue(null);
    this.searchingForm.get('levelId')?.updateValueAndValidity();

    this.getLevelsByOccupationId(occupationId);
  }

  getLevelsByOccupationId(occupationId: any): void {
    let level = this.tamMainList.filter((tamMain) => tamMain?.tamOccupation?.id === occupationId);

    this.levelList = level.map((obj) => {
      return {
        levelId: obj.tamLevel?.id,
        level: obj.tamLevel?.levelNo,
      };
    });
  }

  captchVerificationStatus(verificationStatus: { success: boolean, message: string }) {
    if (verificationStatus.success) {
      this.notification.success("Success", verificationStatus.message);
      this.contentVisble = true;
    } else {
      this.notification.error("Error", verificationStatus.message);
      this.contentVisble = false;
    }
  }

  showModal(): void {
    this.isStpBasicInfoModalVisible = true;
  }

  handleOk(): void {
    this.isStpBasicInfoModalVisible = false;
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isStpBasicInfoModalVisible = false;
    this.isVisible = false;
  }

  getStpAppBasicInfoById(stpMainId: number) {
    this.stpRegistrationStorageService.readStpBasicProfilebyId(stpMainId).subscribe(
      (res) => {
        if (res.success) {
          this.stpBasicData = res.data;
        } else {
          this.notification.error('Error!', res.message);
        }
      },
      (error) => {
        this.notification.error('Error!', 'Error getting STP basic info');
      }
    )
  }

  onStpMainClick(stpMainId: number) {
    this.getStpAppBasicInfoById(stpMainId);
    this.showModal();
  }
}
