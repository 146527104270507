import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Applicant } from '../models/applicant';
import { ApplicantOccupation } from '../models/applicantOccupation';

@Injectable({
  providedIn: 'root',
})
export class ApplicantService {
  onRefreshApplicantList = new Subject<Applicant[]>();
  applicantList: Applicant[] = [];

  onRefreshApplicantOccupationList = new Subject<ApplicantOccupation[]>();
  applicantOccupationList: ApplicantOccupation[] = [];

  rejecionReasonEmitter:EventEmitter<string> = new EventEmitter<string>();

  constructor(
  ) { }

  setApplicantList(applicantList: Applicant[]): void {
    this.applicantList = applicantList;
    this.onRefreshApplicantList.next(this.applicantList.slice());
  }

  setOccupationList(ApplicantOccupationList: ApplicantOccupation[]): void {
    this.applicantOccupationList = ApplicantOccupationList;
    this.onRefreshApplicantOccupationList.next(
      this.applicantOccupationList.slice()
    );
  }


  setRejectionReason(data:string){
    console.log(data);
    this.rejecionReasonEmitter.emit(data);
  }

  getRejectionReason():EventEmitter<string>{
     return this.rejecionReasonEmitter;
  }



}
