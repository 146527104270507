import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationStorageService } from '../../services/authentication-storage.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-NAP-login',
  templateUrl: './NAP-login.component.html',
  styleUrls: ['./NAP-login.component.scss']
})
export class NAPLoginComponent implements OnInit {

  passwordVisible = false;
  password?: string;

  loginForm: FormGroup = this.fb.group({
    userName: [null, [Validators.required]],
    password: [null, [Validators.required]],
    remember: [false],
  });

  constructor(
    private authenticationStorageService: AuthenticationStorageService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private notification: NzNotificationService
  ) {
    if (this.authenticationService.getIsUserAuthenticated()) {
      this.router.navigateByUrl('/NAP-home');
    }
  }

  ngOnInit() {
  }

  submitForm(): void {
    if (!this.loginForm.valid) {
      this.notification.error(
        'Form Validation Failed!',
        'Please Provide your email and password'
      );
      return;
    }

    const credentials = {
      usernameOrEmail: this.loginForm.controls.userName.value,
      password: this.loginForm.controls.password.value,
    };

    this.authenticationStorageService.NAPLogin(credentials).subscribe(
      (response) => {
        const returnUrl = this.route.snapshot.queryParams.returnUrl || '/NAP-home';

        this.notification.success(
          'Success!',
          'You have been logged in successfully'
        );

        this.router.navigateByUrl(returnUrl);
      },
      (errorMessage) => {
        this.notification.error('Failed', errorMessage)
      }
    );

    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
  }

  resetLoginForm() {
    this.loginForm.reset();
  }
}
