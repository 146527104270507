import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { AuthorizationGuard } from '../authorization/services/authorization.guard';
import { TciViewComponent } from './components/tci-view/tci-view.component';
import { TicListComponent } from './components/tic-list/tic-list.component';

const routes: Routes = [
  {
    path: 'tci-list',
    // data: { permissionName: applicationPermissions.tci.tciList.readPermission }, // 'read-tci' },
    // canActivate: [AuthorizationGuard],
    component: TicListComponent,
  },
  {
    path: 'competent-person-list',
    // data: { permissionName: applicationPermissions.tci.tciList.menuPermission }, // 'read-tci-view' },
    // canActivate: [AuthorizationGuard],
    component: TciViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateForCertificateIssuanceRoutingModule {}
