import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { MRA } from 'src/app/modules/mra/components/model/mra.model';
import { Country } from 'src/app/modules/mra/components/model/country.model';
import { AgreementType } from 'src/app/modules/mra/components/model/agreementType.model';
import { HttpErrorHandler } from 'src/app/shared/services/customhttp-error-handler';
import { MraInfoSearch } from 'src/app/modules/mra/components/model/mra-info-search';
import { Attachments } from 'src/app/modules/mra/components/model/attachment.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MraSearchDto } from '../components/model/mra.search.model';
import { MRAInfoSearch } from '../models/DTO/mra-info-search';
import { Attachment } from '../models/DTO/public-view-dto';
import { AccessType } from '../../policy-guidelines/components/documents/models/DTO/document-dto';
import { PaginatedResponse } from 'src/app/modules/mra/components/model/paginated-response.model';

@Injectable({
  providedIn: 'root',
})
export class MraStorageService {
  private apiUrlforAllMra = `${applicationUrls.pgDocument.getAllMra}`;
  private apiUrlforAddMra = `${applicationUrls.pgDocument.addMra}`;
  private apiUrlforDeleteMra= `${applicationUrls.pgDocument.deleteMra}`;
  private apiUrlforSearchMra= `${applicationUrls.pgDocument.searchMra}`;
  private apiUrlforMraReport= `${applicationUrls.pgDocument.getMraReport}`;
  private apiUrlforEditMra= `${applicationUrls.pgDocument.editMra}`;
  private apiGetMraById= `${applicationUrls.pgDocument.getMraById}`;
  private apiGetAttachmentByMraId= `${applicationUrls.pgDocument.getAttachmentByMraId}`;
  private apiGetPublicAttachmentByMraId= `${applicationUrls.pgDocument.getPublicAttachmentByMraId}`;
  private apiGetAttachmentFileByMraId= `${applicationUrls.pgDocument.getAttachmentFileByMraId}`;
  private apiUrlforAddAgreemantType = `${applicationUrls.pgDocument.addAgreementType}`;
  private apiUrlAgrementTypeByMraId = `${applicationUrls.pgDocument.getAgreementTypeByMraId}`;
  private apiUrlCountryByMraId = `${applicationUrls.pgDocument.getCountryByMraId}`;
  private apiUrlForAccessType = `${applicationUrls.pgDocument.getAllAccessType}`;
  private apiUrlForAgreementTypeSortedList = `${applicationUrls.pgDocument.getAllAgreementTypeWithSorting}`;
  private apiUrlForAgreementTypeDelete = `${applicationUrls.pgDocument.deleteAgreementType}`;
  private apiUrlForAgreementTypeEdit = `${applicationUrls.pgDocument.editAgreementType}`;
  private apiUrlForDeleteAttachment = `${applicationUrls.pgDocument.deleteAttachment}`;


  private handleError: <T>(operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

  previewUrl: SafeUrl | null = null;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private customErrorHandler: HttpErrorHandler
  ) {
    this.handleError = customErrorHandler.createErrorHandler('MRAService');
  }


  saveMRA(newMra: MRA): Observable<MRA> {
    return this.httpClient.post<MRA>(this.apiUrlforAddMra, newMra).pipe(
      catchError(this.handleError<MRA>('saveMra')) // Use correct type for error handling
    );
  }

  saveAgreementType(newAgreementType: AgreementType): Observable<AgreementType> {
    return this.httpClient.post<AgreementType>(this.apiUrlforAddAgreemantType, newAgreementType).pipe(
      catchError(this.handleError<AgreementType>('saveMra')) // Use correct type for error handling
    );
  }
  getAgreementTypewithSorting(page: number, size: number, sortingKey: string, sortingValue: string): Observable<PaginatedResponse<AgreementType>> {
    return this.httpClient.get<PaginatedResponse<AgreementType>>(`${this.apiUrlForAgreementTypeSortedList}`, {
      params: {
        page: page.toString(),
        size: size.toString(),
        sortingKey: sortingKey,
        sortingValue: sortingValue
      }
    }).pipe(
      catchError(this.handleError<PaginatedResponse<AgreementType>>('getAgreementType', { items: [], totalCount: 0 }))
    );
  }

  deleteAttachment(id:number, attachment: string): Observable<ServerResponse> {
    const params = {
      id: id,
      attachmentTitle: attachment,
    };
      return this.httpClient.post<ServerResponse>(`${this.apiUrlForDeleteAttachment}`, params);
  }

  deleteAgreementType(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrlForAgreementTypeDelete}/${id}`).pipe(
      catchError(this.handleError<void>('deleteAgreementType'))
    );
  }
  updateAgreementType(id: number, agreementTypeData: AgreementType): Observable<AgreementType> {
    return this.httpClient.put<AgreementType>(`${this.apiUrlForAgreementTypeEdit}/${id}`, agreementTypeData).pipe(
      catchError(this.handleError<AgreementType>('save Agreement Type')) // Use correct type for error handling
    );
  }

  updateMra(id: number, mraData: MRA): Observable<MRA> {
    return this.httpClient.post<MRA>(`${this.apiUrlforEditMra}/${id}`, mraData).pipe(
      catchError(this.handleError<MRA>('saveMra')) // Use correct type for error handling
    );
  }
  getMra(): Observable<MRA[]> {
    return this.httpClient.get<MRA[]>(this.apiUrlforAllMra).pipe(
      map((response) => response as MRA[]), // Cast response to Country[]
      catchError(this.handleError<MRA[]>('getMRA', [])) // Provide an empty array as a fallback
    );
  }

  deleteMra(id: number): Observable<ServerResponse> {
    return this.httpClient.delete<ServerResponse>(`${this.apiUrlforDeleteMra}/${id}`).pipe(
      catchError(this.handleError<ServerResponse>('deleteMra'))
    );
  }

  searchMra(filters: any, page: number, size: number, sortingKey?: string, sortingValue?: string): Observable<any> {
    const params = {
      page: page - 1,
      size: size,
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null,
      agreementTypeId: filters.agreementTypeId,
      nameOfOrganization: filters.nameOfOrganization,
      countryId: filters.countryId,
      subjectEnglish: filters.subjectEnglish,
      signingDateFrom: filters.signingDateFrom,
      signingDateTo: filters.signingDateTo
    };
      return this.httpClient.post<ServerResponse>(`${this.apiUrlforSearchMra}`, params);
  }

  getMraReport(filters: any): Observable<any> {
    const params = {
      agreementTypeId: filters.agreementTypeId,
      nameOfOrganization: filters.nameOfOrganization,
      countryId: filters.countryId,
      accessTypeId: filters.accessTypeId,
      signingDateFrom: filters.signingDateFrom,
      signingDateTo: filters.signingDateTo
    };

    return this.httpClient.post<ServerResponse>(`${this.apiUrlforMraReport}`, params);
  }

  getMraById(id: number): Observable<MRA> {
    return this.httpClient.get<MRA>(`${this.apiGetMraById}/${id}`).pipe(
      catchError(this.handleError<MRA>('getMraById'))
    );
  }

  getAttachmentByMraId(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${this.apiGetAttachmentByMraId}` + id
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }

  getPublicAttachmentByMraId(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${this.apiGetPublicAttachmentByMraId}` + id
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }

  getCountryByMraId(id: number):  Observable<Country> {
    return this.httpClient.get<Country>(`${this.apiUrlCountryByMraId}/${id}`).pipe(
      map((response) => response as Country), // Cast response to Country[]
      catchError(this.handleError<Country>('Country')) // Provide an empty array as a fallback
    );
  }



  getAgreementTypeByMraId(id: number):  Observable<AgreementType> {
    return this.httpClient.get<AgreementType>(`${this.apiUrlAgrementTypeByMraId}/${id}`).pipe(
      map((response) => response as AgreementType), // Cast response to Country[]
      catchError(this.handleError<AgreementType>('AgreementType')) // Provide an empty array as a fallback
    );
  }

  getAttachmentFileByMraId(id: number):  Observable<String[]> {
    return this.httpClient.get<String[]>(`${this.apiGetAttachmentFileByMraId}/${id}`).pipe(
      map((response) => response as String[]), // Cast response to Country[]
      catchError(this.handleError<String[]>('getMRAAttachment', [])) // Provide an empty array as a fallback
    );
  }

  fetchAndPreviewAttachment(filePath: string): void {
    this.httpClient.get(`api/attachments/${filePath}`, { responseType: 'blob' }).subscribe(
      (response) => {
        const objectURL = URL.createObjectURL(response);
        this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      },
      (error) => {
        console.error("Error fetching file preview:", error);
      }
    );
  }

  //#region read cs count
  readCSCount(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.mra.readCSCount}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion

  //#region read cs count
  readCADCount(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.mra.readCADCount}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion

  //#region read cs count
  readCBLMCount(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.mra.readCBLMCount}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion

  //#region read mra description
  readMraDescription(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.mra.readMraDescription}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion

  //#region read mra description
  readRssFeedData(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.mra.readRssFeedData}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion

  readMraPublicInfo(
      mraInfoSearch: MRAInfoSearch
    ): Observable<ServerResponse> {
      mraInfoSearch.disabled = false;
      return this.httpClient.post<ServerResponse>(
        `${applicationUrls.pgDocument.readMraListPublic}`,
        mraInfoSearch
      );
  }

  getAllAccessType(): Observable<AccessType[]> {
    return this.httpClient.get<AccessType[]>(this.apiUrlForAccessType).pipe(
      map((response) => response as AccessType[]), // Cast response to AccessType[]
      catchError(this.handleError<AccessType[]>('AgreementType', [])) // Provide an empty array as a fallback
    );
  }

  saveMraInfo(
    mraInfo: any,
  ): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.mra.saveMraInfo}`,
      mraInfo
    );
  }

  updateMraInfo(
    mraInfo: any,
    mraId: number | null,
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.mra.updateMraInfo}/` +
      mraId,
        mraInfo
    );
  }

  getMRAInfoById(
      id: number
    ): Observable<ServerResponse> {
      const params = new HttpParams().append(
        'id',
        `${id}`
      );
      return this.httpClient.get<ServerResponse>(
        `${applicationUrls.mra.getMraInfoById}`,
        { params }
      );
    }
}
