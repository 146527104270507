import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Idle } from 'idlejs';
import { environment } from 'src/environments/environment';
import { AuthenticationStorageService } from './modules/authentication/services/authentication-storage.service';
import { AuthenticationService } from './modules/authentication/services/authentication.service';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authenticationStorageService: AuthenticationStorageService,
    private authenticationService: AuthenticationService,
    private router: Router,
    // private translate: TranslateService,
  ) {
    this.authenticationStorageService.autoLoginUser();
    this.authenticationStorageService.NAPAutoLoginUser();
    // this.translate.setDefaultLang('en')
  }
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);

      const idle = new Idle()
        .whenNotInteractive()
        .within(environment.autoLogoutTime.inMinute)
        .do(() => this.logout())
        .start();
    });
  }

  logout() {
    this.authenticationService.logout();
  }

  // switchLanguage(language:string){
  //   this.translate.use(language);
  // }
}
