import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptchaComponent } from './captcha/captcha.component';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';

@NgModule({
  declarations: [CaptchaComponent],
  imports: [
    CommonModule,
    SharedModule,    
    NgZorroAntdModule,
    FormsModule,  
    RecaptchaModule
  ],
  exports:[
    CaptchaComponent
  ]
})
export class ReCaptchaModule { }
