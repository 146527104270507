import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-registration-category',
  templateUrl: './registration-category.component.html',
  styleUrls: ['./registration-category.component.scss'],
})
export class RegistrationCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
