import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tci-certificate-list',
  templateUrl: './tci-certificate-list.component.html',
  styleUrls: ['./tci-certificate-list.component.scss']
})
export class TciCertificateListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
