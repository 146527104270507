export class SelfRegistrationRequest {
  public id?: number;
  public name?: string;
  //public userName?: string;
  // public userName?: string;
  public nid?: string;
  public designation?: string;
  public password?: string;
  public email?: string;
  public contactNumber?: string;
  public contactNumberPrefix?: string;
  public role?: string;
  constructor() { } // public contactNumberPrefix: string // public contactNumber: string, // public email: string, // public password: string, // public userName: string, // public name: string, // public id: number,
}
