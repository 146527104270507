import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ErrorHandler, HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';
import { Permission } from '../../authorization/models/permission.model';
import { AuthorizationService } from '../../authorization/services/authorization.service';
import { LoginResponse } from '../models/login-response.dto';
import { Login } from '../models/login.model';
import { ServerResponse } from './../../../shared/models/dto/server-response.dto';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStorageService {
  private handleError: ErrorHandler;
  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = this.httpErrorHandler.createErrorHandler(
      'Authentication Service'
    );
  }

  createUser(newUserInfo: any) {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.user.register}`, newUserInfo)
      .pipe(
        catchError(this.handleError<never>('Create User')),
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        }),
        tap(
          (data) => {
          },
          (error) => {
          }
        )
      );
  }

  createStpUser(newUserInfo: any): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.user.createStpUser}`,
        newUserInfo
      )
      .pipe(
        catchError(this.handleError<never>('Create Stp User')),
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap(
          (data) => {
          },
          (error) => {
          }
        )
      );
  }

  login(userLoginCredential: Login): Observable<LoginResponse> {
    return this.httpClient
      .post<ServerResponse>(applicationUrls.user.login, userLoginCredential)
      .pipe(
        catchError(this.handleError<never>('User login')),
        map((response: ServerResponse) => {
          return response.data;
        }),
        tap(
          (currentUser: LoginResponse) => {
            this.authenticationService.saveCurrentUserInfo(currentUser);
            const currentUserPermissions: Permission[] = [];

            currentUser.roles.forEach((i) =>
              currentUserPermissions.push(...i.permissions)
            );

            this.authorizationService.CurrentUserPermissions =
              currentUserPermissions;

            this.authorizationService.setCurrentUserLocations(
              currentUser.userLocations
            );
          },
          (error) => {
            this.authenticationService.currentUserSubject.next(null);
          }
        )
      );
  }

  NAPLogin(userLoginCredential: Login): Observable<LoginResponse> {
    return this.httpClient
      .post<any>(applicationUrls.agencyUser.login, userLoginCredential)
      .pipe(
        catchError(this.handleError<never>('User login')),
        map((response: any) => {
          return response;
        }),
        tap(
          (currentUser: any) => {
            this.authenticationService.saveNAPUserInfo(currentUser);
          },
          (error) => {
            this.authenticationService.currentUserSubject.next(null);
          }
        )
      );
  }

  autoLoginUser(): void {
    const currentUserInfo = this.authenticationService.getCurrentUserInfo();
    if (!currentUserInfo) {
      return;
    }
    const now = new Date();
    const tokenExpiresIn =
      currentUserInfo.tokenExpirationDate.getTime() - now.getTime();
    if (tokenExpiresIn > 0) {
      this.authenticationService.saveCurrentUserInfo(currentUserInfo);
      const currentUserPermissions: Permission[] = [];

      currentUserInfo.roles.forEach((i) =>
        currentUserPermissions.push(...i.permissions)
      );

      this.authorizationService.CurrentUserPermissions = currentUserPermissions;
      this.authorizationService.setCurrentUserLocations(
        currentUserInfo.userLocations
      );
    }
  }

  NAPAutoLoginUser(): void {
    const currentUserInfo = this.authenticationService.getNAPUserInfo();

    if (!currentUserInfo) {
      return;
    }
    const now = new Date();
    const tokenExpiresIn = currentUserInfo.tokenExpirationDate.getTime() - now.getTime();
    if (tokenExpiresIn > 0) {
      this.authenticationService.saveNAPUserInfo(currentUserInfo);
    }
  }

  readUserByDecreptId(id: string | null): Observable<ServerResponse> {
    const userId = encodeURIComponent(id??"");
    const url = applicationUrls.userVerification.readById+'?id='+userId;
    return this.httpClient.get<ServerResponse>(
      `${url}`);
  }

  checkEmailWithIdExist(email: string, id: number): Observable<ServerResponse> {
    const params = new HttpParams().append('email', `${email}`).append('id', `${id}`);

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.userVerification.checkEmailWithProfileExist}`,{params})
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  checkEmailExist(email: string): Observable<ServerResponse> {
    const params = new HttpParams().append('email', `${email}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.userVerification.checkEmailExist}`,
        {
          params,
        }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  checkNapEmailExist(email: string): Observable<any> {
    const params = new HttpParams().append('email', `${email}`);
    return this.httpClient.get<ServerResponse>(`${applicationUrls.agencyUser.checkEmailExist}`,{params})
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  checkStpNameExist(stpName: string): Observable<ServerResponse> {
    const params = new HttpParams().append('stpName', `${stpName}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.userVerification.checkStpNameExist}`,
        {
          params,
        }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  //#region Applicant

  checkApplicantEmailExist(email: string): Observable<ServerResponse> {
    const params = new HttpParams().append('email', `${email}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.freelancerUserVerification.checkEmailExist}`,
        {
          params,
        }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  checknameExist(name: string): Observable<ServerResponse> {
    const params = new HttpParams().append('name', `${name}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.freelancerUserVerification.checkNameExist}`,
        {
          params,
        }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  createApplicant(newUserInfo: any) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.freelancerUser.register}`,
        newUserInfo
      )
      .pipe(
        catchError(this.handleError<never>('Create User')),
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        }),
        tap(
          (data) => {
          },
          (error) => {
          }
        )
      );
  }


  createAssessor(newUserInfo: any) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.assessorUser.register}`,
        newUserInfo
      )
      .pipe(
        catchError(this.handleError<never>('Create User')),
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        }),
        tap(
          (data) => {
          },
          (error) => {
          }
        )
      );
  }

  createApplicantUser(newUserInfo: any): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.freelancerUser.createFreelancerUser}`,
        newUserInfo
      )
      .pipe(
        catchError(this.handleError<never>('Create Freelancer User')),
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap(
          (data) => {
          },
          (error) => {
          }
        )
      );
  }

  //#region OTP

  getVerifiedOtp(
    otpNumber: string,
    contactNumber: string
  ): Observable<ServerResponse> {
    const params = new HttpParams()
      .append('mobileNo', `${contactNumber}`)
      .append('otp', `${otpNumber}`);
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.freelancerOtp.readOtp}`, {
        params,
      })
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  getOtp(contactNumber: string): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.freelancerOtp.readContact + contactNumber}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  //#endregion OTP

  //#endregion Applicant
}
