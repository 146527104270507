import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { CadPpe } from '../../../models/cad-ppe.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';
import { AddCadPpeComponent } from '../add-cad-ppe/add-cad-ppe.component';

@Component({
  selector: 'app-cad-ppe-table',
  templateUrl: './cad-ppe-table.component.html',
  styleUrls: ['./cad-ppe-table.component.scss']
})
export class CadPpeTableComponent implements OnInit {

  // @Input() tamMainId: string | null;
  // @Input() createButton: boolean;

  // modalRef: NzModalRef;
  // cadList: CadPpe[] = [];
  // onCadPpeSubscription$: Subscription;

  @Input() tamMainId: number | null;
  @Input() createButton: boolean;
  showingAction: boolean | null;

  modalRef: NzModalRef;
  cadList: CadPpe[] = [];
  cadListFiltered: CadPpe[] = [];
  onCadPpeSubscription$: Subscription;

  page = 1;
  size = 10;
  total: number;

  constructor(
    private modal: NzModalService,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private route: ActivatedRoute
  ) { 
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
    const urlPath = String(this.route.snapshot.url[0].path);
    (urlPath.includes("tam-view")) ? this.showingAction = true : this.showingAction = false;
  this.cadList = this.cadService.getCadPpeList(this.tamMainId);
  this.cadListFiltered = [...this.cadList];
  if (this.cadList.length === 0) {
    this.cadStorageService.readCadPpeListByTamId(this.tamMainId,this.page, this.size).subscribe();
  }
  this.onCadPpeSubscription$ =
    this.cadService.onRefreshCadPpeList.subscribe(
      (res) => {
        this.cadList = res;
        this.cadListFiltered = [...this.cadList];
      }
    );
   }

  ngOnInit(): void {
    this.getCadList();
  }

  getCadList() {
    if(!this.tamMainId) {
      this.notification.error('Error', 'Training and Assessment Material Id not found');
    }

    // this.cadList = this.cadService.getCadPpeList(Number(this.tamMainId));

    // if(this.cadList.length == 0) {
    //   this.cadStorageService.readCadPpeListByTamId(Number(this.tamMainId), this.page, this.size)
    //   .subscribe(
    //     {
    //       next: (res) => {
    //         this.cadList = res.data;
    //         this.total = res.count;
    //       },
    //     }

    //   );
    // }

    // this.onCadPpeSubscription$ = this.cadService.onRefreshCadPpeList.subscribe(
    //   (res) => {
    //     this.cadList = res.filter(
    //       (item) =>
    //         item.tamMainId == this.tamMainId ||
    //         item.tamMain.id == this.tamMainId
    //     );

    //     if(this.modalRef) {
    //       this.modalRef.close();
    //     }
    //   }
    // );
  }

  createCustomButtonModal(data? : any): void {
    this.modalRef = this.modal.create({
      nzContent: AddCadPpeComponent,
      nzClosable: false,
      nzOkText: null,
      nzWidth: 1000,
      nzComponentParams: {
        InputData: data
      }
    });
  }

  ngOnDestroy(): void {
      this.onCadPpeSubscription$.unsubscribe();
  }


  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params);
    this.page = params.pageIndex;
    this.size = params.pageSize;
    const { pageSize, pageIndex, sort } = params;
    this.getCadList();
  }

  onEdit(id: number): void {
    this.cadService.setEditingCadPpeId(id);
    this.createCustomButtonModal(id);
  }

  onDelete(id: number): void {
    this.cadStorageService.deleteCadPpe(id).subscribe({
      next: (response) => {
        this.notification.success('Success!', 'Staff deleted successfully');
        this.getCadList();
      },
      error: (error) => this.notification.error('Failed!', error),
    });
  }


}
