import { SendEmailAssessmentCertificateComponent } from './send-email-assessment-certificate/send-email-assessment-certificate.component';
import { DownloadCertificatePortalComponent } from './download-certificate-portal/download-certificate-portal.component';
import { TciCertificateListComponent } from './tci-certificate-list/tci-certificate-list.component';
import { SharedCertificateModule } from './modules/shared-certificate/shared-certificate.module';
import { SharedCourseAccreditationModule } from './modules/shared-course-accreditation/shared-course-accreditation.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData, DatePipe, TitleCasePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { SharedModule } from './shared/modules/shared/shared.module';
import { CoreModule } from './core/core.module';
import {
  NgxUiLoaderHttpModule,
  NgxUiLoaderHttpConfig,
  NgxUiLoaderRouterModule,
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
} from 'ngx-ui-loader';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { P404Component } from './modules/error/404.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { OtpForCompleteInspectionPreviewComponent } from './modules/otp-for-complete-inspection-preview/otp-for-complete-inspection-preview.component';
import { OtpForStpSacInspectionReportPreviewComponent } from './modules/otp-for-stp-sac-inspection-report-preview/otp-for-stp-sac-inspection-report-preview.component';
import { OtpForSharedPreviewInspectionReportComponent } from './modules/otp-for-shared-preview-inspection-report/otp-for-shared-preview-inspection-report.component';
import { LandingModule } from './modules/landing/landing.module';
import { NAPDefaultLayoutModule } from './shared/modules/NAP-default-layout/NAP-default-layout.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  // fgsType: SPINNER.chasingDots, // foreground spinner type
  // pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  // pbThickness: 5, // progress bar thickness
};

const ngxUiLoaderHttpConfig: NgxUiLoaderHttpConfig = {
  // showForeground: true,
};


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  notification: { nzPlacement: 'topRight' },
};

@NgModule({
  declarations: [
    AppComponent,
    P404Component,
    OtpForCompleteInspectionPreviewComponent,
    OtpForStpSacInspectionReportPreviewComponent,
    OtpForSharedPreviewInspectionReportComponent,
    TciCertificateListComponent,
    DownloadCertificatePortalComponent,
    SendEmailAssessmentCertificateComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    SharedModule,
    CoreModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot(ngxUiLoaderHttpConfig),
    NgbModule,
    QRCodeModule,
    PdfViewerModule,
    SharedCourseAccreditationModule,
    SharedCertificateModule,
    SharedCourseAccreditationModule,
    LandingModule,
    NAPDefaultLayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    DatePipe,
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    DatePipe,
    TitleCasePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
