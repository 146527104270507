import { IndustrySkillsCouncilService } from './../../services/industry-skills-council.service';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-published-isc-list',
  templateUrl: './published-isc-list.component.html',
  styleUrls: ['./published-isc-list.component.scss']
})
export class PublishedIscListComponent implements OnInit {

  publishedIscList: any;
  publishedIscListDemo: { name: string, link: string }[] = [
    { name: 'Agro Food ISC', link: 'https://nsda.portal.gov.bd/site/page/3213bf21-c0a6-44a1-bec6-aeec3a5382b4' },
    { name: 'Ceramic ISC', link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/19e5a19d_d51c_4d2a_ab2d_0432be16ccf4/2023-08-27-05-35-de12f835f0b6df974afcf067d99b0c64.pdf' },
    { name: 'Furniture ISC', link: 'https://nsda.portal.gov.bd/site/page/61378dd5-fa07-45fc-b2c9-71671c5d4e1f' },
    { name: 'ICT ISC', link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/ecef705e_8488_40f3_be1e_5e261ff7a8ad/2021-08-25-10-51-e1e6eddf003b49d618c40b71293e6df3.pdf' },
    { name: 'Informal Sector ISC', link: 'https://nsda.portal.gov.bd/site/page/be64c039-31a4-4fae-b83c-c8467755dd22' },
    { name: 'Leather & Leather Goods ISC', link: 'https://nsda.portal.gov.bd/site/page/256e9f4f-83d3-4788-b307-8ef6559ab5f2' },
    { name: 'Light Engineering ISC', link: 'https://nsda.portal.gov.bd/site/page/7ebf7f3b-435a-4021-97e5-6e10800dde73' },
    { name: 'Pharmaceuticals ISC', link: 'https://nsda.portal.gov.bd/site/page/4521be3e-f808-487c-a961-b435df25b716' },
    { name: 'Tourism & Hospitality ISC', link: 'https://nsda.portal.gov.bd/site/page/514194f8-f186-4ebe-9cd8-b792376c442a' },
    { name: 'Creative Media ISC', link: 'https://nsda.portal.gov.bd/site/page/2cc50e57-262a-4a38-aeb9-9860c732dda2' },
    { name: 'Jute Sector ISC', link: 'https://nsda.portal.gov.bd/site/page/2ef87590-1180-4dae-8cee-e29fb552f670' },
    { name: 'RMG & Textile ISC', link: 'https://www.rtisc.org/' },
    { name: 'Construction ISC', link: 'https://ciscbd.com/' },
    { name: 'Plastics ISC', link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/72a24711_d0dd_43db_8423_cba797d5266d/2023-11-13-09-31-0a74b046591c4b6f5e2c15aa8553a1ee.pdf' },
    { name: 'Agriculture ISC', link: '' },
    { name: 'Logistics ISC', link: '' }
  ];

  pageIndex: number;
  sortingKey: any;
  sortingValue: any;
  total: number;
  pageSize: number = 50;
  currentPage: number = 1;

  constructor(
    private notification: NzNotificationService,
    private IndustrySkillsCouncilService: IndustrySkillsCouncilService
  ) {}

  ngOnInit() {
  }

  getPublishedIscList() {
    // this.IndustrySkillsCouncilService.getPublishedIscList(this.pageIndex - 1, this.pageSize, this.sortingKey, this.sortingValue)
    //   .subscribe({
    //     next: (res) => {
    //       this.publishedIscList = res?.data.content;
    //       this.total = res?.data.totalElements;
    //     },
    //     error: (error) => this.notification.error('Failed', error.error.message)
    //   });
  }

  onPageIndexChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;

    params.sort.forEach((element: { value: string | null; key: string }) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });
    this.getPublishedIscList();
  }
}
