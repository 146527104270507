import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationStorageService } from '../../services/authentication-storage.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  passwordVisible = false;
  password?: string;
  loginForm: FormGroup = this.fb.group({
    userName: [null, [Validators.required]],
    password: [null, [Validators.required]],
    remember: [false],
  });

  constructor(
    private authenticationStorageService: AuthenticationStorageService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private notification: NzNotificationService
  ) {
    if (this.authenticationService.getIsUserAuthenticated()) {
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit() {
  }

  submitForm(): void {
    if (!this.loginForm.valid) {
      this.notification.error(
        'Form Validation Failed!',
        'Please Provide your email and password'
      );
      return;
    }

    const credentials = {
      usernameOrEmail: this.loginForm.controls.userName.value,
      password: this.loginForm.controls.password.value,
    };
    let returnUrl: any;
    this.authenticationStorageService.login(credentials).subscribe(
      (response) => {
        const returnUrl = this.route.snapshot.queryParams.returnUrl || '/home';

        this.notification.success(
          'Success!',
          'You have been logged in successfully'
        );

        this.router.navigateByUrl(returnUrl);

        //   if (response.roles[0].name.toLowerCase() === 'user') {
        //     this.stpRegistrationStorageService
        //       .getlUserInfoById(response.id)
        //       .subscribe(
        //         (rs) => {
        //           if (rs.success) {
        //             let data = rs.data;
        //             let isCompleted: string = '';
        //             if (data.stpUserStatus) {
        //               isCompleted = data.stpUserStatus.status;
        //               if (isCompleted == 'Completed') {
        //                 localStorage.setItem(
        //                   'isUserApplicationSubmitted',
        //                   'true'
        //                 );
        //                 returnUrl =
        //                   '/home/stp-registration/stp-application-view/' +
        //                   data.stpMain.id;
        //               } else {
        //                 localStorage.setItem(
        //                   'isUserApplicationSubmitted',
        //                   'false'
        //                 );
        //                 returnUrl = '/home/stp-registration/dash-board';
        //               }
        //             }
        //             // else {
        //             //   localStorage.setItem('isUserApplicationSubmitted', 'false');
        //             //   returnUrl = '/home/stp-registration/dash-board';
        //             // }
        //             this.router.navigateByUrl(returnUrl);
        //             this.notification.success(
        //               'Success!',
        //               'You have been logged in successfully'
        //             );
        //           }
        //         },
        //         (errorMessage) => this.notification.error('Failed', errorMessage)
        //       );
        //   } else if (
        //     response.roles[0].name.toLowerCase() === 'Admin'.toLowerCase()
        //   ) {
        //     returnUrl = '/home/stp-registration/stp-applications';
        //   } else if (
        //     response.roles[0].name.toLowerCase() === 'Tam_User'.toLowerCase()
        //   ) {
        //     returnUrl = '/home/training-and-assessment-materials/tam-list';
        //   }

        //   this.router.navigateByUrl(returnUrl);
      },

      (errorMessage) => this.notification.error('Failed', errorMessage)
    );

    // tslint:disable-next-line:forin
    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
  }

  // tslint:disable-next-line:typedef
  resetLoginForm() {
    this.loginForm.reset();
  }
}
