import { SubmissionData } from './../models/submissionData';
import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BasicInfoData } from '../../course-accreditation/DTO/basicInfoTabData';
import { InspectionResultResponse } from '../../course-accreditation/DTO/InspectionResultResponseDto';
import { BasicInformationResponse } from '../../course-accreditation/models/basic-information-response';
import { InspectionOfficer } from '../../course-accreditation/models/inspectionOfficer';
import { PaymentVerification } from '../../course-accreditation/models/paymentVerification.model';
import { SubmissionEmitter } from '../models/submission-emitter';
import { Recommendation } from 'src/app/modules/course-acc-inspection-report/models/recommendation';

@Injectable()
export class CourseAccInspectionReportService {
  onStartPaymentVerificationEditing = new Subject<number | null>();
  onRefreshPaymentVerificationList = new Subject<PaymentVerification[]>();
  paymentVerifications: PaymentVerification[] = [];
  editingpaymentVerificationId!: number | null;

  //#region Emitter
  courseAcrreditationEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  basicInfoDataEmitter: EventEmitter<BasicInfoData> =
    new EventEmitter<BasicInfoData>();
  machineryEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  measuringInstrumentEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  toolEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  materialsConsumableEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  ppeEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  furnitureEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  learningMaterialEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  trainingFacilityEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  trainersOtherStaffEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  otherRequirementEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  cctvEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  submissionEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();

  occupationListEmitter: EventEmitter<InspectionResultResponse[]> =
    new EventEmitter<InspectionResultResponse[]>();

  addNewOccupationEmitter: EventEmitter<number> = new EventEmitter<number>();
  formResetEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  inspectionIdForNewOccupation: EventEmitter<number> =
    new EventEmitter<number>();

  submissionDataEmitter: EventEmitter<BasicInformationResponse> = new EventEmitter<BasicInformationResponse>();

  previousBtnEmitter:EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion Emitter

  onStartBasicInformationEditing = new Subject<number | null>();
  onRefreshBasicInformationList = new Subject<any[]>();
  basicInformations: any[] = [];
  editingBasicInformationId?: number | null;

  onStartScheduleInspectionEditing = new Subject<number | null>();
  onRefreshScheduleInspectionList = new Subject<any[]>();
  scheduleInspections: any[] = [];
  editingScheduleInspectionId?: number | null;

  onStartInspectionOfficerEditing = new Subject<number | null>();
  onRefreshInspectionOfficerList = new Subject<any[]>();
  inspectionOfficers: InspectionOfficer[] = [];
  editingInspectionOfficerId?: number | null;


  onStartRecommandationEditing = new Subject<number | null>();
  onRefreshRecommandationList = new Subject<any[]>();
  recommandations: Recommendation[] = [];
  editingRecommandationId?: number | null;

  constructor() {}

  //#region  payment
  setPaymentVerifications(paymentVerifications: PaymentVerification[]): void {
    this.paymentVerifications = paymentVerifications;
    this.onRefreshPaymentVerificationList.next(
      this.paymentVerifications.slice()
    );
  }

  addPaymentVerification(paymentVerification: PaymentVerification): void {
    this.paymentVerifications.push(paymentVerification);
    this.onRefreshPaymentVerificationList.next(
      this.paymentVerifications.slice()
    );
  }

  //#endregion payment

  //#region Course Acrreditation (Parent)

  setCourseAcrreditationTamId(data: any): void {
    this.courseAcrreditationEmitter.emit(data);
  }

  getCourseAcrreditationTamId(): EventEmitter<BasicInformationResponse> {
    return this.courseAcrreditationEmitter;
  }
  //#endregion Course Acrreditation (Parent)

  //#region Machinery (Child)

  setMachineryTamId(data: BasicInformationResponse) {
    // console.log(data);
    this.machineryEmitter.emit(data);
  }

  getMachineryTamId(): EventEmitter<BasicInformationResponse> {
    return this.machineryEmitter;
  }
  //#endregion Machinery (Child)

  //#region measuring-instruments (Child)

  setMeasuringInstrumentTamId(data: BasicInformationResponse) {
    this.measuringInstrumentEmitter.emit(data);
  }

  getMeasuringInstrumentTamId(): EventEmitter<BasicInformationResponse> {
    return this.measuringInstrumentEmitter;
  }
  //#endregion measuring-instruments (Child)

  //#region Tools (Child)

  setToolTamId(data: BasicInformationResponse) {
    this.toolEmitter.emit(data);
  }

  getToolTamId(): EventEmitter<BasicInformationResponse> {
    return this.toolEmitter;
  }
  //#endregion Tools (Child)

  //#region Material Consumable (Child)

  setMaterialsConsumableTamId(data: BasicInformationResponse) {
    this.materialsConsumableEmitter.emit(data);
  }

  getMaterialsConsumableTamId(): EventEmitter<BasicInformationResponse> {
    return this.materialsConsumableEmitter;
  }
  //#endregion Material Consumable (Child)

  //#region PPE (Child)

  setPpeTamId(data: BasicInformationResponse) {
    this.ppeEmitter.emit(data);
  }

  getPpeTamId(): EventEmitter<BasicInformationResponse> {
    return this.ppeEmitter;
  }
  //#endregion PPE (Child)

  //#region Furniture (Child)

  setFurnitureTamId(data: any) {
    this.furnitureEmitter.emit(data);
  }

  getFurnitureTamId(): EventEmitter<BasicInformationResponse> {
    return this.furnitureEmitter;
  }
  //#endregion Furniture (Child)

  //#region LearningMaterial (Child)

  setLearningMaterialTamId(data: BasicInformationResponse) {
    this.learningMaterialEmitter.emit(data);
  }

  getLearningMaterialTamId(): EventEmitter<BasicInformationResponse> {
    return this.learningMaterialEmitter;
  }
  //#endregion LearningMaterial (Child)

  //#region TrainingFacility (Child)

  setTrainingFacilityTamId(data: BasicInformationResponse) {
    this.trainingFacilityEmitter.emit(data);
  }

  getTrainingFacilityTamId(): EventEmitter<BasicInformationResponse> {
    return this.trainingFacilityEmitter;
  }
  //#endregion TrainingFacility (Child)

  //#region TrainersOtherStaff (Child)

  setTrainersOtherStaffTamId(data: BasicInformationResponse) {
    this.trainersOtherStaffEmitter.emit(data);
  }

  getTrainersOtherStaffTamId(): EventEmitter<BasicInformationResponse> {
    return this.trainersOtherStaffEmitter;
  }
  //#endregion TrainersOtherStaff (Child)

  //#region otherRequirement (Child)

  setOtherRequirementTamId(data: BasicInformationResponse) {
    this.otherRequirementEmitter.emit(data);
  }

  getOtherRequirementTamId(): EventEmitter<BasicInformationResponse> {
    return this.otherRequirementEmitter;
  }
  //#endregion otherRequirement (Child)

  //#region CCTV (Child)

  setCCTVTamId(data: BasicInformationResponse) {
    this.cctvEmitter.emit(data);
  }

  getCCTVTamId(): EventEmitter<BasicInformationResponse> {
    return this.cctvEmitter;
  }
  //#endregion CCTV (Child)

  //#region Submission (Child)

  setSubmissionTamId(data: BasicInformationResponse) {
    this.submissionEmitter.emit(data);
  }

  getSubmissionTamId(): EventEmitter<BasicInformationResponse> {
    return this.submissionEmitter;
  }
  //#endregion Submission (Child)

  //#region Basic Information
  addBasicInformation(basicInformation: any): void {
    this.basicInformations.push(basicInformation);
    this.onRefreshBasicInformationList.next(this.basicInformations.slice());
  }
  //#endregion Basic Information

  //#region Schedule Inspection

  addScheduleInspection(scheduleInspection: any): void {
    this.scheduleInspections.push(scheduleInspection);
    this.onRefreshScheduleInspectionList.next(this.scheduleInspections.slice());
  }

  setScheduleInspection(scheduleInspections: any): void {
    this.scheduleInspections = scheduleInspections;
    this.onRefreshScheduleInspectionList.next(this.scheduleInspections.slice());
  }

  addInspectionOfficer(officer: InspectionOfficer): void {
    this.inspectionOfficers.push(officer);
    this.onRefreshInspectionOfficerList.next(this.inspectionOfficers.slice());
  }

  setInspectionOfficer(officers: InspectionOfficer[]): void {
    this.inspectionOfficers = officers;
    this.onRefreshInspectionOfficerList.next(this.inspectionOfficers.slice());
  }
  //#endregion Schedule Inspection
 
  //#region Occupation List
  setOccupationList(list: InspectionResultResponse[]): void {
    this.occupationListEmitter.emit(list);
  }

  getOccupationList(): EventEmitter<InspectionResultResponse[]> {
    return this.occupationListEmitter;
  }
  //#endregion Occupation List

  //#region Basic Information Tab Data
  setBasicInfoData(data: BasicInfoData) {
    this.basicInfoDataEmitter.emit(data);
  }

  getBasicInfoData() {
    return this.basicInfoDataEmitter;
  }
  //#endregion Basic Information Tab Data

  //#region occupation list emitter
  setNewOccupationData(inspectionId: number) {
    this.addNewOccupationEmitter.emit(inspectionId);
  }

  getNewOccupationData(): EventEmitter<number> {
    return this.addNewOccupationEmitter;
  }
  //#endregion occupation list emitter

  //#region reset all tabs
  setResetForm(data: boolean) {
    this.formResetEmitter.emit(data);
  }

  getResetForm(): EventEmitter<any> {
    return this.formResetEmitter;
  }
  //#endregion reset all tabs

  //#region set inspection id for new occupation
  setInspectionIdForNewOccupation(id: number) {
    this.inspectionIdForNewOccupation.emit(id);
  }

  getInspectionIdForNewOccupation(): EventEmitter<number> {
    return this.inspectionIdForNewOccupation;
  }

  //#endregion set inspection id for new occupation

  //#region Submission (Child)

  setSubmissionData(submissionData:BasicInformationResponse) {
    this.submissionDataEmitter.emit(submissionData);
  }

  getSubmissionData(): EventEmitter<BasicInformationResponse> {
    return this.submissionDataEmitter;
  }
  //#endregion Submission (Child)

  
  //#region Recommandation 
  
  setRecommandation(recommandations: Recommendation[]): void {
    this.recommandations = recommandations;
    this.onRefreshRecommandationList.next(this.recommandations.slice());
  }
  //#endregion Recommandation 

  //#region PrevButton 
  
  setPreviousBtn(): void {    
    this.previousBtnEmitter.emit(true);
    }
 
    getPreviousBtn():EventEmitter<boolean>{
       return this.previousBtnEmitter;
    }
   //#endregion PrevButton 
 

}
