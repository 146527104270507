import {HttpClient, HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {applicationUrls} from 'src/app/shared/application-constants/application-urls.const';
import {ServerResponse} from 'src/app/shared/models/dto/server-response.dto';
import {FinancingApplicationSearch} from '../models/DTO/financing-application-search';
import {FinanceMainDto} from "../../../shared/models/common.model";

@Injectable({
  providedIn: 'root',
})
export class FinancialStorageService {
  constructor(private httpClient: HttpClient) {
  }

  previousButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  nextButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  previousButtonEmitterPTP: EventEmitter<boolean> = new EventEmitter<boolean>();
  nextButtonEmitterPTP: EventEmitter<boolean> = new EventEmitter<boolean>();

  getFinancingMainByCircularIdAndStpMainId(
    circularId: number,
    stpMainId: number
  ): Observable<any> {
    const params = new HttpParams()
      .append('circularId', `${circularId}`)
      .append('stpMainId', `${stpMainId}`);
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingMain.getFinancingMainByCircularIdAndStpMainId}`,
      {params}
    );
  }

  getTamMains(
    occupationName: string,
    tamType?: number
  ): Observable<ServerResponse> {
    let url = applicationUrls.tamMain.readTamMains;

    if (occupationName && tamType) {
      url += '?occupationName=' + occupationName + '&tamType=' + tamType;
    } else if (occupationName) {
      url += '?occupationName=' + occupationName;
    } else if (tamType) {
      url += '?tamType=' + tamType;
    }

    return this.httpClient.get<ServerResponse>(url).pipe(
      map((serverResponse: ServerResponse) => {
        return serverResponse;
      })
    );
  }

  getFinancingApplicationNotificationByStpMainId(stpMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingMain.getFinancingApplicationNotificationByStpMainId}/${stpMainId}`
    );
  }

  getApplicationStatusList(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.finance.getApplicationStatusList}`);
  }


  getRecommendationStatusList(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.finance.getRecommendationStatusList}`);
  }

  //#region Stp Information Tab
  getStpInfoByCircularIdAndStpMainId(
    circularId: number,
    stpMainId: number
  ): Observable<any> {
    const params = new HttpParams()
      .append('circularId', `${circularId}`)
      .append('stpMainId', `${stpMainId}`);
    return this.httpClient.get<any>(
      `${applicationUrls.finance.stpInformation.getStpInfoByCircularIdAndStpMainId}`,
      {params}
    );
  }

  getStpMainInfo(): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.stpInformation.getStpMainInfo}`
    );
  }

  getStpInfrastructureInfoByStpMainId(stpMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.stpInformation.getStpInfrastructureInfoByStpMainId}/${stpMainId}`
    );
  }

  getStpOwnershipTypeById(stpOwnershipTypeId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.stpInformation.getStpOwnershipTypeById}/${stpOwnershipTypeId}`
    );
  }

  getUpazilaById(upazilaId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.upazila.getUpazilaById}/${upazilaId}`
    );
  }

  saveStpInfoForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.stpInformation.saveStpInfo}`,
      formData
    );
  }

  updateStpInfoForm(formData: FormData): Observable<any> {
    return this.httpClient.put<any>(
      `${applicationUrls.finance.stpInformation.updateStpInfo}`,
      formData
    );
  }

  //#endregion

  //#region Proposed Training Information Tab
  getProposedTrainingProgramInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.proposedTrainingProgram.getProposedTrainingProgramInfoById}/${id}`
    );
  }

  getProposedTrainingProgramList(
    financingMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedTrainingProgram.getProposedTrainingProgramList}`,
      pageableRequestDTO
    );
  }

  saveGeneralCourseInfoForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedTrainingProgram.saveGeneralCourseInfo}`,
      formData
    );
  }

  getApprovedCourseCenter(stpMainId: number, tamMainId: number): Observable<any> {
    const params = new HttpParams()
      .append('stpMainId', `${stpMainId}`)
      .append('tamMainId', `${tamMainId}`);

    return this.httpClient.get<any>(
      `${applicationUrls.inspection.getApprovedCourseCenter}`,
      {params}
    );
  }

  // getApprovedAssessmentCenter(
  //   stpMainId: number,
  //   tamMainId: number
  // ): Observable<any> {
  //   const params = new HttpParams()
  //     .append('stpMainId', `${stpMainId}`)
  //     .append('tamMainId', `${tamMainId}`);

  //   return this.httpClient.get<any>(
  //     `${applicationUrls.inspection.getApprovedAssessmentCenter}`,
  //     { params }
  //   );
  // }

  saveProposedTrainingProgramForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedTrainingProgram.saveProposedTrainingProgramInfo}`,
      formData
    );
  }

  deleteProposedTrainingProgramInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${applicationUrls.finance.proposedTrainingProgram.deleteProposedTrainingProgramInfoById}/${id}`
    );
  }

  //#endregion

  //#region Proposed Course Instructor Tab
  getTrainerListByStpMainId(stpMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.proposedCourseInstructor.getTrainerListByStpMainId}/${stpMainId}`
    );
  }

  getProposedCourseInstructorInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.proposedCourseInstructor.getProposedCourseInstructorInfoById}/${id}`
    );
  }

  getProposedCourseInstructorList(
    financingMainId: number,
    tamMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      tamMainId: tamMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedCourseInstructor.getProposedCourseInstructorList}`,
      pageableRequestDTO
    );
  }

  getProposedCourseInstructorListByFinanceMain(
    financingMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedCourseInstructor.getProposedCourseInstructorListByFinanceMain}`,
      pageableRequestDTO
    );
  }

  saveProposedCourseInstructorForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedCourseInstructor.saveProposedCourseInstructorInfo}`,
      formData
    );
  }

  deleteProposedCourseInstructorInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${applicationUrls.finance.proposedCourseInstructor.deleteProposedCourseInstructorInfoById}/${id}`
    );
  }

  //#endregion

  //#region Foreign Language Training Tab
  getForeignLanguageTrainingInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.foreignLanguageTraining.getForeignLanguageTrainingInfoById}/${id}`
    );
  }

  getForeignLanguageTrainingList(
    financingMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.foreignLanguageTraining.getForeignLanguageTrainingList}`,
      pageableRequestDTO
    );
  }

  saveForeignLanguageTrainingForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.foreignLanguageTraining.saveForeignLanguageTrainingInfo}`,
      formData
    );
  }

  deleteForeignLanguageTrainingInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${applicationUrls.finance.foreignLanguageTraining.deleteForeignLanguageTrainingInfoById}/${id}`
    );
  }

  //#endregion

  //#region Organization Past Information Tab
  getOrganizationPastInfoByFinancingMainId(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.organizationPastInfo.getOrganizationPastInfoByFinancingMainId}/${id}`
    );
  }

  saveOrganizationPastInfoForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.organizationPastInfo.saveOrganizationPastInfo}`,
      formData
    );
  }

  //#endregion

  //#region Training Program Implementation Plan Tab
  getTrainingProgramImplPlanInfoByTamMainIdAndFinanceMainId(
    tamMainId: number,
    financeMainId: number
  ): Observable<any> {
    const params = new HttpParams()
      .append('tamMainId', `${tamMainId}`)
      .append('financeMainId', `${financeMainId}`);

    return this.httpClient.get<any>(
      `${applicationUrls.finance.trainingProgramImplPlan.getTrainingProgramImplPlanInfoByTamMainIdAndFinanceMainId}`,
      {params}
    );
  }

  getTrainingProgramImplPlanInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.trainingProgramImplPlan.getTrainingProgramImplPlanInfoById}/${id}`
    );
  }

  getTrainingProgramImplPlanList(
    financingMainId: number,
    tamMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      tamMainId: tamMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.trainingProgramImplPlan.getTrainingProgramImplPlanList}`,
      pageableRequestDTO
    );
  }

  getTrainingProgramImplPlanListByFinanceMain(
    financingMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.trainingProgramImplPlan.getTrainingProgramImplPlanListByFinanceMain}`,
      pageableRequestDTO
    );
  }

  saveTrainingProgramImplPlanForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.trainingProgramImplPlan.saveTrainingProgramImplPlanInfo}`,
      formData
    );
  }

  deleteTrainingProgramImplPlanInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${applicationUrls.finance.trainingProgramImplPlan.deleteTrainingProgramImplPlanInfoById}/${id}`
    );
  }

  //#endregion

  //#region Proposed Budget Statement Tab
  getProposedBudgetStatementInfoByTamMainIdAndFinanceMainId(
    tamMainId: number,
    financeMainId: number
  ): Observable<any> {
    const params = new HttpParams()
      .append('tamMainId', `${tamMainId}`)
      .append('financeMainId', `${financeMainId}`);
    return this.httpClient.get<any>(
      `${applicationUrls.finance.proposedBudgetStatementInfo.getProposedBudgetStatementInfoByTamMainIdAndFinanceMainId}`,
      {params}
    );
  }

  getProposedTrainingProgramInfo(
    tamMainId: number,
    financeMainId: number
  ): Observable<any> {
    const params = new HttpParams()
      .append('tamMainId', `${tamMainId}`)
      .append('financeMainId', `${financeMainId}`);

    return this.httpClient.get<any>(
      `${applicationUrls.finance.proposedTrainingProgram.getProposedTrainingProgramInfoByTamMainIdAndFinanceMainId}`,
      {params}
    );
  }

  getProposedBudgetStatementInfoList(
    financingMainId: number,
    tamMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      tamMainId: tamMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedBudgetStatementInfo.getProposedBudgetStatementInfoList}`,
      pageableRequestDTO
    );
  }

  getProposedBudgetStatementInfoListByFinanceMain(
    financingMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedBudgetStatementInfo.getProposedBudgetStatementInfoListByFinanceMain}`,
      pageableRequestDTO
    );
  }

  getProposedBudgetStatementInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.proposedBudgetStatementInfo.getProposedBudgetStatementInfoById}/${id}`
    );
  }

  saveProposedBudgetStatementInfoForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.proposedBudgetStatementInfo.saveProposedBudgetStatementInfo}`,
      formData
    );
  }

  deleteProposedBudgetStatementInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${applicationUrls.finance.proposedBudgetStatementInfo.deleteProposedBudgetStatementInfoById}/${id}`
    );
  }

  //#endregion

  //#region Ongoing Training Tab
  getOngoingTrainingInfoById(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.ongoingTrainingInfo.getOngoingTrainingInfoById}/${id}`
    );
  }

  getOngoingTrainingInfoList(
    financingMainId: number,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<any> {
    let pageableRequestDTO = {
      financingMainId: financingMainId,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<any>(
      `${applicationUrls.finance.ongoingTrainingInfo.getOngoingTrainingInfoList}`,
      pageableRequestDTO
    );
  }

  saveOngoingTrainingForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.ongoingTrainingInfo.saveOngoingTrainingInfo}`,
      formData
    );
  }

  deleteOngoingTrainingInfoById(id: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${applicationUrls.finance.ongoingTrainingInfo.deleteOngoingTrainingInfoById}/${id}`
    );
  }

  //#endregion

  //#region Financial Info Tab
  getFinancialInformationByFinanceMainId(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financialInfo.getFinancialInformationByFinanceMainId}/${id}`
    );
  }

  saveFinancialInfoForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.financialInfo.saveFinancialInfo}`,
      formData
    );
  }

  //#endregion

  //#region Capacity And Previous Experience Tab
  getCapacityAndPrevExpInfoByFinanceMainId(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.capacityAndPrevExpInfo.getCapacityAndPrevExpInfoByFinanceMainId}/${id}`
    );
  }

  saveCapacityAndPrevExperienceInfoForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.capacityAndPrevExpInfo.saveCapacityAndPrevExperienceInfo}`,
      formData
    );
  }

  //#endregion

  //#region Truth Declaration Sign Seal Tab
  getTruthDeclarationSignSealByFinanceMainId(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.truthDeclarationSignSealInfo.getTruthDeclarationSignSealByFinanceMainId}/${id}`
    );
  }

  saveTruthDeclarationSignSealInfoForm(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.finance.truthDeclarationSignSealInfo.saveTruthDeclarationSignSealInfo}`,
      formData
    );
  }

  //#endregion

  submitFinancingMain(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingMain.submitFinancingMain}`, formData);
  }

  checkApplicationSubmissionValidation(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingMain.checkApplicationSubmissionValidation}`, formData);
  }

  getAllFinancingMainByStpMainId(stpMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingMain.getAllFinancingMainByStpMainId}/${stpMainId}`
    );
  }

  checkAllProposedBudgetStatementInfoEntryCompleted(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.proposedBudgetStatementInfo.checkAllProposedBudgetStatementInfoEntryCompletedByFinanceMain}/${financeMainId}`
    );
  }

  getUserProfile(id: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.user.getUserProfile}${id}`);
  }

  //#region Financing Approval Process
  getFinancingMainInfoByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingMain.getFinancingMainInfoByFinanceMainId}/${financeMainId}`
    );
  }

  getOrganizationOverallPastInfoByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getOrganizationOverallPastInfoByFinanceMainId}/${financeMainId}`
    );
  }

  getOngoingTrainingInfoByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.ongoingTrainingInfo.getOngoingTrainingInfoByFinanceMainId}/${financeMainId}`
    );
  }

  getProposedTrainingProgramOverallBudgetInfoByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getProposedTrainingProgramOverallBudgetInfoByFinanceMainId}/${financeMainId}`
    );
  }

  getNsdaLevel1ApprovalInfoByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getNsdaLevel1ApprovalInfoByFinanceMainId}/${financeMainId}`
    );
  }

  saveNsdaLevel1ApprovalInfo(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.saveNsdaLevel1ApprovalInfo}`, financingApproval);
  }

  submitNsdaLevel1ApprovalInfo(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.submitNsdaLevel1ApprovalInfo}`, financingApproval);
  }

  requestForCorrection(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.requestForCorrection}`, financingApproval);
  }

  getNsdaLevel1ApprovalInfoForNsdaLevel2ByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getNsdaLevel1ApprovalInfoForNsdaLevel2ByFinanceMainId}/${financeMainId}`
    );
  }

  getNsdaLevel2ApprovalInfoByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getNsdaLevel2ApprovalInfoByFinanceMainId}/${financeMainId}`
    );
  }

  saveNsdaLevel2ApprovalInfo(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.saveNsdaLevel2ApprovalInfo}`, financingApproval);
  }

  submitNsdaLevel2ApprovalInfo(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.submitNsdaLevel2ApprovalInfo}`, financingApproval);
  }

  requestForRollback(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.requestForRollback}`, financingApproval);
  }

  getNsdaLevel2ApprovalInfoForNhrdfLevel1ByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getNsdaLevel2ApprovalInfoForNhrdfLevel1ByFinanceMainId}/${financeMainId}`
    );
  }

  getNhrdfLevel1ApprovalInfoByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getNhrdfLevel1ApprovalInfoByFinanceMainId}/${financeMainId}`
    );
  }

  saveNhrdfLevel1ApprovalInfo(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.saveNhrdfLevel1ApprovalInfo}`, financingApproval);
  }

  submitNhrdfLevel1ApprovalInfo(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.submitNhrdfLevel1ApprovalInfo}`, financingApproval);
  }

  getNhrdfLevel1ApprovalInfoForNhrdfLevel2ByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getNhrdfLevel1ApprovalInfoForNhrdfLevel2ByFinanceMainId}/${financeMainId}`
    );
  }

  getNhrdfLevel2ApprovalInfoByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.financingApproval.getNhrdfLevel2ApprovalInfoByFinanceMainId}/${financeMainId}`
    );
  }

  saveNhrdfLevel2ApprovalInfo(financingApproval: any): Observable<any> {
    return this.httpClient.post<any>(`${applicationUrls.finance.financingApproval.saveNhrdfLevel2ApprovalInfo}`, financingApproval);
  }
  //#endregion

  setPreviousButton(): void {
    this.previousButtonEmitter.emit(true);
  }

  getPreviousButton(): EventEmitter<boolean> {
    return this.previousButtonEmitter;
  }

  setNextButton(): void {
    this.nextButtonEmitter.emit(true);
  }

  getNextButton(): EventEmitter<boolean> {
    return this.nextButtonEmitter;
  }

  setPreviousButtonPTP(): void {
    this.previousButtonEmitterPTP.emit(true);
  }

  getPreviousButtonPTP(): EventEmitter<boolean> {
    return this.previousButtonEmitterPTP;
  }

  setNextButtonPTP(): void {
    this.nextButtonEmitterPTP.emit(true);
  }

  getNextButtonPTP(): EventEmitter<boolean> {
    return this.nextButtonEmitterPTP;
  }

  getFinancingApplicationListAdmin(dataSet: any, page: number, size: number, sortingKey?: string, sortingValue?: string): Observable<ServerResponse> {
    let pageableFinancingMainListPayload = {
      pageNumber: page - 1,
      pageSize: size,
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null,
      generatedCircularId: dataSet?.generatedCircularId,
      applicationId: dataSet?.applicationId,
      applicationStatusTypeId: dataSet?.applicationStatusTypeId,
      applicationStartDate: dataSet?.applicationStartDate,
      applicationEndDate: dataSet?.applicationEndDate
    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.financingMain.getFinancingApplicationListAdmin}`, pageableFinancingMainListPayload
    );
  }

  getFinancingApplicationListNhrdf(dataSet: any, page: number, size: number, sortingKey?: string, sortingValue?: string): Observable<ServerResponse> {
    let pageableFinancingMainListPayload = {
      pageNumber: page - 1,
      pageSize: size,
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null,
      generatedCircularId: dataSet?.generatedCircularId,
      applicationId: dataSet?.applicationId,
      applicationStatusTypeId: dataSet?.applicationStatusTypeId
      // applicationStartDate: dataSet?.applicationStartDate,
      // applicationEndDate: dataSet?.applicationEndDate
    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.financingMain.getFinancingApplicationListNhrdf}`, pageableFinancingMainListPayload
    );
  }

  getResolutionListAdmin(dataSet: any, page: number, size: number, sortingKey?: string, sortingValue?: string): Observable<ServerResponse> {
    let pageableResolutionListPayload = {
      page: page - 1,
      size: size,
      sortingKey: sortingKey || null,
      sortingValue: sortingValue || null,
      financingMainId: dataSet?.financingMainId || null,
      circularId: dataSet?.circularId || null,
      stpMainId: dataSet?.stpMainId || null,
      memorandumNo: dataSet?.memorandumNo || null,
      resolutionLevelId: dataSet?.resolutionLevelId || null,
    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.resolution.getResolutionList}`, pageableResolutionListPayload
    );
  }

  getFinanceMainList(page: number, size: number, sortingKey: string, sortingValue: string, financingApplicationSearch: FinancingApplicationSearch) {
    let financeMainList = {
      page: page - 1,
      size: size,
      sortingKey: sortingKey || null,
      sortingValue: sortingValue || null,
      circularId: financingApplicationSearch.circularId,
      applicationId: financingApplicationSearch.applicationId

    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.resolution.getFinanceMainList}`, financeMainList
    );
  }

  saveResolution(data: any): Observable<any> {
    const transformedList: FinanceMainDto[] = data.financeMainDtoList.map((item: {
      id: number,
      circularInfo: { id: number },
      stpMainId: number
    }) => ({
      financeMainId: item.id,  // Extracting the financeMainId from the `id` field
      circularId: item.circularInfo.id,  // Extracting the circularId from the circularInfo object
      stpMainId: item.stpMainId  // Extracting the stpMainId from the stpMainId field
    }));

    const formData = new FormData();
    formData.append('memorandumNo', data.memorandumNo);
    const formattedDate = new Date(data.memorandumDate).toISOString(); // Converts to ISO string
    formData.append('memorandumDate', formattedDate);
    formData.append('attachmentTitle', data.attachmentTitle);

    // Append the file as a File object (ensure this is a File or Blob in the formData)
    if (data.attachmentUrl) {
      const file = data.attachmentUrl instanceof File ? data.attachmentUrl : new File([data.attachmentUrl], 'attachment.docx');
      formData.append('attachmentUrl', file);
    }

    // Loop through the transformedList and append each element as 'financeMainDtoList[0].financeMainId', etc.
    transformedList.forEach((item: FinanceMainDto, index: number) => {
      formData.append(`financeMainDtoList[${index}].financeMainId`, item.financeMainId.toString());
      formData.append(`financeMainDtoList[${index}].circularId`, item.circularId.toString());
      formData.append(`financeMainDtoList[${index}].stpMainId`, item.stpMainId.toString());
    });

    return this.httpClient.post<any>(applicationUrls.finance.resolution.saveResolution, formData);
  }

  saveResolutionIndv(data: any): Observable<any> {
    const formData = new FormData();
    formData.append('memorandumNo', data.memorandumNoIndv);
    const formattedDate = new Date(data.memorandumDateIndv).toISOString(); // Converts to ISO string
    formData.append('memorandumDate', formattedDate);
    formData.append('attachmentTitle', data.attachmentTitleIndv);
    formData.append('financeMainId', data.financeMainId);
    formData.append('circularId', data.circularId);
    formData.append('stpMainId', data.stpMainId);

    // Append the file as a File object (ensure this is a File or Blob in the formData)
    if (data.attachmentUrlIndv) {
      const file = data.attachmentUrlIndv instanceof File ? data.attachmentUrlIndv : new File([data.attachmentUrlIndv], 'attachment.docx');
      formData.append('attachmentUrl', file);
    }

    return this.httpClient.post<any>(applicationUrls.finance.resolution.saveResolutionIndividual, formData);
  }


  getIndvResList(page: number, size: number, sortingKey: string, sortingValue: string, financingMainId: number) {
    let pageableResolutionListPayload = {
      page: page - 1,
      size: size,
      sortingKey: sortingKey || null,
      sortingValue: sortingValue || null,
      financingMainId: financingMainId || null
    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.resolution.getResolutionIndvList}`, pageableResolutionListPayload
    );
  }

  getResolutionsByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${applicationUrls.finance.resolution.getResolutionsByFinanceMainId}/${financeMainId}`
    );
  }

  getApplicationHistoryListByFinanceMainId(financeMainId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.finance.financingMain.getApplicationHistoryListByFinanceMainId}/${financeMainId}`);
  }

  getCircularDDList(): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.finance.getCircularDDList}`);
  }

  getApplicationIdDDList(circularId: number): Observable<any> {
    return this.httpClient.get<any>(`${applicationUrls.finance.getApplicationIdDDList}/${circularId}`);
  }

  getCorrectionRequestAttachmentList(financeMainId: any) {
    let correctionRequestAttachmentListRequest = {
      financeMainId: financeMainId || null
    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.financingApproval.requestForCorrectionAttachmentList}`, correctionRequestAttachmentListRequest
    );
  }
  getcorrectionRequiredDocumentsList(financeMainId: any) {
    let correctionRequestAttachmentListRequest = {
      financeMainId: financeMainId || null
    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.financingApproval.correctionRequiredDocuments}`, correctionRequestAttachmentListRequest
    );
  }

  updateAttachment(data: any): Observable<any> {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('table', data.table);
    formData.append('columnName', data.columnName);
    formData.append('isCorrected', data.isCorrected);
    formData.append('financeMainId', data.financeMainId);

    if (data.attachmentUrl) {
      const file = data.attachmentUrl instanceof File ? data.attachmentUrl : new File([data.attachmentUrl], 'attachment.docx');
      formData.append('attachmentUrl', file);
    }



    return this.httpClient.post<any>(applicationUrls.finance.financingApproval.updateAttachment, formData);
  }

  submitAfterCorrection(financeMainId: any) {
    let correctionRequestAttachmentListRequest = {
      financeMainId: financeMainId || null
    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.financingApproval.submitAfterCorrection}`, correctionRequestAttachmentListRequest
    );
  }

  correctionApplicationResubmissionByFinanceMainId(financeMainId: any, permission: string) {
    let correctionRequestAttachmentListRequest = {
      financeMainId: financeMainId || null,
      permission: permission
    };

    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.finance.financingApproval.correctionApplicationResubmissionByFinanceMainId}`, correctionRequestAttachmentListRequest
    );
  }
}
