<nz-card nzTitle="Change Password" [nzExtra]="extraTemplate">
  <form nz-form [formGroup]="changePasswordForm" (ngSubmit)="submitForm()" [nzLayout]="'vertical'">

    <nz-form-item>
      <nz-form-label [nzSpan]="6" nzRequired>Old Password</nz-form-label>

          <nz-form-item>
            <nz-form-control [nzSpan]="6" nzHasFeedback nzAllowClear class="row">
              <nz-input-group [nzSuffix]="suffixTemplatePassword">
                <input nz-input [type]="passwordVisible ? 'text' : 'password'" autocomplete="new-password" placeholder="Please input the Old Password"
                formControlName="oldPassword"/>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <ng-template #suffixTemplatePassword>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
          </ng-template>

    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="6" nzRequired>New Password</nz-form-label>

          <nz-form-item>
            <nz-form-control [nzSpan]="6" nzHasFeedback nzAllowClear class="row" nzErrorTip="Password must be minimum 8 digits and must include one uppercase, one lowercase, one number and one special character !">
              <nz-input-group [nzSuffix]="suffixTemplatePassword">
                <input nz-input [type]="passwordVisible ? 'text' : 'password'" autocomplete="new-password" placeholder="Please input the New Password" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$"
                formControlName="newPassword"/>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <ng-template #suffixTemplatePassword>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
          </ng-template>

    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="6" nzRequired>Confirm New Password</nz-form-label>

        <nz-form-item>
          <nz-form-control [nzSpan]="6" nzHasFeedback nzAllowClear class="row" [nzErrorTip]="passwordErrorTpl">
            <nz-input-group [nzSuffix]="suffixTemplateReTypePassword">
              <input nz-input [type]="reTypePasswordVisible ? 'text' : 'password'" autocomplete="new-password" placeholder="Re-type the New Password"
              formControlName="confirmPassword"/>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <ng-template #suffixTemplateReTypePassword>
          <i nz-icon [nzType]="reTypePasswordVisible ? 'eye-invisible' : 'eye'" (click)="reTypePasswordVisible = !reTypePasswordVisible"></i>
        </ng-template>

        <ng-template #passwordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please confirm your password !
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            Password does not match !
          </ng-container>
        </ng-template>

    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzOffset]="5" [nzSpan]="14">
        <button class="mr-3" nz-button type="button" nzType="primary" nzDanger (click)="resetForm()">Clear Fields</button>
        <button nz-button type="submit" nzType="primary" [disabled]="!changePasswordForm.valid"  nz-popconfirm nzPopconfirmTitle="Are you sure you want to change the password?" (nzOnConfirm)="submitForm()"><i nz-icon nzType="save"
            nzTheme="fill"></i>{{'Submit'}}</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-card>
<ng-template #extraTemplate>
  <a></a>
</ng-template>


