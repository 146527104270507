import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { CadEquipment } from '../../../models/cad-equipment.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';
import { AddCadEquipmentComponent } from '../add-cad-equipment/add-cad-equipment.component';

@Component({
  selector: 'app-cad-equipment-table',
  templateUrl: './cad-equipment-table.component.html',
  styleUrls: ['./cad-equipment-table.component.scss'],
})
export class CadEquipmentTableComponent implements OnInit, OnDestroy {
  // @Input() tamMainId: string | null;
  // @Input() createButton: boolean;

  // modalRef: NzModalRef;
  // equipmentList: CadEquipment[] = [];
  // onCadEquipmentSubscription$: Subscription;
  @Input() tamMainId: number | null;
  @Input() createButton: boolean;
  showingAction: boolean | null;

  modalRef: NzModalRef;
  cadList: CadEquipment[] = [];
  cadListFiltered: CadEquipment[] = [];
  onCadEquipmentSubscription$: Subscription;

  total: number;
  page = 1;
  size = 10;

  constructor(
    private modal: NzModalService,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private route: ActivatedRoute
  ) {
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
    const urlPath = String(this.route.snapshot.url[0].path);
    (urlPath.includes("tam-view")) ? this.showingAction = true : this.showingAction = false ;
    this.cadList = this.cadService.getCadEquipmentList(this.tamMainId);
    this.cadListFiltered = [...this.cadList];
    if (this.cadList.length === 0) {
      this.cadStorageService.readCadEquipmentListByTamId(this.tamMainId).subscribe();
    }
    this.onCadEquipmentSubscription$ =
      this.cadService.onRefreshCadEquipmentList.subscribe(
        (res) => {
          this.cadList = res;
          this.cadListFiltered = [...this.cadList];
        }
      );
  }

  ngOnInit(): void {
    this.loadCadFromServer();
  }

  loadCadFromServer() {
    if (!this.tamMainId) {
      this.notification.error(
        'Error',
        'Training and Assessment Material Id not found'
      );
      return;
    }

    // this.cadList = this.cadService.getCadEquipmentList(
    //   Number(this.tamMainId)
    // );

    // if (this.cadList.length == 0) {
    //   this.cadStorageService
    //     .readCadEquipmentListByTamId(Number(this.tamMainId), this.page, this.size)
    //     .subscribe({
    //       next: (res) => {
    //         this.cadList = res.data;
    //         this.total = res.count;
    //       },
    //     });
    // }

    // this.onCadEquipmentSubscription$ =
    //   this.cadService.onRefreshCadEquipmentList.subscribe((res) => {
    //     this.cadList = res.filter(
    //       (item) =>
    //         item.tamMainId == this.tamMainId ||
    //         item.tamMain.id == this.tamMainId
    //     );

    //     if (this.modalRef) {
    //       this.modalRef.close();
    //     }
    //   });
  }

  createCustomButtonModal(data? : any): void {
    this.modalRef = this.modal.create({
      nzContent: AddCadEquipmentComponent,
      nzClosable: false,
      nzOkText: null,
      nzWidth: 1000,
      nzComponentParams: {
        InputData: data
      }
    });
  }

  onEdit(id: number): void {
    this.cadService.setEditingCadEquipmentId(id);
    this.createCustomButtonModal(id);
  }

  onDelete(id: number): void {
    this.cadStorageService.deleteCadEquipment(id).subscribe({
      next: (response) => {
        this.notification.success('Success!', 'Staff deleted successfully');
        this.loadCadFromServer();
      },
      error: (error) => this.notification.error('Failed!', error),
    });
  }
  
  ngOnDestroy(): void {
    this.onCadEquipmentSubscription$.unsubscribe();
  }
}
